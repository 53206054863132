import { EDIT_TITLE } from '../constants/assignator';

const initialState = {
	title: '',
	type: '',
};

const reducer = function productReducer(state = initialState, action) {
	const newState = { ...state };
	switch (action.type) {
		case EDIT_TITLE:
			const { title, typeAssignation } = action;
			newState.title = title;
			newState.type = typeAssignation;
			return newState;

		default:
			return state;
	}
};

export default reducer;
