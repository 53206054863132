import React from 'react';
import Sortable from 'react-sortablejs';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SortableListMulti = React.memo(
	({ onChange, options, tag, style, id, className, children }) => {
		return (
			<Sortable
				options={{ ...options }}
				onChange={onChange}
				tag={tag}
				style={{ ...style }}
				id={id}
				className={classNames(
					'list',
					'stylizedList',
					'list_wrapper_grid',
					className
				)}>
				{children}
			</Sortable>
		);
	}
);

SortableListMulti.propTypes = {
	options: PropTypes.object,
	onChange: PropTypes.func,
	tag: PropTypes.string,
	style: PropTypes.object,
	id: PropTypes.string,
	className: PropTypes.string,
};

const defaultOpt = {
	group: 'shared',
	animation: 150,
};

SortableListMulti.defaultProps = {
	options: defaultOpt,
	tag: 'div',
};

export default SortableListMulti;
