import React, { Component } from 'react';
import { HotTable } from '@handsontable/react';
import find from 'lodash/find';
import 'handsontable/dist/handsontable.full.min.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import PriceCategoryRenderer from './handsonTable/cellRenderer/PriceCategoryRenderer';
import * as productAction from '../actions/product.action';
import * as notificationAction from '../actions/notification.action';
import { renderReactCell, MemoizedReactDomContainers } from 'react-lru';
import { TAGS } from '../constants/notification';
import moment from 'moment';
import { deepEqual, checkPrice } from '../utils';
import EditCell from './handsonTable/cellRenderer/EditCell';

const validators = {
	price: (value, callback) => {
		let result =
			value !== null && value !== undefined
				? parseFloat(
						value
							.toString()
							.replace(/,/, '.')
							.match(/^-?\d*(\.\d+)?$/)
				  ) >= 0
				: false;
		if (callback) {
			callback(result);
		} else {
			return result;
		}
	},
};

let canSelectCell = true;

const memoizedContainers = new MemoizedReactDomContainers(2000);

class ProductPriceList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: 0,
			height: 0,
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		const height =
			window.innerHeight ||
			document.documentElement.clientHeight ||
			document.body.clientHeight;

		const width =
			window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth;

		this.setState({ width, height });
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.width === 0 && nextState.height === 0) {
			return false;
		}
		if (deepEqual(this.props, nextProps) && deepEqual(this.state, nextState)) {
			return false;
		}
		return true;
	}

	updateDimensions = () => {
		const height =
			window.innerHeight ||
			document.documentElement.clientHeight ||
			document.body.clientHeight;

		const width =
			window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth;

		this.setState({ width, height });
	};

	alertWrongPrice = (productName = '') => {
		this.props.notificationAction.addNotification({
			tags: [TAGS.ERROR],
			text: I18n.t('product.wrongPrice', { productName: productName }),
			date: moment().format('DD/MM/YYYY HH:mm'),
			viewed: false,
			openable: false,
		});
	};

	render() {
		if (this.state.width === 0 && this.state.height === 0) {
			return null;
		}
		const { priceCategories, products } = this.props;
		const priceCategoriesIds = priceCategories.map(
			priceCategory => priceCategory.id
		);
		const dataFormated = products.map(product => {
			const prices = priceCategoriesIds.reduce((acc, priceCategoryId) => {
				acc[priceCategoryId] = product.price;
				let productPriceCategory =
					product && product.price != ''
						? product.prices.find(price => price.id === priceCategoryId)
						: null;

				if (productPriceCategory) {
					acc[priceCategoryId] = productPriceCategory.price;
				}

				return acc;
			}, {});
			let price = product.price;
			if (product.is_free_price) {
				price = I18n.t('product.form.free_price');
			}
			if (product.id_retail_price_type != '0') {
				let priceTypeValue = this.props.retailPriceTypes.filter(
					rpt => rpt.id === product.id_retail_price_type
				);
				if (priceTypeValue.length > 0) {
					priceTypeValue = priceTypeValue[0];
				} else {
					priceTypeValue = { unit: 'Kg' };
				}
				price += `/${I18n.t(priceTypeValue.unit)}`;
			}
			const formated = {
				id: product.id,
				name: product.name,
				price: price,
				...prices,
			};

			return formated;
		});
		const { width, height } = this.state;
		const widthWithoutLeftMenu = width - 120 - 196 - 16;
		/*const columnsWidth =
      (widthWithoutLeftMenu - 280) /
      (priceCategories ? priceCategories.length : 0);*/
		const columnsWidth = 80;
		const priceCategoriesName = priceCategories.map(
			priceCategory => priceCategory.name
		);
		const priceCategoriesRenderer = priceCategories.map(
			(priceCategory, index) => {
				return {
					data: priceCategory.id,
					editor: false,
					renderer: (instance, td, row, col, prop, value, cellProperties) => {
						const rowData = instance.getDataAtRow(row);
						renderReactCell({
							memoizedContainers,
							td,
							row,
							col,
							jsx: (
								<PriceCategoryRenderer
									cellProperties={cellProperties}
									products={products}
									id={rowData[0]}
									retailPriceTypes={this.props.retailPriceTypes}
									currentPrice={rowData[index + 3]}
									productAction={this.props.productAction}
									alertWrongPrice={this.alertWrongPrice}
									priceCategoryId={priceCategory.id}
								/>
							),
						});
						if (cellProperties.className !== undefined) {
							td.className = cellProperties.className;
						}
						return td;
					},
					validator: validators.price,
				};
			}
		);

		const columnSizes = priceCategories.map(priceCategory => columnsWidth);

		const options = {
			colHeaders: [
				'',
				I18n.t('product.name').toUpperCase(),
				I18n.t('product.price').toUpperCase(),
				...priceCategoriesName,
				'',
			],
			columns: [
				{
					data: 'id',
					type: 'text',
					readOnly: true,
				},
				{
					data: 'name',
					type: 'text',
					readOnly: true,
				},
				{
					data: 'price',
					type: 'text',
					validator: validators.price,
				},
				...priceCategoriesRenderer,
				{
					data: 'id',
					editor: false,
					renderer: (instance, td, row, col, prop, value, cellProperties) => {
						{
							renderReactCell({
								memoizedContainers,
								td,
								row,
								col,
								onEditClick: this.props.onEditClick,
								jsx: (
									<EditCell
										onEditClick={this.props.onEditClick}
										value={value}
										disabled={!this.props.canEdit}
									/>
								),
							});
							if (cellProperties.className !== undefined) {
								td.className = cellProperties.className;
							}
							return td;
						}
					},
				},
			],
			beforeChange: (changes, source) => {
				/*
				 * changes = [[rowIndex,priceCatId ,oldValue, newValue]]
				 */
				const dataChanges = JSON.parse(JSON.stringify(changes));

				if (dataChanges && dataChanges.length && dataChanges[0][1] == 'price') {
					const { ids, changes } = dataChanges.reduce(
						(acc, data) => {
							let price = data[3].replace(/,/g, '.');
							price = checkPrice(price);
							if (isNaN(price)) {
								this.alertWrongPrice(dataFormated[data[0]].name);
								return acc;
							}

							if (data[2] === price) {
								return acc;
							}
							const productId = dataFormated[data[0]].id;

							acc.ids.push(productId);
							acc.changes.push({ price });

							return acc;
						},
						{ ids: [], changes: [] }
					);

					this.props.productAction.updateProducts(
						ids,
						changes,
						ids.map(() => false)
					);
				} else {
					const productsChange = dataChanges.reduce((acc, data) => {
						if (data[1] === 'id') {
							return acc;
						}
						const isNumber = checkPrice(data[3]);
						if (isNaN(isNumber)) {
							this.alertWrongPrice(dataFormated[data[0]].name);
							return acc;
						}
						const productId = dataFormated[data[0]].id;

						const productPrices = acc[productId] ? acc[productId] : [];
						acc[productId] = [
							...productPrices,
							{
								id: data[1],
								price: isNumber,
								id_table: productId,
							},
						];

						return acc;
					}, {});
					if (!Object.keys(productsChange).length) {
						return false;
					}

					const productIds = Object.keys(productsChange);
					const prices = productIds.map(productId => {
						const product = products.find(p => p.id === productId);
						const pricesChange = productsChange[productId];
						const pricesChangeIds = productsChange[productId].map(
							price => price.id
						);

						const productPrices = product.prices.filter(
							price => !pricesChangeIds.includes(price.id)
						); //blindage id error si le user selectione la cellule avec le bouton edit

						/*let prices = [...pricesChange, ...productPrices].filter(
              price => price.id != "id"
            );*/ return {
							prices: [...pricesChange, ...productPrices],
						};
					});
					const entities = productIds.map(data => 'priceCategory');
					this.props.productAction.updateProducts(productIds, prices, entities);
				}

				//return false comme ça handson ne render pas les changes ils seront render lors de l'ajout dans redux (evite de render 2 fois)
				return false;
			},
			cells: function(row, col, prop) {
				var cellProperties = {};
				var visualRowIndex = this.instance.toVisualRow(row);
				const rowData = this.instance.getDataAtRow(visualRowIndex);
				if (!rowData[0]) {
					if (col === 2 && canSelectCell) {
						cellProperties.selected = true;
					}
					if (col !== 2 && col !== 9) {
						cellProperties.readOnly = true;
						cellProperties.editor = false;
						cellProperties.className = 'handson-disabled';
						cellProperties.renderer = 'text';
					}
				} else {
					const product = find(products, p => p.id === rowData[0]);
					if (!product.active) {
						cellProperties.className = 'handson-disabled';
					}
				}
				/* if (visualRowIndex === 0 && visualColIndex === 0) {
          cellProperties.readOnly = true;
        } */

				return cellProperties;
			},
			afterSelection: (r, c, r2, c2) => {
				const instance = this.tableWrapper.hotInstance;
				const setting = instance.getSettings();
				if (c == 1) {
					instance.updateSettings({
						fillHandle: false,
					});
				} else {
					if (setting.__proto__.fillHandle === false) {
						instance.updateSettings({
							fillHandle: {
								autoInsertRow: false,
							},
						});
					}
				}
			},
			/*afterUpdateSettings: settings => {
        const instance = this.tableWrapper.hotInstance;
        const selected = instance.getSelected(); // [[startRow, startCol, endRow, endCol]]
        if (
          selected &&
          (selected[0][1] === 1 ||
            selected[0][3] === 1 ||
            selected[0][3] === 2 ||
            selected[0][1] === 2)
        ) {
          if (settings.fillHandle !== false) {
            instance.updateSettings({
              fillHandle: false
            });
          }
        }
      },*/
			colWidths: [0.1, 200, 80, ...columnSizes],
			fillHandle: {
				autoInsertRow: false,
			},
			outsideClickDeselects: true,
			rowHeights: 30,
			autoColumnSize: false,
			autoRowSize: false,
			width: widthWithoutLeftMenu,
			height: height - 170,
			fixedColumnsLeft: 3,
			stretchH: 'none',
			//selectionMode: "single",
			renderAllRows: false,
			variableRowHeights: false,
			copyPaste: true,
			manualColumnResize: true,
		};

		//return <div>coucou</div>;
		return (
			<div className="priceCatTable">
				<HotTable
					ref={table => (this.tableWrapper = table)}
					data={dataFormated}
					settings={options}
				/>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		productAction: bindActionCreators(productAction, dispatch),
		notificationAction: bindActionCreators(notificationAction, dispatch),
	};
};

const mapStateToProps = state => {
	return {
		priceCategories: state.entity.priceCategory,
		retailPriceTypes: state.entity.retailPriceType,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductPriceList);
