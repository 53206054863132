export const RECEIVE_TAX = 'RECEIVE_TAX';
export const PRE_DELETE_TAX = 'PRE_DELETE_TAX';
export const DELETE_TAX = 'DELETE_TAX';
export const PRE_CREATE_TAX = 'PRE_CREATE_TAX';
export const CREATE_TAX = 'CREATE_TAX';
export const PRE_UPDATE_TAX = 'PRE_UPDATE_TAX';
export const UPDATE_TAX = 'UPDATE_TAX';
export const PRE_PATCH_TAX = 'PRE_PATCH_TAX';
export const PATCH_TAX = 'PATCH_TAX';
export const REFRESH_TAX_LIST = 'REFRESH_TAX_LIST';
export const PRE_REWEIGHT_TAX = 'PRE_REWEIGHT_TAX';
export const REWEIGHT_TAX = 'REWEIGHT_TAX';
export const DEFAULT_TAXES = ['def0-1', 'def0-2', 'def0-3', 'takeaway'];
