export const RECEIVE_TUTO = 'RECEIVE_TUTO';
export const START_TUTO = 'START_TUTO';
export const STOP_TUTO = 'STOP_TUTO';
export const START_TUTO_TAX = 'START_TUTO_TAX';
export const START_TUTO_PRODUCTS = 'START_TUTO_PRODUCTS';
export const STOP_TUTO_PRODUCTS = 'STOP_TUTO_PRODUCTS';
export const START_TUTO_SUPPLEMENT = 'START_TUTO_SUPPLEMENT';
export const STOP_TUTO_PRODUCT_MENU = 'STOP_TUTO_PRODUCT_MENU';
export const START_TUTO_PRODUCT_MENUS = 'START_TUTO_PRODUCT_MENUS';
export const START_TUTO_PRICE_CATEGORY = 'START_TUTO_PRICE_CATEGORY';
export const ACTIVE_TUTORIAL = 'ACTIVE_TUTORIAL';
