import {
	RECEIVE_VARIABLE_SERVER,
	UPDATE_ID_MENU_SELECTED,
	PRE_SET_SELECTED_DATE,
} from '../constants/variableServer';

const initialState = [];

const reducer = function variableServerReducer(state = initialState, action) {
	const newState = [...state];
	switch (action.type) {
		case RECEIVE_VARIABLE_SERVER:
			if (action.response.menuEmpty || action.response.result.length === 0) {
				return newState;
			}
			const variableServer = action.response.result;
			return variableServer;
		case UPDATE_ID_MENU_SELECTED:
			const variables = newState.map(object => {
				if (object.key_ === 'selected_menu') {
					object.value = action.response;
				}
				return object;
			});
			return variables;
		default:
			return state;
	}
};

export default reducer;
