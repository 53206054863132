import React from 'react';
import { styles } from './styles';
import { Translate } from 'react-redux-i18n';
import styled, { keyframes } from 'styled-components';

const stylesInterac = {
	buttonNext: {
		display: 'none',
	},
	buttonSkip: {
		display: 'none',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const stylesForTooltipBody = {
	buttonBack: {
		display: 'none',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const stylesButtonSave = {
	buttonClose: {
		display: 'none',
	},
	buttonNext: {
		display: 'none',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const styleBis = {
	buttonClose: {
		fontSize: '16px',
	},
	buttonSkip: {
		fontSize: '16px',
	},
	buttonBack: {
		fontSize: '16px',
	},
	buttonNext: {
		fontSize: '16px',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

export const steps = [
	{
		content: <Translate value="tutorial.category.step1.content" />,
		placement: 'center',
		disableBeacon: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.noDemo" />,
			close: <Translate value="tutorial.yesDemo" />,
		},
		target: 'body',
		title: <Translate value="tutorial.category.step1.title" />,
	},
	{
		content: <Translate value="tutorial.category.step2.content" />,
		placement: 'bottom',
		disableBeacon: false,
		placementBeacon: 'left',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
			back: <Translate value="tutorial.back" />,
		},
		styles: styleBis,
		target: '.name-container > div',
		title: <Translate value="tutorial.category.step2.title" />,
	},
	{
		content: <Translate value="tutorial.category.step3.content" />,
		placement: 'left',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
			back: <Translate value="tutorial.back" />,
		},
		styles: styleBis,
		target: '.color_category',
		title: <Translate value="tutorial.category.step3.title" />,
	},
	{
		content: <Translate value="tutorial.category.step4.content" />,
		placement: 'left',
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
			back: <Translate value="tutorial.back" />,
		},
		spotlightClicks: true,
		target: '.icon_category',
		title: <Translate value="tutorial.category.step4.title" />,
	},
	{
		content: <Translate value="tutorial.category.step5.content" />,
		placement: 'top',
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
			back: <Translate value="tutorial.back" />,
		},
		spotlightClicks: true,
		target: '.room_category',
		title: <Translate value="tutorial.category.step5.title" />,
	},
	{
		content: <Translate value="tutorial.category.step6.content" />,
		placement: 'top',
		styles: stylesButtonSave,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
			back: <Translate value="tutorial.back" />,
		},
		spotlightClicks: true,
		target: '.button_save_category',
		title: <Translate value="tutorial.category.step6.title" />,
	},
	{
		content: <Translate value="tutorial.category.step7.content" />,
		placement: 'right',
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
			back: <Translate value="tutorial.back" />,
		},
		spotlightClicks: true,
		target: '.stylizedList',
		title: <Translate value="tutorial.category.step7.title" />,
	},
	{
		content: <Translate value="tutorial.category.step8.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		locale: {
			close: <Translate value="tutorial.category.endDemoCategory" />,
		},
		styles: styleBis,
		target: '.stylizedList > div:first-child',
		title: <Translate value="tutorial.category.step8.title" />,
	},
];
