import React, { Component } from 'react';
import sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import Sortable from 'react-sortablejs';
import { connect } from 'react-redux';
import * as action from '../actions/product.action';
import { getProductsSorted } from '../selectors/product.selector';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import SortableListMulti from './Sort/SortableListMulti';
import ProductCategoryHelperItem from './Sort/ProductCategoryHelperItem';
import ItemPreviewerDraggable from './Sort/ItemPreviewerDraggable';
import { computeChanges, deepEqual } from '../utils';
import SideWindow from './SideWindow';
import ProductAssignator from './ProductAssignator';
import { getColors, getVariableServer } from '../selectors/global.selector';
import { getDefaultColor } from '../selectors/color.selector';
import { compare } from 'compare-versions';
class ProductCategoryList extends Component {
	constructor(props) {
		super(props);
		const { products, categoryId } = this.props;
		const sortedProductsWithSpace = this.getProductsSortedFormatedWithSpace(
			products,
			categoryId,
			true
		);

		this.state = {
			productsFormated: sortedProductsWithSpace,
			isDragging: false,
			SideWindowpen: false,
			allProducts: [],
			productsNoVisible: [],
			assignFromWeight: 0,
			helperSize: {
				width: 200,
				height: 140,
			},
		};

		this.assignProductsToCategory = this.assignProductsToCategory.bind(this);
		this.checkProductsActive = this.checkProductsActive.bind(this);
		this.closeSideWindow = this.closeSideWindow.bind(this);
		this.computeChanges = this.computeChanges.bind(this);
		this.setHelperSize = this.setHelperSize.bind(this);
		this.setIsDragging = this.setIsDragging.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.changeOrder = this.changeOrder.bind(this);
	}

	handleProductClick = product => {
		this.props.onEditClick(product.id);
	};

	setIsDragging() {
		this.setState({ isDragging: !this.state.isDragging });
	}

	formatProduct(products, categoryId) {
		return products.map(product => {
			const productCategory = _find(
				product.product_category_weight,
				cat => cat.idCategory === categoryId
			);
			const productWeight = productCategory.productWeight;
			return {
				active: product.active,
				name: product.name,
				price: product.price,
				backgroundColor: product.id_color || this.props.defaultColor,
				color: product.color_color,
				id: product.id,
				weight: productWeight,
				isSpace: false,
				type: '',
				is_free_price: product.is_free_price,
				id_retail_price_type: product.id_retail_price_type,
				canDelete: true,
			};
		});
	}

	getProductForSpecifiedCategoryId(products, categoryId) {
		return products.filter(product => {
			if (product.categories.indexOf(categoryId) > -1) {
				return product;
			}
		});
	}

	sortProductByWeight(products) {
		return sortBy(products, ['weight']);
	}

	addSpacesBetweenProduct(products) {
		let productsWithSpace = [];
		products.map((product, index) => {
			const nextIndex = index + 1;
			if (index === 0 && products[0].weight > 0) {
				for (let i = 0; i < products[0].weight; i++) {
					productsWithSpace.push({
						name: '',
						price: 0,
						weight: i,
						backgroundColor: '#f3f3f3',
						isSpace: true,
						type: '',
						canDelete: true,
					});
				}
			}

			productsWithSpace.push(product);
			const nextProduct = products[nextIndex];
			if (nextProduct) {
				const diffWeight = nextProduct.weight - product.weight;
				const hasSpace = diffWeight > 1;

				if (hasSpace) {
					for (let i = 1; i <= diffWeight - 1; i++) {
						productsWithSpace.push({
							name: '',
							price: 0,
							weight: product.weight + i,
							backgroundColor: '#f3f3f3',
							isSpace: true,
							type: '',
							canDelete: true,
						});
					}
					return;
				}
			}
		});
		return productsWithSpace;
	}

	getProductsSortedFormatedWithSpace(products, categoryId, fromConstructor) {
		const filteredProduct = this.getProductForSpecifiedCategoryId(
			products,
			categoryId
		);
		const filteredAndformatedProduct = this.formatProduct(
			filteredProduct,
			categoryId
		);
		const filteredAndformatedAndsortedProducts = this.sortProductByWeight(
			filteredAndformatedProduct
		);
		const productsWithSpaces = this.addSpacesBetweenProduct(
			filteredAndformatedAndsortedProducts
		);
		const productsNoVisible = productsWithSpaces.filter(
			product => !product.active && !product.isSpace
		);
		if (!fromConstructor) {
			this.setState({
				allProducts: productsWithSpaces,
				productsNoVisible: productsNoVisible,
			});
		}

		if (this.props.version208) {
			return productsWithSpaces;
		} else {
			return this.deleteNoActiveProduct(productsWithSpaces);
		}
	}

	deleteNoActiveProduct(products) {
		let p = products.filter(product => product.active || product.isSpace);
		//delete space at the end
		for (let i = p.length - 1; i >= 0; i--) {
			if (p[i].isSpace) {
				p.pop();
			} else {
				break;
			}
		}
		return p;
	}

	computeChanges(order) {
		let hasAddedSpace = false;
		return order.reduce((acc, item, index) => {
			let flag = false;
			if (item === '2,1' || item === '2,2') {
				item = 'space,' + index;
				flag = true;
			}
			let itemSplitted = item.split(',');
			if (hasAddedSpace) {
				index -= 1;
			}
			let newWeight = this.state.productsFormated[index].weight;
			if (hasAddedSpace) {
				newWeight += 1;
			}
			if (itemSplitted[0] !== 'space' && newWeight != itemSplitted[1]) {
				const goodItem = _find(
					this.state.productsFormated,
					item => item.id === itemSplitted[2]
				);
				acc.push({ id: goodItem.id, weight: newWeight });
			}
			if (flag) {
				hasAddedSpace = true;
			}
			return acc;
		}, []);
	}

	handleChange(order, sortable, e) {
		e.preventDefault();
		const { productsNoVisible } = this.state;
		const newOrder = productsNoVisible.length
			? this.changeOrder(order, productsNoVisible)
			: order;
		const changes = computeChanges(newOrder, this.state.allProducts);
		if (order.indexOf('2,2') > -1) {
			this.setState({ SideWindowpen: true, assignFromWeight: order });
		}
		if (changes.length > 0) {
			this.props.action.reweightProducts(changes, this.state.categoryId);
		}
	}

	changeOrder(oldOrder, productsNoVisible) {
		let productsNoDisplay = [];

		productsNoVisible.map(product => {
			let order = `1,${product.weight},${product.id}`;
			productsNoDisplay.push(order);
		});

		const newWeight = [...oldOrder, ...productsNoDisplay];
		return newWeight;
	}

	handleRemove(e, id, weight, isSpace) {
		e.preventDefault();
		const changes = this.state.allProducts.reduce((acc, product, index) => {
			if (index <= weight) {
				if (id === product.id && !product.isSpace) {
					acc.push({ id: product.id, weight: -1 });
				}
				return acc;
			}
			if (isSpace && !product.isSpace) {
				acc.push({ id: product.id, weight: product.weight - 1 });
			}
			return acc;
		}, []);
		if (changes.length > 0) {
			this.props.action.reweightProducts(changes, this.state.categoryId);
		}
	}

	closeSideWindow() {
		this.setState({ SideWindowpen: false });
	}

	setHelperSize(bound) {
		this.setState({ helperSize: bound });
	}

	componentWillReceiveProps(nextProps) {
		const { products, categoryId } = nextProps;
		const sortedProductsWithSpace = this.getProductsSortedFormatedWithSpace(
			products,
			categoryId
		);

		this.setState({
			productsFormated: sortedProductsWithSpace,
			categoryId: nextProps.categoryId,
		});
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (deepEqual(this.state, nextState) && deepEqual(nextProps, this.props)) {
			return false;
		}

		return true;
	}

	async assignProductsToCategory(e, productIds) {
		e.preventDefault();

		const actualCategoryProducts = this.props.products.reduce((acc, p) => {
			if (p.categories.includes(this.state.categoryId)) {
				acc.push(p.id);
			}
			return acc;
		}, []);

		const desassignOldValues = actualCategoryProducts
			.filter(id => !productIds.includes(id))
			.map(id => ({ id: id, weight: -1 }));

		if (desassignOldValues.length > 0) {
			this.props.action.reweightProducts(
				desassignOldValues,
				this.state.categoryId
			);
		}

		const assignProductIds = productIds.filter(
			id => !actualCategoryProducts.includes(id)
		);

		if (assignProductIds && assignProductIds.length) {
			const productsCategories = assignProductIds.reduce(
				(acc, productId, index) => {
					const product = _find(
						this.props.products,
						product => product.id === productId
					);
					const newProductCategories = [
						...product.categories,
						this.props.categoryId,
					];
					acc.push({ categories: newProductCategories });

					return acc;
				},
				[]
			);

			const assign = {
				ids: assignProductIds,
				assignNewValues: productsCategories,
			};
			this.checkProductsActive(assignProductIds);

			this.props.action.assignAndReweightProducts(
				assign,
				this.state.assignFromWeight,
				this.props.categoryId
			);
		}

		this.setState({ SideWindowpen: !this.state.SideWindowpen });
	}

	checkProductsActive(productIds) {
		const products = this.props.products;
		let weightProducts = this.state.assignFromWeight;
		let isInActive = productIds.some(id => {
			return products.some(product => {
				return product.id === id && !product.active;
			});
		});

		if (isInActive) {
			const index = weightProducts.indexOf('2,2');
			if (index > -1) weightProducts.splice(index, 1);
			weightProducts.push('2,2');
		}

		this.setState({ assignFromWeight: weightProducts });
	}

	render() {
		const { productsFormated, SideWindowpen } = this.state;
		const actualCategoryProducts = this.props.products.reduce((acc, p) => {
			if (p.categories.includes(this.state.categoryId)) {
				acc.push(p);
			}
			return acc;
		}, []);
		const helperItems = [
			{
				name: I18n.t('product.space'),
				price: '',
				backgroundColor: '#f3f3f3',
				isSpace: true,
				type: 'option',
				weight: '1',
			},
			{
				name: I18n.t('product.assign'),
				price: '',
				backgroundColor: '#f3f3f3',
				isSpace: true,
				type: 'option',
				weight: '2',
			},
		];
		return (
			<div id={'product-category-list'} className={'root'}>
				<SortableListMulti
					id={'product-category-sortable-product'}
					style={{
						width: 'calc( 100% - ' + this.state.helperSize.width + 'px)',
					}}
					options={{
						group: { name: 'product', put: ['helper'] },
						bubbleScroll: true,
					}}
					onChange={this.handleChange}
					className={this.state.isDragging ? 'highlight' : ''}>
					<ItemPreviewerDraggable
						onSize={this.setHelperSize}
						size={this.state.helperSize}
						items={productsFormated}
						onClickRemoveItem={this.handleRemove}
						onClick={this.handleProductClick}
						colors={this.props.colors}
					/>
				</SortableListMulti>
				<SortableListMulti
					id={'product-category-sortable-helper'}
					options={{ group: { name: 'helper' }, bubbleScroll: true }}
					style={{ width: this.state.helperSize.width, paddingLeft: 5 }}>
					<ProductCategoryHelperItem
						size={this.state.helperSize}
						items={helperItems}
						setIsDragging={this.setIsDragging}
					/>
				</SortableListMulti>
				<SideWindow open={SideWindowpen} onCancel={this.closeSideWindow}>
					<ProductAssignator
						onAssignProducts={this.assignProductsToCategory}
						SideWindowpen={SideWindowpen}
						categoryId={this.state.categoryId}
						alreadySelectedProducts={actualCategoryProducts}
						canDeselect={true}
					/>
				</SideWindow>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		action: bindActionCreators(action, dispatch),
	};
};

const mapStateToProps = state => {
	const products = getProductsSorted(state);
	const colors = getColors(state);
	const version = getVariableServer(state.entity.variableServer, 'version');
	const version208 = compare(version, '2.0.8', '>=');
	return {
		products: products,
		colors: colors,
		defaultColor: getDefaultColor(state.user),
		version208,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		pure: false,
	}
)(ProductCategoryList);
