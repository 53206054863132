import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class ColorBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { color, value } = this.props;

		if (color.length === 0) {
			return null;
		}

		let selectedColor = color.filter(c => {
			return c.id === value;
		});
		let style = {
			...this.props.style,
			backgroundColor:
				selectedColor.length > 0 ? selectedColor[0].color : color[0].color,
		};

		return <div className="color-box" style={style} />;
	}
}

const mapStateToProps = state => {
	const { color } = state.entity;

	return {
		color,
	};
};

ColorBlock.propTypes = {
	value: PropTypes.string,
};

export default connect(mapStateToProps)(ColorBlock);
