import { ADD_USER, UPDATE_USER, REMOVE_USER } from '../constants/user';

const initialState = {
	id: null,
	token: null,
	username: null,
	email: null,
	master: false,
	id_company: null,
	peripherals: [],
};

const reducer = function userReducer(state = initialState, action) {
	switch (action.type) {
		case ADD_USER:
		case UPDATE_USER:
			return Object.assign({}, state, {
				id: action.user.hasOwnProperty('id') ? action.user.id : state.id,
				token: action.user.hasOwnProperty('token')
					? action.user.token
					: state.token,
				username: action.user.hasOwnProperty('username')
					? action.user.username
					: state.username,
				email: action.user.hasOwnProperty('email')
					? action.user.email
					: state.email,
				master: action.user.hasOwnProperty('master')
					? action.user.master
					: state.master,
				id_company: action.user.hasOwnProperty('id_company')
					? action.user.id_company
					: state.id_company,
				peripherals: action.user.hasOwnProperty('peripherals')
					? action.user.peripherals
					: state.peripherals,
				isSwiftVersion: action.user.hasOwnProperty('isSwiftVersion')
					? action.user.isSwiftVersion
					: state.isSwiftVersion,
			});

		case REMOVE_USER:
			return Object.assign({}, state);

		default:
			return state;
	}
};

export default reducer;
