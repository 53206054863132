import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import DoneIcon from '@material-ui/icons/Done';
import blue from '@material-ui/core/colors/blue';
import { I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as tutorialEvents from './../actions/tutorial.action.events';
import { getAccess } from '../selectors/access.selector';

class FloorSelectionItem extends Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		e.preventDefault();
		if (this.props.tutorial.run) this.props.tutorialEvent.triggerCheckRooms();

		this.props.onSelectedChange({
			id: this.props.id,
			name: this.props.name,
			counter_type: this.props.counter_type,
			selected: !this.props.selected,
		});
	}

	render() {
		const { name, selected, id, counter_type } = this.props;
		const style = { margin: 5 };
		let label = name;
		if (id === 'takeaway') {
			const type =
				counter_type === 1
					? 'stay_in'
					: counter_type === 2
					? 'take_away'
					: 'delivery';
			label += ' (' + I18n.t('floor.counter_type.' + type) + ')';
		}

		let clickable = !this.props.disabled;
		return (
			<div style={style}>
				<Chip
					onClick={clickable ? this.onChange : null}
					classes={{
						label: 'mui-chip__label',
						root: `${selected ? 'mui-chip_selected' : ''} ${
							!clickable ? 'mui-chip_disabled' : ''
						}`,
						avatarChildren: 'mui-chip-avatar-icon',
					}}
					className={`floor_price_category_${name}`}
					label={label}
					clickable={clickable}
					avatar={
						<Avatar
							classes={{
								root: 'mui-avatar',
								colorDefault: selected ? 'mui-avatar_selected' : '',
							}}
							style={{ backgroundColor: selected ? blue['800'] : 'inherit' }}>
							{selected ? <DoneIcon /> : ''}
						</Avatar>
					}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		tutorial: state.tutorial,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		tutorialEvent: bindActionCreators(tutorialEvents, dispatch),
	};
};
FloorSelectionItem.propTypes = {
	id: PropTypes.string.isRequired,
	counter_type: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	selected: PropTypes.bool.isRequired,
	onSelectedChange: PropTypes.func.isRequired,
	selectedIcon: PropTypes.string,
	backgroundColor: PropTypes.string,
	avatarBackgroundColor: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(FloorSelectionItem);
