export const RECEIVE_CATEGORY = 'RECEIVE_CATEGORY';
export const PRE_DELETE_CATEGORY = 'PRE_DELETE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const PRE_CREATE_CATEGORY = 'PRE_CREATE_CATEGORY';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const PRE_UPDATE_CATEGORY = 'PRE_UPDATE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const PRE_PATCH_CATEGORY = 'PRE_PATCH_CATEGORY';
export const PATCH_CATEGORY = 'PATCH_CATEGORY';
export const REFRESH_CATEGORIES_LIST = 'REFRESH_CATEGORIES_LIST';
export const PRE_REWEIGHT_CATEGORY = 'PRE_REWEIGHT_CATEGORY';
export const REWEIGHT_CATEGORY = 'REWEIGHT_CATEGORY';
