import React, { Component } from 'react';
import asyncComponent from './components/AsyncComponent';
import { Router } from 'react-router';
import { I18n } from 'react-redux-i18n';
import { extractGetVariables } from './utils';
import Product from './containers/Product';
const App = asyncComponent(() => import('./containers/App'));
const Category = asyncComponent(() => import('./containers/Category'));

const PriceCategory = asyncComponent(() =>
	import('./containers/PriceCategory')
);

const Menu = asyncComponent(() => import('./containers/Menu'));
const MenuBin = asyncComponent(() => import('./containers/MenuBin'));
const Supplement = asyncComponent(() => import('./containers/Supplement'));
const Tax = asyncComponent(() => import('./containers/Tax'));
const ProductMenu = asyncComponent(() => import('./containers/ProductMenu'));
const Deploy = asyncComponent(() => import('./containers/Deploy'));
const DeployRecap = asyncComponent(() => import('./containers/DeployRecap'));
const AccessForbidden = asyncComponent(() =>
	import('./components/AccessForbidden')
);

// from develop
const REFRESH_TOKEN = 'refresh_token';

export default class route extends Component {
	constructor(props) {
		super(props);

		this.routes = [
			{
				path: '/',
				component: App,
				indexRoute: { component: Product },
				onEnter: (nextState, replace) => {
					// save refresh token if setted (coming back from auth)
					const getVar = extractGetVariables(document.location.toString());

					if (getVar.refreshToken) {
						localStorage.setItem(REFRESH_TOKEN, getVar.refreshToken);
					}
				},
				childRoutes: [
					{
						path: `/category`,
						component: Category,
					},
					{
						path: `/deploy`,
						component: Deploy,
					},
					{
						path: '/deployRecap',
						component: DeployRecap,
					},
					{
						path: `/product`,
						component: Product,
					},
					{
						path: `/tax`,
						component: Tax,
					},
					{
						path: `/supplement`,
						component: Supplement,
					},
					{
						path: `/productMenu`,
						component: ProductMenu,
					},
					{
						path: `/menu`,
						component: Menu,
					},
					{
						path: `/menuBin`,
						component: MenuBin,
					},

					{
						path: `/priceCategory`,
						component: PriceCategory,
					},
					{
						path: `/*`,
						component: () => (
							<AccessForbidden urlRejected={true} status={404} />
						),
					},
				],
			},
		];
	}

	render() {
		return <Router history={this.props.history} routes={this.routes} />;
	}
}
