import React, { Component } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { deepEqual } from '../../../utils';

class SelectRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = { value: props.value || '' };

		this.handleChange = this.handleChange.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.value) {
			this.setState({ value: nextProps.value });
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (deepEqual(nextState.value, this.state.value)) {
			return false;
		}
		return true;
	}

	handleChange(value) {
		this.setState({ value });
	}

	render() {
		const { row, value, items, keyAccessor } = this.props;
		let item;
		let text;
		let isMultiple = false;
		if (Array.isArray(value)) {
			let selectedItems = value.map(val => {
				let found = find(items, item => item.value == val);
				return found ? found.text : '';
			});
			text = selectedItems
				.sort((a, b) => {
					return a.toLowerCase().localeCompare(b.toLowerCase());
				})
				.join(', ');
			isMultiple = true;
		} else {
			item = find(items, item => item.value == value);
			text = item ? item.text : '';
		}
		return (
			<div
				className={'select-render'}
				data-id={value}
				data-row={row}
				data-is-multiple={isMultiple}
				data-key-accessor={keyAccessor}>
				{text}
			</div>
		);
	}
}

SelectRenderer.propTypes = {
	row: PropTypes.number,
	td: PropTypes.string,
	col: PropTypes.number,
	prop: PropTypes.string,
	value: PropTypes.any,
};

export default SelectRenderer;
