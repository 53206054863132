import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import * as globalSelector from './global.selector';
import { PRICE_PRECISION } from '../utils';

const PRODUCT_SORT_BY_CATEGORY_WEIGHT = 'category_weight';
const PRODUCT_SORT_BY_NAME = 'name';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getFormatedProducts = createDeepEqualSelector(
	[
		globalSelector.getProducts,
		globalSelector.getProductTypes,
		globalSelector.getCategories,
		globalSelector.getPlaceSends,
		globalSelector.getColors,
		globalSelector.getTaxes,
	],
	(products, productTypes, categories, placeSends, colors, taxes) => {
		const formatedProducts = products.map((product, index) => {
			product.index = index;
			product.price = parseFloat(product.price).toFixed(PRICE_PRECISION);
			const tax = find(taxes, t => t.id === product.id_tax_rate);
			tax !== undefined ? (product.taxName = tax.name) : (product.taxName = '');

			const productType = find(
				productTypes,
				pt => pt.id === product.id_product_type
			);
			productType !== undefined
				? (product.product_type_name = productType.name)
				: (product.product_type_name = '');

			//Start: Je ne peux récupérer les noms de catégories que si je crée une nouvelle clé dans product
			let array = [];
			product.categories.forEach(productCategory => {
				categories.forEach(category => {
					if (category.id === productCategory) {
						array.push(category);
					}
				});
			});
			product.category_name_a_z = [];
			if (array.length) {
				array.forEach(arr => product.category_name_a_z.push(arr.name));
			}

			product.category_name_a_z.sort();
			//End.

			const category = find(categories, c => c.id === product.category_id);
			category !== undefined
				? (product.category_name = category.name)
				: (product.category_name = '');

			const placeSend = find(placeSends, ps => ps.id === product.id_place_send);
			placeSend !== undefined
				? (product.place_send_name = placeSend.name)
				: (product.place_send_name = '');

			const selectedColor = find(colors, co => co.id === product.id_color);
			if (colors.length > 0) {
				selectedColor !== undefined
					? (product.color_color = selectedColor.color)
					: (product.color_color = colors[0].color);
			} else {
				product.color_color = '#5a5a5a';
			}
			return product;
		});
		return formatedProducts;
	}
);

export const getProductsSorted = createDeepEqualSelector(
	[
		globalSelector.getProductsFilter,
		getFormatedProducts,
		globalSelector.getCategories,
	],
	(productsFilter, products, categories) => {
		switch (productsFilter) {
			case PRODUCT_SORT_BY_CATEGORY_WEIGHT:
				let sortedProducts = products.map(product => {
					const productCategory = find(
						categories,
						category => category.id === product.category_id
					);
					product.category_name = productCategory ? productCategory.name : '';
					return product;
				});
				return sortBy(sortedProducts, [
					'category_weight',
					'category_name',
					'weight',
				]);
			case PRODUCT_SORT_BY_NAME:
				return sortBy(products, ['name']);
			default:
				return products;
		}
	}
);

//export const getProductsForProductList = createSelector
