import React from 'react';
import { styles } from './styles';
import { Translate } from 'react-redux-i18n';

const stylesInterac = {
	buttonNext: {
		display: 'none',
	},
	buttonSkip: {
		display: 'none',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const stylesForTooltipBody = {
	buttonBack: {
		display: 'none',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const styleBis = {
	buttonClose: {
		fontSize: '16px',
	},
	buttonSkip: {
		fontSize: '16px',
	},
	buttonBack: {
		fontSize: '16px',
	},
	buttonNext: {
		fontSize: '16px',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

export const stepsSupplement = [
	{
		content: <Translate value="tutorial.optionsKitchen.step1.content" />,
		placement: 'center',
		disableBeacon: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.noDemo" />,
			close: <Translate value="tutorial.yesDemo" />,
		},
		target: 'body',
		title: <Translate value="tutorial.optionsKitchen.step1.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step2.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: stylesInterac,
		target: '.button_add_category_supplement',
		title: <Translate value="tutorial.optionsKitchen.step2.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step3.content" />,
		placement: 'center',
		disableBeacon: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: stylesForTooltipBody,
		target: 'body',
		title: <Translate value="tutorial.optionsKitchen.step3.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step4.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.name_input_supplement',
		title: <Translate value="tutorial.optionsKitchen.step4.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step5.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.information_form_supplement',
		title: <Translate value="tutorial.optionsKitchen.step5.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step6.content" />,
		placement: 'right',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.icon_form_supplement',
		title: <Translate value="tutorial.optionsKitchen.step6.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step7.content" />,
		placement: 'left',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.color_form_supplement',
		title: <Translate value="tutorial.optionsKitchen.step7.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step8.content" />,
		placement: 'left',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.exclusion_form_supplement',
		title: <Translate value="tutorial.optionsKitchen.step8.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step9.content" />,
		placement: 'left',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: stylesInterac,
		target: '.button_save_form_supplement',
		title: <Translate value="tutorial.optionsKitchen.step9.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step10.content" />,
		placement: 'right',
		spotlightClicks: true,
		styles: stylesInterac,
		target: '.stylizedList > div > div:first-of-type',
		title: <Translate value="tutorial.optionsKitchen.step10.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step11.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		styles: stylesInterac,
		target: '.add-option-button',
		title: <Translate value="tutorial.optionsKitchen.step11.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step12.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.name-container',
		title: <Translate value="tutorial.optionsKitchen.step12.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step13.content" />,
		placement: 'right',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.option-kitchen-price',
		title: <Translate value="tutorial.optionsKitchen.step13.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step14.content" />,
		placement: 'left',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.option-kitchen-color',
		title: <Translate value="tutorial.optionsKitchen.step14.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step15.content" />,
		placement: 'left',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.option-kitchen-information',
		title: <Translate value="tutorial.optionsKitchen.step15.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step16.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		styles: stylesInterac,
		target: '.option-kitchen-save',
		title: <Translate value="tutorial.optionsKitchen.step16.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step17.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		styles: stylesInterac,
		target:
			'.stylizedList > div > div:first-of-type > div:nth-child(5) > button',
		title: <Translate value="tutorial.optionsKitchen.step17.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step18.content" />,
		placement: 'left',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.list_menu_right_menu3',
		title: <Translate value="tutorial.optionsKitchen.step18.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step19.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: stylesInterac,
		target: '.button_save_menu3',
		title: <Translate value="tutorial.optionsKitchen.step19.title" />,
	},
	{
		content: <Translate value="tutorial.optionsKitchen.step20.content" />,
		placement: 'center',
		spotlightClicks: true,
		disableBeacon: true,
		styles: stylesForTooltipBody,
		locale: {
			close: <Translate value="tutorial.optionsKitchen.endDemoOptionKitchen" />,
		},
		target: 'body',
		title: <Translate value="tutorial.optionsKitchen.step20.title" />,
	},
];
