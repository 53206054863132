import React, { Component } from 'react';
import './index.scss';
import Icon from '@material-ui/core/Icon';

export default function Alert({ type = 'info', children, icon = null }) {
	let _icon = 'info';
	if (type === 'error') {
		_icon = 'error_outline';
	} else if (type === 'warning') {
		_icon = 'warning';
	} else if (type === 'success') {
		_icon = 'check_circle_outline';
	}
	if (icon) {
		_icon = icon;
	}
	return (
		<div className={`cw-alert cw-alert-${type}`}>
			<Icon>{_icon}</Icon>
			<span>{children}</span>
		</div>
	);
}
