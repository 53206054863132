import { i18nReducer } from 'react-redux-i18n';
import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import loginReducer from './login';
import userReducer from './user';
import productReducer from './product';
import categoryReducer from './category';
import colorReducer from './color';
import taxReducer from './tax';
import requestReducer from './request';
import supplementCategoryReducer from './supplementCategory';
import placeSendReducer from './placeSend';
import supplementReducer from './supplement';
import retailPriceTypeReducer from './retailPriceType';
import productTypeReducer from './productType';
import variableServerReducer from './variableServer';
import variableReducer from './variable';
import synchroReducer from './synchro';
import floorReducer from './floor';
import menuReducer from './menu';
import priceCategoryReducer from './priceCategory';
import productMenuReducer from './productMenu';
import loadingReducer from './loading';
import notification from './notification.reducer';
import assignator from './assignator.reducer';
import patchMenuReducer from './patchMenu.reducer';
import importReducer from './import.reducer';
import tutorialReducer from './tutorial.reducer';
import tutorialEvents from './tutorial.events.reducer';
import helperReducer from './helper.reducer';
import menuMasterMonoReducer from './menuMasterMono';
import dayChangesReducer from './dayChanges.reducer';
import zoneReducer from './zone';
import childrenReducer from './children';
import accessReducer from './access.reducer';
import { reducer as formReducer } from 'redux-form';

const entityReducer = combineReducers({
	product: productReducer,
	category: categoryReducer,
	color: colorReducer,
	tax: taxReducer,
	productMenu: productMenuReducer,
	supplementCategory: supplementCategoryReducer,
	placeSend: placeSendReducer,
	supplement: supplementReducer,
	productType: productTypeReducer,
	variableServer: variableServerReducer,
	variable: variableReducer,
	synchro: synchroReducer,
	floor: floorReducer,
	menu: menuReducer,
	menuMasterMono: menuMasterMonoReducer,
	patchMenu: patchMenuReducer,
	priceCategory: priceCategoryReducer,
	retailPriceType: retailPriceTypeReducer,
	importProduct: importReducer,
	dayChanges: dayChangesReducer,
	zone: zoneReducer,
	children: childrenReducer,
});

const appReducer = combineReducers({
	i18n: i18nReducer,
	form: formReducer,
	routing: routerReducer,
	request: requestReducer,
	login: loginReducer,
	user: userReducer,
	entity: entityReducer,
	loading: loadingReducer,
	notification: notification,
	assignator: assignator,
	tutorial: tutorialReducer,
	tutorialEvents,
	helper: helperReducer,
	access: accessReducer,
});

export default appReducer;
