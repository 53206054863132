import React, { Component } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {
	deepEqual,
	uppercaseFirstLetter,
	handleProductTypeChange,
	getTypeLabel,
	DRINK_TYPE,
	haveSkuRequierments,
} from '../utils';
import ColorPanel from './ColorPanel';
import SideWindow from './SideWindow.js';
import ProductPreview from './ProductPreview.js';
import FormProductSupplements from './FormProductSupplements.js';
import FloorSelection from './FloorSelection.js';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid, Row, Col } from 'react-flexbox-grid';
import SelectMultiple from './utils/SelectMultiple';
import findIndex from 'lodash/findIndex';
import Prices from './Prices';
import moment from 'moment/moment';
import * as notificationAction from '../actions/notification.action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { checkPrice, showInvalid, deleteInvalidClass } from './../utils';
import { TAGS } from '../constants/notification';
import FormPaper from '../components/FormPaper';
import DialogBin from '../components/DialogBin';
import {
	getSigle,
	getNbTempsDenvoi,
	getVariableServer,
} from '../selectors/global.selector';
import { getAccess } from '../selectors/access.selector';
import { notifSkuRequired } from '../actions/product.action';
import { getDefaultColor } from '../selectors/color.selector';

class PopupProduct extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: {},
			selectedSupplements: [],
			selectedPriceCategories: [],
			excludedFloors: [],
			selectedCategories: [],
			openDialogBin: false,
			isRecuperation: false,
			wrongPrice: false,
		};

		this.assignDefaultValue = this.assignDefaultValue.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onChangeType = this.onChangeType.bind(this);
		this.onChangeTVA = this.onChangeTVA.bind(this);
		this.onChangeCategory = this.onChangeCategory.bind(this);
		this.onExcludedChange = this.onExcludedChange.bind(this);
		this.onSupplementChange = this.onSupplementChange.bind(this);
		this.onPriceCategoryChange = this.onPriceCategoryChange.bind(this);
		this.onRetailPriceTypeChange = this.onRetailPriceTypeChange.bind(this);
		this.validForm = this.validForm.bind(this);
		this.onCancelWindow = this.onCancelWindow.bind(this);
		this.saveButtonDisabled = this.saveButtonDisabled.bind(this);
		this.alertProductInMenu = this.alertProductInMenu.bind(this);
		this.isProductInMenu = this.isProductInMenu.bind(this);
		this.updateWrongPrice = this.updateWrongPrice.bind(this);
	}

	componentWillMount() {
		const { data } = this.props;

		this.assignDefaultValue(data);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ selectedSupplements: nextProps.selectedSupplements });
		this.assignDefaultValue(nextProps.product);
	}

	assignDefaultValue(product) {
		const { placeSends } = this.props;

		let defaultData;
		defaultData = {
			name: '',
			short_name: '',
			description: '',
			price: '0.00',
			id_tax_rate: '',
			id_color: this.props.defaultColor,
			id_product_type: 0,
			id_place_send: '',
			id_place_send_2: '',
			id_retail_price_type: '0',
			active: true,
			id_status_send_index: 0,
			sku: '',
			is_free_price: false,
			id_menu: this.props.id_menu,
		};

		if (product && !product.name && !this.state.data.id) {
			product = Object.assign({}, defaultData, {});
		}

		let newState = {
			selectedSupplements: Object.assign(
				[],
				product ? product.supplements : []
			),
			selectedPriceCategories: Object.assign([], product ? product.prices : []),
			excludedFloors: Object.assign([], product ? product.exclusions : []),
			selectedCategories: Object.assign([], product ? product.categories : []),
			data: Object.assign({}, defaultData, product),
		};

		newState.data.id_product_type = parseInt(newState.data.id_product_type, 10);
		newState.data.id_status_send_index = parseInt(
			newState.data.id_status_send_index,
			10
		);

		newState.active = !!newState.active;

		if (!newState.data.id_place_send && placeSends.length > 0) {
			newState.data.id_place_send = placeSends[0].id;
		}
		/*
            if (!newState.data.id_tax_rate && taxes.length > 0) {
                let tax;
                for (let i = 0, length = taxes.length; i < length; i++) {
                    if (taxes[i].is_default) {
                        tax = taxes[i];
                        break;
                    }
                }

                if (!tax) {
                    tax = taxes[0];
                }

                newState.data.id_tax_rate = tax.id;
            }
    */
		/*
    if (!newState.data.id_product_type && productTypes.length > 0) {
        newState.data.id_product_type = productTypes[0].id;
    }
    */

		if (newState.data.id_status_send_index === null) {
			newState.data.id_status_send_index = 0;
		}

		this.setState(newState);
	}

	isProductInMenu(id_product) {
		let inMenu = false;
		this.props.productMenu.forEach(menu => {
			menu.menuLevels.forEach(menuLevels => {
				menuLevels.products.forEach(product => {
					if (id_product === product.id) inMenu = true;
				});
			});
		});
		return inMenu;
	}

	alertProductInMenu() {
		this.props.notificationAction.addNotification({
			tags: [TAGS.WARNING],
			text: I18n.t('product.priceWarning', {
				productName:
					this.state.data.name !== '' ? `${this.state.data.name}` : '',
			}),
			date: moment().format('DD/MM/YYYY HH:mm'),
			viewed: false,
			openable: false,
		});
	}

	validForm() {
		const { onValid, validators } = this.props;

		if (onValid) {
			this.removeInvalid();

			const data = Object.assign({}, this.state.data);
			data.supplements = this.state.selectedSupplements;
			data.prices = this.state.selectedPriceCategories;
			data.exclusions = this.state.excludedFloors;
			data.categories = this.state.selectedCategories;
			data.name = uppercaseFirstLetter(data.name.trim());
			data.price = checkPrice(data.price);

			let invalidFields = [];
			for (let key in validators) {
				if (validators[key]) {
					if (!validators[key](this.state.data[key])) {
						invalidFields.push(key);
					}
				}
			}

			if (invalidFields.length === 0) {
				for (let key in this.state.data) {
					if (this.state.data[key] === null) {
						delete this.state.data[key];
					}
				}

				if (data.id && data.price == 0 && this.isProductInMenu(data.id)) {
					this.alertProductInMenu();
				} else {
					let canCreate = !haveSkuRequierments(
						data.sku,
						this.props.check_sku,
						this.props.check_sku_int
					);
					if (!canCreate) {
						this.props.notificationAction.addNotification({
							tags: [TAGS.WARNING],
							text: I18n.t(
								this.props.check_sku_int == '1'
									? 'product.skuIntRequired'
									: 'product.skuRequired'
							),
							date: moment().format('DD/MM/YYYY HH:mm'),
							viewed: false,
							openable: false,
						});
						return;
					}

					if (this.state.isRecuperation) {
						data.removed = 0;
						data.active = 1;
						return onValid(data);
					}
					onValid(data, this.state.originalProduct);
				}
			} else {
				showInvalid(invalidFields);
			}
		}
	}

	removeInvalid() {
		let invalids = document.getElementsByClassName('invalid-fields');

		for (let i = 0, length = invalids.length; i < length; i++) {
			if (invalids[i]) {
				invalids[i].classList.remove('invalid-fields');
			}
		}
	}

	/* showInvalid(invalidFields) {
    for (let i = 0, length = invalidFields.length; i < length; i++) {
      let field = document.getElementsByClassName(
        `${invalidFields[i]}-container`
      );

      if (field && field[0]) {
        field[0].classList.add("invalid-fields");
      }
    }
  } */

	onChange(prop, value) {
		let newState = Object.assign({}, this.state.data);
		newState[prop] = value;
		if (prop === 'is_free_price') {
			newState.id_retail_price_type = '0';
		}
		if (prop === 'price') {
			const isNumber = checkPrice(value);
			if (isNaN(isNumber)) {
				this.setState({ wrongPrice: true });
				showInvalid(['price']);
			} else {
				this.setState({ wrongPrice: false });
				deleteInvalidClass('price');
			}
		}

		this.setState({
			data: newState,
		});
	}

	/*  deleteInvalidClass(fieldToRemove) {
    const field = document.getElementsByClassName(`${fieldToRemove}-container`);
    field[0].classList.remove("invalid-fields");
  } */

	updateWrongPrice(state) {
		this.setState({ wrongPrice: state });
	}

	onChangeType(value) {
		this.onChange('id_product_type', value);

		if (this.state.data.id) {
			// const changes = handleProductTypeChange(
			//   this.state.data.id_product_type,
			//   value,
			//   this.props.taxes
			// );
			// if (changes) {
			//   this.props.notificationAction.addNotification({
			//     tags: [TAGS.WARNING],
			//     text: I18n.t(changes.notification, {
			//       productName: this.state.data.name ? this.state.data.name : ""
			//     }),
			//     date: moment().format("DD/MM/YYYY HH:mm"),
			//     viewed: false,
			//     openable: false
			//   });

			//   if (changes.id_tax) {
			//     setTimeout(() => {
			//       this.onChange("id_tax_rate", changes.id_tax.id);
			//     }, 50);
			//   }
			// }
			// const isNewTypeDrink = DRINK_TYPE.indexOf(parseInt(value, 10)) > -1;
			// let newPlaceSend;

			// if (isNewTypeDrink) {
			//   newPlaceSend = "def0-2";
			// } else {
			//   newPlaceSend = "def0-1";
			// }
			// if (newPlaceSend !== this.state.data.id_place_send) {
			//   setTimeout(() => {
			//     this.onChange("id_place_send", newPlaceSend);
			//   }, 50);
			// }

			this.props.notificationAction.addNotification({
				tags: [TAGS.WARNING],
				text: I18n.t('product.productTypeChange', {
					productName: `( ${this.state.data.name} )`,
				}),
				date: moment().format('DD/MM/YYYY HH:mm'),
				viewed: false,
				openable: false,
			});
		}
	}

	onChangeTVA(value) {
		this.onChange('id_tax_rate', value);

		if (
			this.state.data.id &&
			this.state.data.id_tax_rate !== '' &&
			this.state.data.id_tax_rate !== value
		) {
			this.props.notificationAction.addNotification({
				tags: [TAGS.WARNING],
				text: I18n.t('product.productTVAChange', {
					productName:
						this.state.data.name !== '' ? `(${this.state.data.name})` : '',
				}),
				date: moment().format('DD/MM/YYYY HH:mm'),
				viewed: false,
				openable: false,
			});
		}
	}

	handleBin = () => {
		if (this.state.data.id) return;
		const productsRemoved = this.props.products.productsRemoved;
		const { taxes } = this.props;
		let productMatched = [];
		const inputName = this.state.data.name;
		if (inputName.length >= 3) {
			productsRemoved.forEach(product => {
				if (
					!['delivery', 'service'].includes(product.id) &&
					product.name.toLowerCase().includes(inputName.toLowerCase())
				) {
					productMatched.push(product);
				}
			});
		}
		if (productMatched.length > 0) {
			const checkTaxExist = taxes.filter(
				tax => tax.id === productMatched[0].id_tax_rate
			);
			if (checkTaxExist.length) productMatched[0].id_tax_rate = taxes[1].id;
			this.setState({ openDialogBin: true, productMatched });
		}
	};

	onChangeCategory(categories) {
		this.setState({ selectedCategories: categories });
	}

	onExcludedChange(floors) {
		this.setState({ excludedFloors: floors });
	}

	/*
  * return {
      id : string (id_supplement),
      min : number,
      max : number,
      checked : bool (associé au produit)
  }
  */
	onSupplementChange(supplement) {
		const selected = Object.assign([], this.state.selectedSupplements);

		let index = -1;
		for (let i = 0, length = selected.length; i < length; i++) {
			if (selected[i].id === supplement.id) {
				index = i;
				break;
			}
		}

		if (supplement.checked) {
			if (index > -1) {
				selected[index] = supplement;
			} else {
				selected.push(supplement);
			}
		} else {
			selected.splice(index, 1);
		}

		this.setState({
			selectedSupplements: selected,
		});
	}

	/*
	 * return {id : string (id_price_category), price : string, active : bool (associé au produit}
	 */
	onPriceCategoryChange(priceCategory) {
		const selected = Object.assign([], this.state.selectedPriceCategories);

		const index = findIndex(selected, pc => pc.id === priceCategory.id);

		if (priceCategory.checked) {
			if (index > -1) {
				selected[index] = priceCategory;
			} else {
				selected.push(priceCategory);
			}
		} else if (!priceCategory.checked && index > -1) {
			selected.splice(index, 1);
		}

		this.setState({
			selectedPriceCategories: selected,
		});
	}

	/*
	 * return id
	 */
	onRetailPriceTypeChange(id) {
		let product = Object.assign({}, this.state.data);

		product.id_retail_price_type = id;

		this.setState({ data: product });
	}

	onCancelWindow() {
		this.setState(
			{
				selectedSupplements: [],
				selectedPriceCategories: [],
				excludedFloors: [],
				selectedCategories: [],
			},
			this.props.onCancel
		);
	}

	saveButtonDisabled() {
		const equal = deepEqual(this.state.data, this.props.product);
		const floorsEqual = deepEqual(
			this.props.product.floors,
			this.state.excludedFloors
		);
		const nameEmpty = this.state.data.name
			? this.state.data.name.trim() === ''
			: true;
		const taxEmpty = this.state.data.id_tax_rate
			? this.state.data.id_tax_rate.trim() === ''
			: true;
		const typeEmpty = this.state.data.id_product_type ? false : true;
		const wrongPrice = this.state.wrongPrice ? true : false;

		return (
			(equal && floorsEqual) || nameEmpty || taxEmpty || typeEmpty || wrongPrice
		);
	}

	handleCloseDialogBin = () => {
		this.setState({ valueDialogBin: '', openDialogBin: false });
	};

	handleConfirmDialogBin = value => {
		this.setState({ valueDialogBin: value, openDialogBin: false });
		this.state.productMatched.forEach(product => {
			if (product.id === value) {
				const _product = { ...product };
				delete _product.created_at;
				delete _product.updated_at;
				this.setState({
					data: _product,
					isRecuperation: true,
					originalProduct: _product,
				});
			}
		});
	};

	render() {
		const {
			open,
			color,
			taxes,
			categories,
			productTypes,
			placeSends,
			floors,
			priceCategories,
			leftPosition,
			supplementCategory,
			nbTempsDenvoi,
		} = this.props;
		const data = this.state.data || {};
		const { excludedFloors, selectedCategories } = this.state;

		const saveDisabled = this.saveButtonDisabled();

		const taxesFiltered = taxes.filter(tax => tax.active);
		let taxOptions = [
			<MenuItem key={0} value={''}>
				{I18n.t('product.form.select')}
			</MenuItem>,
			...taxesFiltered.map(tax => {
				return (
					<MenuItem key={tax.id} value={tax.id}>
						{tax.name}
					</MenuItem>
				);
			}),
		];
		let productTypeOptions = [
			<MenuItem key={0} value={''}>
				{I18n.t('product.form.select')}
			</MenuItem>,
			...productTypes.map(pt => {
				return (
					<MenuItem key={pt.id} value={pt.id}>
						{pt.name + ' (' + getTypeLabel(pt.id) + ')'}
					</MenuItem>
				);
			}),
		];
		let placeOptions1 = <MenuItem />;
		if (placeSends && placeSends.length > 0) {
			placeOptions1 = placeSends.map(ps => {
				return (
					<MenuItem
						key={ps.id}
						value={ps.id}
						disabled={ps.id === data.id_place_send_2}>
						{ps.name}
					</MenuItem>
				);
			});
		}

		let placeOptions2 = [
			<MenuItem key={0} value={0}>
				{I18n.t('product.form.no_place')}
			</MenuItem>,
		];
		if (placeSends && placeSends.length > 0) {
			placeOptions2 = [
				<MenuItem key={0} value={0}>
					{I18n.t('product.form.no_place')}
				</MenuItem>,
				...placeSends.map(ps => {
					return (
						<MenuItem
							key={ps.id}
							value={ps.id}
							disabled={ps.id === data.id_place_send}>
							{ps.name}
						</MenuItem>
					);
				}),
			];
		}

		let timeOptions = [
			<MenuItem key={0} value={0}>
				{I18n.t('product.form.direct')}
			</MenuItem>,
		];

		for (let i = 1, length = nbTempsDenvoi; i <= length; i++) {
			timeOptions.push(
				<MenuItem key={i} value={i}>
					{i}
				</MenuItem>
			);
		}

		const supplements = this.state.selectedSupplements.map(s => {
			return s.id;
		});

		return (
			<div className={'product-form'}>
				<SideWindow
					open={open}
					onCancel={this.onCancelWindow}
					leftPosition={leftPosition}>
					<DialogBin
						text={I18n.t('product.form.binConfirmText')}
						title={I18n.t('product.form.binConfirmTitle')}
						data={this.state.productMatched}
						open={this.state.openDialogBin}
						onClose={this.handleCloseDialogBin}
						onConfirm={this.handleConfirmDialogBin}
						sigle={this.props.sigle}
					/>
					<Button
						variant="raised"
						color={'primary'}
						style={{ float: 'right', width: 200, height: 50 }}
						onClick={this.validForm}
						disabled={saveDisabled}
						className={'information_button_save_product'}>
						{I18n.t('save')}
					</Button>

					<div className={'name-container sideWindow-mainInput'}>
						<TextField
							placeholder={I18n.t('product.form.name')}
							value={data.name || ''}
							onChange={ev => {
								this.onChange('name', ev.target.value);
							}}
							autoFocus={true}
							onBlur={this.handleBin}
							className={'form-textfield'}
							fullWidth={true}
							name={'product_name'}
						/>
					</div>

					<Grid fluid>
						<Row style={{ marginTop: '30px' }}>
							<Col xs={12} sm={12} md={4}>
								{/* * * * * *
                  DENOMINATION
                 * * * * * */}
								<FormPaper
									title={I18n.t('title.denomination')}
									className={'name_product_form'}>
									<Row around={'xs'}>
										<Col>
											{/* NOM COURT */}
											<div>
												<TextField
													label={I18n.t('product.form.shortName')}
													value={data.short_name || ''}
													onChange={ev => {
														this.onChange('short_name', ev.target.value);
													}}
													className="form-field"
													name={'product_short_name'}
													margin="normal"
												/>
											</div>
											{/* SKU */}
											<div>
												<TextField
													label={I18n.t(
														this.props.check_sku && this.props.check_sku == '1'
															? 'product.form.sku_required'
															: 'product.form.sku'
													)}
													value={data.sku || ''}
													onChange={ev => {
														this.onChange('sku', ev.target.value);
													}}
													className="form-field"
													name={'product_sku'}
													margin="normal"
												/>
											</div>
										</Col>
										<Col>
											{/* DESCRIPTION */}
											<div>
												<TextField
													label={I18n.t('product.form.description')}
													//style={{marginLeft : 15}}
													value={data.description || ''}
													multiline
													rows="4"
													rowsMax="5"
													onChange={ev => {
														this.onChange('description', ev.target.value);
													}}
													className="form-field"
													name={'product_description'}
												/>
											</div>
										</Col>
									</Row>
								</FormPaper>

								{/* * * * * *
                    PRIX
                 * * * * * */}
								<FormPaper
									title={I18n.t('title.price')}
									hidden={!!this.state.data.is_free_price}
									action={
										<div>
											<FormControlLabel
												className="form-field"
												control={
													<Switch
														checked={!!data.is_free_price || false}
														onChange={e => {
															this.onChange('is_free_price', e.target.checked);
														}}
													/>
												}
												label={I18n.t('product.form.free_price')}
											/>
										</div>
									}
									className={'price_product_form'}>
									<Prices
										price={data.price || ''}
										priceCategories={priceCategories}
										selectedPriceCategories={this.state.selectedPriceCategories}
										onPriceChange={value => {
											this.onChange('price', value);
										}}
										onPriceCategoryChange={this.onPriceCategoryChange}
										canBeRetailled={true}
										retailPriceTypes={this.props.retailPriceTypes}
										idRetailPriceType={data.id_retail_price_type}
										onRetailPriceTypeChange={this.onRetailPriceTypeChange}
										displayPrice={false}
										tag={'div'}
										updateWrongPrice={this.updateWrongPrice}
									/>
								</FormPaper>
							</Col>
							<Col xs={12} sm={12} md={4}>
								{/* * * * * *
                    INFOS
                 * * * * * */}
								<FormPaper
									title={I18n.t('title.informations')}
									className={'form-product-info'}
									action={
										<div>
											<FormControlLabel
												control={
													<Switch
														onChange={e => {
															this.onChange('active', e.target.checked);
														}}
														checked={!!data.active || false}
													/>
												}
												label={I18n.t('product.form.visible')}
											/>
										</div>
									}
									className={'informations_product'}>
									{/* TVA */}
									<div className="form-field informations_tva_product">
										<InputLabel htmlFor="select-tax">
											{' '}
											{I18n.t('product.form.tax')} :{' '}
										</InputLabel>
										<Select
											value={data.id_tax_rate || ''}
											onChange={ev => {
												this.onChangeTVA(ev.target.value);
											}}
											MenuProps={{ id: 'select-tax' }}>
											{taxOptions}
										</Select>
									</div>

									{/* TYPE */}
									<div className="form-field informations_type_product">
										<InputLabel htmlFor="select-type">
											{' '}
											{I18n.t('product.form.type')} :{' '}
										</InputLabel>
										<Select
											style={{ maxHeight: 150 }}
											value={data.id_product_type}
											onChange={ev => {
												this.onChangeType(ev.target.value);
											}}
											inputProps={{ id: 'select-type' }}>
											{productTypeOptions}
										</Select>
									</div>

									{/* CATEGORY */}
									<div className="form-field">
										<InputLabel htmlFor="select-category">
											{' '}
											{I18n.t('product.form.category')} :{' '}
										</InputLabel>
										<SelectMultiple
											elements={categories}
											values={selectedCategories}
											onSelectChange={this.onChangeCategory}
										/>
									</div>

									{/* LIEUX ENVOI 1 */}
									<div className="form-field informations_place_product">
										<InputLabel htmlFor="place-send-1">
											{' '}
											{I18n.t('product.form.place')} :{' '}
										</InputLabel>
										<Select
											style={{ maxHeight: 150 }}
											onChange={ev => {
												this.onChange('id_place_send', ev.target.value);
											}}
											value={data.id_place_send || ''}
											className="form-select"
											inputProps={{ id: 'place-send-1' }}>
											{placeOptions1}
										</Select>
									</div>

									{/* LIEUX ENVOI 2 */}
									<div className="form-field">
										<InputLabel htmlFor="place-send-2">
											{' '}
											{I18n.t('product.form.place2')} :{' '}
										</InputLabel>
										<Select
											style={{ maxHeight: 150 }}
											onChange={ev => {
												this.onChange('id_place_send_2', ev.target.value);
											}}
											value={data.id_place_send_2 || ''}
											className="form-select"
											inputProps={{ id: 'place-send-2' }}>
											{placeOptions2}
										</Select>
									</div>

									{/* TEMPS ENVOI */}
									<div className="form-field">
										<InputLabel htmlFor="select-temps-envoi">
											{' '}
											{I18n.t('product.form.statusSend')} :{' '}
										</InputLabel>
										<Select
											style={{ maxHeight: 150 }}
											value={data.id_status_send_index}
											onChange={ev => {
												this.onChange('id_status_send_index', ev.target.value);
											}}
											className="form-select"
											inputProps={{ id: 'select-temps-envoi' }}>
											{timeOptions}
										</Select>
									</div>
								</FormPaper>

								{/* * * * * *
                    SUPPLEMENTS
                 * * * * * */}
								<FormPaper
									title={I18n.t('title.supplements')}
									className={'kitchen_option'}>
									{supplementCategory.length ? (
										<div>
											<Row between={'xs'}>
												<Col xs={6}>
													<i>{I18n.t('product.form.supp.name')}</i>
												</Col>
												<Col xs={6}>
													<i>{I18n.t('product.form.supp.minMax')}</i>
												</Col>
											</Row>

											{supplementCategory.map(s => {
												// le supplément est t'il coché ?
												let index = supplements.indexOf(s.id);
												// si oui on le récupère
												let supp =
													index > -1
														? this.state.selectedSupplements[index]
														: null;

												let min = supp ? supp.min : s.nb_min;
												let max = supp ? supp.max : s.nb_max;
												let checked = !!supp;

												return (
													<FormProductSupplements
														key={s.id}
														id={s.id}
														min={min}
														max={max}
														cat_min={s.nb_min}
														cat_max={s.nb_max}
														multiple={s.type > 1}
														name={s.name}
														checked={checked}
														onChange={this.onSupplementChange}
													/>
												);
											})}
										</div>
									) : (
										<p>
											<strong>
												{' '}
												<i>
													<Translate value={'supplement.empty'} />{' '}
												</i>{' '}
											</strong>
										</p>
									)}
								</FormPaper>
							</Col>
							<Col xs={12} sm={12} md={4}>
								{/* * * * * *
                    PREVISUALISATION
                 * * * * * */}
								<ProductPreview
									colors={color}
									backgroundColor={data.id_color}
									name={this.state.data.name}
									price={this.state.data.price}
									prices={this.state.selectedPriceCategories}
									free_price={!!this.state.data.is_free_price}
									description={this.state.data.description}
									retailPriceTypes={this.props.retailPriceTypes}
									idRetailPriceType={data.id_retail_price_type}
								/>

								{/* * * * * *
                    COULEUR
                 * * * * * */}
								<FormPaper
									title={I18n.t('title.color')}
									className={'informations_color_product'}>
									<ColorPanel
										color={color}
										onSelectValue={color => {
											this.onChange('id_color', color.id);
										}}
										selectedData={data.id_color}
									/>
								</FormPaper>

								{/* * * * * *
                    EXCLUSIONS
                 * * * * * */}
								{this.props.accessExclusion.read ? (
									<FormPaper
										title={I18n.t('title.exclusions')}
										className={'informations_exclusion_product'}>
										{floors && floors.length ? (
											<div>
												<h3>
													{' '}
													<Translate value={'floor.exclusion_label'} />{' '}
												</h3>
												<FloorSelection
													selectedIcon={'done'}
													floors={floors}
													onChange={this.onExcludedChange}
													floorsSelected={excludedFloors}
												/>
											</div>
										) : (
											<p>
												<strong>
													{' '}
													<i>
														{' '}
														<Translate value={'floor.empty'} />{' '}
													</i>{' '}
												</strong>
											</p>
										)}
									</FormPaper>
								) : null}
							</Col>
						</Row>
					</Grid>
				</SideWindow>
			</div>
		);
	}
}

PopupProduct.propTypes = {
	onValid: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	color: PropTypes.array.isRequired,
	taxes: PropTypes.array.isRequired,
	categories: PropTypes.array.isRequired,
	productTypes: PropTypes.array.isRequired,
	placeSends: PropTypes.array.isRequired,
	supplementCategory: PropTypes.array.isRequired,
	floors: PropTypes.array.isRequired,
	validators: PropTypes.object,
	priceCategories: PropTypes.array.isRequired,
	product: PropTypes.object,
	nbTempsDenvoi: PropTypes.number.isRequired,
	id_menu: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
	const { entity, access } = state;
	const { retailPriceType, product, variable, variableServer } = entity;

	return {
		retailPriceTypes: retailPriceType,
		products: product,
		sigle: getSigle(variable),
		priceCategories: state.entity.priceCategory,
		floors: state.entity.floor,
		nbTempsDenvoi: getNbTempsDenvoi(state.entity.variable),
		productMenu: state.entity.productMenu,
		supplementCategory: state.entity.supplementCategory,
		placeSends: state.entity.placeSend,
		retailPriceTypes: state.entity.retailPriceType,
		color: state.entity.color,
		taxes: state.entity.tax,
		productTypes: state.entity.productType,
		accessExclusion: getAccess(access, 'exclusion'),
		check_sku: getVariableServer(variableServer, 'check_sku'),
		check_sku_int: getVariableServer(variableServer, 'check_sku_int'),
		defaultColor: getDefaultColor(state.user),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		notificationAction: bindActionCreators(notificationAction, dispatch),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PopupProduct);
