import { buffers } from 'redux-saga';
import { take, actionChannel, put } from 'redux-saga/effects';
import { ADD_LOADING, SUBSTRACT_LOADING } from '../constants/loading';

const loaderBuffer = buffers.expanding();
export function* requestReceiver() {
	const requestChan = yield actionChannel('REQUESTING', loaderBuffer);
	while (true) {
		yield take(requestChan);
		yield put({ type: ADD_LOADING });
	}
}

export function* requestSuccessLoadingSaga() {
	const requestChan = yield actionChannel(
		action =>
			/^REQUEST_SUCCESS/.test(action.type) ||
			/^REQUEST_REJECT/.test(action.type) ||
			/^REQUEST_ERROR/.test(action.type),
		buffers.expanding(10)
	);
	while (true) {
		yield take(requestChan);
		yield put({ type: SUBSTRACT_LOADING });
	}
}
