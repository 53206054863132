import {
	RECEIVE_IMPORT_CATEGORY,
	RECEIVE_IMPORT_PRODUCT,
	PRE_IMPORT_CATEGORY,
	PRE_IMPORT_PRODUCT,
	DISABLE_IMPORT,
	ACTIVE_IMPORT,
} from './../constants/import';

export const activeImport = () => {
	return { type: ACTIVE_IMPORT };
};

export const disableImport = () => {
	return { type: DISABLE_IMPORT };
};

export const importProduct = id_menu => {
	return { type: PRE_IMPORT_PRODUCT, id_menu };
};

export const importCategory = id_menu => {
	return { type: PRE_IMPORT_CATEGORY, id_menu };
};
