import React from 'react';
import PropTypes from 'prop-types';
import ProductPreview from '../ProductPreview';
import { I18n } from 'react-redux-i18n';
import classNames from 'classnames';
import RemoveIcon from '@material-ui/icons/Remove';
import Badge from '@material-ui/core/Badge';
import Measure from 'react-measure';

const createDataId = (val, customDataId) => {
	if (customDataId) {
		if (!val.isSpace) {
			return customDataId + ',' + val.weight + ',' + val.id;
		}
		return customDataId + ',' + val.weight + ',space';
	} else {
		if (!val.isSpace) {
			return '1,' + val.weight + ',' + val.id;
		}
		return 'space,' + val.weight;
	}
};

/**
 *
 * @param {Array} items
 *
 * items : [{
 *  id: def0-1,
 *  weight: 1,
 *  price: 10,
 *  backgroundColor: '#333',
 *  name: test,
 *  isSpace: false
 * }]
 */
const ItemPreviewerDraggable = React.memo(
	({
		items,
		onClickRemoveItem,
		onClick,
		onSize,
		size,
		colors,
		customDataId,
		renderTwoCols,
	}) => {
		size = size || {};
		const spaceStyle = {
			height: 140,
			width: '100%',
			maxWidth: size.width < 300 ? size.width : 300,
			marginLeft: 'auto',
			marginRight: 'auto',
			padding: 0,
			backgroundColor: '#f3f3f3',
			textAlign: 'center',
			lineHeight: '140px',
			textTransform: 'uppercase',
		};

		const classesItem = ['stylizedItem', 'gridItem'];
		if (renderTwoCols) classesItem.push('gridItem50');

		const renderContent = (children, item) => {
			const styleContainer = {
				marginRight: 'auto',
				marginLeft: 'auto',
				width: '100%',
			};
			return item.canDelete ? (
				<Badge
					badgeContent={
						<RemoveIcon
							onClick={e =>
								onClickRemoveItem(e, item.id, item.weight, item.isSpace)
							}
							style={{ fontSize: 18 }}
						/>
					}
					classes={{ badge: 'badge-reweight__remove' }}
					style={styleContainer}
					color={'error'}>
					{children}
				</Badge>
			) : (
				<div style={styleContainer}>{children}</div>
			);
		};

		return items.map((val, index) => {
			return index === 0 ? (
				<div
					className={classNames(classesItem)}
					data-id={createDataId(val, customDataId)}
					key={val.id}>
					{renderContent(
						!val.isSpace ? (
							<div
								className={'product-preview container'}
								style={{ width: '100%', maxWidth: size.width }}>
								<ProductPreview
									name={val.name}
									backgroundColor={val.backgroundColor}
									price={val.price}
									prices={val.prices}
									free_price={!!val.is_free_price || false}
									idRetailPriceType={val.id_retail_price_type || '0'}
									disabled={val.active != null && !val.active}
									onClick={() => {
										if (onClick) onClick(val);
									}}
								/>
							</div>
						) : (
							<div style={spaceStyle}>{I18n.t('product.space')}</div>
						),
						val
					)}
				</div>
			) : (
				<div
					className={classNames(classesItem)}
					key={val.id}
					data-id={createDataId(val, customDataId)}>
					{renderContent(
						!val.isSpace ? (
							<div
								onClick={() => {
									if (onClick) onClick(val);
								}}
								className={'product-preview container'}
								style={{ width: '100%', maxWidth: size.width }}>
								<ProductPreview
									name={val.name}
									backgroundColor={val.backgroundColor}
									price={val.price}
									prices={val.prices}
									colors={colors}
									free_price={!!val.is_free_price || false}
									idRetailPriceType={val.id_retail_price_type || '0'}
									disabled={val.active != null && !val.active}
								/>
							</div>
						) : (
							<div style={spaceStyle}>{I18n.t('product.space')}</div>
						),
						val
					)}
				</div>
			);
		});
	}
);

ItemPreviewerDraggable.propTypes = {
	items: PropTypes.array,
	onClickRemoveItem: PropTypes.func,
	onClick: PropTypes.func,
};

export default ItemPreviewerDraggable;
