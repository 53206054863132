import { RECEIVE_FLOOR } from '../constants/floor';

const initialState = [];

const reducer = function floorReducer(state = initialState, action) {
	const newState = { ...state };
	switch (action.type) {
		case RECEIVE_FLOOR:
			if (action.response.result.length === 0) {
				return newState;
			}
			const floor = action.response.result;
			return floor;
		default:
			return state;
	}
};

export default reducer;
