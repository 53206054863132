import React, { Component } from 'react';
import SvgIcon from '../../SvgIcon';
import DeleteCell from './DeleteCell';

class SaveOrDeleteCell extends Component {
	render() {
		const { value, onSaveClick, onDeleteClick } = this.props;
		return (
			<div className="action-button">
				<button
					className={'edit'}
					style={{
						backgroundColor: 'rgb(232, 66, 73)',
					}}
					onClick={ev => {
						return onSaveClick(value);
					}}>
					<SvgIcon icon="plus" />
				</button>

				<DeleteCell onDeleteClick={onDeleteClick} value={value} />
			</div>
		);
	}
}

export default SaveOrDeleteCell;
