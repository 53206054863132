import { put } from 'redux-saga/effects';

export default function* setUser(decodedAccessToken, token) {
	const user = {
		id: decodedAccessToken.id,
		token: token,
		username: decodedAccessToken.username,
		email: decodedAccessToken.email,
		id_company: decodedAccessToken.account_id,
	};
	yield put({ type: 'ADD_USER', user });
}
