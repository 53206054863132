import React, { Component } from 'react';
import { List, AutoSizer, WindowScroller } from 'react-virtualized';
import { I18n, Translate } from 'react-redux-i18n';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import ColorBlock from './ColorBlock';
import CheckEmptyIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/CheckBox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { deepEqual } from '../utils';

class ProductAssignatorProductList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			products: props.products,
		};
		this._rowRenderer = this._rowRenderer.bind(this);
		this._noRowsRenderer = this._noRowsRenderer.bind(this);
		this.listRef = React.createRef();
	}

	_rowRenderer({ index, isScrolling, key, style }) {
		const style2 = {
			btnSave: {
				float: 'right',
				width: 200,
				height: 50,
			},
			searchInput: {
				fontSize: '30px',
				backgroundColor: '#e3e3e3',
				paddingLeft: '10px',
				paddingRight: '10px',
				borderBottom: '5px solid #4ea3ff',
			},
			tagContainer: {
				width: '100%',
				height: 52,
				float: 'right',
			},
			tagPaper: {
				padding: 10,
				whiteSpace: 'nowrap',
				height: 52,
				overflowX: 'auto',
				//display: selectedProducts.length > 0 ? "block" : "none"
			},
			tagEmpty: {
				color: '#898989',
				fontStyle: 'italic',
				fontWeight: 'bold',
				position: 'relative',
				top: 7,
			},
			chipMargin: {
				marginRight: 5,
			},
			categoryListContainer: {
				width: '20%',
				backgroundColor: '#fff',
				height: '100%',
				overflow: 'auto',
				marginTop: 20,
				float: 'left',
			},
			categoryListItemAll: {
				paddingRight: 0,
				paddingLeft: 60,
			},
			categoryListItem: {
				paddingRight: 0,
			},
			productListItemAll: {
				paddingRight: 0,
				backgroundColor: '#fff',
				cursor: 'pointer',
				height: '60px',
			},
			productListContainer: {
				width: 'calc(80% - 25px)',
				marginLeft: 25,
				//backgroundColor: "#fff",
				height: '100%',
				overflow: 'auto',
				marginTop: 20,
				float: 'right',
				borderRadius: '20px',
			},
			productListItem: {
				backgroundColor: '#fff',
				borderBottom: '1px solid #cdcdcd',
			},
			productListItemNoActive: {
				backgroundColor: '#0000001f',
				borderBottom: '1px solid #cdcdcd',
			},
			checkIcon: {
				cursor: 'pointer',
			},
		};

		const CheckIconFilled = this.props.checkIcon || CheckIcon;
		if (index > 0) {
			const product = this.state.products[index - 1];
			let labelPrices = '';
			if (this.props.isMenu) {
				let price = product.price;
				let sigle = this.props.sigle;
				if (product.is_free_price) {
					price = I18n.t('product.free_price');
					sigle = '';
				}
				if (product.id_retail_price_type !== '0') {
					const retailPriceType = this.props.retailPriceTypes.find(
						r => r.id === product.id_retail_price_type
					);
					const labelRetailPrice = retailPriceType
						? '/' + I18n.t(retailPriceType.unit)
						: '';
					sigle += labelRetailPrice;
				}
				labelPrices =
					' - ' + I18n.t('category.product.price') + ' : ' + price + sigle;
				if (product.price == 0)
					labelPrices +=
						' - ' +
						I18n.t('category.product.alert_price', {
							currency: this.props.sigle,
						});
			} else {
				let price = product.price;
				let sigle = this.props.sigle;
				if (product.is_free_price) {
					price = I18n.t('product.free_price');
					sigle = '';
				}
				if (product.id_retail_price_type !== '0') {
					const retailPriceType = this.props.retailPriceTypes.find(
						r => r.id === product.id_retail_price_type
					);
					const labelRetailPrice = retailPriceType
						? '/' + I18n.t(retailPriceType.unit)
						: '';
					sigle += labelRetailPrice;
				}
				labelPrices =
					'(' +
					price +
					(product.prices.length
						? ' / ' + product.prices.map(p => p.price).join(' / ')
						: '') +
					') ' +
					sigle;
			}

			const type = this.props.productType.find(
				pt => pt.id === product.id_product_type
			);
			let infosProduct = `${I18n.t('category.product.type')} : ${
				type ? type.name : ''
			} / `;

			const tax = this.props.tax.find(t => t.id === product.id_tax_rate);
			infosProduct += `${I18n.t('category.product.tax')} : ${
				tax ? tax.name : ''
			} / `;

			const placeSend = this.props.placeSend.find(
				ps => ps.id === product.id_place_send
			);
			const placeSend2 = this.props.placeSend.find(
				ps => ps.id === product.id_place_send_2
			);
			infosProduct += `${
				placeSend2
					? I18n.t('category.product.places_send')
					: I18n.t('category.product.place_send')
			} : ${placeSend ? placeSend.name : ''} ${
				placeSend2 ? ', ' + placeSend2.name : ''
			} `;

			const disabled =
				(this.props.isMenu && product.price == 0) ||
				(this.props.categorySelected === 'already_selected' &&
					!this.props.canDeselect);
			return (
				<React.Fragment key={product.id}>
					<ListItem
						button
						style={
							product.active
								? { ...style2.productListItem, ...style }
								: { ...style2.productListItemNoActive, ...style }
						}
						onClick={this.props.handleToggle(product.id)}
						disabled={disabled}>
						<ColorBlock value={product.id_color} />{' '}
						<ListItemText
							primary={product.name + ' ' + labelPrices}
							secondary={infosProduct}
						/>{' '}
						<ListItemSecondaryAction className={'check_product_menu3'}>
							{' '}
							{disabled ? (
								''
							) : this.props.selectedProducts.indexOf(product.id) !== -1 ? (
								<CheckIconFilled
									style={style2.checkIcon}
									onClick={this.props.handleToggle(product.id)}
								/>
							) : (
								<CheckEmptyIcon
									style={style2.checkIcon}
									onClick={this.props.handleToggle(product.id)}
								/>
							)}{' '}
						</ListItemSecondaryAction>{' '}
					</ListItem>
				</React.Fragment>
			);
		} else {
			return this.props.categorySelected !== 'already_selected' ? (
				<React.Fragment key={key}>
					<ListItem
						button
						style={style2.productListItemAll}
						onClick={
							this.props.allSelected
								? this.props.handleDeselectAll(this.state.products)
								: this.props.handleSelectAll(this.state.products)
						}>
						<ListItemText primary={I18n.t('product.select_all')} />{' '}
						<ListItemSecondaryAction>
							{this.props.allSelected ? (
								<CheckIconFilled
									style={style.checkIcon}
									onClick={this.props.handleDeselectAll(this.state.products)}
								/>
							) : (
								<CheckEmptyIcon
									style={style.checkIcon}
									onClick={this.props.handleSelectAll(this.state.products)}
								/>
							)}{' '}
						</ListItemSecondaryAction>{' '}
					</ListItem>
				</React.Fragment>
			) : null;
		}
	}

	_noRowsRenderer() {
		return (
			<p className={'label-empty'}>
				{' '}
				<Translate value={'product.category_empty'} />
			</p>
		);
	}

	shouldComponentUpdate(nextProps) {
		if (!deepEqual(this.props, nextProps)) {
			return true;
		}

		return false;
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ products: nextProps.products }, () => {
			this.listRef.current.forceUpdateGrid();
		});
	}

	render() {
		const { products } = this.state;
		const style = {
			productListContainer: {
				width: 'calc(80% - 25px)',
				marginLeft: 25,
				height: '100%',
				overflow: 'auto',
				marginTop: 20,
				float: 'right',
				borderRadius: '20px',
			},
		};

		return (
			<div
				className={'list_menu_right_menu3'}
				style={style.productListContainer}>
				<AutoSizer>
					{({ width, height }) => (
						<List
							ref={this.listRef}
							height={height}
							width={width}
							rowHeight={60}
							rowRenderer={this._rowRenderer}
							noRowsRenderer={this._noRowsRenderer}
							rowCount={products.length + 1}
							overscanRowCount={50}
						/>
					)}
				</AutoSizer>
			</div>
		);
	}
}

export default ProductAssignatorProductList;
