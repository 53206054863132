import {
	PRE_DELETE_CATEGORY,
	DELETE_CATEGORY,
	PRE_PATCH_CATEGORY,
	PATCH_CATEGORY,
	PRE_CREATE_CATEGORY,
	CREATE_CATEGORY,
	PRE_UPDATE_CATEGORY,
	UPDATE_CATEGORY,
	PRE_REWEIGHT_CATEGORY,
	REWEIGHT_CATEGORY,
} from '../constants/category';
import { RECEIVE_PRODUCT } from '../constants/product';
import { buffers } from 'redux-saga';
import { call, put, take, actionChannel, select } from 'redux-saga/effects';
import { requestHandler } from './fetcher.saga';
import { ENTITY_EXCLUSION } from '../utils';
import { getMenuSelected } from './login';

const PRE_REQUEST_DELETE_CATEGORY = 'PRE_REQUEST_DELETE_CATEGORY';
const PRE_REQUEST_PATCH_CATEGORY = 'PRE_REQUEST_PATCH_CATEGORY';
const PRE_REQUEST_CREATE_CATEGORY = 'PRE_REQUEST_CREATE_CATEGORY';
const PRE_REQUEST_UPDATE_CATEGORY = 'PRE_REQUEST_UPDATE_CATEGORY';
const PRE_REQUEST_REWEIGHT_CATEGORY = 'PRE_REQUEST_REWEIGHT_CATEGORY';

const REQUESTS_DELETE_CATEGORY_FINISHED = 'REQUESTS_DELETE_CATEGORY_FINISHED';
const REQUESTS_PATCH_CATEGORY_FINISHED = 'REQUESTS_PATCH_CATEGORY_FINISHED';
const REQUESTS_CREATE_CATEGORY_FINISHED = 'REQUESTS_CREATE_CATEGORY_FINISHED';
const REQUESTS_UPDATE_CATEGORY_FINISHED = 'REQUESTS_UPDATE_CATEGORY_FINISHED';
const REQUESTS_REWEIGHT_CATEGORY_FINISHED = 'REQUESTS_UPDATE_CATEGORY_FINISHED';

const ENTITY = 'category';
const DELETE_METHOD = 'DELETE';
const POST_METHOD = 'POST';
const PATCH_METHOD = 'PATCH';

////////////
// DELETE //
////////////
export function* preDeleteCategory() {
	while (true) {
		const action = yield take(PRE_DELETE_CATEGORY);
		const { ids, id_menu } = action;
		let length = ids.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_DELETE_CATEGORY,
				body: { id: ids[i], id_menu: id_menu },
				index: i,
				totalLength: length,
			});
		}
		yield take(REQUESTS_DELETE_CATEGORY_FINISHED);
		//yield put({ type : DELETE_CATEGORY, ids : ids});
		const categories = yield call(
			requestHandler,
			'category',
			'GET',
			true,
			null,
			'',
			{
				id_menu: id_menu,
			}
		);
		const products = yield call(
			requestHandler,
			'product',
			'GET',
			true,
			null,
			'',
			{
				id_menu: id_menu,
			}
		);
		yield put({ type: DELETE_CATEGORY, response: categories.result });
		yield put({ type: RECEIVE_PRODUCT, response: products.result });
	}
}

export function* watchDeleteCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_DELETE_CATEGORY,
		buffers.expanding()
	);

	while (true) {
		const action = yield take(requestChan);

		yield call(requestHandler, ENTITY, DELETE_METHOD, true, action.body);
		if (action.index === action.totalLength - 1) {
			yield put({ type: REQUESTS_DELETE_CATEGORY_FINISHED });
		}
	}
}

////////////
// PATCH  //
////////////
export function* prePatchCategory() {
	while (true) {
		const action = yield take(PRE_PATCH_CATEGORY);
		const { data } = action;
		let length = data.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_PATCH_CATEGORY,
				body: { patchs: data[i] },
				index: i,
				totalLength: length,
			});
		}

		yield take(REQUESTS_PATCH_CATEGORY_FINISHED);

		yield put({ type: PATCH_CATEGORY });
	}
}

export function* watchPatchCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_PATCH_CATEGORY,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);
		const id_menu = yield call(getMenuSelected);
		yield call(requestHandler, ENTITY, PATCH_METHOD, true, {
			...action.body.patchs,
			id_menu,
		});

		if (action.index === action.totalLength - 1) {
			yield put({ type: REQUESTS_PATCH_CATEGORY_FINISHED });
		}
	}
}

////////////
// CREATE //
////////////
export function* preCreateCategory() {
	while (true) {
		const action = yield take(PRE_CREATE_CATEGORY);
		const { newCategories } = action;
		let length = newCategories.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_CREATE_CATEGORY,
				body: { category: newCategories[i] },
				index: i,
				totalLength: length,
			});
		}
		yield take(REQUESTS_CREATE_CATEGORY_FINISHED);
		const id_menu = yield call(getMenuSelected);
		const categories = yield call(
			requestHandler,
			'category',
			'GET',
			true,
			null,
			'',
			{
				id_menu: id_menu,
			}
		);
		const products = yield call(
			requestHandler,
			'product',
			'GET',
			true,
			null,
			'',
			{
				id_menu: id_menu,
			}
		);
		yield put({ type: CREATE_CATEGORY, response: categories.result });
		yield put({ type: RECEIVE_PRODUCT, response: products.result });
	}
}

export function* watchCreateCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_CREATE_CATEGORY,
		buffers.expanding()
	);
	let createdCategories = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			action.body.category
		);
		let newCategory = { ...action.body.category };
		newCategory.id = result.result.result;

		createdCategories.push(newCategory);

		if (action.index === action.totalLength - 1) {
			yield put({
				type: REQUESTS_CREATE_CATEGORY_FINISHED,
				createdCategories: createdCategories,
			});
			createdCategories = [];
		}
	}
}

////////////
// UPDATE //
////////////
export function* preUpdateCategory() {
	const requestChan = yield actionChannel(
		PRE_UPDATE_CATEGORY,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);
		const { ids, entity } = action;
		const idsLength = ids.length;
		const id_menu = yield call(getMenuSelected);
		for (let i = 0; i < idsLength; i++) {
			let dataToSend;

			if (entity === ENTITY_EXCLUSION) {
				dataToSend = {
					id: ids[i],
					exclusions: action.newValues[i].exclusions,
					id_menu: id_menu,
				};
			} else {
				const key = Object.keys(action.newValues[i])[0];
				dataToSend = {
					id: ids[i],
					key: key,
					value: action.newValues[i][key],
					id_menu: id_menu,
				};
			}

			yield put({
				type: PRE_REQUEST_UPDATE_CATEGORY,
				body: dataToSend,
				index: i,
				totalLength: idsLength,
				entity,
			});
		}
		yield take(REQUESTS_UPDATE_CATEGORY_FINISHED);

		const products = yield call(
			requestHandler,
			'product',
			'GET',
			true,
			null,
			'',
			{
				id_menu: id_menu,
			}
		);
		const categories = yield call(
			requestHandler,
			'category',
			'GET',
			true,
			null,
			'',
			{
				id_menu: id_menu,
			}
		);
		yield put({ type: UPDATE_CATEGORY, response: categories.result });
		yield put({ type: RECEIVE_PRODUCT, response: products.result });
	}
}

export function* watchUpdateCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_UPDATE_CATEGORY,
		buffers.expanding()
	);
	let errored = [];
	while (true) {
		const action = yield take(requestChan);
		const result = yield call(
			requestHandler,
			ENTITY,
			PATCH_METHOD,
			true,
			action.body,
			action.entity ? '/' + action.entity : ''
		);

		if (result.result === false) {
			errored.push(action.body.id);
		} else {
			errored.push(false);
		}
		if (action.index === action.totalLength - 1) {
			yield put({ type: REQUESTS_UPDATE_CATEGORY_FINISHED, errored: errored });
			errored = [];
		}
	}
}

//////////////
// REWEIGHT //
//////////////
export function* preReweightCategory() {
	while (true) {
		const action = yield take(PRE_REWEIGHT_CATEGORY);
		const { newValues } = action;
		let length = newValues.length;
		const id_menu = yield call(getMenuSelected);

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_REWEIGHT_CATEGORY,
				body: { entity: newValues[i], id_menu },
				index: i,
				totalLength: length,
			});
		}
		yield take(REQUESTS_REWEIGHT_CATEGORY_FINISHED);

		const categories = yield call(
			requestHandler,
			'category',
			'GET',
			true,
			null,
			'',
			{
				id_menu: id_menu,
			}
		);
		const products = yield call(
			requestHandler,
			'product',
			'GET',
			true,
			null,
			'',
			{
				id_menu: id_menu,
			}
		);
		yield put({ type: REWEIGHT_CATEGORY, response: categories.result });
		yield put({ type: RECEIVE_PRODUCT, response: products.result });
	}
}

export function* watchReweightCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_REWEIGHT_CATEGORY,
		buffers.expanding()
	);
	let successfullRequest = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			PATCH_METHOD,
			true,
			action.body,
			'/weight'
		);
		if (result.result && result.result.status == 200) {
			successfullRequest.push(action.body);
		}

		if (action.index === action.totalLength - 1) {
			yield put({
				type: REQUESTS_REWEIGHT_CATEGORY_FINISHED,
				successfullRequest: successfullRequest,
			});
			successfullRequest = [];
		}
	}
}
