import {
	DELETE_SUPPLEMENT_CATEGORY,
	RECEIVE_SUPPLEMENT_CATEGORY,
	REFRESH_SUPPLEMENT_CATEGORIES_LIST,
	UPDATE_SUPPLEMENT_CATEGORY,
	CREATE_SUPPLEMENT_CATEGORY,
} from '../constants/supplementCategory';

import findIndex from 'lodash/findIndex';
import _forEach from 'lodash/forEach';
import _reject from 'lodash/reject';
import _find from 'lodash/find';

const initialState = [];

const reducer = function supplementCategoryReducer(
	state = initialState,
	action
) {
	const newState = [...state];
	const { ids } = action;
	switch (action.type) {
		case RECEIVE_SUPPLEMENT_CATEGORY:
			// if (action.response.result.length === 0) {
			//   return newState;
			// }
			const supplementCategory = action.response.result;
			return supplementCategory;

		case DELETE_SUPPLEMENT_CATEGORY:
			return _reject(newState, sc => {
				return _find(ids, id => id === sc.id);
			});

		case UPDATE_SUPPLEMENT_CATEGORY:
			_forEach(ids, id => {
				const elementToUpdateIndex = findIndex(newState, p => p.id === id);
				const elementToUpdate = newState[elementToUpdateIndex];
				const updatedElement = { ...elementToUpdate };
				_forEach(action.newValues, value => {
					updatedElement[value.key] = value.value;
				});
				newState[elementToUpdateIndex] = updatedElement;
			});
			return newState;

		case REFRESH_SUPPLEMENT_CATEGORIES_LIST:
			const { newList } = action;

			return newList;

		case CREATE_SUPPLEMENT_CATEGORY:
			const { newSupplementCategories } = action;

			return [...newState, ...newSupplementCategories];
		default:
			return state;
	}
};

export default reducer;
