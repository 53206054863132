import { buffers } from 'redux-saga';
import {
	call,
	put,
	take,
	actionChannel,
	all,
	select,
} from 'redux-saga/effects';
import { requestHandler } from './fetcher.saga';
import {
	CREATE_MENU,
	PRE_CREATE_MENU,
	PRE_UPDATE_MENU,
	UPDATE_MENU,
	PRE_DELETE_MENU,
	DELETE_MENU,
	DELETE_MENU_MASTER_MONO,
	PRE_PATCH_MENU,
	PATCH_MENU,
	FETCH_MENU,
	BIND_MENU,
	PRE_FETCH_MENU,
	PRE_COPY_MENU,
	PRE_BIND_MENU,
	PRE_UNTIE_MENU,
	UNTIE_MENU,
	RECEIVE_MENU,
	RECEIVE_MENU_MASTER_MONO,
	PRE_EDIT_MENU,
	EDIT_MENU,
	PRE_SELECT_MENU,
} from '../constants/menu';

import {
	UPDATE_ID_MENU_SELECTED,
	UPDATE_SESSION_ID,
} from '../constants/variableServer';
import { browserHistory } from 'react-router';

import { getMenuSelected } from './login';
import { fetchAll } from './login';

import { sendUnloadBeacon } from '../utils';
import { ADD_LOADING, SUBSTRACT_LOADING } from '../constants/loading';

const ENTITY = 'menu';
const DELETE_METHOD = 'DELETE';
const POST_METHOD = 'POST';
const PUT_METHOD = 'PUT';
const PATCH_METHOD = 'PATCH';

const PRE_REQUEST_UPDATE_MENU = 'PRE_REQUEST_UPDATE_MENU';
const PRE_REQUEST_CREATE_MENU = 'PRE_REQUEST_CREATE_MENU';
const PRE_REQUEST_DELETE_MENU = 'PRE_REQUEST_DELETE_MENU';
const PRE_REQUEST_BIND_MENU = 'PRE_REQUEST_BIND_MENU';
const PRE_REQUEST_PATCH_MENU = 'PRE_REQUEST_PATCH_MENU';
const PRE_REQUEST_UNTIE_MENU = 'PRE_REQUEST_UNTIE_MENU';
const PRE_REQUEST_COPY_MENU = 'PRE_REQUEST_COPY_MENU';
const PRE_REQUEST_EDIT_MENU = 'PRE_REQUEST_EDIT_MENU';
const PRE_REQUEST_SELECT_MENU = 'PRE_REQUEST_SELECT_MENU';

const REQUESTS_UPDATE_MENU_FINISHED = 'REQUESTS_UPDATE_MENU_FINISHED';
const REQUESTS_CREATE_MENU_FINISHED = 'REQUESTS_CREATE_MENU_FINISHED';
const REQUESTS_DELETE_MENU_FINISHED = 'REQUESTS_DELETE_MENU_FINISHED';
const REQUESTS_PATCH_MENU_FINISHED = 'REQUESTS_PATCH_MENU_FINISHED';
const REQUESTS_COPY_MENU_FINISHED = 'REQUESTS_COPY_MENU_FINISHED';
const REQUESTS_BIND_MENU_FINISHED = 'REQUESTS_BIND_MENU_FINISHED';
const REQUESTS_UNTIE_MENU_FINISHED = 'REQUESTS_UNTIE_MENU_FINISHED';
const REQUESTS_EDIT_MENU_FINISHED = 'REQUESTS_EDIT_MENU_FINISHED';
const REQUESTS_SELECT_MENU_FINISHED = 'REQUESTS_SELECT_MENU_FINISHED';

// ////////////
// // FETCH  //
// ////////////
export function* fetchMenuInfo() {
	while (true) {
		const action = yield take(PRE_FETCH_MENU);
		const { id_menu } = action;
		const results = yield all([
			call(requestHandler, 'category', 'GET', true, null, '', {
				id_menu: id_menu,
			}),
			call(requestHandler, 'supplementCategory', 'GET', true, null, '', {
				id_menu: id_menu,
			}),
			call(requestHandler, 'product', 'GET', true, null, '', {
				id_menu: id_menu,
			}),
			call(requestHandler, 'productMenu', 'GET', true, null, '', {
				id_menu: id_menu,
			}),
			call(requestHandler, 'supplement', 'GET', true, null, '', {
				id_menu: id_menu,
			}),
			call(requestHandler, 'productType'),
			call(requestHandler, 'priceCategory'),
		]);
		const formatedResult = {};
		results.forEach(entity => {
			formatedResult[entity.entity] = entity.result.result;
		});
		yield put({ type: FETCH_MENU, response: formatedResult, id_menu });
	}
}

// ////////////
// // DELETE //
// ////////////
export function* preDeleteMenu() {
	while (true) {
		const action = yield take(PRE_DELETE_MENU);
		const { ids, account_id } = action;
		let length = ids.length;
		const id_menu_selected = yield call(getMenuSelected);
		yield call(setMasterMonoChild, account_id);

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_DELETE_MENU,
				body: { id: ids[i] },
				index: i,
				totalLength: length,
			});
		}
		yield take(REQUESTS_DELETE_MENU_FINISHED);
		yield call(setMasterMonoChild);
		if (id_menu_selected === ids[0]) {
			yield put({ type: UPDATE_ID_MENU_SELECTED, response: '' });
		}

		if (account_id) {
			yield put({ type: DELETE_MENU_MASTER_MONO, ids: ids, account_id });
		} else {
			yield put({ type: DELETE_MENU, ids: ids });
		}
	}
}

export function* watchDeleteMenu() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_DELETE_MENU,
		buffers.expanding()
	);

	while (true) {
		const action = yield take(requestChan);

		yield call(requestHandler, ENTITY, DELETE_METHOD, true, action.body);
		if (action.index === action.totalLength - 1) {
			yield put({ type: REQUESTS_DELETE_MENU_FINISHED });
		}
	}
}

// ////////////
// // PATCH  //
// ////////////
export function* prePatchMenu() {
	while (true) {
		const action = yield take(PRE_PATCH_MENU);
		const { ids } = action;
		let length = ids.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_PATCH_MENU,
				body: { id: ids[i] },
				index: i,
				totalLength: length,
			});
		}

		yield take(REQUESTS_PATCH_MENU_FINISHED);
		const idsRestored = ids;
		yield put({ type: PATCH_MENU, idsRestored: idsRestored });
	}
}

export function* watchPatchMenu() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_PATCH_MENU,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);

		yield call(requestHandler, ENTITY, PATCH_METHOD, true, action.body);

		if (action.index === action.totalLength - 1) {
			yield put({ type: REQUESTS_PATCH_MENU_FINISHED });
		}
	}
}

////////////
// CREATE //
////////////
export function* preCreateMenu() {
	while (true) {
		const action = yield take(PRE_CREATE_MENU);
		const { newMenu, isCopy } = action;
		let length = newMenu.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_CREATE_MENU,
				body: newMenu[i],
				index: i,
				totalLength: length,
			});
		}
		const { createdMenu, listMenu } = yield take(REQUESTS_CREATE_MENU_FINISHED);
		yield put({ type: RECEIVE_MENU, response: listMenu.result });
		/*     yield put({ type: CREATE_MENU, newMenu: createdMenu });
		 */
	}
}

export function* watchCreateMenu() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_CREATE_MENU,
		buffers.expanding()
	);
	let createdMenu = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			action.body
		);
		let newMenu = { ...action.body.menu };
		newMenu.id = result.result.result;

		createdMenu.push(newMenu);

		if (action.index === action.totalLength - 1) {
			const id_menu = yield call(getMenuSelected);
			const listMenu = yield call(
				requestHandler,
				'menu',
				'GET',
				true,
				null,
				'',
				{
					id_menu: id_menu,
				}
			);
			yield put({
				type: REQUESTS_CREATE_MENU_FINISHED,
				createdMenu: createdMenu,
				listMenu: listMenu,
			});
			createdMenu = [];
		}
	}
}

////////////
// UPDATE //
////////////
export function* preUpdateMenu() {
	while (true) {
		const action = yield take(PRE_UPDATE_MENU);
		const { updatedMenu } = action;
		let length = updatedMenu.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_UPDATE_MENU,
				body: { menu: updatedMenu[i] },
				index: i,
				totalLength: length,
			});
		}
		yield take(REQUESTS_UPDATE_MENU_FINISHED);
		yield put({ type: UPDATE_MENU, updatedMenu: updatedMenu });
	}
}

export function* watchUpdateMenu() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_UPDATE_MENU,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);

		yield call(requestHandler, ENTITY, PUT_METHOD, true, action.body.menu);

		if (action.index === action.totalLength - 1) {
			yield put({ type: REQUESTS_UPDATE_MENU_FINISHED });
		}
	}
}

////////////
// COPY //
////////////
export function* preCopyMenu() {
	while (true) {
		const action = yield take(PRE_COPY_MENU);
		const { newMenu } = action;
		let length = newMenu.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_COPY_MENU,
				body: newMenu[i],
				index: i,
				totalLength: length,
			});
		}
		const { createdMenu, listMenu } = yield take(REQUESTS_COPY_MENU_FINISHED);
		yield put({ type: CREATE_MENU, newMenu: createdMenu });
		yield put({ type: RECEIVE_MENU, response: listMenu.result });
	}
}

export function* watchCopyMenu() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_COPY_MENU,
		buffers.expanding()
	);
	let createdMenu = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			action.body,
			'/copy'
		);
		let newMenu = { ...action.body.menu };
		newMenu.id = result.result.result;

		createdMenu.push(newMenu);

		if (action.index === action.totalLength - 1) {
			const id_menu = yield call(getMenuSelected);
			const listMenu = yield call(
				requestHandler,
				'menu',
				'GET',
				true,
				null,
				'',
				{
					id_menu: id_menu,
				}
			);
			yield put({
				type: REQUESTS_COPY_MENU_FINISHED,
				createdMenu: createdMenu,
				listMenu: listMenu,
			});
			createdMenu = [];
		}
	}
}

////////////
// BIND //
////////////

export function* preBindMenu() {
	while (true) {
		const action = yield take(PRE_BIND_MENU);
		const { children } = action;

		yield put({
			type: PRE_REQUEST_BIND_MENU,
			body: children,
		});

		const result = yield take(REQUESTS_BIND_MENU_FINISHED);
		yield put({ type: BIND_MENU, bindedChildren: children });
		yield put({ type: RECEIVE_MENU, response: result.list.result });
	}
}

export function* watchBindMenu() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_BIND_MENU,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);

		yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			action.body,
			'/child'
		);

		const id_menu = yield call(getMenuSelected);
		const listMenu = yield call(requestHandler, 'menu', 'GET', true, null, '', {
			id_menu: id_menu,
		});

		yield put({
			type: REQUESTS_BIND_MENU_FINISHED,
			list: listMenu,
		});
	}
}

////////////
/// EDIT ///
////////////

export function* preEditMenu() {
	while (true) {
		const action = yield take(PRE_EDIT_MENU);
		const { body, isMaster, accountId } = action;

		yield call(setMasterMonoChild, accountId);

		yield put({
			type: PRE_REQUEST_EDIT_MENU,
			body: body,
			isMaster: isMaster,
			isMasterMono: !!accountId,
		});

		const { editedMenu } = yield take(REQUESTS_EDIT_MENU_FINISHED);

		yield call(setMasterMonoChild);
		yield put({ type: EDIT_MENU });
		if (accountId) {
			yield put({
				type: RECEIVE_MENU_MASTER_MONO,
				response: editedMenu.result,
			});
		} else {
			yield put({ type: RECEIVE_MENU, response: editedMenu.result });
		}
	}
}

export function* watchEditMenu() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_EDIT_MENU,
		buffers.expanding()
	);

	while (true) {
		const action = yield take(requestChan);

		if (action.isMaster) {
			yield call(
				requestHandler,
				ENTITY,
				PATCH_METHOD,
				true,
				{ ...action.body, apply_on_children: true },
				'/'
			);
		} else {
			yield call(
				requestHandler,
				ENTITY,
				PATCH_METHOD,
				true,
				action.body,
				'/child'
			);
		}

		let listMenu;
		if (action.isMasterMono) {
			listMenu = yield call(requestHandler, 'menuMasterMono');
		} else {
			const id_menu = yield call(getMenuSelected);
			listMenu = yield call(requestHandler, 'menu', 'GET', true, null, '', {
				id_menu: id_menu,
			});
		}

		yield put({
			type: REQUESTS_EDIT_MENU_FINISHED,
			editedMenu: listMenu,
		});
	}
}

////////////
/// UNTIE //
////////////

export function* preUntieMenu() {
	while (true) {
		const action = yield take(PRE_UNTIE_MENU);
		const { children } = action;

		yield put({
			type: PRE_REQUEST_UNTIE_MENU,
			body: children,
		});

		const result = yield take(REQUESTS_UNTIE_MENU_FINISHED);
		yield put({ type: UNTIE_MENU, untiedChildren: children });
		yield put({ type: RECEIVE_MENU, response: result.list.result });
	}
}

export function* watchUntieMenu() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_UNTIE_MENU,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);

		yield call(
			requestHandler,
			ENTITY,
			DELETE_METHOD,
			true,
			action.body,
			'/child'
		);

		const id_menu = yield call(getMenuSelected);
		const listMenu = yield call(requestHandler, 'menu', 'GET', true, null, '', {
			id_menu: id_menu,
		});

		yield put({
			type: REQUESTS_UNTIE_MENU_FINISHED,
			list: listMenu,
		});
	}
}

////////////
// SELECT //
////////////

export function* preSelectMenu() {
	while (true) {
		const action = yield take(PRE_SELECT_MENU);
		yield put({ type: ADD_LOADING });

		const { id, where, account_id } = action;
		const last_id_menu_selected = yield call(getMenuSelected);

		if (last_id_menu_selected !== id || account_id) {
			if (account_id) {
				yield call(sendUnloadBeacon);
				yield call(setMasterMonoChild, account_id);
				yield put({
					type: UPDATE_SESSION_ID,
					master_mono_use: true,
				});
			}

			window.localStorage.MENU_CHANGED = true;
			yield put({ type: 'INIT_RECEIVED_ALL' });
			yield put({ type: UPDATE_ID_MENU_SELECTED, response: id });
			yield put({
				type: PRE_REQUEST_SELECT_MENU,
				body: { id: id },
			});
			const result = yield take(REQUESTS_SELECT_MENU_FINISHED);
		}
		yield call(fetchAll);

		browserHistory.push(where);
	}
}

export function* watchSelectMenu() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_SELECT_MENU,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);

		yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			action.body,
			'/select'
		);

		yield put({
			type: REQUESTS_SELECT_MENU_FINISHED,
		});
	}
}

function setMasterMonoChild(id) {
	if (id) {
		window.sessionStorage.setItem('master_mono_child', id);
	} else {
		window.sessionStorage.removeItem('master_mono_child');
	}
}
