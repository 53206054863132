import React, { Component } from 'react';
import { MenuItem, TextField, Popover, Chip, Avatar } from '@material-ui/core';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import ColorPanel from './ColorPanel';
import ColorBlock from './ColorBlock';
import FilterIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/SortByAlpha';
import SearchIcon from '@material-ui/icons/Search';
import { getDefaultColor } from '../selectors/color.selector';
import { withStyles } from '@material-ui/core/styles';

const ChipClasses = {
	root: { backgroundColor: 'white' },
	avatar: {
		backgroundColor: '#e6e6e6',
	},
};

class FilterBar extends Component {
	constructor(props) {
		super(props);
		const ranges = ['0 - 25', '25 - 50', '50 - 75', '75 - 100', '100 -200'];
		const filters = [
			{
				id: 2,
				name: I18n.t('product.filter.color'),
				type: 'colorPicker',
				entity: 'color',
				productKey: 'id_color',
			},
			{
				id: 3,
				name: I18n.t('product.filter.price'),
				type: 'range',
				entity: 'price',
				productKey: 'price',
			},
			{
				id: 4,
				name: I18n.t('product.filter.taxe_rate'),
				type: 'select',
				entity: 'tax',
				productKey: 'id_tax_rate',
			},
			{
				id: 5,
				name: I18n.t('product.filter.product_type'),
				type: 'select',
				entity: 'productType',
				productKey: 'id_product_type',
			},
			{
				id: 6,
				name: I18n.t('product.filter.category'),
				type: 'select',
				entity: 'category',
				productKey: 'categories',
			},
			{
				id: 7,
				name: I18n.t('product.filter.place_send'),
				type: 'select',
				entity: 'placeSend',
				productKey: 'id_place_send',
			},
			{
				id: 8,
				name: I18n.t('product.filter.visible'),
				type: 'select',
				entity: 'visible',
				productKey: 'visible',
			},
		];
		const orderLabels = [
			I18n.t('product.sort.category_asc'),
			I18n.t('product.sort.category_desc'),
			I18n.t('product.sort.category_A_Z'),
			I18n.t('product.sort.category_Z_A'),
			I18n.t('product.sort.product_asc'),
			I18n.t('product.sort.product_desc'),
			I18n.t('product.sort.price_asc'),
			I18n.t('product.sort.price_desc'),
		];
		let sorts = [...props.sortList];
		sorts = sorts.map((s, i) => {
			s.name = orderLabels[i];
			return s;
		});
		let orderSelected = props.selectedSort || sorts[0];
		orderSelected.name = orderLabels[orderSelected.id];

		this.state = {
			open: false,
			selectedFilter: [
				{
					ids: [],
					filter: filters[0],
					value: null,
					texts: [],
				},
				{
					ids: [],
					filter: filters[1],
					value: null,
					texts: [],
				},
				{
					ids: [],
					filter: filters[2],
					value: null,
					texts: [],
				},
				{
					ids: [],
					filter: filters[3],
					value: null,
					texts: [],
				},
				{
					ids: [],
					filter: filters[4],
					value: null,
					texts: [],
				},
				{
					ids: [],
					filter: filters[5],
					value: null,
					texts: [],
				},
				{
					ids: [],
					filter: { productKey: 'name' },
					text: '',
				},
				{
					ids: [],
					filter: filters[6],
					value: null,
					texts: [],
				},
				{
					ids: [],
					filter: filters[7],
					value: null,
					texts: [],
				},
				{
					ids: [],
					filter: filters[8],
					value: null,
					texts: [],
				},
			],
			filterNeedValue: false,
			filterValueType: '',
			filterEntity: '',
			filterValue: '',
			rangeValue: { min: 0, max: 150 },
			filters: filters,
			anchorEl: null,
			anchorElSort: null,
			ranges: ranges,
			sorts: sorts,
			selectedSort: orderSelected,
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleFilterDelete = this.handleFilterDelete.bind(this);
		this.changeFilterValue = this.changeFilterValue.bind(this);
		this.getFilterValue = this.getFilterValue.bind(this);
		this.addFilterValue = this.addFilterValue.bind(this);
		this.openSortPopover = this.openSortPopover.bind(this);
		this.closeSortPopover = this.closeSortPopover.bind(this);
		this.changeSort = this.changeSort.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.paperRef = React.createRef();
	}

	handleClick = event => {
		event.preventDefault();
		this.setState({
			anchorEl: event.currentTarget,
		});
	};

	handleClose = event => {
		event.preventDefault();
		this.setState({
			anchorEl: null,
			filterNeedValue: false,
		});
	};

	handleFilterAdd(e, filterIndex) {
		const filter = this.state.filters[filterIndex];
		let selectedFilter = [...this.state.selectedFilter];
		this.setState({
			selectedFilter: selectedFilter,
			filterNeedValue: true,
			filterValueType: filter.type,
			filterEntity: filter.entity,
		});
	}

	handleFilterDelete(e, selectedFilterIndex) {
		e.preventDefault();
		const newSelectedFilter = this.state.selectedFilter.reduce(
			(acc, filter, index) => {
				if (selectedFilterIndex === index) {
					filter.ids = [];
					filter.texts = [];
				}
				acc.push(filter);
				return acc;
			},
			[]
		);
		this.setState({
			selectedFilter: newSelectedFilter,
			filterNeedValue: false,
		});
		this.props.filterProducts(newSelectedFilter);
	}

	changeFilterValue(e) {
		e.preventDefault();
		this.setState({ filterValue: e.target.value });
	}

	addFilterValue(entity, type, value, id) {
		const selectedFilter = this.state.selectedFilter.reduce(
			(acc, filter, index) => {
				if (filter && filter.filter && filter.filter.entity === entity) {
					const listSelectedId = [...filter.ids, id];
					const listSelectedTexts = [...filter.texts, value];
					const length = listSelectedId.length - 1;
					if (type === 'colorPicker') {
						filter.value = listSelectedId.map((id, index) => (
							<React.Fragment key={index}>
								<div>
									<ColorBlock value={id} />
								</div>
								{index !== length ? (
									<span
										style={{ margin: '0px 10px 0px -5px', fontWeight: 'bold' }}>
										{I18n.t('product.sort.or')}
									</span>
								) : (
									''
								)}
							</React.Fragment>
						));
					}
					if (type === 'select') {
						filter.value = listSelectedId.map((id, index) => (
							<React.Fragment key={index}>
								<span>{listSelectedTexts[index]}</span>
								<span style={{ margin: '0px 5px 0px 5px', fontWeight: 'bold' }}>
									{index !== length ? I18n.t('product.sort.or') : ''}
								</span>
							</React.Fragment>
						));
					}
					filter.ids.push(id);
					filter.texts.push(value);
				}
				acc.push(filter);
				return acc;
			},
			[]
		);

		this.setState({
			selectedFilter: selectedFilter,
			anchorEl: null,
			filterNeedValue: false,
			filterEntity: '',
		});
		this.props.filterProducts(selectedFilter);
	}

	getFilterValue() {
		const { filterValueType, filterEntity } = this.state;
		if (filterValueType === 'colorPicker') {
			return (
				<div className="main">
					<ColorPanel
						onSelectValue={selectedColor => {
							this.addFilterValue(
								filterEntity,
								filterValueType,
								selectedColor.id,
								selectedColor.id
							);
						}}
						color={this.props.colors}
						selectedData={this.props.defaultColor}
					/>
				</div>
			);
		} else if (filterValueType === 'range') {
			const { ranges } = this.state;
			return (
				<React.Fragment>
					{ranges.map((value, index) => {
						return (
							<MenuItem
								key={index}
								value={index}
								onClick={e => {
									e.preventDefault();
									this.addFilterValue(
										filterEntity,
										'select',
										ranges[e.target.value],
										index
									);
								}}>
								{value}
							</MenuItem>
						);
					})}
				</React.Fragment>
			);
		} else if (filterValueType === 'select') {
			let array = this.props[filterEntity];
			if (filterEntity === 'category') {
				array = [...array.categories];
				array.unshift({
					id: 'no_category',
					name: I18n.t('product.filter.no_category'),
				});
			}
			if (filterEntity === 'visible') {
				array = [
					{ text: I18n.t('product.sort.visible'), value: 1 },
					{ text: I18n.t('product.sort.not_visible'), value: 0 },
				];
				return (
					<React.Fragment>
						{array.map((value, index) => {
							return (
								<MenuItem
									key={index}
									value={value.value}
									onClick={e => {
										e.preventDefault();
										this.addFilterValue(
											filterEntity,
											'select',
											value.text,
											value.value
										);
									}}>
									{value.text}
								</MenuItem>
							);
						})}
					</React.Fragment>
				);
			}
			return (
				<React.Fragment>
					{array.map((value, index) => {
						return (
							<MenuItem
								key={index}
								value={index}
								onClick={e => {
									e.preventDefault();
									this.addFilterValue(
										filterEntity,
										'select',
										array[e.target.value].name,
										array[e.target.value].id
									);
								}}>
								{value.name}
							</MenuItem>
						);
					})}
				</React.Fragment>
			);
		} else {
			return (
				<TextField
					label=""
					onChange={this.changeFilterValue}
					value={this.state.filterValue}
					margin="normal"
				/>
			);
		}
	}

	openSortPopover(e) {
		e.preventDefault();

		this.setState({ anchorElSort: e.currentTarget });
	}

	closeSortPopover(e) {
		e.preventDefault();
		this.setState({
			anchorElSort: null,
		});
	}

	changeSort(sort) {
		this.setState({ selectedSort: sort, anchorElSort: null });
		this.props.sortProducts(sort);
		//this.props.reSort(sort);
	}

	handleSearchChange(ev) {
		ev.preventDefault();

		const value = ev.target.value;

		const selectedFilter = this.state.selectedFilter;
		selectedFilter[6].text = value;

		this.props.filterProducts(selectedFilter);
	}

	render() {
		const {
			anchorEl,
			anchorElSort,
			filters,
			selectedFilter,
			filterNeedValue,
			sorts,
			selectedSort,
		} = this.state;

		return (
			<div
				ref={this.paperRef}
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					flexWrap: 'wrap',
				}}>
				<Chip
					avatar={
						<Avatar>
							<SearchIcon />
						</Avatar>
					}
					classes={this.props.classes}
					style={{ marginLeft: 10 }}
					label={
						<input
							type="text"
							className={'filterSearchBar'}
							placeholder={I18n.t('product.filter.search')}
							onChange={this.handleSearchChange}
						/>
					}
				/>
				<Chip
					avatar={
						<Avatar>
							<SortIcon />
						</Avatar>
					}
					classes={this.props.classes}
					style={{ marginLeft: 10 }}
					label={selectedSort.name}
					onClick={this.openSortPopover}
				/>
				{selectedFilter.map((filter, index) => {
					return (
						<React.Fragment key={index}>
							{filter.ids.length > 0 ? (
								<React.Fragment>
									<Chip
										style={{
											marginLeft: 5,
											marginRight: 5,
										}}
										classes={this.props.classes}
										key={index}
										label={
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
												}}>
												<h4>{filter.filter.name}</h4>:{' '}
												<div
													style={{
														marginLeft:
															filter.filter.type === 'colorPicker' ? 10 : 5,
														marginRight:
															filter.filter.type === 'colorPicker' ? -10 : 0,
														display: 'flex',
														alignItems: 'center',
													}}>
													{filter.value}
												</div>
											</div>
										}
										onDelete={e => this.handleFilterDelete(e, index)}
									/>
									{selectedFilter.length - 1 !== index ? <h5>{' - '}</h5> : ''}
								</React.Fragment>
							) : null}
						</React.Fragment>
					);
				})}
				<Chip
					avatar={
						<Avatar>
							<FilterIcon />
						</Avatar>
					}
					classes={this.props.classes}
					style={{ marginLeft: 10 }}
					label={I18n.t('product.filter.add_filter')}
					onClick={this.handleClick}
				/>
				<Popover
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					onClose={this.handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}>
					{filterNeedValue
						? this.getFilterValue()
						: filters.map((filter, index) => (
								<MenuItem
									key={index}
									value={index}
									onClick={e => this.handleFilterAdd(e, index)}>
									{filter.name}
								</MenuItem>
						  ))}
				</Popover>

				<Popover
					open={Boolean(anchorElSort)}
					anchorEl={anchorElSort}
					onClose={this.closeSortPopover}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}>
					{sorts.map((sort, index) => (
						<MenuItem
							key={index}
							value={sort.id}
							selected={selectedSort.id === sort.id}
							onClick={e => {
								e.preventDefault();
								this.changeSort(sort);
							}}>
							{sort.name}
						</MenuItem>
					))}
				</Popover>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const { entity } = state;
	const { color, category, tax, placeSend, productType } = entity;

	return {
		colors: color,
		category,
		tax,
		placeSend,
		productType,
		sortList: entity.product.sortList || [],
		selectedSort: entity.product.sort,
		defaultColor: getDefaultColor(state.user),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		sortProducts: sort =>
			dispatch({
				type: 'SWITCH_SORT_PRODUCT',
				sort: sort,
			}),
	};
};
export default withStyles(ChipClasses)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(FilterBar)
);
