export const SET_OPEN = 'SET_OPEN';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';

export const HELPER_TABS = {
	PRODUCT: '/product',
	CATEGORY: '/category',
	SUPPLEMENT: '/supplement',
	MENU: '/productMenu',
	PRICE_CATEGORY: '/priceCategory',
	TAX: '/tax',
	QUICKSTART: '/quickstart',
	VIDEO: '/video',
};
