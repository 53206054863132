import {
	PRE_DELETE_PRICE_CATEGORY,
	DELETE_PRICE_CATEGORY,
	PRE_CREATE_PRICE_CATEGORY,
	CREATE_PRICE_CATEGORY,
	UPDATE_PRICE_CATEGORY,
	PRE_UPDATE_PRICE_CATEGORY,
	PRE_COPY_PRICE_CATEGORY,
	COPY_PRICE_CATEGORY,
	PRE_PATCH_PRICE_CATEGORY,
	PATCH_PRICE_CATEGORY,
	REWEIGHT_PRICE_CATEGORY,
	PRE_REWEIGHT_PRICE_CATEGORY,
	PRE_INIT_PRICE_CATEGORY,
	INIT_PRICE_CATEGORY,
	PRE_REWEIGHT_FLOOR_PRICE_CATEGORY,
	REWEIGHT_FLOOR_PRICE_CATEGORY,
} from '../constants/priceCategory';
import { buffers } from 'redux-saga';
import { call, put, take, actionChannel, select } from 'redux-saga/effects';
import { requestHandler } from './fetcher.saga';
import { getMenuSelected } from './login';
import { RECEIVE_PRODUCT } from '../constants/product';

const PRE_REQUEST_DELETE_PRICE_CATEGORY = 'PRE_REQUEST_DELETE_PRICE_CATEGORY';
const PRE_REQUEST_CREATE_PRICE_CATEGORY = 'PRE_REQUEST_CREATE_PRICE_CATEGORY';
const PRE_REQUEST_UPDATE_PRICE_CATEGORY = 'PRE_REQUEST_UPDATE_PRICE_CATEGORY';
const PRE_REQUEST_COPY_PRICE_CATEGORY = 'PRE_REQUEST_COPY_PRICE_CATEGORY';
const PRE_REQUEST_INIT_PRICE_CATEGORY = 'PRE_REQUEST_INIT_PRICE_CATEGORY';
const PRE_REQUEST_PATCH_PRICE_CATEGORY = 'PRE_REQUEST_PATCH_PRICE_CATEGORY';
const PRE_REQUEST_REWEIGHT_PRICE_CATEGORY =
	'PRE_REQUEST_REWEIGHT_PRICE_CATEGORY';
const PRE_REQUEST_REWEIGHT_FLOOR_PRICE_CATEGORY =
	'PRE_REQUEST_REWEIGHT_FLOOR_PRICE_CATEGORY';

const REQUESTS_DELETE_PRICE_CATEGORY_FINISHED =
	'REQUESTS_DELETE_PRICE_CATEGORY_FINISHED';
const REQUESTS_CREATE_PRICE_CATEGORY_FINISHED =
	'REQUESTS_CREATE_PRICE_CATEGORY_FINISHED';
const REQUESTS_UPDATE_PRICE_CATEGORY_FINISHED =
	'REQUESTS_UPDATE_PRICE_CATEGORY_FINISHED';
const REQUESTS_COPY_PRICE_CATEGORY_FINISHED =
	'REQUESTS_COPY_PRICE_CATEGORY_FINISHED';
const REQUESTS_INIT_PRICE_CATEGORY_FINISHED =
	'REQUESTS_COPY_PRICE_CATEGORY_FINISHED';
const REQUESTS_PATCH_PRICE_CATEGORY_FINISHED =
	'REQUESTS_PATCH_PRICE_CATEGORY_FINISHED';
const REQUESTS_REWEIGHT_PRICE_CATEGORY_FINISHED =
	'REQUESTS_REWEIGHT_PRICE_CATEGORY_FINISHED';
const REQUESTS_REWEIGHT_FLOOR_PRICE_CATEGORY_FINISHED =
	'REQUESTS_REWEIGHT_FLOOR_PRICE_CATEGORY_FINISHED';

const ENTITY = 'priceCategory';
const GET_METHOD = 'GET';
const DELETE_METHOD = 'DELETE';
const POST_METHOD = 'POST';
const PUT_METHOD = 'PUT';
const PATCH_METHOD = 'PATCH';

////////////
// DELETE //
////////////
export function* preDeletePriceCategory() {
	while (true) {
		const action = yield take(PRE_DELETE_PRICE_CATEGORY);
		const { ids } = action;
		let length = ids.length;

		const id_menu = yield call(getMenuSelected);

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_DELETE_PRICE_CATEGORY,
				body: { id: ids[i], id_menu },
				index: i,
				totalLength: length,
			});
		}
		yield take(REQUESTS_DELETE_PRICE_CATEGORY_FINISHED);
		yield put({ type: DELETE_PRICE_CATEGORY, ids: ids });
	}
}

export function* watchDeletePriceCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_DELETE_PRICE_CATEGORY,
		buffers.expanding()
	);

	while (true) {
		// 2- take from the channel
		const action = yield take(requestChan);
		// 3- Note that we're using a blocking call
		yield call(requestHandler, ENTITY, DELETE_METHOD, true, action.body);
		if (action.index === action.totalLength - 1) {
			yield put({ type: REQUESTS_DELETE_PRICE_CATEGORY_FINISHED });
		}
	}
}

////////////
// CREATE //
////////////
export function* preCreatePriceCategory() {
	while (true) {
		const action = yield take(PRE_CREATE_PRICE_CATEGORY);
		const { newPriceCategories } = action;
		let length = newPriceCategories.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_CREATE_PRICE_CATEGORY,
				body: { priceCategory: newPriceCategories[i] },
				index: i,
				totalLength: length,
			});
		}
		yield take(REQUESTS_CREATE_PRICE_CATEGORY_FINISHED);
		const receivedPriceCategories = yield call(requestHandler, 'priceCategory');
		yield put({
			type: 'RECEIVE_PRICE_CATEGORY',
			response: receivedPriceCategories.result,
		});
	}
}

export function* watchCreatePriceCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_CREATE_PRICE_CATEGORY,
		buffers.expanding()
	);
	let createdPriceCategories = [];
	while (true) {
		const action = yield take(requestChan);

		const id_menu = yield call(getMenuSelected);

		const result = yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			action.body.priceCategory
		);
		let newPriceCategory = { ...action.body.priceCategory, id_menu };
		newPriceCategory.id = result.result.result;
		newPriceCategory.associations = 0;

		createdPriceCategories.push(newPriceCategory);

		if (action.index === action.totalLength - 1) {
			yield put({
				type: REQUESTS_CREATE_PRICE_CATEGORY_FINISHED,
				createdPriceCategories: createdPriceCategories,
			});
			createdPriceCategories = [];
		}
	}
}

////////////
// UPDATE //
////////////
export function* preUpdatePriceCategory() {
	while (true) {
		const action = yield take(PRE_UPDATE_PRICE_CATEGORY);
		const { updatedPriceCategories } = action;
		let length = updatedPriceCategories.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_UPDATE_PRICE_CATEGORY,
				body: { priceCategory: updatedPriceCategories[i] },
				index: i,
				totalLength: length,
			});
		}
		yield take(REQUESTS_UPDATE_PRICE_CATEGORY_FINISHED);
		yield put({
			type: UPDATE_PRICE_CATEGORY,
			updatedPriceCategories: updatedPriceCategories,
		});
	}
}

export function* watchUpdatePriceCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_UPDATE_PRICE_CATEGORY,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);

		const id_menu = yield call(getMenuSelected);

		yield call(requestHandler, ENTITY, PUT_METHOD, true, {
			...action.body.priceCategory,
			id_menu,
		});

		if (action.index === action.totalLength - 1) {
			yield put({ type: REQUESTS_UPDATE_PRICE_CATEGORY_FINISHED });
		}
	}
}

////////////
//  COPY  //
////////////
export function* preCopyPriceCategory() {
	while (true) {
		const action = yield take(PRE_COPY_PRICE_CATEGORY);
		const { data } = action;

		yield put({ type: PRE_REQUEST_COPY_PRICE_CATEGORY, body: { data: data } });

		yield take(REQUESTS_COPY_PRICE_CATEGORY_FINISHED);

		yield put({ type: COPY_PRICE_CATEGORY, data: data });
	}
}

export function* watchCopyPriceCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_COPY_PRICE_CATEGORY,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);

		const id_menu = yield call(getMenuSelected);
		yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			{ ...action.body.data, id_menu },
			'/copy'
		);

		yield put({ type: REQUESTS_COPY_PRICE_CATEGORY_FINISHED });
	}
}

////////////
//  INIT  //
////////////
export function* preInitPriceCategory() {
	while (true) {
		const action = yield take(PRE_INIT_PRICE_CATEGORY);
		const { data } = action;

		yield put({ type: PRE_REQUEST_INIT_PRICE_CATEGORY, body: { data: data } });

		const result = yield take(REQUESTS_INIT_PRICE_CATEGORY_FINISHED);

		yield put({ type: INIT_PRICE_CATEGORY, data: data });
		yield put({ type: RECEIVE_PRODUCT, response: result.newProducts.result });
	}
}

export function* watchInitPriceCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_INIT_PRICE_CATEGORY,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);
		const id_menu = yield call(getMenuSelected);
		yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			{ ...action.body.data, id_menu },
			'/init'
		);

		const newProducts = yield call(
			requestHandler,
			'product',
			GET_METHOD,
			true,
			null,
			'',
			{
				id_menu: id_menu,
			}
		);

		yield put({
			type: REQUESTS_INIT_PRICE_CATEGORY_FINISHED,
			newProducts: newProducts,
		});
	}
}

////////////
// PATCH  //
////////////
export function* prePatchPriceCategory() {
	while (true) {
		const action = yield take(PRE_PATCH_PRICE_CATEGORY);
		const { data } = action;
		let length = data.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_PATCH_PRICE_CATEGORY,
				body: { patchs: data[i] },
				index: i,
				totalLength: length,
			});
		}

		yield take(REQUESTS_PATCH_PRICE_CATEGORY_FINISHED);

		yield put({ type: PATCH_PRICE_CATEGORY });
	}
}

export function* watchPatchPriceCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_PATCH_PRICE_CATEGORY,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);
		const id_menu = yield call(getMenuSelected);

		yield call(requestHandler, ENTITY, PATCH_METHOD, true, {
			...action.body.patchs,
			id_menu,
		});

		if (action.index === action.totalLength - 1) {
			yield put({ type: REQUESTS_PATCH_PRICE_CATEGORY_FINISHED });
		}
	}
}

//////////////
// REWEIGHT //
//////////////
export function* preReweightPriceCategory() {
	while (true) {
		const action = yield take(PRE_REWEIGHT_PRICE_CATEGORY);
		const { newValues } = action;
		let length = newValues.length;

		const id_menu = yield call(getMenuSelected);

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_REWEIGHT_PRICE_CATEGORY,
				body: { entity: newValues[i], id_menu },
				index: i,
				totalLength: length,
			});
		}
		const result = yield take(REQUESTS_REWEIGHT_PRICE_CATEGORY_FINISHED);
		yield put({
			type: REWEIGHT_PRICE_CATEGORY,
			newValues: result.successfullRequest,
		});
	}
}

export function* watchReweightPriceCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_REWEIGHT_PRICE_CATEGORY,
		buffers.expanding()
	);
	let successfullRequest = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			PATCH_METHOD,
			true,
			action.body,
			'/weight'
		);
		if (result.result && result.result.status == 200) {
			successfullRequest.push(action.body);
		}

		if (action.index === action.totalLength - 1) {
			yield put({
				type: REQUESTS_REWEIGHT_PRICE_CATEGORY_FINISHED,
				successfullRequest: successfullRequest,
			});
			successfullRequest = [];
		}
	}
}

///////////////////////////////////
// REWEIGHT FLOOR PRICE CATEGPRY //
///////////////////////////////////
export function* preReweightFloorPriceCategory() {
	while (true) {
		const action = yield take(PRE_REWEIGHT_FLOOR_PRICE_CATEGORY);
		const { id_floor, counter_type, price_categories } = action;
		const id_menu = yield call(getMenuSelected);

		yield put({
			type: PRE_REQUEST_REWEIGHT_FLOOR_PRICE_CATEGORY,
			body: { id_floor, counter_type, price_categories, id_menu },
			index: 0,
			totalLength: 1,
		});

		const result = yield take(REQUESTS_REWEIGHT_FLOOR_PRICE_CATEGORY_FINISHED);
		const newPriceCategories = yield call(requestHandler, 'priceCategory');
		yield put({
			type: 'RECEIVE_PRICE_CATEGORY',
			response: newPriceCategories.result,
		});
		yield put({
			type: REWEIGHT_FLOOR_PRICE_CATEGORY,
			newValues: result.successfullRequest,
		});
	}
}

export function* watchReweightFloorPriceCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_REWEIGHT_FLOOR_PRICE_CATEGORY,
		buffers.expanding()
	);
	let successfullRequest = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			PATCH_METHOD,
			true,
			action.body,
			'/floor-weight'
		);
		if (result.result && result.result.status == 200) {
			successfullRequest.push(action.body);
		}

		if (action.index === action.totalLength - 1) {
			yield put({
				type: REQUESTS_REWEIGHT_FLOOR_PRICE_CATEGORY_FINISHED,
				successfullRequest: successfullRequest,
			});
			successfullRequest = [];
		}
	}
}
