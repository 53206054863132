export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const TAGS = {
	ERROR: 'ERROR',
	SUCCESS: 'SUCCESS',
	WARNING: 'WARNING',
	INFO: 'INFO',
};
export const COLORS = {
	ERROR: '#e84249',
	SUCCESS: '#43A047',
	WARNING: '#FFA726',
	INFO: '#1f8dd6',
};
