import {
	UPDATE_NOTIFICATION,
	REMOVE_NOTIFICATION,
	ADD_NOTIFICATION,
} from '../constants/notification';

export const updateNotification = idToUpdate => {
	return {
		type: UPDATE_NOTIFICATION,
		id: idToUpdate,
	};
};
export const removeNotification = idToRemove => {
	return {
		type: REMOVE_NOTIFICATION,
		id: idToRemove,
	};
};
export const addNotification = notification => {
	return {
		type: ADD_NOTIFICATION,
		notification,
	};
};
