import {
	START_TUTO,
	STOP_TUTO,
	START_TUTO_TAX,
	START_TUTO_PRODUCTS,
	STOP_TUTO_PRODUCTS,
	START_TUTO_SUPPLEMENT,
	STOP_TUTO_PRODUCT_MENU,
	START_TUTO_PRODUCT_MENUS,
	START_TUTO_PRICE_CATEGORY,
	ACTIVE_TUTORIAL,
} from '../constants/tutorial';
import { steps } from './../components/tutorial/tuto.category';
import { stepsTax } from './../components/tutorial/tuto.tax';
import { stepsProducts } from './../components/tutorial/tuto.products';
import { stepsProductMenus } from './../components/tutorial/tuto.productMenus';
import { stepsSupplement } from './../components/tutorial/tuto.optionskitchen';
import { stepsPriceCategory } from './../components/tutorial/tuto.priceCategory';

const initialState = {
	steps: [],
	run: false,
	runProductMenu: false,
	stateTutorial: {
		product: false,
		category: false,
		tax: false,
		supplement: false,
		productMenu: false,
		priceCategory: false,
		topics: false,
	},
	currentTutorial: null,
	isActive: false,
};

const reducer = function tutorial(state = initialState, action) {
	let newState = { ...state };
	switch (action.type) {
		case START_TUTO:
			return { ...newState, run: true, steps, currentTutorial: 'category' };
		case START_TUTO_PRODUCTS:
			return {
				...newState,
				run: true,
				runProductMenu: false,
				steps: stepsProducts,
				currentTutorial: 'product',
			};
		case START_TUTO_SUPPLEMENT:
			return {
				...newState,
				run: true,
				runProductMenu: false,
				steps: stepsSupplement,
				currentTutorial: 'supplement',
			};
		case START_TUTO_PRODUCT_MENUS:
			return {
				...newState,
				run: true,
				runProductMenu: false,
				steps: stepsProductMenus,
				currentTutorial: 'productMenu',
			};
		case STOP_TUTO:
			const stateAllTuto = Object.assign({}, newState.stateTutorial);
			stateAllTuto[newState.currentTutorial] = true;
			return {
				...newState,
				run: false,
				runProductMenu: false,
				stateTutorial: stateAllTuto,
				isActive: false,
			};
		case START_TUTO_TAX:
			return {
				...newState,
				run: true,
				runProductMenu: false,
				steps: stepsTax,
				currentTutorial: 'tax',
			};
		case START_TUTO_PRICE_CATEGORY:
			return {
				...newState,
				run: true,
				runProductMenu: false,
				steps: stepsPriceCategory,
				currentTutorial: 'priceCategory',
			};
		case STOP_TUTO_PRODUCT_MENU: {
			const stateAllTuto = Object.assign({}, newState.stateTutorial);
			stateAllTuto[newState.currentTutorial] = true;
			return {
				...newState,
				run: false,
				runProductMenu: true,
				stateTutorial: stateAllTuto,
			};
		}
		case STOP_TUTO_PRODUCTS: {
			const stateAllTuto = Object.assign({}, newState.stateTutorial);
			stateAllTuto[newState.currentTutorial] = true;
			return {
				...newState,
				run: false,
				runProductMenu: true,
				stateTutorial: stateAllTuto,
			};
		}
		case ACTIVE_TUTORIAL:
			return {
				...newState,
				isActive: action.value,
				stateTutorial: initialState.stateTutorial,
			};

		default:
			return state;
	}
};

export default reducer;
