export const RECEIVE_MENU = 'RECEIVE_MENU';
export const RECEIVE_MENU_MASTER_MONO = 'RECEIVE_MENU_MASTER_MONO';
export const PRE_DELETE_MENU = 'PRE_DELETE_MENU';
export const DELETE_MENU = 'DELETE_MENU';
export const DELETE_MENU_MASTER_MONO = 'DELETE_MENU_MASTER_MONO';
export const PRE_CREATE_MENU = 'PRE_CREATE_MENU';
export const PRE_SELECT_MENU = 'PRE_SELECT_MENU';
export const CREATE_MENU = 'CREATE_MENU';
export const PRE_UPDATE_MENU = 'PRE_UPDATE_MENU';
export const UPDATE_MENU = 'UPDATE_MENU';
export const PRE_PATCH_MENU = 'PRE_PATCH_MENU';
export const PATCH_MENU = 'PATCH_MENU';
export const FETCH_MENU = 'FETCH_MENU';
export const PRE_FETCH_MENU = 'PRE_FETCH_MENU';
export const PRE_COPY_MENU = 'PRE_COPY_MENU';
export const PRE_BIND_MENU = 'PRE_BIND_MENU';
export const BIND_MENU = 'BIND_MENU';
export const PRE_UNTIE_MENU = 'PRE_UNTIE_MENU';
export const UNTIE_MENU = 'UNTIE_MENU';
export const PRE_EDIT_MENU = 'PRE_EDIT_MENU';
export const EDIT_MENU = 'EDIT_MENU';
export const FETCH_DATA_MENUS = 'FETCH_DATA_MENUS';
export const DELETE_KEY_INFO = 'DELETE_KEY_INFO';
