import { RECEIVE_PLACE_SEND } from '../constants/placeSend';

const initialState = [];

const reducer = function placeSendReducer(state = initialState, action) {
	const newState = { ...state };
	switch (action.type) {
		case RECEIVE_PLACE_SEND:
			// if (action.response.result.length === 0) {
			//   return newState;
			// }
			const placeSend = action.response.result;
			return placeSend;
		default:
			return state;
	}
};

export default reducer;
