import LoginSaga from './login';
import i18nFlowSaga from './i18n';
import productSaga, {
	handleUndoRedo,
	preCreateProduct,
	preDeleteProduct,
	watchCreateProduct,
	watchDeleteProduct,
	preReweightProduct,
	watchReweightProduct,
	preAssignCategoryAndReweight,
	preImportProducts,
	watchImportProducts,
	restoreProduct,
} from './product';
import { watchUpdateProduct } from './product';
import {
	preDeletePriceCategory,
	watchDeletePriceCategory,
	preCreatePriceCategory,
	watchCreatePriceCategory,
	preUpdatePriceCategory,
	watchUpdatePriceCategory,
	preCopyPriceCategory,
	watchCopyPriceCategory,
	prePatchPriceCategory,
	watchPatchPriceCategory,
	preReweightPriceCategory,
	watchReweightPriceCategory,
	preInitPriceCategory,
	watchInitPriceCategory,
	preReweightFloorPriceCategory,
	watchReweightFloorPriceCategory,
} from './priceCategory.saga';
import {
	requestSuccessLoadingSaga,
	receiveLoadingSaga,
	requestReceiver,
} from './loading.saga';
import { all } from 'redux-saga/effects';
import {
	preCreateCategory,
	preDeleteCategory,
	prePatchCategory,
	preReweightCategory,
	preUpdateCategory,
	watchCreateCategory,
	watchDeleteCategory,
	watchPatchCategory,
	watchReweightCategory,
	watchUpdateCategory,
} from './Category.saga';
import {
	preCreateMenu,
	preUpdateMenu,
	preDeleteMenu,
	prePatchMenu,
	watchCreateMenu,
	watchDeleteMenu,
	watchUpdateMenu,
	watchPatchMenu,
	fetchMenuInfo,
	preBindMenu,
	watchBindMenu,
	preUntieMenu,
	watchUntieMenu,
	preCopyMenu,
	watchCopyMenu,
	preEditMenu,
	watchEditMenu,
	preSelectMenu,
	watchSelectMenu,
} from './menu.saga';

import {
	preCreateTax,
	preDeleteTax,
	prePatchTax,
	watchPatchTax,
	preUpdateTax,
	watchCreateTax,
	watchDeleteTax,
	watchUpdateTax,
	preReweightTax,
	watchReweightTax,
} from './tax.saga';
import { watchRequestError } from './fetcher.saga';
import {
	preCreateSupplementCategory,
	preDeleteSupplementCategory,
	prePatchSupplementCategory,
	preReweightSupplementCategory,
	preUpdateSupplementCategory,
	watchCreateSupplementCategory,
	watchDeleteSupplementCategory,
	watchPatchSupplementCategory,
	watchReweightSupplementCategory,
	watchUpdateSupplementCategory,
	preAssignProducts,
	watchAssignProducts,
} from './supplementCategory.saga';
import {
	assoSuppToCategory,
	preCreateSupplement,
	preDeleteSupplement,
	prePatchSupplement,
	preReweightSupplement,
	preUpdateSupplement,
	watchCreateSupplement,
	watchDeleteSupplement,
	watchPatchSupplement,
	watchReweightSupplement,
	watchUpdateSupplement,
} from './supplement.saga';
import {
	preCreateProductMenu,
	watchCreateProductMenu,
	watchDeleteProductMenu,
	preDeleteProductMenu,
	watchUpdateProductMenu,
	preUpdateProductMenu,
	watchReweightProductMenu,
	preReweightProductMenu,
} from './productMenu.saga';

import {
	preDeployPatchMenu,
	watchDeployPatchMenu,
	preResetPatchMenu,
	watchResetPatchMenu,
} from './patchMenu.saga';

import {
	preImportProductSaga,
	watchImportProductSaga,
	preImportCategorySaga,
	watchImportCategorySaga,
} from './import.saga';

import variableServerSaga from './variableServer.saga';

import routerFlowSaga from './router.saga';

import { selectCompany, selectZone, selectMasterGlobal } from './zone.saga';
import { refreshProvider } from './synchro.saga';

export default function* IndexSaga() {
	yield all([
		i18nFlowSaga(),
		routerFlowSaga(),
		requestSuccessLoadingSaga(),
		watchRequestError(),
		//receiveLoadingSaga(),
		requestReceiver(),
		//requestErrorLoadingSaga(),
		LoginSaga(),

		// PRODUCT
		productSaga(),
		watchUpdateProduct(),
		preCreateProduct(),
		watchCreateProduct(),
		preDeleteProduct(),
		watchDeleteProduct(),
		preReweightProduct(),
		watchReweightProduct(),
		preAssignCategoryAndReweight(),
		handleUndoRedo(),
		preImportProducts(),
		watchImportProducts(),
		restoreProduct(),

		//PATCH MENU
		preResetPatchMenu(),
		watchResetPatchMenu(),

		//PRICE CATEGORY
		preDeletePriceCategory(),
		watchDeletePriceCategory(),
		preCreatePriceCategory(),
		watchCreatePriceCategory(),
		preUpdatePriceCategory(),
		watchUpdatePriceCategory(),
		preCopyPriceCategory(),
		watchCopyPriceCategory(),
		preInitPriceCategory(),
		watchInitPriceCategory(),
		prePatchPriceCategory(),
		watchPatchPriceCategory(),
		preReweightPriceCategory(),
		watchReweightPriceCategory(),
		preReweightFloorPriceCategory(),
		watchReweightFloorPriceCategory(),

		// CATEGORY
		preDeleteCategory(),
		watchDeleteCategory(),
		prePatchCategory(),
		watchPatchCategory(),
		preCreateCategory(),
		watchCreateCategory(),
		preUpdateCategory(),
		watchUpdateCategory(),

		//MENU
		preDeleteMenu(),
		watchDeleteMenu(),
		prePatchMenu(),
		watchPatchMenu(),
		preCreateMenu(),
		watchCreateMenu(),
		preUpdateMenu(),
		watchUpdateMenu(),
		fetchMenuInfo(),
		preDeployPatchMenu(),
		watchDeployPatchMenu(),
		preBindMenu(),
		watchBindMenu(),
		preCopyMenu(),
		watchCopyMenu(),
		preReweightCategory(),
		watchReweightCategory(),
		preUntieMenu(),
		watchUntieMenu(),
		preEditMenu(),
		watchEditMenu(),
		preSelectMenu(),
		watchSelectMenu(),

		//TAX
		preDeleteTax(),
		watchDeleteTax(),
		preCreateTax(),
		watchCreateTax(),
		preUpdateTax(),
		watchUpdateTax(),
		prePatchTax(),
		watchPatchTax(),
		preReweightTax(),
		watchReweightTax(),

		// SUPPLEMENT CATEGORY
		preDeleteSupplementCategory(),
		watchDeleteSupplementCategory(),
		prePatchSupplementCategory(),
		watchPatchSupplementCategory(),
		preCreateSupplementCategory(),
		watchCreateSupplementCategory(),
		preUpdateSupplementCategory(),
		watchUpdateSupplementCategory(),
		preReweightSupplementCategory(),
		watchReweightSupplementCategory(),
		preAssignProducts(),
		watchAssignProducts(),

		// SUPPLEMENT
		preDeleteSupplement(),
		watchDeleteSupplement(),
		prePatchSupplement(),
		watchPatchSupplement(),
		preCreateSupplement(),
		watchCreateSupplement(),
		preUpdateSupplement(),
		watchUpdateSupplement(),
		preReweightSupplement(),
		watchReweightSupplement(),
		assoSuppToCategory(),

		// PRODUCTMENU
		preCreateProductMenu(),
		watchCreateProductMenu(),
		preDeleteProductMenu(),
		watchDeleteProductMenu(),
		preUpdateProductMenu(),
		watchUpdateProductMenu(),
		preReweightProductMenu(),
		watchReweightProductMenu(),

		// IMPORT
		preImportProductSaga(),
		watchImportProductSaga(),
		preImportCategorySaga(),
		watchImportCategorySaga(),

		// VARIABLE SERVER
		variableServerSaga(),

		//ZONE
		selectCompany(),
		selectZone(),
		selectMasterGlobal(),

		//SYNCHRO
		refreshProvider(),
	]);
}
