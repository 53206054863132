import {
	RECEIVE_PRICE_CATEGORY,
	DELETE_PRICE_CATEGORY,
	CREATE_PRICE_CATEGORY,
	UPDATE_PRICE_CATEGORY,
	COPY_PRICE_CATEGORY,
	REFRESH_PRICE_CATEGORIES_LIST,
} from '../constants/priceCategory';
import findIndex from 'lodash/findIndex';
import _forEach from 'lodash/forEach';

const initialState = [];

const reducer = function priceCategoryReducer(state = initialState, action) {
	const newState = [...state];
	switch (action.type) {
		case RECEIVE_PRICE_CATEGORY:
			// if (action.response.result.length === 0) {
			//   return newState;
			// }
			const pc = action.response.result;
			return pc;
		case DELETE_PRICE_CATEGORY:
			const { ids } = action;

			_forEach(ids, id => {
				const indexToDelete = findIndex(newState, pc => pc.id === id);

				if (indexToDelete > -1) newState.splice(indexToDelete, 1);
			});

			return [...newState];

		case CREATE_PRICE_CATEGORY:
			const { newPriceCategories } = action;
			return [...newState, ...newPriceCategories];

		case UPDATE_PRICE_CATEGORY:
			const { updatedPriceCategories } = action;

			_forEach(newState, (pc, key) => {
				const elementToUpdateIndex = findIndex(
					updatedPriceCategories,
					p => p.id === pc.id
				);

				if (elementToUpdateIndex > -1)
					newState[key] = updatedPriceCategories[elementToUpdateIndex];
				return pc;
			});
			return [...newState];

		case COPY_PRICE_CATEGORY:
			const { data } = action;

			const indexCible = findIndex(newState, pc => pc.id === data.id_cible);
			const indexSource = findIndex(newState, pc => pc.id === data.id_source);

			if (indexCible > -1 && indexSource > -1)
				newState[indexCible].associations = newState[indexSource].associations;

			return [...newState];

		case REFRESH_PRICE_CATEGORIES_LIST:
			const { newList } = action;

			return newList;

		default:
			return state;
	}
};

export default reducer;
