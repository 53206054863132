import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { I18n } from 'react-redux-i18n';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default class FormProductSupplements extends Component {
	constructor(props) {
		super(props);

		this.state = {
			min: 0,
			max: 0,
			cat_min: 0,
			cat_max: 0,
			required: false,
			checked: false,
		};

		this.onChange = this.onChange.bind(this);
		this.onCheckChange = this.onCheckChange.bind(this);
		this.onMinChange = this.onMinChange.bind(this);
		this.onMaxChange = this.onMaxChange.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			checked: nextProps.checked,
			min: nextProps.min,
			max: nextProps.max,
		});
	}

	onChange() {
		this.props.onChange({
			id: this.props.id,
			min: this.state.min,
			max: this.state.max,
			checked: this.state.checked,
		});
	}

	onCheckChange(ev) {
		const value = ev.target.checked;
		if (value) {
			this.setState(
				{ checked: value, min: this.props.cat_min, max: this.props.cat_max },
				this.onChange
			);
		} else {
			this.setState({ checked: value }, this.onChange);
		}
	}

	onMinChange(value) {
		value = parseInt(value, 10) || 0;

		let max = value > this.state.max ? value : this.state.max; // si on dépasse le max
		this.setState({ min: value, max: max }, this.onChange);
	}

	onMaxChange(value) {
		value = parseInt(value, 10) || 0;
		let min = value < this.state.min ? value : this.state.min; // si on descend en dessous du min
		this.setState({ max: value, min: min }, this.onChange);
	}

	onRequiredChange(checked) {
		const n = !!checked ? 1 : 0;
		this.setState({ min: n, max: n, required: checked }, () => this.onChange());
	}

	render() {
		const { name, multiple, checked, min, max, cat_min, cat_max } = this.props;
		const required = !!(!this.props.multiple && min && max);

		const inputMin = checked ? min : cat_min;
		const inputMax = checked ? max : cat_max;

		const style = { display: checked ? 'inherit' : 'none' };

		const fieldMinMax = (
			
				<div style={style}>
					<Row middle={'xs'}>
						<Col xs={6}>
							<TextField
								placeholder={I18n.t('product.form.supp.min')}
								type={'number'}
								value={inputMin}
								inputProps={{ min: 0, max: 1000 }}
								onChange={ev => {
									this.onMinChange(ev.target.value);
								}}
								className="form-textfield"
								name={'product_supp_min'}
								fullWidth={true}
							/>
						</Col>
						<Col xs={6}>
							<TextField
								placeholder={I18n.t('product.form.supp.max')}
								type={'number'}
								value={inputMax}
								inputProps={{ min: 0, max: 1000 }}
								onChange={ev => {
									this.onMaxChange(ev.target.value);
								}}
								className="form-textfield"
								fullWidth={true}
								name={'product_supp_max'}
							/>
						</Col>
					</Row>
				</div>
		);

		const fieldUnique = (
				<div style={style}>
					<FormControlLabel
						control={
							<Switch
								checked={required}
								onChange={e => {
									this.onRequiredChange(e.target.checked);
								}}
							/>
						}
						label={I18n.t('product.form.supp.required')}
					/>
				</div>
		);

		return (
			<div style={{width : '100%'}}>
				<div className={"formProductSupplementLine"}>
					<div style={{width : "30%",  display: "flex", alignItems: "center", justifyContent: "center"}}>
						<strong> {name} </strong>
					</div>
					<div style={{width : "20%"}}>
						<Checkbox
							checked={checked}
							onChange={ev => {
								this.onCheckChange(ev);
							}}
						/>
					</div>
					<div style={{width : "50%"}}>
					{multiple ? fieldMinMax : fieldUnique}
					</div>
				</div>
				<Divider />
			</div>
		);
	}
}

FormProductSupplements.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	multiple: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	min: PropTypes.number,
	max: PropTypes.number,
	cat_min: PropTypes.number.isRequired,
	cat_max: PropTypes.number.isRequired,
	checked: PropTypes.bool.isRequired,
};
