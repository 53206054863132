import {
	TRIGGER_PRODUCT_MENUS_STEP1_BUTTON_NEXT,
	TRIGGER_PRODUCT_MENUS_BUTTON_HEADER,
	TRIGGER_BUTTON_ADD_OPTION_KITCHEN,
	TRIGGER_BUTTON_ADD_PRICE_CATEGORY,
	TRIGGER_BUTTON_SAVE_PRICE_CATEGORY,
	TRIGGER_PRODUCT_BUTTON_MAIN_PAGE,
	TRIGGER_BUTTON_SAVE_LEVEL_MENU3,
	TRIGGER_BUTTON_ADD_PRODUCT_FAST,
	TRIGGER_ENTRANCE_LEVEL_MENU3,
	TRIGGER_BUTTON_SAVE_OPTION_KITCHEN,
	TRIGGER_BUTTON_SAVE_UNDER_OPTION_KITCHEN,
	TRIGGER_BUTTON_ADD_UNDER_OPTION_KITCHEN,
	TRIGGER_PRODUCT_FOR_OPTION_KITCHEN,
	TRIGGER_OPTION_KITCHEN_ROW,
	TRIGGER_BUTTON_ADD_PRODUCT,
	TRIGGER_BUTTON_SAVE_CATEGORY,
	TRIGGER_BUTTON_SAVE_MENU3,
	TRIGGER_PRICE_CATEGORY_ROW,
	TRIGGER_CHANGE_PRODUCT_CATEGORY,
	TRIGGER_ADD_LEVEL_MENU3,
	TRIGGER_BUTTON_SAVE_TAX,
	TRIGGER_BUTTON_ADD_TAX,
	TRIGGER_CHECK_ROOMS,
	TRIGGER_SAVE_PRODUCT,
	PUT_FALSE_TRIGGER,
	PUT_FALSE_ACTIVE_TRIGGER,
} from '../constants/tutorialEvents';

export const triggerProductButtonMainPage = () => {
	return {
		type: TRIGGER_PRODUCT_BUTTON_MAIN_PAGE,
	};
};

export const triggerButtonAddProduct = () => {
	return {
		type: TRIGGER_BUTTON_ADD_PRODUCT,
	};
};

export const triggerButtonSaveCategory = () => {
	return {
		type: TRIGGER_BUTTON_SAVE_CATEGORY,
	};
};

export const triggerButtonAddProductFast = () => {
	return {
		type: TRIGGER_BUTTON_ADD_PRODUCT_FAST,
	};
};

export const triggerSaveProduct = () => {
	return {
		type: TRIGGER_SAVE_PRODUCT,
	};
};

export const triggerChangeProductCategory = () => {
	return {
		type: TRIGGER_CHANGE_PRODUCT_CATEGORY,
	};
};

export const triggerProductMenusButtonHeader = () => {
	return {
		type: TRIGGER_PRODUCT_MENUS_BUTTON_HEADER,
	};
};

export const triggerProductMenusButtonNext = () => {
	return {
		type: TRIGGER_PRODUCT_MENUS_STEP1_BUTTON_NEXT,
	};
};

export const triggerAddLevelMenu3 = () => {
	return {
		type: TRIGGER_ADD_LEVEL_MENU3,
	};
};

export const triggerButtonSaveLevelMenu = () => {
	return {
		type: TRIGGER_BUTTON_SAVE_LEVEL_MENU3,
	};
};

export const triggerEntranceLevelMenu3 = () => {
	return {
		type: TRIGGER_ENTRANCE_LEVEL_MENU3,
	};
};

export const triggerButtonSaveMenu3 = () => {
	return {
		type: TRIGGER_BUTTON_SAVE_MENU3,
	};
};

export const triggerButtonAddOptionkitchen = () => {
	return {
		type: TRIGGER_BUTTON_ADD_OPTION_KITCHEN,
	};
};

export const triggerButtonSaveOptionKitchen = () => {
	return {
		type: TRIGGER_BUTTON_SAVE_OPTION_KITCHEN,
	};
};

export const triggerOptionKitchenRow = () => {
	return {
		type: TRIGGER_OPTION_KITCHEN_ROW,
	};
};

export const triggerUnderOptionKitchen = () => {
	return {
		type: TRIGGER_BUTTON_ADD_UNDER_OPTION_KITCHEN,
	};
};

export const triggerButtonSaveUnderOptionKitchen = () => {
	return {
		type: TRIGGER_BUTTON_SAVE_UNDER_OPTION_KITCHEN,
	};
};

export const triggerProductForOptionKitchen = () => {
	return {
		type: TRIGGER_PRODUCT_FOR_OPTION_KITCHEN,
	};
};

export const triggerButtonAddTax = () => {
	return {
		type: TRIGGER_BUTTON_ADD_TAX,
	};
};

export const triggerButtonSaveTax = () => {
	return {
		type: TRIGGER_BUTTON_SAVE_TAX,
	};
};

export const triggerButtonAddPriceCategory = () => {
	return {
		type: TRIGGER_BUTTON_ADD_PRICE_CATEGORY,
	};
};

export const triggerButtonSavePriceCategory = () => {
	return {
		type: TRIGGER_BUTTON_SAVE_PRICE_CATEGORY,
	};
};

export const triggerPriceCategoryRow = () => {
	return {
		type: TRIGGER_PRICE_CATEGORY_ROW,
	};
};

export const triggerCheckRooms = () => {
	return {
		type: TRIGGER_CHECK_ROOMS,
	};
};

export const putFalseTriggerTutorial = () => {
	return {
		type: PUT_FALSE_TRIGGER,
	};
};

export const putFalseIsActiveTutorial = tutoActive => {
	return {
		type: PUT_FALSE_ACTIVE_TRIGGER,
		tutoActive: tutoActive,
	};
};
