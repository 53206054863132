import {
	TRIGGER_PRODUCT_MENUS_STEP1_BUTTON_NEXT,
	TRIGGER_PRODUCT_MENUS_BUTTON_HEADER,
	TRIGGER_BUTTON_ADD_OPTION_KITCHEN,
	TRIGGER_BUTTON_ADD_PRICE_CATEGORY,
	TRIGGER_BUTTON_SAVE_PRICE_CATEGORY,
	TRIGGER_PRODUCT_BUTTON_MAIN_PAGE,
	TRIGGER_BUTTON_SAVE_LEVEL_MENU3,
	TRIGGER_ENTRANCE_LEVEL_MENU3,
	TRIGGER_BUTTON_SAVE_OPTION_KITCHEN,
	TRIGGER_BUTTON_ADD_UNDER_OPTION_KITCHEN,
	TRIGGER_BUTTON_SAVE_UNDER_OPTION_KITCHEN,
	TRIGGER_PRODUCT_FOR_OPTION_KITCHEN,
	TRIGGER_OPTION_KITCHEN_ROW,
	TRIGGER_BUTTON_ADD_PRODUCT,
	TRIGGER_BUTTON_ADD_PRODUCT_FAST,
	TRIGGER_BUTTON_SAVE_CATEGORY,
	TRIGGER_PRICE_CATEGORY_ROW,
	TRIGGER_CHANGE_PRODUCT_CATEGORY,
	TRIGGER_BUTTON_SAVE_MENU3,
	TRIGGER_ADD_LEVEL_MENU3,
	TRIGGER_BUTTON_SAVE_TAX,
	TRIGGER_BUTTON_ADD_TAX,
	TRIGGER_CHECK_ROOMS,
	TRIGGER_SAVE_PRODUCT,
	PUT_FALSE_TRIGGER,
	PUT_FALSE_ACTIVE_TRIGGER,
} from '../constants/tutorialEvents';

const reducer = function tutorialEvents(state = {}, action) {
	let newState = { ...state };
	switch (action.type) {
		case TRIGGER_PRODUCT_BUTTON_MAIN_PAGE:
			return {
				...newState,
				TRIGGER_PRODUCT_BUTTON_MAIN_PAGE: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'product',
							value: 1,
							multiple: false,
						},
						{ tutorial: 'product', value: 7, multiple: false },
					],
				},
				trigger: true,
			};
		case TRIGGER_BUTTON_ADD_PRODUCT:
			return {
				...newState,
				TRIGGER_BUTTON_ADD_PRODUCT: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'product',
							value: 8,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_BUTTON_SAVE_CATEGORY:
			return {
				...newState,
				TRIGGER_BUTTON_SAVE_CATEGORY: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'category',
							value: 5,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_CHANGE_PRODUCT_CATEGORY:
			return {
				...newState,
				TRIGGER_CHANGE_PRODUCT_CATEGORY: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'product',
							value: 19,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_BUTTON_ADD_PRODUCT_FAST:
			return {
				...newState,
				TRIGGER_BUTTON_ADD_PRODUCT_FAST: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'product',
							value: 2,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_SAVE_PRODUCT:
			return {
				...newState,
				TRIGGER_SAVE_PRODUCT: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'product',
							value: 16,
							multiple: false,
						},
					],
				},
				trigger: true,
			};

		case TRIGGER_PRODUCT_MENUS_BUTTON_HEADER:
			return {
				...newState,
				TRIGGER_PRODUCT_MENUS_BUTTON_HEADER: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'productMenu',
							value: 1,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_PRODUCT_MENUS_STEP1_BUTTON_NEXT:
			return {
				...newState,
				TRIGGER_PRODUCT_MENUS_STEP1_BUTTON_NEXT: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'productMenu',
							value: 5,
							multiple: false,
						},
						{
							tutorial: 'productMenu',
							value: 8,
							multiple: false,
						},
						{
							tutorial: 'productMenu',
							value: 20,
							multiple: false,
						},
						{
							tutorial: 'productMenu',
							value: 23,
							multiple: false,
						},
						{
							tutorial: 'productMenu',
							value: 25,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_ADD_LEVEL_MENU3:
			return {
				...newState,
				TRIGGER_ADD_LEVEL_MENU3: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'productMenu',
							value: 10,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_BUTTON_SAVE_LEVEL_MENU3:
			return {
				...newState,
				TRIGGER_BUTTON_SAVE_LEVEL_MENU3: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'productMenu',
							value: 15,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_ENTRANCE_LEVEL_MENU3:
			return {
				...newState,
				TRIGGER_ENTRANCE_LEVEL_MENU3: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'productMenu',
							value: 17,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_BUTTON_SAVE_MENU3:
			return {
				...newState,
				TRIGGER_BUTTON_SAVE_MENU3: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'productMenu',
							value: 20,
							multiple: false,
						},
						{
							tutorial: 'supplement',
							value: 18,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_BUTTON_ADD_OPTION_KITCHEN:
			return {
				...newState,
				TRIGGER_BUTTON_ADD_OPTION_KITCHEN: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'supplement',
							value: 1,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_BUTTON_SAVE_OPTION_KITCHEN:
			return {
				...newState,
				TRIGGER_BUTTON_SAVE_OPTION_KITCHEN: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'supplement',
							value: 8,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_BUTTON_SAVE_UNDER_OPTION_KITCHEN:
			return {
				...newState,
				TRIGGER_BUTTON_SAVE_UNDER_OPTION_KITCHEN: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'supplement',
							value: 15,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_OPTION_KITCHEN_ROW:
			return {
				...newState,
				TRIGGER_OPTION_KITCHEN_ROW: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'supplement',
							value: 9,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_PRODUCT_FOR_OPTION_KITCHEN:
			return {
				...newState,
				TRIGGER_PRODUCT_FOR_OPTION_KITCHEN: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'supplement',
							value: 16,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_BUTTON_ADD_UNDER_OPTION_KITCHEN:
			return {
				...newState,
				TRIGGER_BUTTON_ADD_UNDER_OPTION_KITCHEN: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'supplement',
							value: 10,
							multiple: false,
						},
					],
				},
				trigger: true,
			};

		case TRIGGER_BUTTON_ADD_TAX:
			return {
				...newState,
				TRIGGER_BUTTON_SAVE_TAX: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'tax',
							value: 2,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_BUTTON_SAVE_TAX:
			return {
				...newState,
				TRIGGER_BUTTON_SAVE_TAX: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'tax',
							value: 5,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_BUTTON_ADD_PRICE_CATEGORY:
			return {
				...newState,
				TRIGGER_BUTTON_ADD_PRICE_CATEGORY: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'priceCategory',
							value: 1,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_BUTTON_SAVE_PRICE_CATEGORY:
			return {
				...newState,
				TRIGGER_BUTTON_SAVE_PRICE_CATEGORY: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'priceCategory',
							value: 9,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_PRICE_CATEGORY_ROW:
			return {
				...newState,
				TRIGGER_PRICE_CATEGORY_ROW: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'priceCategory',
							value: 12,
							multiple: false,
						},
					],
				},
				trigger: true,
			};
		case TRIGGER_CHECK_ROOMS:
			return {
				...newState,
				TRIGGER_CHECK_ROOMS: {
					isActive: true,
					stepIndex: [
						{
							tutorial: 'category',
							value: 4,
							multiple: true,
						},
						{
							tutorial: 'product',
							value: 15,
							multiple: true,
						},
						{
							tutorial: 'productMenu',
							value: 6,
							multiple: true,
						},
						{
							tutorial: 'supplement',
							value: 7,
							multiple: true,
						},
						{
							tutorial: 'supplement',
							value: 14,
							multiple: true,
						},
						{
							tutorial: 'priceCategory',
							value: 9,
							multiple: true,
						},
					],
				},
				trigger: true,
			};
		case PUT_FALSE_TRIGGER:
			return { ...newState, trigger: false };
		case PUT_FALSE_ACTIVE_TRIGGER:
			newState[action.tutoActive].isActive = false;
			return { ...newState, trigger: false };
		default:
			return newState;
	}
};

export default reducer;
