import {
	ADD_NOTIFICATION,
	UPDATE_NOTIFICATION,
	REMOVE_NOTIFICATION,
} from '../constants/notification';
import shortId from 'shortid';
import _findIndex from 'lodash/findIndex';

const initialState = [];
/**
 * notification Object
 * {
 *  id: 1 (ne pas renseigner),
 *  tags: [],
 *  text: 'Some text',
 *  date: '20/03/2018 20:00',
 *  viewed: false,
 *  openable: true
 * }
 */

const reducer = function productReducer(state = initialState, action) {
	const newState = [...state];
	switch (action.type) {
		case ADD_NOTIFICATION:
			const { notification, forceInsert = false } = action;
			if (newState && newState[0]) {
				if (newState[0].text !== notification.text || forceInsert) {
					notification.id = shortId.generate();
					return [...newState, notification];
				} else {
					return [...newState];
				}
			} else {
				notification.id = shortId.generate();
				return [...newState, notification];
			}

			notification.id = shortId.generate();
			return [...newState, notification];

		case UPDATE_NOTIFICATION:
			const idToUpdate = action.id;
			const notificationToUpdate = newState.map(notif => {
				if (notif.id === idToUpdate) {
					notif.viewed = true;
				}
				return notif;
			});
			return notificationToUpdate;
		case REMOVE_NOTIFICATION:
			const idToRemove = action.id;
			const indexToRemove = _findIndex(
				newState,
				notif => notif.id === idToRemove
			);
			newState.splice(indexToRemove, 1);
			return newState;
		default:
			return state;
	}
};

export default reducer;
