import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FloorSelectionItem from './FloorSelectionItem';
import findIndex from 'lodash/findIndex';
import { connect } from 'react-redux';
import { getAccess } from '../selectors/access.selector';
import Alert from './Alert';
import { I18n } from 'react-redux-i18n';

class FloorSelection extends Component {
	constructor(props) {
		super(props);

		this.onSelectedChange = this.onSelectedChange.bind(this);
	}

	componentWillReceiveProps(nextProps) {}

	/*
        Renvoie la liste des salles selectionée (à la fonction onChange) sous la forme d'un tableau d'objets : { id , counter_type }
     */
	onSelectedChange(floor) {
		let selected = [...this.props.floorsSelected];

		let index = findIndex(selected, function(f) {
			return f.id === floor.id && f.counter_type === floor.counter_type;
		});
		if (index > -1) {
			selected.splice(index, 1);
		} else {
			selected.push({
				id: floor.id,
				counter_type: floor.counter_type,
				name: floor.name,
			});
		}

		this.props.onChange(selected);
	}

	render() {
		const { floors } = this.props;
		const { selectedIcon, floorsSelected } = this.props;

		return (
			<div>
				{!this.props.accessExclusion.update ? (
					<Alert type={'warning'}>
						<p>{I18n.t('floor.alert_floor_mapping')}</p>
					</Alert>
				) : null}
				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					{floors.map(f => {
						let index = findIndex(floorsSelected, function(floor) {
							return f.id === floor.id && f.counter_type === floor.counter_type;
						});
						let isSelected = index > -1;
						return (
							<FloorSelectionItem
								key={f.id + '#' + f.counter_type}
								name={f.name}
								counter_type={f.counter_type}
								selected={!!isSelected}
								id={f.id}
								onSelectedChange={this.onSelectedChange}
								selectedIcon={selectedIcon}
								disabled={!this.props.accessExclusion.update}
							/>
						);
					})}
				</div>
			</div>
		);
	}
}

FloorSelection.propTypes = {
	floors: PropTypes.array.isRequired,
	floorsSelected: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	selectedIcon: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		accessExclusion: getAccess(state.access, 'exclusion'),
	};
};

export default connect(mapStateToProps)(FloorSelection);
