import {
	SWITCH_SORT_PRODUCT,
	UNDO_PRODUCT,
	REDO_PRODUCT,
	PRE_UPDATE_PRODUCT,
	PRE_CREATE_PRODUCT,
	PRE_DELETE_PRODUCT,
	PRE_REWEIGHT_PRODUCT,
	PRE_ASSIGN_AND_REWEIGHT_PRODUCT,
	PRE_IMPORT_PRODUCTS,
} from '../constants/product';

export const filterProducts = sort => {
	return {
		type: SWITCH_SORT_PRODUCT,
		sort,
	};
};
export const updateProducts = (
	ids,
	newValues,
	entities,
	disableLoader = false
) => {
	return {
		type: PRE_UPDATE_PRODUCT,
		ids,
		newValues,
		entities,
		disableLoader,
	};
};
export const createProducts = (newProducts, massInsert = false) => {
	return {
		type: PRE_CREATE_PRODUCT,
		newProducts,
		massInsert,
	};
};
export const deleteProducts = (ids, id_menu) => {
	return {
		type: PRE_DELETE_PRODUCT,
		ids,
		id_menu,
	};
};
export const reweightProducts = (newValues, id_category) => {
	return {
		type: PRE_REWEIGHT_PRODUCT,
		newValues,
		id_category,
	};
};
export const assignAndReweightProducts = (
	assign,
	assignFromWeight,
	categoryId
) => {
	return {
		type: PRE_ASSIGN_AND_REWEIGHT_PRODUCT,
		assign,
		assignFromWeight,
		categoryId,
	};
};
export const undo = () => {
	return {
		type: UNDO_PRODUCT,
	};
};
export const redo = () => {
	return {
		type: REDO_PRODUCT,
	};
};

export const importProducts = (id_menu_source, ids) => {
	return { type: PRE_IMPORT_PRODUCTS, id_menu_source, ids };
};

export const restoreProduct = (id_product, name) => {
	return { type: 'PRE_RESTORE_PRODUCT', id_product, name, entity: 'product' };
};
