import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';

class SideWindow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			classHidden: 'SideWindow-hidden',
			classVisible: 'SideWindow-visible',
			classSmall: 'SideWindow-small',
		};

		this.close = this.close.bind(this);
	}

	componentWillReceiveProps(next) {
		this.setState({ open: next.open });
	}

	close(e) {
		e.preventDefault();
		this.props.onCancel();
	}

	render() {
		const { open, classVisible, classHidden, classSmall } = this.state;
		const { small } = this.props;
		return (
			<div
				style={this.props.style}
				className={
					'SideWindow ' +
					(open ? classVisible : classHidden) +
					' ' +
					(small && open ? classSmall : '')
				}>
				<div className={'SideWindowContent'}>
					{/* TODO STYLE */}
					{/* <Button variant="fab" style={{backgroundColor: '#ffffff'}} iconStyle={style} onClick={this.close}> */}
					<Button
						variant="fab"
						style={{
							backgroundColor: '#ffffff',
							width: small ? '36px' : '56px',
							height: small ? '36px' : '56px',
						}}
						onClick={this.close}>
						<Close />
					</Button>

					{this.props.children}
				</div>
			</div>
		);
	}
}

export default SideWindow;
