export const RECEIVE_PRICE_CATEGORY = 'RECEIVE_PRICE_CATEGORY';

export const PRE_DELETE_PRICE_CATEGORY = 'PRE_DELETE_PRICE_CATEGORY';
export const DELETE_PRICE_CATEGORY = 'DELETE_PRICE_CATEGORY';

export const PRE_CREATE_PRICE_CATEGORY = 'PRE_CREATE_PRICE_CATEGORY';
export const CREATE_PRICE_CATEGORY = 'CREATE_PRICE_CATEGORY';

export const PRE_UPDATE_PRICE_CATEGORY = 'PRE_UPDATE_PRICE_CATEGORY';
export const UPDATE_PRICE_CATEGORY = 'UPDATE_PRICE_CATEGORY';

export const PRE_COPY_PRICE_CATEGORY = 'PRE_COPY_PRICE_CATEGORY';
export const COPY_PRICE_CATEGORY = 'COPY_PRICE_CATEGORY';

export const PRE_INIT_PRICE_CATEGORY = 'PRE_INIT_PRICE_CATEGORY';
export const INIT_PRICE_CATEGORY = 'INIT_PRICE_CATEGORY';

export const PRE_PATCH_PRICE_CATEGORY = 'PRE_PATCH_PRICE_CATEGORY';
export const PATCH_PRICE_CATEGORY = 'PATCH_PRICE_CATEGORY';

export const REFRESH_PRICE_CATEGORIES_LIST = 'REFRESH_PRICE_CATEGORIES_LIST';

export const PRE_REWEIGHT_PRICE_CATEGORY = 'PRE_REWEIGHT_PRICE_CATEGORY';
export const REWEIGHT_PRICE_CATEGORY = 'REWEIGHT_PRICE_CATEGORY';

export const PRE_REWEIGHT_FLOOR_PRICE_CATEGORY =
	'PRE_REWEIGHT_FLOOR_PRICE_CATEGORY';
export const REWEIGHT_FLOOR_PRICE_CATEGORY = 'REWEIGHT_FLOOR_PRICE_CATEGORY';
