import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { I18n } from 'react-redux-i18n';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default class DialogBin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
		};
	}

	handleClose = () => {
		this.props.onClose();
	};

	handleConfirm = () => {
		if (this.props.data && this.props.data.length === 1) {
			this.props.onConfirm(this.props.data[0].id);
		}
		this.props.onConfirm(this.state.value);
	};

	handleChange = event => {
		this.setState({ value: event.target.value });
	};

	renderRadioSelection = () => {
		if (this.props.data && this.props.data.length > 0) {
			let data = this.props.data;
			return (
				<RadioGroup
					name="productSelect"
					value={this.state.value}
					onChange={this.handleChange}>
					{data.map(row => {
						return (
							<FormControlLabel
								value={row.id}
								key={row.id}
								control={<Radio />}
								label={
									<div>
										<span
											style={{
												textTransform: 'capitalize',
												fontWeight: 'bold',
											}}>
											{row.name.toLowerCase()}
										</span>
										<span style={{ marginLeft: '5px' }}>
											{row.price}
											{this.props.sigle}
										</span>
									</div>
								}
							/>
						);
					})}
				</RadioGroup>
			);
		} else {
			return <div>NOOO</div>;
		}
	};

	render() {
		const { text, title, data } = this.props;
		const unique = data && data.length === 1;
		const radioSelection = this.renderRadioSelection();
		return (
			<Dialog
				open={this.props.open}
				onEscapeKeyDown={this.handleClose}
				onBackdropClick={this.handleClose}>
				<DialogTitle> {title} </DialogTitle>
				<DialogContent>
					<div style={{ marginTop: '5px' }}>
						<DialogContentText>{text}</DialogContentText>
						{unique ? (
							<div>
								<span
									style={{
										textTransform: 'capitalize',
										fontWeight: 'bold',
										marginLeft: '20px',
									}}>
									{data[0].name.toLowerCase()}
								</span>
								<span style={{ marginLeft: '5px' }}>
									{data[0].price}
									{this.props.sigle}
								</span>
							</div>
						) : (
							radioSelection
						)}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} color={'secondary'}>
						{I18n.t('generalButtons.cancel')}
					</Button>
					<Button
						onClick={this.handleConfirm}
						variant="raised"
						color={'primary'}>
						{I18n.t('generalButtons.yes')}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
