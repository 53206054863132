import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../SvgIcon';
import DeleteCell from './DeleteCell';
import EditCell from './EditCell';

const EditOrDeleteCell = React.memo(
	({
		value,
		onEditClick,
		onDeleteClick,
		creationInProgress,
		canDelete,
		canEdit,
	}) => {
		return (
			<div className="action-button">
				<EditCell
					onEditClick={onEditClick}
					creationInProgress={creationInProgress}
					value={value}
					disabled={!canEdit}
				/>

				<DeleteCell
					onDeleteClick={onDeleteClick}
					value={value}
					disabled={!canDelete}
				/>
			</div>
		);
	}
);

EditOrDeleteCell.propTypes = {
	row: PropTypes.number,
	td: PropTypes.string,
	col: PropTypes.number,
	prop: PropTypes.string,
	value: PropTypes.any,
	onEditClick: PropTypes.func,
	onDeleteClick: PropTypes.func,
};

export default EditOrDeleteCell;
