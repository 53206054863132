export default {
	product: {
		name: 'Nom',
		shortName: 'Nom court',
		price: 'Prix',
		tva: 'TVA',
		type: 'Type',
		categories: 'Catégorie(s)',
		place: 'Point de fabrication',
		space: 'espace',
		assign: 'Assigner / désassigner un produit',
		add: 'Ajouter',
		confirm: 'Êtes-vous sûr(e) de vouloir supprimer ce produit ?',
		deleteConfirm:
			'Êtes-vous sûr(e) de vouloir supprimer le produit "%{name}" ?',
		deleteBulkConfirm:
			'Êtes-vous sûr(e) de vouloir supprimer tous ces produits ?',
		free_price: 'PRIX LIBRE',
		productInCreation:
			'Veuillez enregistrer votre nouveau produit: %{productName} avant de modifier un produit déjà existant.',
		productTypeChange:
			'Vous avez changé le type de produit %{productName}, pensez à vérifier le point de fabrication et sa TVA.',
		productTVAChange:
			'Vous avez changé le taux de TVA du produit %{productName}, pensez à vérifier le type du produit.',
		disalowAutoFillForPrice:
			'Modification non appliquée à partir du produit %{productName}. Cette action est interdite sur les prix au détail.',
		disalowForFreePrice:
			'Modification non appliquée à partir du produit %{productName}. Cette action est interdite sur les prix libres.',
		disalowForProductVolume:
			'Vous ne pouvez pas copier le prix sur des produits ayant un prix au détail ou un prix libre.',
		productTVAAjusted:
			'La TVA a été changée automatiquement sur le produit %{productName}.',
		skuRequired: 'Le champ SKU est requis',
		skuIntRequired: 'Le champ SKU est requis et doit être un nombre entier',
		category_empty: 'Aucun produit associé à cette catégorie',
		nb_supplements: 'Nombre de suppléments',
		nb_prices: 'Nombre de catégories de prix',
		select_all: 'Tout sélectionner  / désélectionner',
		wrongPrice:
			'Vous avez renseigné un prix pour %{productName} au mauvais format',
		form: {
			supp: {
				min: 'Minimum',
				max: 'Maximum',
				required: 'Obligatoire',
				minMax: 'Min/Max',
				name: 'Nom',
			},
			name: 'Nom',
			shortName: 'Nom court (Facultatif)',
			description: 'Description (Facultatif)',
			price: 'Prix',
			price_default: 'Prix par défaut',
			tax: 'TVA',
			type: 'Type',
			category: 'Catégorie(s)',
			no_place: 'Aucun',
			place: 'Point de fabrication 1',
			place2: 'Point de fabrication 2 (Facultatif)',
			statusSend: "Temps d'envoi",
			visible: 'Visible',
			exclusion: 'Exclusions',
			direct: 'Direct',
			sku: 'SKU (Facultatif)',
			sku_required: 'SKU (Obligatoire)',
			free_price: 'Prix libre',
			no_name: 'Sans titre',
			binConfirmTitle: 'Récupération de produit(s)',
			binConfirmText:
				'Le(s) produit(s) suivant(s) supprimé(s) ont un nom similaire, souhaitez-vous récupérer ces informations pour pré-remplir le formulaire ? ',
			exclusion_label: 'Produit exclu pour les salles',
			retail_price: 'Prix au détail',
			select: 'Sélectionnez...',
		},
		speedDial: {
			add_product: 'Nouveau produit (formulaire détaillé)',
			add_product_line: 'Nouveau produit (rapide)',
			add_product_menu: "Ajouter des produits à partir d'une carte existante",
			restore_from_bin: "Récupération d'un produit supprimé",
			add_product_menu_form: 'Nouveau menu (formulaire détaillé)',
		},
		tooltip_bulk_delete: 'Suppression de produits en masse',
		assign_form: {
			search: 'Chercher',
			title_delete_bulk: 'Suppression de produits',
			delete: 'Supprimer',
		},
		filter: {
			add_filter: 'Ajouter un filtre',
			color: 'Couleur',
			price: 'Prix',
			taxe_rate: 'TVA',
			product_type: 'Type',
			category: 'Catégorie',
			place_send: 'Lieu de fabrication',
			visible: 'Visible',
			no_category: 'Sans catégorie',
			search: 'Rechercher...',
		},
		sort: {
			category_asc: 'Ordre des catégories ascendant',
			category_desc: 'Ordre des catégories descendant',
			category_A_Z: 'Ordre des catégories A-Z',
			category_Z_A: 'Ordre des catégories Z-A',
			product_asc: 'Nom de produit A-Z',
			product_desc: 'Nom de produit Z-A',
			price_asc: 'Prix croissant',
			price_desc: 'Prix décroissant',
			visible: 'Visible',
			not_visible: 'Non visible',
			or: ' Ou ',
		},
		types: {
			solid: 'Solide',
			alcool: 'Alcool',
			no_alcool: 'Non Alcool',
		},
		popUpAddProduct: {
			title: 'Importer des produits depuis la carte : ',
			import: 'Importer',
		},
		grid: {
			price_mode: 'Gestion des prix',
			delete_mode: 'Suppression de masse',
		},
		restore: {
			title_restore_product: 'Restauration de produits',
			title_restore_supp: 'Restauration de suppléments',
			title_restore_menu: 'Restauration de menu',
			description_restore:
				'Cliquez sur un élément pour le récupérer de la corbeille',

			secondary: 'Créé le %{created} - Supprimé le %{updated} (ID : %{id})',
			success:
				'Le produit "%{name}" a été restauré. Pensez à lui ré-affecter une catégorie',
			product_menu_success:
				'Le menu "%{name}" a été restauré. Vous pouvez lui ré-affecter des produits',
		},
	},
	url: {
		category: 'categorie',
		product: 'produit',
		tax: 'tax',
		supplement: 'supplement',
		placeSend: 'placeSend',
		product_menu: 'produitMenu',
		price_category: 'categoriePrix',
		floor: 'salle',
		category_display: 'Catégories',
		product_display: 'Produits',
		tax_display: 'TVA',
		supplement_display: 'Suppléments',
		placeSend_display: 'Fabrication',
		menu_display: 'Menu',
		price_category_display: 'Catégorie de Prix',
		floor_display: 'Salles / Activités',
		deploy: 'gestionDeploiement',
		deploy_display: 'Déploiement',
		menu: 'menu',
		menuBin: 'corbeilleMenu',
	},
	header: {
		update: 'Modifier le nom des modifications',
		delete: 'Supprimer les modifications du jour',
		menu: 'Vers le gestionnaire des cartes',
		logout: 'Déconnexion',
		reset: 'Suppression des modifications du %{date}',
	},
	deploy: {
		title: 'Gestion du déploiement des modifications',
		titleNewClient: 'Initialisation de votre carte',
		recap: {
			name: 'Nom de vos modifications',
			dateDeploy: 'Date du déploiement',
			nameRestaurant: 'Le patch a été déployé sur votre établissement',
			children:
				'Le patch a également été déployé sur les établissements suivants',
			title: 'Récapitulatif du déploiement',
			description:
				'Vos modifications du %{date} concernant la carte “%{nameMenu}” viennent d’être déployées. ',
			immediatly: 'Immédiatement',
			immediateDeploy:
				'Les données sont transmises à l’iPad et seront prises en compte à la date prévue',
			when: 'Quand ?',
			company: 'Etablissement',
			other_companies: 'Etablissement(s)',
			and_other_companies: 'Et %{nb} autre établissements',
			zone: 'Zone',
		},
		warning: 'Attention',
		weirdVatProducts:
			"Les produits suivants ont une TVA incohérente avec le type de produit. Si ce n'est pas le cas dans votre contexte, ignorez ces informations.",
		weirdPlaceSendProducts:
			"Les produits suivants ont un point de fabrication incohérent avec le type de produit. Si ce n'est pas le cas dans votre contexte, ignorez ces informations.",
		confirm_weird:
			'Certains produits ont une TVA ou un point de fabrication incohérent avec le type de produit. Voulez-vous quand même déployer ces modifications ?',
		unknowVAT: 'TVA inconnue',
		unknowPlaceSend: 'Point de fabrication inconnu',
		dayOfDeploy: 'Jour du déploiement',
		form: {
			name: 'Nom de la modification',
			date: 'Date du déploiement',
			now: 'Déployer maintenant',
			later: 'Déployer ultérieurement à la date : ',
			createMenu: 'Initialiser la carte',
			submit: 'Déployer',
			nameNewClient: "Nom de l'initialisation",
			placeHolderRestaurant: 'Nom du restaurant',
			restaurant_select_all: 'Tout sélectionner  / désélectionner',
			restaurant_empty: 'Aucun restaurant',
			toBeDeployed: 'Déploiement prévu le : ',
			noDeploy: 'Impossible de modifier la sélection',
			impossibleAction:
				'Déployer maintenant - Action impossible, un déploiement est déjà prévu',
			assignRestaurant:
				"Choisir et déployer également sur d'autres restaurants",
			titleAssignator: 'Choisir mes restaurants',
			warningDeleteChildren:
				'Attention, cela supprimera le déploiement sur les restaurants précédemment choisis',
			warningDate:
				"Impossible de déployer une modification si la date et l'heure sont inférieures au dernier déploiement prévu",
			warningOneDate:
				'Vous ne pouvez pas déployer une modification avant 24 heures',
			defaultName: 'Modifications du %{date}',
		},
		menu_display: 'Menus',
		price_category_display: 'Catégories de prix',
		floor_display: 'Salles / Activités',
	},
	tax: {
		name: 'Nom',
		rates: 'Taux',
		rate: 'Taux sur place (%)',
		rate_take_away: 'Taux à emporter (%)',
		labelTaxRateError: 'Ce champ doit comporter un nombre entre 0 et 100.',
		confirm: 'Êtes-vous sûr(e) de vouloir supprimer cette TVA ?',
		default: 'Par défaut',
		active: 'Actif',
		editConfirm:
			'Attention : cette TVA est associée à %{count} produit(s), êtes-vous sûr(e) de vouloir la modifier ?',
	},
	category: {
		empty: "Aucune catégorie de produits n'est configurée",
		empty_exemple: 'Exemples : Entrées, Plats, Vins...',
		name: 'Nom',
		color: 'Couleur',
		icon: 'Icône',
		all: 'Tous les produits',
		confirm:
			"Êtes-vous sûr(e) de vouloir supprimer cette catégorie ? Les produits contenus dans la catégorie ne seront pas supprimés et seront toujours visibles dans 'Tous les produits'.",
		deleteConfirm: 'Confirmation de suppression',
		no_category: 'Sans catégorie',
		already_selected: 'Déjà sélectionné',
		product: {
			price: 'Prix à la carte',
			type: 'Type',
			tax: 'TVA',
			place_send: 'Point de fabrication',
			places_send: 'Points de fabrication',
			alert_price:
				'Vous ne pouvez pas ajouter des produits à 0.00%{currency} dans un menu.',
		},
	},
	supplementCategory: {
		empty: 'Aucune catégorie de suppléments configurée',
		empty_exemple: 'Exemples : Cuisson, Parfums de glace...',
		form_name: 'Nom de la catégorie de suppléments',
		name: 'nom',
		color: 'couleur',
		icon: 'icône',
		type1: 'Un seul choix',
		type2: 'Plusieurs choix',
		type3: 'Plusieurs choix en quantité',
		confirm:
			'Êtes-vous sûr(e) de vouloir supprimer cette catégorie de suppléments ?',
		assign_product: 'Assigner des produits',
		min: 'min',
		max: 'max',
		labelMultiple:
			'Les articles associés régissent à la règle suivante (0 = falcultatif)',
		labelUnique: 'La saisie pour les articles associés est',
		add: 'Ajouter une catégorie de suppléments',
		changes_confirmation:
			'Voulez-vous appliquer les changements sur les produits liés à cette catégorie de supplément',
	},
	floorPriceCategoryReweight: {
		title: 'Gestion des priorités de catégories de prix',
		name: 'Catégories de prix',
		floors: 'Salles',
	},
	supplement: {
		empty: 'Aucun supplément configurée',
		empty_exemple: 'Exemples : Vanille, Fraise, Saignant, A point ...',
		name: 'Nom du supplément',
		price: 'Prix',
		confirm: 'Êtes-vous sûr(e) de vouloir supprimer ce supplément ?',
		add: 'Ajouter un supplément',
		add_restore: 'Restaurer un supplément supprimé',
		addSpace: 'Ajouter un espace',
		sku: 'SKU',
	},
	generalButtons: {
		cancel: 'Annuler',
		ok: 'Ok',
		yes: 'Oui',
		no: 'Non',
		edit: 'Modifier',
		delete: 'Supprimer',
		reload: 'Recharger',
	},
	user: {
		login: 'Se connecter',
		confirmLogout: 'Êtes-vous sûr de vouloir vous déconnecter ?',
	},
	productMenu: {
		name: 'Nom',
		shortName: 'Nom court',
		empty: 'Aucun menu configuré',
		price: 'Prix',
		tax: 'TVA',
		visible: 'Visible',
		confirmMenu: 'Êtes-vous sûr(e) de vouloir supprimer ce menu ?',
		confirmMenuLevel:
			'Êtes-vous sûr(e) de vouloir supprimer ce niveau de menu ?',
		nbMenuLevel: 'Niveaux',
		nbProduct: 'Produits',
		next: 'Suivant',
		back: 'Retour',
		levelName: 'Niveau de menu',
		excludedOn: 'Le menu est exclu pour :',
		title: 'Liste des menus',
		noProduct:
			'Vous devez configurer un produit avec un prix supérieur à 0.00 avant de configurer des menus',
		form: {
			titleCreation: "Création d'un menu",
			titleCreation2: 'Création du menu : ',
			name: 'Nom du menu',
			short_name: 'Nom court (Facultatif)',
			sku: 'SKU (Facultatif)',
			sku_required: 'SKU (Obligatoire)',
			color: 'Couleur du Menu',
			description: 'Description (Facultatif)',
			errorName: 'Champs requis',
			errorPrice: 'Nombre positif requis',
			price: 'Prix',
			defaultPrice: 'Prix par défaut',
			step1: 'Dénomination',
			step2: 'Prix et Exclusions',
			step3: 'Gestion des produits',
			step4: 'Validation',
			submit: 'Valider',
			defaultMenuLevelName1: 'Entrée',
			defaultMenuLevelName2: 'Plat',
			is_alternative: 'Facultatif',
			is_required: 'Obligatoire',
			is_required_manufacturing: "Obligatoire pour l'envoi en cuisine",
			add_menu_level: '+ Ajouter un niveau de menu',
			menu_level_form_name: 'Formulaire niveau de menu',
			product_menu_level_form_name: 'Formulaire Produit',
			active: 'Actif',
		},
	},
	menu: {
		addNew: 'Ajouter une nouvelle carte',
		active: 'Active',
		goToBin: 'Voir les cartes supprimées',
		modify: 'Modifier la carte',
		deploy: 'Gérer le déploiement',
		delete: 'Supprimer la carte',
		return: 'Retour en arrière',
		inactive: 'Inactive',
		removed: 'Supprimée',
		update: 'Modifier',
		permanent: 'Permanent',
		startDate: 'Date de début',
		endDate: 'Date de fin',
		resume: 'Résumé',
		company: 'Etablissement',
		institution: 'Etablissement(s)',
		product: 'Produit(s)',
		category: 'Catégorie(s)',
		carteSelected: 'Carte active iPad',
		nextPatch: 'Prochain déploiement',
		dateDeploy: 'Date déploiement',
		noFuturPatch: 'Aucun déploiement programmé',
		noAccess:
			'Pour accéder à la carte, veuillez effectuer une synchronisation depuis votre iPad principal',
		menu: 'Menu(s)',
		titlePopUpEdit: 'Modifier les informations',
		createMenu: 'Nous vous invitons à créer votre première carte',
		synchroCells: {
			never: 'Jamais déployé',
			noSynchro: 'Non synchronisé',
			synchroNoDeployed: 'Synchronisé non appliqué',
			synchro: 'Synchronisé',
		},
		synchroInstitution: 'Synchronisation établissements',
		synchro: 'Synchronisation',
		access: 'Cliquez ici pour accéder',
		deleteMenu:
			'Etes vous sûr de vouloir supprimer cette carte ? Tous les produits présents seront supprimés. Attention, cette action est irréversible',
		cards: 'carte(s)',
		currentCard: 'carte(s) en cours - ',
		pastCard: 'carte passée - ',
		futurCard: 'carte à venir - ',
		neverCurrentCard: 'Aucune carte en cours',
		neverPastCard: 'Aucune carte passée',
		neverFuturCard: 'Aucune carte à venir',
		form: {
			name: 'Nom de la carte',
			placeholderName: 'Hiver',
			errorName: 'Champ requis',
			startDate: 'Date de début',
			warning:
				'Attention, vous souhaitez supprimer cette carte, êtes-vous sûr ? Cette carte sera visible et récupérable pendant 1 mois avant sa suppression définitive.',
			warning2:
				'Attention, vous souhaitez restaurez cette carte, êtes-vous sûr ? Cette restauration peut entrainer des doublons avec vos produits.',
			warningCopy:
				"La création d'une nouvelle carte n'est utile seulement si vous souhaitez avoir au moins 50% de vos articles différents par rapport à votre carte actuelle",
			endDate: 'Date de fin',
			new: 'Toute nouvelle carte',
			copy: 'Copie à partir de',
			submit: 'Valider',
			submit2: 'Confirmer',
			add: 'Nouvelle carte',
			warningCopyMenu: `Une fois les éléments de la carte copiés, la nouvelle carte sera automatiquement déployée sans que vous n'ayez besoin d'effectuer un déploiement via la rubrique "Déployer". Pensez à indiquer une date de début si vous ne souhaitez pas qu'elle soit disponible sur l'iPad dès la prochaine synchronisation ou prochain service.`,
			cancel: 'Annuler',
			save: 'Enregistrer',
		},
		formCopy: {
			choiceElement: 'Choix des éléments à copier',
			category: 'Catégories de produits',
			categorySupplement: 'Catégories de suppléments',
			productsSelected: 'produits(s) sélectionné(s)',
			menusSelected: 'menu(s) sélectionné(s)',
			supplementSelected: 'supplément(s) sélectionné(s)',
			menus: 'Menus',
			integralCopy: "Copier l'intégralité de la carte",
		},
		patch: 'Patch en cours',
		patchName: 'Nom du patch',
		nbModif: 'Modification(s)',
		carteAccess: 'Accéder à la carte',
		startDatePermanent: 'Date de début permanente',
		endDatePermanent: 'Date de fin permanente',
		addMenuToRestaurant: {
			title: 'Lier vos établissements à votre carte',
			select: 'Sélection des établissements',
			selected: 'Etablissements liés',
			name: 'Nom',
			synchro: 'Synchronisation',
			beginDate: 'Date début',
			endDate: 'Date fin',
			edit: 'Modifier',
			validate: 'valider',
			cancel: 'Annuler',
			add: 'Ajouter',
			deAssign: 'Désassigner',
			close: 'Fermer',
			selectRestaurant: 'Aucun établissement à assigner',
			untiedRestaurant: 'Aucun établissement liès à la carte',
			confirmAssign: 'Êtes-vous sur de vouloir assigner cet établissement ?',
			confirmUntied: 'Êtes-vous sur de vouloir désassigner cet établissement ?',
			assign: 'assigner',
		},
		deploy: 'Gérer le déploiement',
		acess_menu_allready_in_use:
			"Attention, il semblerait qu'une session soit déjà ouverte avec cette carte. Voulez-vous tout de même y accéder ?",
		edits: {
			title: 'Modifications à venir',
			no_edits: 'Aucune modification en cours pour cette carte',
			create: 'création(s) %{type}',
			update: 'modification(s) %{type}',
			delete: 'suppression(s) %{type}',
			minorEdits: '%{nbModifs} modifications diverses',
			remainingEdits: 'et %{nbModifs} autres modifications',
			type: {
				category: 'de catégorie',
				product: 'de produit/menu/supplément',
				price: 'de prix',
				category_supplement: 'de supplement',
				price_category: 'de catégorie de prix',
				category_product: "de l'organisation des catégories",
				product_product: 'de liaisons produit-menu ou produit-supplément',
			},
		},
	},
	priceCategory: {
		name: 'Nom',
		name_short: 'Nom court',
		time: 'Durée',
		days: 'Jours',
		floors: 'Salles',
		active: 'Actif',
		deleteConfirm:
			'Êtes-vous sûr de vouloir  supprimer cette catégorie de prix ? Les prix associés seront supprimés toutes échéances comprises.',
		empty: 'Aucune catégorie de prix',
		empty_exemple: 'Exemples : Happy Hour, Terrasse ...',
		associations: 'Voir les associations',
		form: {
			name: 'Nom',
			name_short: 'Nom court (Facultatif)',
			time: 'Durée',
			days: 'Jours',
			days_label: 'Jours concernés par cette catégorie de prix',
			floors: 'Salles',
			floors_label: 'Salles concernées par cette catégorie de prix',
			active: 'Actif',
			start: 'Début',
			end: 'Fin',
			all_day: 'Toute la journée',
			display_settings: 'Affichage nom court',
			display_short_name_order: 'Affichage du nom court en commande',
			display_short_name_cashing: "Affichage du nom court à l'encaissement",
			display_short_name_manufacturing: 'Affichage du nom court en cuisine',
			associations: 'Produit(s) associé(s)',
			times: {
				from: 'De',
				to: 'à',
				tomorrow: 'le lendemain',
			},
			init: {
				button: 'Configuration rapide',
			},
			copy: {
				button: "Copier à partir d'une autre catégorie",
			},
			copyConfirmation:
				'Vous allez changer les prix de cette catégorie par ceux de la catégorie "%{categoryName}". Les prix de la catégorie actuelle seront remplacés. ',
			initConfirmation:
				'Vous allez configurer cette catégorie de prix ; si des prix ont été précédemment renseignés, ils seront écrasés. Voulez-vous continuer ?  ',
		},
		init: {
			no_category:
				'Veuillez configurer des catégories et y associer des produits',
			no_other_price_category: "Il n'existe aucune autre catégorie de prix",
			none: 'Aucun changement',
			reinit: 'Réinitialiser (suppression des prix)',
			price_fixed: 'Prix fixe',
			price_plus: 'Montant en plus',
			price_minus: 'Montant en moins',
			percent_plus: '% en plus',
			percent_minus: '% en moins',
		},
		popupAssociation: {
			type: 'Type',
			name: 'Nom',
			price: 'Prix',
			product: 'Produit',
			productMenu: 'Menu',
			supplement: 'Supplément',
			productInMenu: 'Produit Menu',
			empty:
				'Aucun(e) Produit / Menu / Supplément associé à cette catégorie de prix',
		},
		button_floor_price_category_weight:
			'Gérer les priorités de catégories de prix',
		initPercentage: {
			initsentence: 'Veuillez choisir la méthode pour les arrondis en %',
			warning: 'Attention',
			aroundMath: 'Arrondi mathématique',
			aroundTen: 'Arrondi à 0,10',
			aroundFive: 'Arrondi à 0,05',
			cancel: 'Annuler',
		},
		alert:
			'Les catégories de prix sont globales ; si vous supprimez une catégorie de prix, vous supprimez également tous les prix associés à cette catégorie de prix pour toutes les prochaines échéances.',
	},
	patchMenu: {
		deniedAccessText:
			'Un déploiement est en cours, vous ne pouvez pas accéder à votre carte pour le moment.',
		deniedAccessTitle: 'Attention',
		deniedAccessQuit: 'Quitter',
		confirmDelete:
			'Êtes-vous sûr de vouloir supprimer ce patch ? Cela supprimera également les modifications en cours',
		ipadChanges: "Les données de l'iPad ont été synchronisées.",
		ipadChangesCompany:
			"Les données de l'iPad ont été synchronisées pour l'établissement %{nameCompany} - Carte : %{nameMenu}",
		ipadNoChanges:
			"Les données de l'ipad n'ont pas pu être synchonisées pour la carte: %{nameMenu}",
		ipadNoChangesCompany:
			"Les données de l'ipad n'ont pas pu être synchonisées pour l'établissement : %{nameCompany} - Carte : %{nameMenu}",
		deniedModification:
			'Un déploiement est en cours, vous ne pouvez pas faire de modification pour le moment',
		warningDelete:
			' Êtes-vous sûr de vouloir supprimer les modifications en cours du %{date} ? Les valeurs propres à la date sélectionnée reprendront leurs valeurs initiales avant modification.',
		deploySuccess: 'Déploiement effectué avec succès',
		deploySuccessSynchro:
			"Déploiement effectué avec succès, en attente d'une synchronisation d'un iPad",
		resetSuccess:
			'Suppressions des modifications du jour effectuées avec succès',
		noModifications:
			"Impossible d'effectuer un déploiement car aucune modification n'a été réalisée.",
		disabledMasterMultiSelected:
			'Veuillez vous positionner sur une zone pour déployer les changements de carte',
		updateFail:
			'Erreur lors de la mise à jour du patch. Déploiement non effectué',
		deployFail: 'Error lors du déploiement des modifications',
		updatePatch: {
			title: 'Modifier',
			where: "Vous êtes sur l'écran de modification de patch",
			secondTitle: 'Modifier le nom de votre patch',
			thirdTitle: "Modifier l'heure de déploiement",
			failPrev: 'Vous ne pouvez pas modifier une date avant un patch existant',
			failAft: 'Vous ne pouvez pas modifier une date aprés un patch existant',
			failTime: "Vous ne pouvez rentrer une date avant l'heure actuelle",
			failBefore24H: 'Vous ne pouvez pas déployer un patch avant 24 heures',
		},
		deletePatchFail:
			'Vous ne pouvez pas supprimer un déploiement avant un autre déploiement de prévu',
		errorDeletePatch: "Oops, Une erreur s'est produite",
		display: {
			title: 'Patches à venir',
			date: 'Date de déploiement',
		},
		deleteSuccess: 'Déploiement supprimé avec succès',
	},
	floor: {
		no_configuration_here:
			"Les salles et activités doivent être configurées directement sur l'application iPad.",
		empty: 'Aucune salle configurée',
		no_floors: 'Aucune salle',
		all: 'Toutes les salles',
		name: 'Nom',
		type: 'Type',
		takeaway: 'Restauration rapide',
		counter_type: {
			stay_in: 'Sur place',
			take_away: 'A emporter',
			delivery: 'Livraison',
		},
		form: {
			name: 'Nom',
			color: 'Couleur',
			category: 'catégorie',
			counter: 'Comptoir',
			general_options: 'Options générales',
			advanced_options: 'Options avancées',
			auto_order_closing: 'Fermeture auto',
			payment_after_ticket: 'Encaissement après ticket',
			flash_cashing: 'Encaissement flash',
			client: 'Ouverture automatique des clients',
			counter_type: 'Type',
			display_amount: 'Afficher le montant des commandes',
			print_auto_manufacturing_close:
				"Envoi automatique à la fermeture d'une commande",
			print_auto_manufacturing_exit:
				"Envoi automatique à la sortie d'une commande",
			print_auto_ticket_close_nb: 'Ticket(s) à imprimer',
			print_auto_ticket_close_cashed:
				'Afficher le montant encaissé sur le ticket',
			print_auto_ticket_close_status: 'Même si le ticket a déjà été imprimé',
		},
		exclusion_label: 'Interdire la sélection sur :',
		alert_floor_mapping:
			'Une ou plusieurs salles du compte ne sont pas liées à au moins une salle de chaque site, veuillez contacter le service technique',
	},
	days: {
		monday: 'Lundi',
		tuesday: 'Mardi',
		wednesday: 'Mercredi',
		thursday: 'Jeudi',
		friday: 'Vendredi',
		saturday: 'Samedi',
		sunday: 'Dimanche',
		EVERY_DAY: 'Tous les jours',
		no_days: 'Aucun jour',
	},
	retail: {
		type: {
			size: {
				title: 'Prix au mètre',
				unit: 'Mètre',
				subUnit: 'centimère',
			},
			time: {
				title: "Prix à l'heure",
				unit: 'Heure',
				subUnit: 'minute',
			},
			volume: {
				title: 'Prix au litre',
				unit: 'Litre',
				subUnit: 'centilitre',
			},
			weight: {
				title: 'Prix au Kg',
				unit: 'Kg',
				subUnit: 'gramme',
			},
		},
	},
	assignator: {
		tag_empty: 'Aucun produit sélectionné',
		category: 'Catégorie',
		menuLevel: 'Niveau de menu',
		supplementCategory: 'Catégorie de suppléments',
	},
	zone: {
		company_loaded: 'La carte de %{name} a été chargée avec succès',
		zone_loaded: 'La carte de %{name} a été chargée avec succès',
		master_loaded: 'La carte du siège a été chargée avec succès',
	},
	helper: {
		title: "Rubrique d'aide",
		button: "Besoin d'aide ?",
		close: 'Fermer',
		titles: {
			product: 'Produits',
			category: 'Catégories',
			supplement: 'Suppléments',
			menu: 'Menus',
			priceCategory: 'Catégories de prix',
			tax: 'TVA',
			patchMenu: 'Déploiements',
			quickstart: 'Par où commencer ?',
			video: 'Démonstration en vidéo',
			master: 'Master Multi-établissements',
		},
		sections: {
			quickstart: {
				title: 'Par où commencer ?',
				paragraph_1:
					'Bienvenue sur votre outil de gestion de carte en ligne. Vous allez dès à présent pouvoir créer et personnaliser la carte de votre établissement.',
				paragraph_2:
					'La "Carte Web" permet de créer tous les éléments de votre carte. Catégories, produits, suppléments, menus. ',
				paragraph_3:
					"Consultez les différentes sections de cette rubrique d'aide afin d'utiliser au mieux cet outil.",
				paragraph_4:
					'Pour retrouver cette section d\'aide, cliquez sur le bouton "Besoin d\'aide ?" en haut à gauche de la fenêtre.',
			},
			product: {
				title: 'Produits',
				create: {
					title: 'Créer un nouveau produit',
					paragraph_1:
						"Pour ajouter un nouveau produit, cliquez sur la croix rouge « + ». Deux icônes s'affichent alors de part et d'autre de cette croix :",
					fast: {
						title: '"Nouveau produit (rapide)" ',
						warning: 'Attention, ',
						paragraph_1:
							"(à gauche de la croix rouge) : permet d'ajouter une ligne en bas de la grille afin de créer rapidement un produit. Dans cette nouvelle ligne, vous pouvez saisir les informations du produit en cliquant directement dans chaque champ.",
						paragraph_1_2:
							" votre nouveau produit ne sera sauvegardé qu'après avoir cliqué sur l'icône verte '+' en bout de ligne.",
						paragraph_2:
							"Si vous souhaitez accéder au formulaire détaillé du produit, cliquez sur l'icône de modification (picto crayon) en fin de ligne.",
						paragraph_3:
							'Dans le cas où plusieurs de vos produits ont le même prix, TVA, Type, Catégorie et/ou point de fabrication, une astuce vous permet de simplifier le renseignement des champs pour vous éviter de remplir chaque champ',
						paragraph_4:
							'Pour cela, créez vos produits en « Nouveau produit (rapide) ». Renseignez le nom de chacun, et renseignez le prix du premier produit. Puis cliquez sur le champ « Prix » de ce produit. Le champ s’entoure d’un cadre bleu avec un petit carré bleu dans l’angle en bas à droite. Avec votre souris, faites glisser ce petit carré sur les autres lignes de produit : le prix s’appliquera alors à tous les produits concernés.',
						paragraph_5:
							'Vous pouvez faire de même avec la TVA, le type, la catégorie et le point de fabrication.',
					},
					form: {
						title: '"Nouveau produit (formulaire détaillé)" ',
						content:
							"(à droite de la croix rouge) : permet d'accéder au formulaire détaillé de création de produit.",
						list: {
							name: {
								title: 'Nom : ',
								content:
									"(champ obligatoire) Libellé du produit utilisé par défaut dans l'application.",
							},
							short_name: {
								title: 'Nom court : ',
								content:
									'Libellé du produit utilisé, si l’option est activée dans la grille de produits durant la prise de commande et/ou sur les impressions (en cuisine et le ticket de caisse).',
							},
							sku: {
								title: 'SKU : ',
								content:
									'Si vous avez plusieurs établissements et des plats communs mais qui n’ont pas le même nom, entrez un code commun pour ce plat.',
							},
							description: {
								title: 'Description : ',
								content:
									'Description ou commentaire du plat (accessible lors de la prise de commande).',
							},
							price: {
								title: 'Prix : ',
								content: 'Prix TTC du produit.',
								paragraph_1:
									'Le prix peut aussi être « Libre », c’est-à-dire que l’application vous demandera de renseigner le prix souhaité lors de la commande du produit.',
								paragraph_2:
									"Le prix peut aussi être « au détail ». Pour cela cochez la coche « prix au détail » et sélectionnez l'unité de mesure (prix au kg, au mètre, au litre ou à l'heure) et renseignez le prix.",
								paragraph_3:
									"Si vous définissez des catégories de prix (ex : Happy hour), dans la rubrique « Catégorie de prix », vous pourrez choisir ici d'attribuer ou non la catégorie de prix à ce produit et de définir le prix pour le produit en question.",
							},
							visible: {
								title: 'Visible : ',
								content:
									'Rendre le produit visible ou non dans la prise de commande (en cas de produit non disponible temporairement ou hors saison) sans le supprimer de vos réglages de carte.',
							},
							vat: {
								title: 'TVA : ',
								content:
									'(champ obligatoire) Sélectionner le taux de TVA à appliquer sur ce produit.',
								paragraph:
									'Trois TVA sont pré-configurées. Vous pouvez configurer de nouveaux taux dans la rubrique « TVA ».',
							},
							type: {
								title: 'Type : ',
								content:
									'(champ obligatoire) Le type de produit est utilisé pour faire des rapports au sein de votre ou vos établissements (disponible dans le reporting en ligne). La liste des types de produits est prédéfinie. Lorsque vous cliquez sur le champ « Type », vous verrez apparaître la liste de tous les types possibles, avec l’information suivante : Solide, Alcool ou Non Alcool.',
							},
							category: {
								title: 'Catégorie : ',
								content:
									'Permet de classer le produit dans une ou plusieurs catégories de produit que vous avez créées dans la rubrique « Catégorie » (vous pouvez cocher plusieurs catégories).',
							},
							place_send_1: {
								title: 'Point de fabrication 1 : ',
								content:
									"Lieu d'envoi du produit (lieu où sera imprimé le ticket de fabrication). Par défaut, le point de fabrication pour tout nouveau produit est « Cuisine ».",
							},
							place_send_2: {
								title: 'Point de fabrication 2 : ',
								content:
									"Lieu d'envoi secondaire du produit (second lieu où sera envoyé le ticket de fabrication).",
							},
							status_send_index: {
								title: "Temps d'envoi : ",
								content:
									"Temps d'envoi du produit (Direct, Suite 1, Suite 2, Suite 3...). Par défaut, le temps d'envoi est fixé à « Direct » pour tout nouveau produit.",
							},
							supplements: {
								title: 'Suppléments : ',
								content:
									"Ici s'affichent les suppléments créées dans la rubrique « Suppléments ».",
								paragraph_1:
									"En cochant une ou plusieurs catégories de suppléments, un menu déroulant apparaitra sur la droite dans l'application lors de la prise de commande pour indiquer au serveur de renseigner les suppléments (par exemple ➔ plat : Entrecôte, suppléments : Cuisson, Accompagnement et/ou Sauce).",
								paragraph_2:
									'Ces suppléments peuvent être obligatoires ou facultatifs, ou avec un min/max pour les suppléments permettant de sélectionner plusieurs choix (voir la section « Suppléments » pour plus d’informations).',
							},
							color: {
								title: 'Couleur : ',
								content:
									'Couleur de la touche du produit dans la grille de prise de commande.',
							},
							exclusions: {
								title: 'Exclusions : ',
								content:
									'Cette fonctionnalité permet de choisir les salles dans lesquelles le produit n’apparaîtra pas sur la grille de commande.',
							},
						},
						paragraph_1_bold:
							'Pour enregistrer votre produit, cliquez sur le bouton bleu « Enregistrer » ',
						paragraph_1:
							"en haut à droite du formulaire. Si ce bouton est grisé, cela signifie qu'un des 3 champs obligatoires (nom, TVA ou type) n'est pas renseigné.",
						paragraph_2:
							"Si vous souhaitez qu'un produit soit disponible dans un menu mais non visible à la carte, créez une catégorie « Menu » dans laquelle vous classerez tous les produits de vos menus et excluez cette catégorie de toutes les salles. Une fois l'application installée, il vous suffira d'aller dans « Réglages / Options » et de cocher « Autoriser la sélection d'un produit non visible dans un menu ».",
					},
				},
				update: {
					title: 'Modifier un produit',
					paragraph_1:
						"Au sein du tableau de produits, vous pouvez modifier des informations d'un produit à la volée, en cliquant directement sur le champ d'un produit que vous souhaitez modifier dans la grille. Exemple : modification de couleur, nom, catégories...",
					paragraph_2:
						"Pour accéder au formulaire détaillé, cliquez sur l'icône de modification (picto crayon) en fin de ligne.",
				},
				cancel_update: {
					title: 'Annuler une modification',
					paragraph:
						"En haut à droite du tableau de l'ensemble des produits, figurent 2 flèches. Ces flèches permettent d'annuler la dernière modification faite (flèche vers la gauche) ou bien de revenir à une modification annulée (flèche vers la droite).",
				},
				reweight: {
					title: 'Organiser les produits dans la grille de commande',
					paragraph:
						"Les catégories de produits apparaissent en liste à gauche du tableau des produits. Cliquez sur une des catégories pour afficher uniquement les produits associés à une catégorie. Vous pouvez ainsi voir la façon dont s'afficheront les produits dans la grille de commande sur l'application.",
					list: {
						item_1:
							"Pour les réorganiser dans la grille, cliquez sur un des produits et faites-le glisser à l'endroit souhaité.",
						item_2:
							"Pour ajouter des espaces, cliquez sur le bloc gris « espace » et faites-le glisser à l'endroit souhaité. Les « espaces » sont des blocs vides dans le cas où vous voudriez fait une mise en forme spécifique.",
						item_3_1:
							"Pour ajouter un produit, cliquez sur le bloc gris « Assigner un produit » et faites-le glisser à l'endroit souhaité.",
						item_3_2:
							"Une fenêtre s'ouvre. Sélectionnez le ou les produits que vous souhaitez ajouter en cochant la case à droite de la ligne de produit. Le champ « chercher » vous permet de rechercher facilement un produit par son nom. Vous pouvez aussi chercher un produit dans une des catégories sur la gauche.",
						item_3_3:
							"Cliquez sur le bouton « Enregistrer » en haut à droite du formulaire. Le ou les produits ajoutés apparaissent alors en bas de votre grille. Vous pouvez le déplacer à l'endroit que vous souhaitez.",
						item_4:
							"Pour supprimer un produit d'une catégorie, passer la souris sur le bloc produit à supprimer et cliquez sur l'icône rouge de suppression qui apparaît en haut à droite du bloc. Le produit sera conservé dans la liste globale des produits mais n'appartiendra plus à aucune catégorie.",
						item_5:
							"Pour modifier un produit, cliquez sur le bloc du produit en question afin d'afficher le formulaire de modification.",
					},
				},
				filters: {
					title: 'Filtres',
					content:
						'Vous pouvez filtrer la liste des produits créés. Pour cela, plusieurs filtres sont proposés :',
					list: {
						search: {
							title: 'Rechercher : ',
							content: 'effectuer une recherche par nom de produit',
						},
						sort: {
							title: 'Tri : ',
							content:
								'trier par ordre des catégories, par ordre alphabétique de nom de produits ou bien par prix.',
						},
						add_filter: {
							title: 'Ajouter un filtre : ',
							content:
								'afficher uniquement les produits correspondant à une couleur, un prix, une TVA, un type, une catégorie ou bien un point de fabrication',
						},
					},
				},
			},
			category: {
				title: 'Catégories',
				paragraph_1:
					'Les catégories permettent d’organiser la carte en regroupant des plats ou des boissons selon la logique que vous souhaitez. Cette section permet de gérer les catégories dans lesquelles seront classés vos produits.',
				paragraph_2:
					'Pour créer une nouvelle catégorie, cliquez sur la croix rouge « + ».',
				paragraph_3:
					'Dans le formulaire à droite, renseignez le nom de la catégorie, sa couleur, son icône et éventuellement la ou les salles à exclure dans lesquelles cette catégorie ne sera pas proposée sur la grille de commande, puis cliquez sur « Enregistrer » en haut à droite du formulaire.',
				paragraph_4:
					"Vous pouvez réorganiser l'ordre des catégories créées. Pour cela, sélectionnez une des catégories en cliquant sur l'icône avec les 3 traits (à gauche de l'intitulé de la catégorie) et déplacez-la en la faisant glisser à l'endroit souhaité dans la liste.",
				paragraph_5:
					'Pour modifier une catégorie, cliquez sur celle que vous souhaitez modifier et changez les informations dans le formulaire à droite.',
				paragraph_6:
					"Pour supprimer une catégorie, cliquez sur l'icône « supprimer » en fin de ligne. Si des produits sont associés à la catégorie supprimée, les produits seront conservés mais n'appartiendront plus à aucune catégorie.",
				paragraph_7:
					"Si vous souhaitez qu'un produit soit disponible dans un menu mais non visible à la carte, créez une catégorie « Menu » dans laquelle vous classerez tous les produits de vos menus et excluez cette catégorie de toutes les salles. Une fois l'application installée, il vous suffira d'aller dans « Réglages / Options » et de cocher « Autoriser la sélection d'un produit non visible dans un menu ».",
			},
			supplement: {
				title: 'Supplément',
				paragraph_1:
					'Cette section regroupe tout ce qui peut être ajouté (gratuitement ou non, et obligatoirement ou non) à un produit. Ces suppléments sont regroupés par catégories de suppléments (ex : Cuisson) et des suppléments peuvent être associées à chacune de ces catégories (ex : saignant, à point...).',
				create: {
					title: 'Créer une catégorie de de supplément',
					paragraph_1:
						'Pour créer une catégorie de supplément, cliquez sur le bouton "Ajouter une catégorie de supplément".',
					list: {
						name: {
							title: 'Nom de la catégorie de supplément : ',
							content:
								'Libellé de la catégorie de supplément. Privilégiez un nom court.',
						},
						required: {
							title: 'Obligatoire : ',
							content:
								"cochez cette coche si vous souhaitez que l'option soit obligatoire lorsqu'elle sera attribuée à un produit. Si un supplément est paramétré comme étant obligatoire, le serveur ne pourra pas envoyer la commande en fabrication tant qu’il n’aura pas saisi le nombre de suppléments attendus.",
							paragraph:
								"Vous pouvez laisser cette option décochée par défaut et la cocher plus tard au cas par cas dans le formulaire des produits auxquels vous l'associerez. Ainsi, cette option pourra être obligatoire pour certains produits, et facultative pour d'autres.",
						},
						type: {
							title: 'Type : ',
							content: 'Il existe 3 types de suppléments : ',
							list: {
								unique: {
									title: 'Un seul choix ',
									content:
										'permet de sélectionner un seul supplément lors de la commande : une cuisson pour une viande par exemple.',
								},
								multiple: {
									title: 'Plusieurs choix ',
									content:
										'permet de sélectionner plusieurs suppléments mais en quantité unique : le client désire une salade et des frites en accompagnement par exemple.',
								},
								multiple_quantity: {
									title: 'Plusieurs choix en quantité ',
									content:
										'permet de sélectionner plusieurs suppléments en quantité X : le client désire 2 boules de glace vanille et 1 boule de glace chocolat, vous pourrez donc cliquer deux fois sur vanille et une fois sur chocolat.',
								},
							},
							description: {
								paragraph_1:
									'En fonction du type de supplément, le caractère obligatoire ou non se présentera différemment :',
								list: {
									unique: {
										title: '« Un seul choix » : ',
										content:
											'si l’option « Obligatoire » est activée, le serveur devra saisir un seul supplément dans la commande. Sinon, la saisie du supplément est facultative.',
									},
									multiple: {
										title:
											'« Plusieurs choix » ou « Plusieurs choix en quantité » : ',
										content:
											'pour ces deux types, il est possible de paramétrer un nombre minimum et maximum de supplément(s) à saisir. ',
									},
								},
								paragraph_2:
									'Par exemple pour une glace avec 3 boules obligatoires, il faudra indiquer min : 3 / max 3. Si le nombre est "0", la saisie minimum et/ou maximum devient facultative, le serveur pourra sélectionner autant de boules de glace qu\'il souhaite, ou bien aucune.',
								paragraph_3:
									'Vous pouvez laisser min 0 et max 0 par défaut et le préciser plus tard au cas par cas dans le formulaire des produits auxquels vous associerez ce supplément. Ainsi, ce supplément pourra avoir des min/max différents pour chaque produit.',
							},
						},
						icon_color: {
							title: 'Icône et couleur : ',
							content:
								"Choisissez l'icône et la couleur qui représentera la catégorie de supplément dans la prise de commande.",
						},
						exclusions: {
							title: 'Exclusions : ',
							content:
								'Cette fonctionnalité permet de choisir les salles dans lesquelles la catégorie de supplément n’apparaîtra pas lors de la commande. ',
						},
					},
					paragraph_2:
						'Pour modifier une catégorie de supplément créée, cliquez sur l’icône de modification (le crayon).',
				},
				reweight: {
					title: 'Ordre des suppléments',
					paragraph:
						"Vous pouvez réorganiser l'ordre des catégories de supplément créées. Pour cela, sélectionnez une des catégories en cliquant sur l'icône avec les 3 traits (à gauche de l'intitulé de la catégorie) et déplacez-la vers le haut ou le bas en la faisant glisser à l'endroit souhaité dans la liste.",
				},
				supplements: {
					title: "Définir les suppléments d'une catégorie",
					paragraph_1:
						'Vous pouvez ajouter autant de supplément que vous souhaitez à une catégorie',
					paragraph_2:
						"Pour cela, sélectionnez la catégorie de supplément (cliquez sur la ligne de l'intitulé de la catégorie) et cliquez sur « Ajouter supplément » dans la partie droite. Dans le formulaire qui apparait, indiquez le nom du supplément, sa couleur, les salles éventuelles à exclure et enregistrez.",
					paragraph_3:
						"Pour un supplément payant, saisissez le montant du supplément (montant qui s'ajoutera au montant initial du produit).",
					paragraph_4:
						"Les suppléments sont présentées en grille de façon similaire à celles que vous retrouverez sur la prise de commande de l'application. Vous pouvez déplacer chaque bloc en cliquant dessus et en le faisant glisser à l'endroit souhaité.",
					paragraph_5:
						'Pour ajouter un espace, cliquez sur le bouton « ajouter un espace ». Les "ESPACES" sont des espaces vides dans le cas ou vous voudriez faire une mise en forme spécifique.',
				},
				associate: {
					title: 'Attribuer une catégorie de supplément à un produit',
					paragraph:
						"Il existe 2 façons d'attribuer une catégorie de supplément à des produits :",
					list: {
						item_1:
							"1 / Cliquez sur l'icône « Assigner des produits » (bloc note) et cochez les produits auxquels vous souhaitez associer ce supplément.",
						item_2:
							'2 / Vous pouvez également associer le supplément directement depuis la section « Supplément » au sein du formulaire du produit souhaité (dans la rubrique « Produits »).',
					},
				},
			},
			patchMenu: {
				title: 'Patches / Déploiements',
				create: {
					title: 'Qu’est-ce qu’un patch ?',
					paragraph_1:
						"Lorsque vous accédez à une carte sur la carte web, un « patch » est automatiquement créé. Un patch est un ensemble de modifications pour lesquelles vous pouvez choisir la date à laquelle il sera déployé sur l'application.",
					paragraph_2:
						'Un patch est automatiquement créé lorsque vous accédez à une carte sur la carte Web, même si aucune modification n’est en cours sur cette carte. Par défaut, le nom du patch est intitulé « Modifications » suivi de la date à laquelle le patch a été initialisé. Vous pouvez modifier son nom en cliquant sur le picto crayon à côté du nom du patch (en haut de l’écran, lorsque vous accédez à votre carte).',
					paragraph_3:
						"Vous pouvez modifier votre carte à volonté depuis la carte web. Tant que le déploiement n'aura pas été programmé, les modifications de votre patch ne seront pas disponibles sur l'application.",
				},
				deploy: {
					title: 'Déploiements',
					paragraph_1:
						'Lorsque les modifications de votre carte sont terminées, vous devez créer un déploiement pour les rendre accessibles sur l’application. Le déploiement peut être effectué de 2 façons: ',
					paragraph_2:
						'Soit en cliquant sur la rubrique « Déploiement » de la barre de navigation à gauche, lorsque vous êtes sur une carte: ',
					paragraph_3:
						"Soit en cliquant sur l'icône de calendrier sur l'écran de gestion de cartes (accessible depuis le menu en haut à droite): ",
					paragraph_4:
						'Vous accédez alors au formulaire de déploiement avec deux informations à renseigner: ',
					paragraph_5: 'Nom du déploiement: ',
					paragraph_6: `Vous pouvez modifier son nom en cliquant sur le champ « Nom de la modification ». Le nom est obligatoire. Vous retrouverez ce nom dans l’historique des déploiements sur l’iPad, dans la rubrique « Carte ».`,
					paragraph_7: 'Date du déploiement: ',
					paragraph_8: 'Vous pouvez choisir de déployer: ',
					paragraph_9:
						'immédiatement : dans ce cas, les modifications seront disponibles sur l’iPad lors de la prochaine synchronisation (voir paragraphe suivant)',
					paragraph_10:
						"ou bien à une date ultérieure. Pour cela, cliquez sur « Déployer ultérieurement à la date », puis cliquez sur le champ « Date du déploiement » et choisissez le jour et l'heure du déploiement. Les modifications seront disponibles sur l’application seulement à partir du moment où une synchronisation sera faite à partir de cette date sera atteinte.",
					paragraph_11:
						'Cliquez ensuite sur « Valider » pour valider le déploiement.',
					paragraph_12:
						'Synchronisation des modifications de carte sur l’application',
					paragraph_13:
						'Une fois le déploiement validé sur la Carte Web, la nouvelle carte ne sera pas immédiatement activée sur l’iPad. Vous pouvez donc faire un déploiement à tout moment de la journée, sans crainte de remplacer une carte en plein service. Les modifications seront disponibles sur l’application seulement à la prochaine synchronisation de l’application.',
					paragraph_14:
						'Une synchronisation peut s’effectuer de 2 façons, si votre iPad est connecté au réseau Wifi internet: ',
					paragraph_15:
						'De manière « automatique » : Effectuez une clôture de service puis une ouverture de service',
					paragraph_16:
						'De manière « forcée » : Aller dans « Réglages / A propos » depuis l’iPad maître et cliquer sur le bouton « Synchro manuelle »',
					paragraph_17:
						'Dans ces deux cas, un message s’affichera alors vous informant que des modifications de carte ont été effectuées. Vous pouvez cliquer sur « Oui » pour les accepter, ou bien sur « Non » pour les appliquer ultérieurement (Voir section suivante pour activer une nouvelle carte).',
					paragraph_18:
						'A partir de ce moment, l’accès à la carte sur la Carte Web est momentanément bloqué. Pour y accéder de nouveau, il faut de nouveau effectuer une clôture de service ou bien une synchronisation manuelle.',
					paragraph_19: 'Pour résumer: ',
					paragraph_20:
						'Déploiement depuis la Carte web = Envoi des modifications vers l’application, mais modifications non appliquées sur iPad. Dans le cas d’un déploiement immédiat, la carte sera en statut « Non synchronisé ». Cette information est visible sur le bloc récapitulatif de votre carte, dans la rubrique “Gestion des cartes” (accessible depuis le menu en haut à droite) A ce stade vous pouvez toujours accéder à la carte et effectuer d’autres déploiements à des dates ultérieures.',
					paragraph_21:
						'Synchronisation sur l’Application depuis l’iPad maître = Réception des modifications et blocage de l’accès à la carte sur la Carte Web. A ce stade, la carte passe en statut « Synchronisé non appliqué » sur l’interface web.',
					paragraph_22:
						'Nouvelle synchronisation sur l’Application depuis l’iPad = Déblocage de l’accès à la carte web. A ce stade, la carte passe en statut « Synchronisé ».',
					paragraph_23: 'Appliquer les modifications: ',
					paragraph_24: 'Modification d’une carte existante: ',
					paragraph_25:
						'Dans le cas où des modifications sont effectuées sur la carte qui est active sur l’iPad, alors, une fois le déploiement effectué et la synchronisation faite sur iPad, un message indiquera que des modifications ont été faites sur la carte. En cliquant sur « Oui », les modifications seront immédiatement mises à jour sur la carte active.',
					paragraph_26:
						'Si vous ne souhaitez pas les appliquer tout de suite, cliquez sur « Non ». Vous pourrez les appliquer plus tard en vous rendant dans l’historique des cartes (voir ci-dessous « Consulter les différentes cartes sur iPad »).',
					paragraph_27: 'Création d’une nouvelle carte: ',
					paragraph_28:
						'Dans le cas où une toute nouvelle carte est déployée, différente de la carte qui est active sur l’iPad, alors après déploiement et synchronisation sur iPad, un message indiquera qu’une nouvelle carte est disponible. En cliquant sur le nom de la carte, celle-ci sera récupérée mais pas tout de suite activée. Vous devrez aller dans l’historique des cartes pour l’activer (voir ci-dessous « Consulter les différentes cartes sur iPad »)',
					paragraph_29:
						'Consulter les différentes cartes sur iPad et appliquer les modifications en attente',
					paragraph_30:
						'La liste de vos cartes et de leurs déploiements peut être consultée sur iPad dans la rubrique « Réglages / Carte » : un bouton en haut à droite mentionne le nom de votre carte actuellement active. Cliquez dessus pour accéder à la liste des différentes cartes que vous avez déployées: ',
					paragraph_31:
						'La coche bleue sous l’intitulé d’une carte indique la carte actuellement activée sur votre application. Pour activer une autre carte, il suffit de cliquer sur la coche d’une autre carte, puis de cliquer sur « Valider ».',
					paragraph_32:
						'Pour consulter l’historique des différents déploiements effectués sur une même carte, cliquez sur le bouton bleu « Modifié le XX/XX/XXXX (Voir) ». Vous accédez alors à l’historique des déploiements effectués sur la carte en question.',
					paragraph_33:
						'Le cercle bleu avec un numéro vous permet d’avoir des informations sur les modifications importantes effectuées.',
					paragraph_34:
						'Dans le cas de modifications de carte mises en attente, c’est-à-dire que vous n’avez pas souhaité appliquer immédiatement, ou bien d’une nouvelle carte que vous n’avez pas acceptée immédiatement, un bouton orange s’affichera alors sous la carte: ',
					paragraph_35:
						'Cliquez dessus pour appliquer les modifications. Dans le cas d’une nouvelle carte, vous devrez en plus cocher la coche bleue pour réellement activer la carte.',
					paragraph_36: 'Supprimer un déploiement programmé',
					paragraph_37:
						"Sur la Carte Web, dans la section « Gérer les déploiements », les patchs dont le déploiement est programmé à une date ultérieure sont visibles dans l'ordre chronologique. Il est possible de supprimer un patch à venir à condition qu'il soit programmé dans plus de 24 heures. Il est possible de supprimer uniquement le dernier patch à venir. S’il y en a plusieurs à venir, vous pourrez donc les supprimer un à un.",
					paragraph_38:
						'Modifier la carte sur iPad et synchroniser avec la Carte web',
					paragraph_39:
						'Si vous effectuez une modification de carte depuis l’iPad, cette modification sera prise en compte dans la Carte Web lors de la prochaine synchronisation manuelle ou clôture de service.',
					paragraph_40:
						'Il est possible de modifier la carte depuis la Carte Web et de faire des modifications en même temps sur l’iPad. Lors de la prochaine synchronisation, l’ensemble des modifications seront réunies. Cependant, la Carte Web a toujours la priorité sur les modifications par rapport aux modifications faites sur l’iPad : si vous modifiez les mêmes champs, les modifications effectuées depuis la Carte Web viendront écraser les données concernées sur l’iPad.',
					paragraph_41:
						'Par exemple : sur la Carte web, vous avez passé le prix d’un produit à 10€. En parallèle, depuis l’iPad, vous passez le prix de ce même produit à 12€, mais vous ne faites pas de synchronisation. Lors du prochain déploiement suivi d’une synchronisation, le prix que vous avez défini depuis la Carte Web (10€) sera appliqué dans l’application.',
				},
			},
			menu: {
				title: 'Menus',
				paragraph:
					"Pour ajouter un nouveau menu, cliquez sur l'icône rouge « + ». La création d'un menu s'effectue en 4 étapes. A la fin de chaque étape, cliquez sur « suivant » pour passer à l'étape suivante :",
				denomination: {
					title: '1 / Dénomination',
					list: {
						name: {
							title: 'Nom du menu : ',
							content:
								"(champ obligatoire) Libellé du menu utilisé par défaut dans l'application",
						},
						short_name: {
							title: 'Nom court :  ',
							content:
								'Libellé du menu utilisé si l’option est activée dans la grille de produits durant la prise de commande et/ou sur les impressions (en cuisine et le ticket de caisse).',
						},
						sku: {
							title: 'SKU : ',
							content:
								'Si vous avez plusieurs établissements et des menus communs mais qui n’ont pas le même nom, entrez un code commun pour ce menu.',
						},
						description: {
							title: 'Description : ',
							content:
								'Description ou commentaire du menu. Accessible lors de la prise de commande.',
						},
						active: {
							title: 'Actif : ',
							content:
								'Rendre le menu visible ou non dans la prise de commande (en cas de menu non disponible temporairement ou saisonnier)',
						},
						color: {
							title: 'Couleur du Menu : ',
							content:
								'Couleur de la touche du menu dans la grille de prise de commande.',
						},
					},
				},
				prices_exclusions: {
					title: '2 / Prix et exclusions',
					list: {
						exclusions: {
							title: 'Exclusions : ',
							content:
								'Cette fonctionnalité permet de choisir les salles dans lesquelles le produit n’apparaîtra pas sur la grille de commande.',
						},
						prices: {
							title: 'Prix :  ',
							content: 'Prix TTC du Menu',
							paragraph:
								"Si vous définissez des catégories de prix (ex : Happy hour), dans la rubrique « Catégories de prix », les catégories de prix apparaissent sous le prix TTC du menu. Vous pouvez choisir d'attribuer ou non la catégorie de prix à ce menu et de définir le prix pour le menu en question.",
						},
					},
				},
				products: {
					title: '3 / Gestion des produits',
					paragraph_1:
						'Cette étape vous permet de définir les différents niveaux du menu et les produits attribués à chaque niveau.',
					paragraph_2:
						'Par défaut, 2 niveaux sont prédéfinis : « Entrée » et « Plat ».',
					list: {
						item_1:
							"Pour ajouter un nouveau niveau, cliquez sur « Ajouter un niveau de menu ». Renseignez le nom du niveau (ex : Dessert ») et l'icône associée, et enregistrez.",
						item_2_1:
							'Pour modifier leur intitulé et leur attribuer une icône, cliquez sur le picto de modification (le crayon).',
						item_2_2:
							'Vous pouvez rendre un niveau de menu « facultatif » en cochant la coche « Facultatif ». Lors de la prise de commande, le menu pourra être envoyé sans sélectionner de produit dans ce niveau.',
						item_2_3:
							'Par exemple pour un menu Entrée/Plat et Plat/dessert au même prix, vous pouvez créer 3 niveaux de menu (Entrée/plat/dessert) et rendre les niveaux « Entrée » et « dessert » facultatifs.',
						item_3:
							"Pour supprimer un niveau, cliquez sur la croix près de l'intitulé du niveau de menu.",
						item_4:
							"Pour réorganiser les niveaux, sélectionnez un des niveaux et déplacez-le vers le haut ou vers le bas à l'endroit souhaité.",
						item_5:
							"Pour ajouter des produits à un niveau, cliquez sur le bloc gris « + produit ». Une fenêtre s'ouvre alors. Pour sélectionner le ou les produits que vous souhaitez ajouter, cochez la case à droite de la ligne de produit. Le champ « Chercher » vous permet de rechercher facilement un produit par son nom. Vous pouvez aussi chercher un produit dans une des catégories sur la gauche.",
					},
					paragraph_3:
						'Cliquez sur le bouton « Enregistrer » en haut à droite du formulaire.',
					paragraph_4:
						'Le ou les produits ajoutés apparaissent alors dans la grille du niveau de menu.',
					paragraph_5:
						"Vous pouvez réorganiser leur ordre : pour cela, cliquez sur un produit et déplacez-le à l'endroit souhaité.",
				},
				validation: {
					title: '4 / Validation',
					content:
						'Cette étape vous permet de vérifier les informations du menu avant de valider.',
				},
			},
			priceCategory: {
				title: 'Catégories de prix (Multiprix)',
				paragraph:
					'Les catégories de prix servent à attribuer plusieurs prix à un même produit afin de gérer par exemple des happy hours, des prix à emporter ou des prix terrasse. Vous pouvez en effet créer autant de prix que nécessaire !',
				create: {
					title: 'Créer une catégorie de prix',
					paragraph_1:
						'Pour créer une nouvelle catégorie, cliquez sur la croix « + » rouge pour afficher le formulaire.',
					list: {
						title: 'Remplissez les champs suivants :',
						name: {
							title: 'Nom : ',
							content:
								'Libellé de la catégorie de prix utilisé par défaut dans l’application.',
						},
						short_name: {
							title: 'Nom court : ',
							content:
								'Libellé court de la catégorie de prix qui s’affichera à côté du nom du produit / menu / supplément aux endroits listés ci-dessous si les options correspondantes sont activées :',
							list: {
								item_1:
									'Affichage du nom court en commande : le nom court de la catégorie s’affichera dans la grille de commande',
								item_2:
									'Affichage du nom court à l’encaissement : le nom court de la catégorie s’affichera dans l’écran d’encaissement et également sur les tickets de caisse',
								item_3:
									'Affichage du nom court en fabrication : le nom court de la catégorie s’affichera sur les bons de fabrication ',
							},
						},
						active: {
							title: 'Actif : ',
							content:
								'Cet interrupteur sert à activer ou désactiver la catégorie de prix, pour pouvoir la réutiliser ultérieurement sans la supprimer.',
						},
						duration: {
							title: 'Durée : ',
							content:
								' « Journée entière ou horaires » : si la catégorie de prix doit être utilisable toute la journée, laissez l’interrupteur « Journée entière » activé, sinon désactivez-le pour accéder aux horaires, et définir une heure de début ainsi qu’une heure de fin de validité de la catégorie. L’heure de fin peut très bien être définie le lendemain, par exemple de 19:00 à 02:00 le lendemain.',
						},
						days: {
							title: 'Jours : ',
							content:
								'Ce champ sert à sélectionner les jours pendant lesquels la catégorie de prix sera active. S’il n’y a pas de distinction à faire, laisser «Tous les jours».',
						},
						floors: {
							title: 'Salles : ',
							content:
								'Ce champ sert à sélectionner les salles dans lesquelles la catégorie de prix sera active. S’il n’y a pas de distinction à faire, laissez la sélection par défaut.',
						},
					},
					paragraph_2:
						'Cliquez sur « Enregistrer » une fois les paramètres définis.',
				},
				config: {
					title: 'Configurer des prix',
					paragraph_1:
						'Il existe trois façons de configurer les prix de votre catégorie de prix :',
					list: {
						item_1: 'Configuration rapide',
						item_2: 'Copie intégrale de catégorie de prix',
						item_3: 'Configuration manuelle produit par produit',
					},
					paragraph_2_1:
						'Lorsque vous avez créé votre catégorie de prix, cliquez sur la catégorie créée pour afficher de nouveau le formulaire : les boutons ',
					paragraph_2_2: "« Copier à partir d'une autre catégorie »",
					paragraph_2_3: ' et ',
					paragraph_2_4: '« Configuration rapide » ',
					paragraph_2_5: 'sont devenus actifs.',
					fast: {
						title: '1 / Configuration rapide : ',
						paragraph_1:
							'Le bouton « Configuration rapide » sert à paramétrer rapidement des prix sur les produits de votre carte (uniquement les produits, et non pas les menus ou suppléments).',
						paragraph_2:
							'Cliquer sur le bouton pour faire apparaître la fenêtre de configuration rapide.',
						paragraph_3:
							'Dans cette fenêtre sont listées toutes les catégories de produits de votre carte.',
						paragraph_4:
							'Pour chaque catégorie souhaitée, sélectionnez un des calculs disponibles pour paramétrer les prix liés à la catégorie de prix :',
						list: {
							no_changes: {
								title: 'Aucun changement : ',
								content:
									'laissez ainsi si vous ne souhaitez pas appliquer la catégorie de prix à cette catégorie de produit.',
							},
							fixed_price: {
								title: 'Prix fixe : ',
								content:
									'appliquer le même prix sur tous les produits des catégories sélectionnées.',
							},
							price_plus: {
								title: 'Montant en plus : ',
								content:
									'ajouter un montant déterminé au prix par défaut de chacun des produits des catégories sélectionnées',
							},
							price_minus: {
								title: 'Montant en moins : ',
								content:
									'enlever un montant déterminé au prix par défaut de chacun des produits des catégories sélectionnées',
							},
							percent_plus: {
								title: '% en plus : ',
								content:
									'ajouter un pourcentage au prix par défaut de chacun des produits des catégories sélectionnées (par exemple 50%) ',
							},
							percent_minus: {
								title: '% en moins : ',
								content:
									'enlever un pourcentage au prix par défaut de chacun des produits des catégories sélectionnées',
							},
							reinit: {
								title: 'Réinitialiser : ',
								content:
									'cette fonctionnalité permet de supprimer les paramètres de catégorie de prix  sur tous les produits des catégories sélectionnées.',
							},
						},
						paragraph_5:
							'Sélectionnez un des choix, renseignez un montant ou un pourcentage si besoin, puis validez. Vous pouvez définir des règles différentes selon vos catégories de produits.',
						paragraph_6:
							'Après avoir cliqué sur « Valider », une fenêtre s’affiche pour confirmer le paramétrage des prix. Cliquez sur « Oui » pour confirmer. ',
						paragraph_7:
							'Pour vérifier que les prix de la catégorie de prix ont bien été sauvegardés,  cliquez sur le picto gris « i » sur la ligne de la catégorie de prix. Vous verrez apparaître à côté de chaque produit deux prix : le prix par défaut, ainsi que le prix de la catégorie prix qui vient d’être paramétrée.',
					},
					integral: {
						title: '2 / Copie intégrale :',
						paragraph_1_1: 'Le bouton ',
						paragraph_1_2: "« Copier à partir d'une autre catégorie » ",
						paragraph_1_3:
							"sert à copier l'intégralité des prix déjà paramétrés d'une catégorie de prix (produits, menus, suppléments) vers une autre.",
						paragraph_2:
							"Pour se faire, après avoir créé une catégorie de prix, affichez de nouveau le formulaire de la catégorie de prix et cliquez sur le bouton « Copier à partir d'une autre catégorie ». Une fenêtre s’affiche avec la liste des catégories de prix déjà existantes. Cliquez sur la catégorie dont vous voulez copier l’intégralité des prix, puis confirmez la copie.",
						paragraph_3:
							'Pour vérifier que la copie des prix de la catégorie de prix a bien eu lieu, même principe que pour la configuration rapide : cliquez sur le picto gris « i » sur la ligne de la catégorie de prix. Vous verrez apparaître à côté de chaque produit deux prix : le prix par défaut, ainsi que le prix de la catégorie prix qui vient d’être paramétré.',
					},
					by_product: {
						title:
							'3 / Configuration manuelle par produit / menu / supplément :',
						paragraph_1:
							'Pour configurer les prix d’une catégorie de prix sur chacun des produits ou menus, rendez-vous dans la rubrique « Produits » ou « Menus ».',
						paragraph_2:
							"La ou les catégories de prix que vous avez créées apparaissent dans la section « Prix » dans le formulaire d'un produit ou d'un menu.",
						paragraph_3:
							'Cochez la catégorie de prix que vous souhaitez activer et indiquez son prix.',
						paragraph_4:
							'Pour les suppléments,  rendez-vous dans la rubrique « Supplément ». Sélectionnez la catégorie de supplémentde votre choix et cliquez sur une des suppléments associées. La ou les catégories de prix que vous avez créées apparaissent dans la section « Prix ».',
					},
				},
			},
			tax: {
				title: 'TVA',
				paragraph_1_1: 'Trois TVAs sont pré-configurées : ',
				paragraph_1_2: '10% ',
				paragraph_1_3: 'pour la nourriture et les boissons non alcoolisées, ',
				paragraph_1_4: ' 10% / 5,5% ',
				paragraph_1_5:
					'pour gérer les produits vendus « sur place » et « à emporter » et qui sont assujettis à la taxe 5,5%, et enfin ',
				paragraph_1_6: '20% ',
				paragraph_1_7: 'pour les produits alcoolisés.',
				paragraph_2:
					"Il est possible de modifier ces TVA en cliquant dessus et en modifiant le formulaire qui s'affiche sur le côté droit.",
				note: {
					title: 'A noter : ',
					content_1:
						'si on change un taux, tous les produits qui sont liés à ce taux seront ',
					content_2: 'impactés.',
				},
				paragraph_3:
					"La TVA à 10% est attribuée par défaut lors de la création d'un nouveau produit. Pour définir une autre TVA par défaut, cliquez sur une des TVA, cochez « par défaut » dans la partie de droite et enregistrez.",
				create: {
					title: 'Créer une nouvelle TVA :',
					paragraph_1:
						'Pour ajouter une nouvelle TVA, cliquez sur le picto rouge « + » et remplissez les champs présents sur le côté droit.',
					paragraph_2:
						'Pour une même TVA il est possible de configurer des taux différents pour le service sur place et à emporter.',
				},
				delete: {
					title: 'Supprimer une TVA :',
					paragraph_1: 'Seules les TVA non actives peuvent être supprimées',
					paragraph_2:
						"Pour supprimer une TVA, il faut donc au préalable réaffecter l’ensemble des produits attribués à cette TVA, puis la rendre inactive (pour cela cliquez sur l'intitulé de la TVA et décochez « actif »).",
					paragraph_3:
						'Une fois définie comme inactive, un picto de suppression apparaît sur la ligne de la TVA. Cliquez dessus pour la supprimer.',
					note: {
						title: 'A noter : ',
						content:
							'une TVA définie « par défaut » ne peut pas être supprimée.',
					},
					paragraph_4:
						'Si un doute subsiste sur le taux de TVA à appliquer sur certains produits, il est recommandé de vous rapprocher de votre comptable qui pourra indiquer le bon taux à appliquer. ',
				},
			},
			master: {
				title: 'Master Multi-établissements',
				presentation: {
					title: 'Présentation des fonctionnalités',
					p1_1:
						'Gestion Multi-Établissements à distance - Interface web / Connexion',
					p1_2:
						'Depuis un navigateur web, il est nécessaire de se connecter à l’adresse menu.laddition.com et d’utiliser les identifiants correspondants au compte « Master Carte» de vos établissements.',
					p2_1: 'Modifications centralisées, par zone ou par établissement',
					p2_2:
						'Selon les règles définies (voir après) il est possible de gérer les modifications de carte en centralisé (mode « siège») par zone ou par site',
					p3_1: 'Programmation en avance des modifications de carte',
					p3_2:
						'Il est possible d’utiliser le calendrier disponible afin de programmer des modifications de carte. Il est possible de créer ou retirer des produit en avance, de changer les prix',
					p3_3: 'ATTENTION',
					p3_4:
						'Si vous changez l’un de ces champs à une date ultérieure, le changement s’appliquera le jour où vous l’avez changé et déployé. En effet, il est recommandé de créer un nouveau produit plutôt que changer son nom. D’un point de vu statistiques des ventes, cela sera bénéfique car le produit en question pourra être sélectionné (dans les produits invisibles ou supprimés).',
					p3_5: 'Nom du produit + Nom court',
					p3_6: 'SKU',
					p3_7: 'Couleur',
					p3_8: "Lieu d'envoi",
					p4_1: 'Reporting optimisé pour l’analyse des ventes',
					p4_2:
						'En plus des rapports existants par établissement, certains rapports d’analyse sont disponibles au niveau « multi-établissements » comme par exemple : ',
					p4_3: 'Comparaison d’établissements',
					p4_5: 'Analyse produits groupée (Mise en place de SKU obligatoire)',
					p4_6: 'Synthèse des paiements ',
					p4_7: 'Etc...',
					p4_8:
						'Pour plus d’informations sur le reporting, voir le manuel utilisateur du reporting.',
				},
				synchro: {
					title: 'LES REGLES DE SYNCHRONISATION',
					p1:
						'Toutes les caractéristiques marquées d’une coche sur les captures ci-après peuvent être centralisées ou personnalisables par site. Lorsqu’il y a une flèche à double sens, cela signifie que l’ordre des éléments peut également  être centralisé ou personnalisable par site.',
					p2: 'LES PRODUITS',
					p3: 'LES CATEGORIES DE PRODUITS',
					p4: 'LES CATÉGORIES DE SUPPLÉMENTS ET SUPPLEMENTS',
					p5: 'LES SUPPLEMENTS ASSIGNES AUX PRODUITS',
				},
				deploy: {
					title: 'LES DEPLOIEMENTS',
					p1_1: 'CHOIX DU SITE OU DE LA ZONE CONCERNES',
					p1_2:
						'Le déploiement peut se faire par zone ou par site. Il faut s’assurer du site ou de la zone choisie avant de déployer en cliquant sur l’icône « bonhomme » sur le menu à gauche de l’écran et choisissez la zone ou le site sur lequel vous souhaitez déployer. S’affiche alors une nouvelle icône dont la siginification est détaillée ci-après.',
					p1_3:
						'Une fois les modifications effectuées, il faut aller dans l’onglet « déploiement » en bas du menu sur la gauche de l’écran. Les changements sont conservés automatiquement même sans déployer si vous décidez de ne pas envoyer les modifications sur les caisses immédiatement',
					p1_3_1: '-> Mode siège (pas de déploiement possible)',
					p1_3_2: '-> Zone',
					p1_3_3: '-> Site',
					p1_4:
						'Une fois sur la page de déploiement, vous trouverez les informations suivantes : ',
					p1_4_1: '1 - La date concernée pour les changements',
					p1_4_2: '2 - Le site ou la zone concernés ',
					p1_4_3:
						'3 - Le nom personnalisable des modifications (initialement la date du jour pré-remplie)',
					p1_4_4: '4 - Le bouton de déploiement',
					p1_5: 'ATTENTION :',
					p1_5_1:
						'Bouton bleu = Des modifications n’ont pas encore été déployées',
					p1_5_2: 'Bouton gris = Pas de modification à déployer',
					p1_6:
						'Après avoir cliqué sur le bouton « déployer», vous vous retrouverez sur la page récapitulative du déploiement effectué. Vous pouvez sélectionner un nouveau site ou une nouvelle zone pour poursuivre les déploiements si nécessaire.',
					p2:
						'COMMENT LES ETABLISSEMENTS PEUVENT RECUPERER LES MODIFICATIONS DE CARTE APRES DEPLOIEMENT',
					p2_1: 'RECUPERATION « MANUELLE» DES MODIFICATIONS',
					p2_2:
						"Après le déploiement de la carte, chaque établissement doit synchroniser l'iPad principal (soit de manière automatique lors de l'ouverture/clôture d'un service, soit de manière « forcée » : aller dans « Réglages / A propos » depuis l’iPad et cliquer sur le bouton « Synchro manuelle »)",
					p2_3:
						"L'application alerte que des modifications peuvent être apportées sur la carte, dans la fenêtre il faut cliquer sur le bouton « Oui» afin de récupérer la carte master qui sera commune à tous les établissements.",
					p2_4:
						'Lorsque les modifications sont récupérées, l’application indique que « Les modifications ont été effectuées avec succès».',
					p3: 'RECUPERATION « AUTOMATIQUE» DES MODIFICATIONS',
					p3_1:
						'Avec un paramétrage spécifique, il est possible de mettre en place la récupération automatique des modifications de carte à partir du moment que la tablette maître est connectée à Internet, que le service est ouvert et que l’application est en premier plan.',
					p3_2:
						'Pour cela il est nécessaire de faire parvenir un mail à integration@laddition.com',
					p3_3:
						'A ce moment, lorsque le déploiement est effectué, une notification s’affiche sur l’iPad Maître pour indiquer que « des modifications de carte ont été appliquées » (il suffit de faire glisser la notification vers la gauche pour la supprimer).',
				},
			},
		},
	},
	save: 'Enregistrer',
	confirmation: 'Confirmation',
	validate: 'Valider',
	cancel: 'Annuler',
	remove: 'Supprimer',
	edit: 'Modifier',
	close: 'Fermer',
	warning: 'Attention',
	notification: {
		title: 'Notifications',
		show: 'Voir',
		empty: 'Aucune notification',
		error: {
			product:
				'Une erreur est survenue lors de la %{method} du produit: %{name}',
			category:
				'Une erreur est survenue lors de la %{method} de la catégorie: %{name}',
			priceCategory:
				'Une erreur est survenue lors de la %{method} de la catégorie de prix: %{name}',
		},
		toast: {
			warning: 'Une situation mérite votre attention',
			error: 'Il y a eu des erreurs',
			forbidden: 'Accès refusé',
			success: 'Action effectuée avec succès',
			unauthorized:
				"Vous n'avez pas les droits suffisants pour effectuer cette action",
		},
		type: {
			WARNING: 'Attention',
			ERROR: 'Erreur',
			INFO: 'Info',
			SUCCESS: 'Succès',
		},
		no_detail: 'Pas de détail supplémentaire',
	},
	method: {
		PATCH: 'mise à jour',
		PUT: 'mise à jour',
		POST: 'création',
		DELETE: 'suppression',
	},
	no_access: "Vous n'avez pas accès à l'outil de gestion de la carte en ligne",
	no_access_reject:
		"Vous n'avez pas accès à l'outil de gestion de la carte en ligne, un patch va être prochainement déployé. Veuillez synchroniser vos iPads. Vous aurez de nouveau accès à cet outil après la prochaine clôture de service. ",
	no_access_explained:
		"Vous n'avez pas accès à l'outil de gestion de la carte en ligne. Vous avez surement déjà configuré votre Carte, pour plus de renseignement veuillez contacter le support.",
	no_access_tax_rate_empty:
		"Vous n'avez pas accès à l'outil de gestion de la carte en ligne. Veuillez contacter le support en indiquant le code d'erreur \"601\"",
	no_access_version:
		"Vous n'avez pas accès à l'outil de gestion de la carte en ligne. Veuillez mettre à jours tous vos appareils",
	no_access_need_activation:
		"Vous n'avez pas accès à l'outil de gestion de la carte en ligne. Veuillez activer votre application sur un iPad",
	no_access_in_synchro:
		'Une synchronisation est en cours sur la carte en ligne. Veuillez réessayer dans quelques instants',
	no_access_maintenance:
		'L’application est en maintenance... veuillez nous excuser pour la gêne occasionnée.',
	no_access_multi_window:
		'Une session a été ouverte depuis un autre navigateur,',
	no_access_restriction: "Vous n'avez pas access à cette section",
	no_access_multi_window_2:
		'Cliquez sur le bouton ci-dessous pour générer une nouvelle session.',
	no_access_multi_window_button: 'Activer ma session',
	multi_session_master_mono_use:
		"La session a été ouverte par l'établissement gérant. Pour accéder à votre carte veuillez le contacter.",
	wrong_url: 'Page non trouvée',
	browser_compatibility: {
		version_title: 'Navigateur obsolète',
		version_label:
			'Afin de vous offrir une experience optimale, veuillez passer sur la dernière version de Google Chrome ou Firefox',
		chrome: 'Google Chrome',
		firefox: 'Mozilla Firefox',
		screen_size_title: 'Votre écran est trop petit',
		screen_size_label:
			'Afin de vous offrir une experience optimale, veuillez passer sur un ordinateur avec un ecran plus grand.',
	},
	icon: {
		search: 'Chercher une icône',
	},
	title: {
		denomination: 'Dénomination',
		informations: 'Informations',
		price: 'Prix',
		supplements: 'Suppléments',
		exclusions: 'Exclusions',
		color: 'Couleur',
		icon: 'Icône',
		floors: 'Salles',
		service: 'Carte',
	},
	offline: 'Attention vous êtes hors ligne',
	tutorial: {
		interactTuto: 'Tutoriel intéractif',
		noDemo: 'Ne pas suivre la démo',
		yesDemo: 'Suivre la démo',
		nextStep: 'Etape suivante',
		leftDemo: 'Quitter la démo',
		back: 'Retour',
		initial_popup: {
			title: 'Vous souhaitez découvrir notre outil de carte web ?',
			text: `Vous ne savez pas par ou commencer ? vous pouvez lire l'aide écrite. Vous pourrez également y revenir plus tard via le bouton "Besoin d'aide ?".`,
			tutorial: 'Tutoriel',
			help: 'Aide',
			no_thank: 'Non merci',
		},
		topics: {
			endDemoTopics: 'Fin de la démo Rubriques',
			step1: {
				content:
					'Cette partie de l’interface vous permet de naviguer entre les différentes rubriques de création de votre carte.',
				title: 'Rubriques',
			},
			step2: {
				content:
					'La section Catégories vous permet de gérer les catégories dans lesquelles seront classés vos produits.',
				title: 'Catégories',
			},
			step3: {
				content: `Consultez la rubrique "Besoin d'aide" pour revoir le tutoriel intéractif ou lire l'aide écrite.`,
				title: "Besoin d'aide",
			},
		},
		category: {
			endDemoCategory: 'Fin de la démo Catégorie',
			step1: {
				content:
					"Cette rubrique permet de créer des catégories dans lesquelles vous pourrez regrouper vos produits afin d'organiser votre carte.",
				title: 'Catégories',
			},
			step2: {
				content: 'Spécifiez le nom de votre nouvelle catégorie',
				title: 'Définir un nom',
			},
			step3: {
				content:
					'Spécifiez la couleur de votre nouvelle catégorie, afin de créer un repère visuel',
				title: 'Définir une couleur',
			},
			step4: {
				content:
					"Spécifiez l'icône de votre nouvelle catégorie, afin de créer un repère visuel",
				title: 'Définir une icône',
			},
			step5: {
				content:
					'Spécifiez la ou les salles où votre catégorie ne sera pas disponible',
				title: 'Exclusion de salle(s)',
			},
			step6: {
				content: `Si ce n'est pas déjà fait, donnez un nom à votre catégorie en cliquant sur "Retour" puis cliquer sur "Enregistrer" afin de poursuivre le tutoriel.`,
				title: 'Enregistrer',
			},
			step7: {
				content:
					"Vous pouvez changer l'ordre de vos catégories grâce au glisser/déposer",
				title: "Modifier l'ordre",
			},
			step8: {
				content:
					"Vous pouvez éditer votre catégorie en cliquant dessus, et la supprimer en cliquant sur l'icône poubelle",
				title: 'Editer / Supprimer',
			},
		},
		product: {
			endDemoProduct: 'Fin du tutoriel Produits',
			step1: {
				content:
					"Cette rubrique permet de créer des produits de trois manières différentes : rapide, détaillée, ou à partir d'une carte existante",
				title: 'Produits',
			},
			step2: {
				content:
					"Cliquez sur le bouton + pour ajouter un produit de façon rapide, ou détaillée, ou copier un produit d'une carte existante",
				title: 'Ajouter un produit',
			},
			step3: {
				content: 'Cliquez sur ce bouton pour ajouter un produit rapidement',
				title: 'Ajout rapide',
			},
			step4: {
				content:
					'Une nouvelle ligne vide se crée, renseignez toutes les informations sur votre produit (nom, prix, catégorie, etc.) en sélectionnant directement une case. Veuillez commencer par compléter le champ "nom" afin d\'initialiser les autres cellules.',
				title: 'Ajout rapide',
			},
			step5: {
				content:
					"A droite de la ligne, le premier bouton permet d'enregistrer votre produit (la case 'nom' doit être remplie pour enregistrer un produit), le second permet la suppression de votre produit",
				title: 'Enregistrer / Supprimer',
			},
			step6: {
				content:
					'Un champ de recherche ainsi que des filtres peuvent être appliqués si vous souhaitez rechercher un ou plusieurs produits en particulier',
				title: 'Recherche / Filtres',
			},
			step7: {
				content:
					"Une fonction Annuler / Rétablir est disponible, en cas d'erreur de saisie",
				title: 'Annuler / Rétablir',
			},
			step8: {
				content:
					'Cliquez sur le bouton ⊕, pour ajouter un produit de façon détaillée',
				title: 'Ajouter un produit',
			},
			step9: {
				content: 'Cliquez sur ce bouton pour ajouter un produit détaillé',
				title: 'Ajout détaillé',
			},
			step10: {
				content: 'Spécifiez le nom de votre produit',
				title: 'Nom du produit',
			},
			step11: {
				content:
					'Spécifiez le nom court, le SKU et la description de votre produit',
				title: 'Informations supplémentaires (facultatif)',
			},
			step12: {
				content:
					'Les informations relatives au prix de votre produit (prix au kilo, prix en happy hour, etc...)',
				title: 'Prix',
			},
			step13: {
				content:
					'Les informations générales de votre produit (TVA, Type, Catégorie, etc.). TVA et Type sont obligatoires pour enregistrer votre produit',
				title: 'Informations générales',
			},
			step14: {
				content:
					'Spécifiez une option pour la cuisine, au préalable configurée dans la rubrique "Options cuisine"',
				title: 'Option cuisine',
			},
			step15: {
				content:
					'Spécifiez une pastille de couleur pour le différencier des autres produits ou le catégoriser',
				title: 'Définir une couleur',
			},
			step16: {
				content:
					'Spécifiez la ou les salles où votre produit ne sera pas disponible',
				title: 'Exclusion de salle(s)',
			},
			step17: {
				content:
					'Enregistrez votre produit. Vous devez configurer, au minimum, un nom, un prix, une TVA et un type afin de pouvoir sauvegarder votre produit',
				title: 'Enregistrer',
			},
			step18: {
				content:
					'Votre produit a bien été enregistré. Vous pouvez créer une nouvelle entrée de produit rapidement en effectuant la commande Shift + Entrer',
				title: 'Récapitulatif',
			},
			step19: {
				content:
					'Une pastille située en bas en droite de la cellule vous permet de dupliquer la valeur sur plusieurs éléments',
				title: "Duplication d'informations",
			},
			step20: {
				content:
					'Vous retrouvez toutes vos catégories de produit sur le panel de gauche, sélectionnez en une',
				title: 'Catégories de produit',
			},
			step21: {
				content:
					"Vous pouvez assigner un produit à une catégorie de produit directement depuis cette page, faites un glisser/déposer d'un produit ou d'un espace",
				title: 'Assigner un produit à une catégorie',
			},
		},
		productMenu: {
			endDemoProductMenu: 'Fin de la démo Menu',
			step1: {
				content:
					'Cette rubrique permet la gestion des menus (création, édition, suppression)',
				title: 'Menu',
			},
			step2: {
				content: 'Cliquez sur le bouton ⊕ pour créer votre menu',
				title: 'Création du menu',
			},
			step3: {
				content: "La création de menu s'effectue en 4 étapes",
				title: 'Menu par étapes',
			},
			step4: {
				content:
					'Spécifiez le nom de votre menu et renseignez, si besoin, les informations facultatives. Vous pouvez également rendre inactif votre menu.',
				title: 'Nom du menu',
			},
			step5: {
				content: 'Spécifiez la couleur de votre menu',
				title: 'Couleur du menu',
			},
			step6: {
				content: `Si ce n'est pas déjà fait, donnez un nom à votre menu en cliquant sur "Retour" puis cliquer sur "Suivant" afin de poursuivre le tutoriel.`,
				title: 'Fin de la première partie',
			},
			step7: {
				content:
					'Spécifiez la ou les salles où votre menu ne sera pas disponible',
				title: 'Exclusion de salle(s)',
			},
			step8: {
				content: 'Spécifiez le prix de votre menu',
				title: 'Prix du menu',
			},
			step9: {
				content: `Si ce n'est pas déjà fait, renseignez un prix en cliquant sur "Retour" puis cliquer sur "Suivant" afin de poursuivre le tutoriel.`,
				title: 'Fin de la deuxième partie',
			},
			step10: {
				content:
					'Cette partie vous permet de créer les niveaux de votre menu (défaut: Entrée / Plat). Vous pourrez définir les plats en fonction des différents niveaux de menu',
				title: 'Gestion des niveaux de menu',
			},
			step11: {
				content: 'Cliquez ici pour ajouter un niveau de menu (ex: Dessert)',
				title: 'Nouveau niveau',
			},
			step12: {
				content:
					'Un niveau de menu peut contenir un nom, une icône et quelques options',
				title: "Description d'un niveau de menu",
			},
			step13: {
				content: 'Spécifiez le nom de votre niveau de menu',
				title: 'Nom du niveau',
			},
			step14: {
				content: 'Spécifiez les options de votre niveau',
				title: 'Options',
			},
			step15: {
				content: 'Spécifiez une icône',
				title: 'Icône',
			},
			step16: {
				content:
					'Enregistrez votre niveau de menu afin de définir les différents plats qui le constitueront',
				title: 'Sauvegarder le niveau de menu',
			},
			step17: {
				content:
					'Spécifiez le ou les plat(s) que vous souhaitez dans votre niveau de menu',
				title: 'Définir les produits',
			},
			step18: {
				content:
					'Cliquez sur + Produit pour ajouter un produit dans le niveau de menu "Entrée"',
				title: 'Ajouter un produit',
			},
			step19: {
				content:
					'Le panel de gauche recense les catégories de plat que vous avez définies préalablement',
				title: 'Catégories de plat',
			},
			step20: {
				content:
					'Le panel de droite recense tous les produits de la catégorie sélectionnée. Pour ajouter un produit, cochez la case',
				title: 'Choix du produit',
			},
			step21: {
				content: 'Sauvegardez vos modifications',
				title: 'Sauvegarder',
			},
			step22: {
				content:
					"Récapitulatif de vos niveaux de menu et des produits qu'ils contiennent. Vous pouvez les réorganiser au sein d'un niveau ou les déplacer d'un niveau à l'autre grâce au glisser/déposer",
				title: 'Récapitulatif',
			},
			step23: {
				content:
					'En cliquant sur votre produit, vous pouvez le modifier rapidement. Définir une couleur, interdire une salle ou définir un prix en particulier',
				title: "Edition rapide d'un produit",
			},
			step24: {
				content:
					"Fin de la troisième étape, si vous avez terminé de compléter tous vos niveaux de menu, l'étape suivante est un récapitulatif de votre menu",
				title: 'Fin de la troisième étape',
			},
			step25: {
				content: 'Récapitulatif des différentes informations de votre menu',
				title: 'Récapitulatif de votre menu',
			},
			step26: {
				content: "Valider votre menu pour pouvoir l'utiliser",
				title: 'Validation',
			},
			step27: {
				content: 'Vous revenez sur la liste de vos menus existants.',
				title: 'Récapitulatif des menus',
			},
		},
		optionsKitchen: {
			endDemoOptionKitchen: 'Fin de la démo Options cuisine',
			step1: {
				content: `Cette rubrique permet de créer des options de cuisine regroupées par catégorie (ex : dans la catégorie d'option "Cuisson", vous pourrez proposer les options "Saignant", "A point", etc.)`,
				title: 'Options Cuisine',
			},
			step2: {
				content:
					"Cliquez ici pour ajouter une catégorie d'options cuisine et poursuivre le tutoriel",
				title: 'Ajouter une catégorie',
			},
			step3: {
				content:
					"Définissez les caractéristiques de votre catégorie d'option cuisine",
				title: 'Description',
			},
			step4: {
				content: 'Spécifiez le titre de votre catégorie (Ex: Cuisson)',
				title: 'Nom de la catégorie',
			},
			step5: {
				content:
					"Définissez si la catégorie d'options sera systématiquement obligatoire pour chaque produit qui y sera associé et le type de choix possibles.",
				title: 'Informations de la catégorie',
			},
			step6: {
				content: "Spécifiez l'icône de votre catégorie",
				title: 'Icône',
			},
			step7: {
				content: 'Spécifiez la couleur de votre catégorie',
				title: 'Couleur',
			},
			step8: {
				content:
					'Spécifiez la ou les salles où votre catégorie ne sera pas disponible',
				title: 'Exclusion de salle(s)',
			},
			step9: {
				content: `Si ce n'est pas déjà fait, donnez un nom à votre option cuisine en cliquant sur "Retour" puis cliquer sur "Enregistrer" afin de poursuivre le tutoriel.`,
				title: 'Enregistrer',
			},
			step10: {
				content:
					"Vous pouvez réorganiser vos catégories d'options cuisine grâce au glisser/déposer, supprimer une catégorie en cliquant sur l'icône poubelle, éditer une catégorie d'option en cliquant sur le crayon. Cliquez sur la ligne d'une catégorie pour ajouter les options qui lui seront associées.",
				title: 'Récapitulatif',
			},
			step11: {
				content:
					"Ajoutez une option ou un espace (pour différencier les différentes options) à votre catégorie d'options cuisine",
				title: 'Ajouter une option',
			},
			step12: {
				content: 'Spécifiez le nom de votre option (ex: Saignant)',
				title: "Nom de l'option",
			},
			step13: {
				content:
					'Spécifiez, si besoin, le prix de votre option (Ex: Saignant +2.00€)',
				title: 'Prix',
			},
			step14: {
				content: 'Spécifiez la couleur pour votre option',
				title: 'Couleur',
			},
			step15: {
				content:
					'Spécifiez la ou les salles où votre option ne sera pas disponible',
				title: 'Exclusion de salle(s)',
			},
			step16: {
				content: 'Enregistrez votre option',
				title: 'Enregistrer',
			},
			step17: {
				content:
					"Spécifiez les produits auxquels vous souhaitez associer votre catégorie d'options, en cliquant sur cette icône",
				title: 'Assignation de produit',
			},
			step18: {
				content:
					'Sélectionnez les produits qui seront impactés par votre option de cuisine en cliquant sur le produit ou la checkbox',
				title: 'Assignation de produit',
			},
			step19: {
				content: 'Enregistrez vos produits impactés par cette option',
				title: 'Enregistrer',
			},
			step20: {
				content:
					"Vous revenez sur la liste de vos catégories d'options cuisine existantes.",
				title: 'Récapitulatif',
			},
		},
		tax: {
			endDemoTax: 'Fin de la démo Taxes',
			step1: {
				content:
					'Cette rubrique permet de créer des TVA supplémentaires. Par défaut, 4 TVA sont prédéfinies et sont de rigueur en France. Vous pouvez en ajouter ou modifier celles existantes',
				title: 'T.V.A',
			},
			step2: {
				content:
					"La partie de gauche récapitule l'ensemble des TVA existantes (prédéfinies + créées)",
				title: 'Récapitulatif',
			},
			step3: {
				content: 'Spécifiez le nom de la taxe',
				title: 'Nom',
			},
			step4: {
				content:
					'Vous pouvez spécifier un taux différent si le produit est consommé sur place ou à emporter',
				title: 'Taux différenciel',
			},
			step5: {
				content:
					"En spécifiant une taxe par défaut, celle-ci s'appliquera automatiquement à chaque nouveau produit",
				title: 'Taux par défaut',
			},
			step6: {
				content: 'Enregistrez votre taxe',
				title: 'Enregistrer',
			},
			step7: {
				content: 'Vous pouvez ajouter une nouvelle taxe en cliquant ici',
				title: 'Nouvelle taxe',
			},
		},
		priceCategory: {
			endDemoPriceCategory: 'Fin de la démo catégorie de prix',
			step1: {
				content:
					'Cette rubrique permet de créer des catégories de prix (Ex: prix Happy Hour, à emporter, en terrasse...)',
				title: 'Catégorie de Prix',
			},
			step2: {
				content:
					'Cliquez ici pour créer une nouvelle catégorie et poursuivre le tutoriel',
				title: 'Nouvelle catégorie',
			},
			step3: {
				content:
					'Ce formulaire permet de paramétrer les caractéristiques de la catégorie de prix',
				title: 'Catégorie de Prix',
			},
			step4: {
				content: 'Spécifiez le nom de votre nouvelle catégorie',
				title: 'Nom',
			},
			step5: {
				content:
					"Spécifiez un nom court (facultatif) et choisissez d'activer ou désactiver la catégorie de prix",
				title: 'Nom court',
			},
			step6: {
				content:
					"Spécifiez le ou les moment(s) où le nom court de votre catégorie s'affichera (si vous avez renseigné un nom court)",
				title: 'Utilisation du nom court',
			},
			step7: {
				content: `Par défaut, votre catégorie est disponible toute la journée. Vous pouvez décocher "Toute la journée" et définir un créneau horaire pendant lequel votre catégorie de prix sera active (Ex: Happy Hour de 17h à 19h)`,
				title: 'Disponibilité heure',
			},
			step8: {
				content: `Par défaut, votre catégorie est disponible toute la semaine. Vous pouvez décocher "Tous les jours" et définir les jours de disponibilité de votre catégorie.`,
				title: 'Disponibilité jour',
			},
			step9: {
				content: `Spécifiez la ou les salles où votre catégorie de prix sera active`,
				title: 'Salle(s) concernée(s)',
			},
			step10: {
				content: `Si ce n'est pas déjà fait, donnez un nom à votre catégorie de prix en cliquant sur "Retour" puis cliquer sur "Enregistrer" afin de poursuivre le tutoriel.`,
				title: 'Enregistrer',
			},
			step11: {
				content: `Votre catégorie de prix a bien été enregistrée. Editez une catégorie en cliquant dessus, ou modifier leur ordre grâce au glisser-déposer`,
				title: 'Récapitulatif des catégories de prix',
			},
			step12: {
				content: `Les associations permettent de voir quel produit / menu / option cuisine est associé à cette catégorie de prix`,
				title: 'Associations',
			},
			step13: {
				content: `En cliquant sur une catégorie de prix, vous pourrez éditer les informations et l'associer à une catégorie de produit`,
				title: 'Association avec une catégorie de produit',
			},
			step14: {
				content: `La configuration rapide vous permet de spécifier la ou les catégories de produits qui seront automatiquement impactées et la façon dont elles seront impactées. Vous pouvez également associer la catégorie de prix à des produits au cas par cas, dans les fiches produit`,
				title: 'Configurer les catégories de produits associées',
			},
		},
	},
	dateZonePicker: {
		title_master: "Gérer la date et le mode d'édition",
		title_mono: "Gérer la date d'édition",
		activated: 'Activé',
		select: 'Sélectionner',
		date: {
			title: 'Calendrier',
			description: 'Appliquer les modifications sur la carte du',
		},
		nbCompanies: '%{nb} établissement(s)',
		management: {
			title: 'Visualisation de la carte',
			master: 'Siège',
			master_description: 'Modifications centralisées',
			zone: 'Zones',
			zone_description: 'Modifications par zone',
			company: 'Sites',
			company_description: 'Modifications par site',
		},
		prodiverChanges: {
			title: 'Changements exterieurs',
			text: 'Il y a %{n} patchs effectués depuis une application tierce.',
			button: 'Appliquer les patchs',
		},
	},
};
