import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const PaperClasses = {
	root: { borderRadius: '20px' },
};

class FormPaper extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const { title, hidden, action, className, visible } = this.props;

		return (
			<div>
				<div className={'FormPaper-header'}>
					<span className={'FormPaper-title'}> {title} </span>
					<span className={'FormPaper-action'}> {action} </span>
				</div>
				<Paper
					classes={this.props.classes}
					className={'FormPaper ' + className}
					style={{ display: visible ? 'block' : 'none' }}>
					<Typography
						component="div"
						className={'FormPaper-content'}
						style={{ display: hidden ? 'none' : 'inherit' }}>
						{this.props.children}
					</Typography>
				</Paper>
			</div>
		);
	}
}

FormPaper.propTypes = {
	title: PropTypes.string,
	action: PropTypes.element,
	hidden: PropTypes.bool,
	visible: PropTypes.bool,
	className: PropTypes.string,
};

FormPaper.defaultProps = {
	hidden: false,
	action: <span />,
	visible: true,
};

export default withStyles(PaperClasses)(FormPaper);
