import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogContentText, Input } from '@material-ui/core';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';

class PopupRestoreBin extends React.Component {
	constructor() {
		super();
		this.state = {
			searchInput: '',
		};
	}

	render() {
		const label =
			this.props.type == 302
				? I18n.t('product.restore.title_restore_supp')
				: this.props.type == 201
				? I18n.t('product.restore.title_restore_menu')
				: I18n.t('product.restore.title_restore_product');
		return (
			<Dialog
				aria-labelledby="simple-dialog-title"
				open={this.props.open}
				onClose={() => this.props.onSelect()}>
				<DialogTitle id="simple-dialog-title">{label}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{I18n.t('product.restore.description_restore')}
					</DialogContentText>

					<Input
						placeholder={I18n.t('product.filter.search')}
						type="text"
						value={this.state.searchInput}
						onChange={e => this.setState({ searchInput: e.target.value })}
					/>

					<List>
						{this.props.products
							.filter(p =>
								p.name
									.toLowerCase()
									.includes(this.state.searchInput.trim().toLocaleLowerCase())
							)
							.map(p => {
								let secondary = '';
								if (p.updated_at && p.updated_at) {
									secondary = I18n.t('product.restore.secondary', {
										created: moment(p.created_at * 1000).format('DD-MM-YYYY'),
										updated: moment(p.updated_at * 1000).format('DD-MM-YYYY'),
										id: p.id,
									});
								}

								return (
									<ListItem
										button
										onClick={() => this.props.onSelect(p.id)}
										key={p.id}>
										<ListItemText
											primary={`${p.name} - ${p.price}€`}
											secondary={secondary}
										/>
									</ListItem>
								);
							})}
					</List>
				</DialogContent>
			</Dialog>
		);
	}
}

export default PopupRestoreBin;
