import {
	RECEIVE_CATEGORY,
	DELETE_CATEGORY,
	CREATE_CATEGORY,
	PATCH_CATEGORY,
	REFRESH_CATEGORIES_LIST,
	UPDATE_CATEGORY,
	REWEIGHT_CATEGORY,
} from '../constants/category';

import findIndex from 'lodash/findIndex';
import _forEach from 'lodash/forEach';
const initialState = {
	sort: [],
	categories: [],
};

const reducer = function categoryReducer(state = initialState, action) {
	const newState = { ...state };
	switch (action.type) {
		case CREATE_CATEGORY:
		case RECEIVE_CATEGORY:
		case REWEIGHT_CATEGORY:
		case UPDATE_CATEGORY:
		case DELETE_CATEGORY:
			const categories = action.response.result;
			return {
				filter: '',
				categories: categories,
			};

		/* case DELETE_CATEGORY:
      const {ids} = action;
      _forEach(ids, (id) => {
        const indexToDelete = findIndex( newState.categories, c => c.id === id );

        if(indexToDelete > -1) newState.categories.splice(indexToDelete, 1);

      });

      return newState; */

		case PATCH_CATEGORY:
			const { updatedCategories } = action;

			_forEach(newState.categories, (pc, key) => {
				const elementToUpdateIndex = findIndex(
					updatedCategories,
					p => p.id === pc.id
				);

				if (elementToUpdateIndex > -1)
					newState.categories[key] = updatedCategories[elementToUpdateIndex];
				return pc;
			});
			return newState;

		/* case UPDATE_CATEGORY :

      const newValue = action.newValues[0];
      const id = action.ids[0];

     newState.categories.map((c) => {
        if(c.id === id){
          for(let cle in newValue){
            c[cle] = newValue[cle];
          }
        }
        return c;
      });

      return newState;
 */
		case REFRESH_CATEGORIES_LIST:
			const { newList } = action;

			newState.categories = newList;

			return newState;

		default:
			return state;
	}
};

export default reducer;
