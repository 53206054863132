export const TRIGGER_PRODUCT_BUTTON_MAIN_PAGE =
	'TRIGGER_PRODUCT_BUTTON_MAIN_PAGE';
export const TRIGGER_BUTTON_ADD_PRODUCT = 'TRIGGER_BUTTON_ADD_PRODUCT';
export const TRIGGER_PRODUCT_MENUS_BUTTON_HEADER =
	'TRIGGER_PRODUCT_MENUS_BUTTON_HEADER';
export const TRIGGER_PRODUCT_MENUS_STEP1_BUTTON_NEXT =
	'TRIGGER_PRODUCT_MENUS_STEP1_BUTTON_NEXT';
export const TRIGGER_ADD_LEVEL_MENU3 = 'TRIGGER_ADD_LEVEL_MENU3';
export const TRIGGER_BUTTON_SAVE_LEVEL_MENU3 =
	'TRIGGER_BUTTON_SAVE_LEVEL_MENU3';
export const TRIGGER_ENTRANCE_LEVEL_MENU3 = 'TRIGGER_ENTRANCE_LEVEL_MENU3';
export const TRIGGER_BUTTON_SAVE_MENU3 = 'TRIGGER_BUTTON_SAVE_MENU3';
export const TRIGGER_BUTTON_ADD_OPTION_KITCHEN =
	'TRIGGER_BUTTON_ADD_OPTION_KITCHEN';
export const TRIGGER_BUTTON_ADD_TAX = 'TRIGGER_BUTTON_ADD_TAX';
export const TRIGGER_BUTTON_SAVE_TAX = 'TRIGGER_BUTTON_SAVE_TAX';
export const TRIGGER_BUTTON_ADD_PRICE_CATEGORY =
	'TRIGGER_BUTTON_ADD_PRICE_CATEGORY';
export const TRIGGER_BUTTON_SAVE_PRICE_CATEGORY =
	'TRIGGER_BUTTON_SAVE_PRICE_CATEGORY';
export const TRIGGER_CHECK_ROOMS = 'TRIGGER_CHECK_ROOMS';
export const PUT_FALSE_TRIGGER = 'PUT_FALSE_TRIGGER';
export const PUT_FALSE_ACTIVE_TRIGGER = 'PUT_FALSE_ACTIVE_TRIGGER';
export const TRIGGER_BUTTON_ADD_PRODUCT_FAST =
	'TRIGGER_BUTTON_ADD_PRODUCT_FAST';
export const TRIGGER_BUTTON_SAVE_OPTION_KITCHEN =
	'TRIGGER_BUTTON_SAVE_OPTION_KITCHEN';
export const TRIGGER_OPTION_KITCHEN_ROW = 'TRIGGER_OPTION_KITCHEN_ROW';
export const TRIGGER_BUTTON_ADD_UNDER_OPTION_KITCHEN =
	'TRIGGER_BUTTON_ADD_UNDER_OPTION_KITCHEN';
export const TRIGGER_BUTTON_SAVE_UNDER_OPTION_KITCHEN =
	'TRIGGER_BUTTON_SAVE_UNDER_OPTION_KITCHEN';
export const TRIGGER_PRODUCT_FOR_OPTION_KITCHEN =
	'TRIGGER_PRODUCT_FOR_OPTION_KITCHEN';
export const TRIGGER_BUTTON_SAVE_CATEGORY = 'TRIGGER_BUTTON_SAVE_CATEGORY';
export const TRIGGER_PRICE_CATEGORY_ROW = 'TRIGGER_PRICE_CATEGORY_ROW';
export const TRIGGER_SAVE_PRODUCT = 'TRIGGER_SAVE_PRODUCT';
export const TRIGGER_CHANGE_PRODUCT_CATEGORY =
	'TRIGGER_CHANGE_PRODUCT_CATEGORY';
