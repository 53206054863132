import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import ColorBlock from './ColorBlock';
import { I18n, Translate } from 'react-redux-i18n';
import Fuse from 'fuse.js';
import FilterIcon from '@material-ui/icons/KeyboardArrowRight';
import CheckEmptyIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/CheckBox';
import _reduce from 'lodash/reduce';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import findIndex from 'lodash/findIndex';
import { getSigle } from '../selectors/global.selector';
import { deepEqual } from '../utils';
import { bindActionCreators } from 'redux';
import * as tutorialEvents from './../actions/tutorial.action.events';
import ProductAssignatorProductList from './ProductAssignatorProductList';

const options = {
	shouldSort: true,
	threshold: 0.3,
	location: 0,
	distance: 100,
	maxPatternLength: 32,
	minMatchCharLength: 1,
	keys: ['name'],
};

class ProductAssignator extends Component {
	constructor(props) {
		super(props);

		const products = this.getProductWithoutAlreadySelected(
			props.products,
			props.alreadySelectedProducts
		);
		const categoriesWithoutCurrentOne = this.getAllCategoriesExceptCurrentOne(
			props.categories,
			props.categoryId
		);
		const productsNotInCurrentCategory = this.getAllProductNotInCurrentCategories(
			products,
			props.categoryId
		);

		const nbProductByCategory = this.getNbProductByCategory(
			productsNotInCurrentCategory,
			categoriesWithoutCurrentOne
		);

		let productAlreadyIn = [];

		if (props.alreadySelectedProducts.length > 0) {
			productAlreadyIn = [...props.alreadySelectedProducts];
		}
		const productInCurrentCategory = this.getAllProductInCurrentCategories(
			products,
			props.categoryId
		);
		if (productInCurrentCategory.length > 0) {
			productAlreadyIn = [...productInCurrentCategory];
		}

		this.state = {
			categories: categoriesWithoutCurrentOne || [],
			products: productsNotInCurrentCategory || [],
			searchResult: [],
			searchProductName: '',
			nbProductByCategory: nbProductByCategory || {},
			categorySelected: 'all',
			selectedProducts: [],
			productFiltered: products,
			productAlreadyIn: productAlreadyIn,
		};

		this.onSearch = this.onSearch.bind(this);
		this.getProductName = this.getProductName.bind(this);
		this.getProductColor = this.getProductColor.bind(this);
		this.onClickFilterByCategory = this.onClickFilterByCategory.bind(this);
	}

	getProductWithoutAlreadySelected(products, alreadySelected) {
		if (alreadySelected.length === 0) {
			return products;
		}
		return products.reduce((acc, product) => {
			const index = findIndex(alreadySelected, p => p.id === product.id);

			if (index === -1) {
				acc.push(product);
			}
			return acc;
		}, []);
	}

	getAllCategoriesExceptCurrentOne(categories, currentCategoryId) {
		return categories.reduce((acc, category) => {
			if (category.id !== currentCategoryId) {
				acc.push(category);
			}

			return acc;
		}, []);
	}

	getAllProductNotInCurrentCategories(products, currentCategoryId) {
		return products.reduce((acc, product) => {
			if (product.categories.indexOf(currentCategoryId) === -1) {
				acc.push(product);
			}

			return acc;
		}, []);
	}

	getAllProductInCurrentCategories(products, currentCategoryId) {
		return products.reduce((acc, product) => {
			if (product.categories.indexOf(currentCategoryId) !== -1) {
				acc.push(product);
			}

			return acc;
		}, []);
	}

	getNbProductByCategory(products, categories) {
		const nbProductByCategory = categories.reduce((acc, category, index) => {
			const nbProductInCat = products.reduce(
				(nbProductInCat, product, pIndex) => {
					if (product.categories.indexOf(category.id) > -1) {
						return nbProductInCat + 1;
					}
					return nbProductInCat;
				},
				0
			);

			return Object.assign(acc, {
				[category.id]: nbProductInCat,
			});
		}, {});

		nbProductByCategory.no_category = products.reduce(
			(nbProductInCat, product, pIndex) => {
				if (product.categories.length === 0) {
					return nbProductInCat + 1;
				}
				return nbProductInCat;
			},
			0
		);

		nbProductByCategory.all = _reduce(
			nbProductByCategory,
			(total, nbProduct) => {
				return total + nbProduct;
			},
			0
		);

		return nbProductByCategory;
	}

	getProductForSpecifiedCategoryId(products, categoryId) {
		return products.filter(product => {
			if (categoryId === 'no_category') {
				if (product.categories.length === 0) {
					return product;
				}
			} else {
				if (product.categories.indexOf(categoryId) > -1) {
					return product;
				}
			}
		});
	}

	componentWillReceiveProps(nextProps) {
		const receiveProducts = this.getProductWithoutAlreadySelected(
			nextProps.products,
			nextProps.alreadySelectedProducts
		);
		if (nextProps.SideWindowpen) {
			const categoriesWithoutCurrentOne = this.getAllCategoriesExceptCurrentOne(
				nextProps.categories,
				nextProps.categoryId
			);
			const productsNotInCurrentCategory = this.getAllProductNotInCurrentCategories(
				receiveProducts,
				nextProps.categoryId
			);
			const nbProductByCategory = this.getNbProductByCategory(
				productsNotInCurrentCategory,
				categoriesWithoutCurrentOne
			);
			let productAlreadyIn = [];

			if (nextProps.alreadySelectedProducts.length > 0) {
				productAlreadyIn = [...nextProps.alreadySelectedProducts];
			}
			const productInCurrentCategory = this.getAllProductInCurrentCategories(
				receiveProducts,
				nextProps.categoryId
			);
			if (productInCurrentCategory.length > 0) {
				productAlreadyIn = [...productInCurrentCategory];
			}
			const selectedProducts = this.props.canDeselect
				? productAlreadyIn.map(p => p.id)
				: [];
			this.setState({
				categories: categoriesWithoutCurrentOne,
				products: productsNotInCurrentCategory,
				nbProductByCategory: nbProductByCategory,
				productFiltered: receiveProducts,
				productAlreadyIn: productAlreadyIn,
				selectedProducts,
			});
		} else if (!nextProps.SideWindowpen) {
			this.setState({
				searchResult: [],
				searchProductName: '',
				categorySelected: 'all',
				selectedProducts: [],
				productFiltered: [],
				productAlreadyIn: [],
			});
		}
	}

	onSearch(ev, newSearchValue) {
		ev.preventDefault();
		const fuse = new Fuse(this.state.products, options);
		const result = fuse.search(newSearchValue);
		//this.state
		this.setState({
			searchProductName: newSearchValue,
			searchResult: result,
		});
	}

	onClickFilterByCategory(ev, categoryId) {
		ev.preventDefault();

		const productsNotInCurrentCategory = this.getAllProductNotInCurrentCategories(
			this.state.productFiltered,
			this.props.categoryId
		);
		if (categoryId === 'all') {
			this.setState({
				products: productsNotInCurrentCategory,
				categorySelected: categoryId,
			});
		} else if (categoryId === 'already_selected') {
			let alreadySelected = this.getAllProductInCurrentCategories(
				this.state.productFiltered,
				this.props.categoryId
			);
			if (this.props.alreadySelectedProducts.length > 0) {
				alreadySelected = this.props.alreadySelectedProducts;
			}
			this.setState({
				products: alreadySelected,
				categorySelected: categoryId,
			});
		} else {
			const productsFiltered = this.getProductForSpecifiedCategoryId(
				productsNotInCurrentCategory,
				categoryId
			);

			this.setState({
				products: productsFiltered,
				categorySelected: categoryId,
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (deepEqual(this.state, nextState) && deepEqual(nextProps, this.props)) {
			return false;
		}

		return true;
	}

	handleToggle = productId => () => {
		const { selectedProducts } = this.state;
		const currentIndex = selectedProducts.indexOf(productId);
		const newSelectedProducts = [...selectedProducts];

		if (currentIndex === -1) {
			newSelectedProducts.push(productId);
		} else {
			newSelectedProducts.splice(currentIndex, 1);
		}

		this.setState({
			selectedProducts: newSelectedProducts,
		});
	};

	handleSelectAll = products => () => {
		const { selectedProducts } = this.state;
		const newSelectedProducts = [...selectedProducts];

		products.forEach(p => {
			const currentIndex = newSelectedProducts.indexOf(p.id);

			if (currentIndex === -1 && !(this.props.isMenu && p.price == 0)) {
				newSelectedProducts.push(p.id);
			}
		});

		this.setState({
			selectedProducts: newSelectedProducts,
		});
	};

	handleDeselectAll = products => () => {
		const { selectedProducts } = this.state;
		const newSelectedProducts = [...selectedProducts];

		products.forEach(p => {
			const currentIndex = newSelectedProducts.indexOf(p.id);
			if (currentIndex > -1) {
				newSelectedProducts.splice(currentIndex, 1);
			}
		});

		this.setState({
			selectedProducts: newSelectedProducts,
		});
	};

	getProductName(productId) {
		return this.props.products.reduce((name, product) => {
			if (product.id === productId) {
				return product.name;
			}
			return name;
		}, '');
	}

	getProductColor(productId) {
		return this.props.products.reduce((name, product) => {
			if (product.id === productId) {
				return product.color_color;
			}
			return name;
		}, '');
	}

	render() {
		const {
			categories,
			products,
			searchResult,
			categorySelected,
			nbProductByCategory,
			selectedProducts,
		} = this.state;
		let displayableProducts = products;
		if (searchResult.length > 0) {
			displayableProducts = searchResult;
		}
		const selectedInProducts = products.filter(
			p => selectedProducts.indexOf(p.id) > -1
		);
		const unSelectableProducts = products.filter(
			p => this.props.isMenu && p.price == 0
		);
		const allSelected =
			products.length - unSelectableProducts.length ===
			selectedInProducts.length;

		const style = {
			btnSave: {
				float: 'right',
				width: 200,
				height: 50,
			},

			tagContainer: {
				width: '100%',
				height: 52,
				float: 'right',
			},
			tagPaper: {
				padding: 10,
				whiteSpace: 'nowrap',
				height: 52,
				overflowX: 'auto',
				borderRadius: '30px',
				//display: selectedProducts.length > 0 ? "block" : "none"
			},
			tagEmpty: {
				color: '#898989',
				fontStyle: 'italic',
				fontWeight: 'bold',
				position: 'relative',
				top: 7,
			},
			chipMargin: {
				marginRight: 5,
			},
			categoryListContainer: {
				width: '20%',
				backgroundColor: '#fff',
				height: '100%',
				overflow: 'auto',
				marginTop: 20,
				float: 'left',
				borderRadius: '20px',
			},
			categoryListItemAll: {
				paddingRight: 0,
				paddingLeft: 60,
				position: 'initial',
			},
			categoryListItem: {
				paddingRight: 0,
				position: 'initial',
			},

			productListContainer: {
				width: 'calc(80% - 25px)',
				marginLeft: 25,
				//backgroundColor: "#fff",
				height: '100%',
				overflow: 'auto',
				marginTop: 20,
				float: 'right',
				borderRadius: '20px',
			},
			productListItem: {
				backgroundColor: '#fff',
			},
			checkIcon: {
				cursor: 'pointer',
			},
		};
		return (
			<React.Fragment>
				<Button
					variant="raised"
					color={'primary'}
					style={style.btnSave}
					onClick={e => {
						this.props.onAssignProducts(e, selectedProducts);
						if (this.props.tutorial.run)
							this.props.tutorialEvent.triggerButtonSaveMenu3();
					}}
					className={'button_save_menu3'}>
					{this.props.saveLabel || I18n.t('save')}{' '}
				</Button>{' '}
				<div id="product-assignator">
					<div className={'assignator-title-container'}>
						<p className={'assignator-title'}>
							{this.props.title ||
								`${I18n.t('assignator.' + this.props.assignator.type)} : ${
									this.props.assignator.title
								}`}
						</p>{' '}
					</div>
					<div className={'name-container assignator-mainInput'}>
						<TextField
							placeholder={I18n.t('product.assign_form.search')}
							value={this.state.searchProductName || ''}
							onChange={ev => {
								this.onSearch(ev, ev.target.value);
							}}
							className={'form-textfield'}
							fullWidth={true}
							name={'searchProductName'}
						/>{' '}
					</div>{' '}
					<div style={style.tagContainer}>
						<Paper style={style.tagPaper}>
							{selectedProducts.map(selectedProduct => {
								const name = this.getProductName(selectedProduct);
								const color = this.getProductColor(selectedProduct);
								return (
									<Chip
										style={style.chipMargin}
										key={selectedProduct}
										label={name}
										onDelete={this.handleToggle(selectedProduct)}
										avatar={
											<Avatar
												style={{
													backgroundColor: color,
													color: 'white',
												}}>
												{' '}
												{name.substring(0, 1).toUpperCase()}{' '}
											</Avatar>
										}
									/>
								);
							})}{' '}
							{selectedProducts.length === 0 ? (
								<span style={style.tagEmpty}>
									{' '}
									<Translate value={'assignator.tag_empty'} />
								</span>
							) : (
								''
							)}{' '}
						</Paper>{' '}
					</div>{' '}
					<List
						style={style.categoryListContainer}
						className={'list_menu_left_menu3'}>
						<React.Fragment>
							<ListItem
								button
								style={style.categoryListItemAll}
								onClick={e => this.onClickFilterByCategory(e, 'all')}>
								<ListItemText
									primary={`${I18n.t('category.all')} (${
										nbProductByCategory.all
									})`}
								/>{' '}
								{categorySelected === 'all' ? (
									<ListItemIcon>
										<FilterIcon />
									</ListItemIcon>
								) : null}{' '}
							</ListItem>
							{nbProductByCategory['no_category'] > 0 ? (
								<ListItem
									button
									style={style.categoryListItemAll}
									onClick={e => this.onClickFilterByCategory(e, 'no_category')}>
									<ListItemText
										primary={`${I18n.t('category.no_category')} (${
											nbProductByCategory.no_category
										})`}
									/>{' '}
									{categorySelected === 'no_category' ? (
										<ListItemIcon>
											<FilterIcon />
										</ListItemIcon>
									) : null}{' '}
								</ListItem>
							) : (
								''
							)}
							{this.state.productAlreadyIn.length > 0 ? (
								<ListItem
									button
									style={style.categoryListItemAll}
									onClick={e =>
										this.onClickFilterByCategory(e, 'already_selected')
									}>
									<ListItemText
										primary={`${I18n.t('category.already_selected')} (${
											this.state.productAlreadyIn.length
										})`}
									/>{' '}
									{categorySelected === 'already_selected' ? (
										<ListItemIcon>
											<FilterIcon />
										</ListItemIcon>
									) : null}{' '}
								</ListItem>
							) : (
								''
							)}
							{categories.map(category => {
								return (
									<React.Fragment key={category.id}>
										<ListItem
											button
											onClick={e =>
												this.onClickFilterByCategory(e, category.id)
											}
											style={style.categoryListItem}>
											<ColorBlock value={category.id_color} />{' '}
											<ListItemText
												primary={`${category.name} (${
													nbProductByCategory[category.id]
												})`}
											/>{' '}
											{categorySelected === category.id ? (
												<ListItemIcon>
													<FilterIcon />
												</ListItemIcon>
											) : null}{' '}
										</ListItem>{' '}
										<Divider />
									</React.Fragment>
								);
							})}{' '}
						</React.Fragment>{' '}
					</List>
					<ProductAssignatorProductList
						products={displayableProducts}
						categorySelected={this.state.categorySelected}
						isMenu={this.props.isMenu}
						sigle={this.props.sigle}
						tax={this.props.tax}
						retailPriceTypes={this.props.retailPriceTypes}
						placeSend={this.props.placeSend}
						productType={this.props.productType}
						selectedProducts={selectedProducts}
						handleToggle={this.handleToggle}
						handleSelectAll={this.handleSelectAll}
						handleDeselectAll={this.handleDeselectAll}
						allSelected={allSelected}
						canDeselect={this.props.canDeselect}
						checkIcon={this.props.checkIcon}
					/>{' '}
				</div>{' '}
			</React.Fragment>
		);
	}
}

ProductAssignator.defaultProps = {
	alreadySelectedProducts: [],
	canDeselect: false,
	isMenu: false,
	title: '',
};

const mapStateToProps = state => {
	const { entity, assignator, tutorial } = state;

	const {
		category,
		product,
		variable,
		productType,
		tax,
		placeSend,
		importProduct,
	} = entity;
	return {
		categories: importProduct.active
			? importProduct.categories
			: category.categories,
		products: importProduct.active ? importProduct.products : product.products,
		retailPriceTypes: state.entity.retailPriceType,
		sigle: getSigle(variable),
		productType,
		tax,
		placeSend,
		assignator,
		tutorial,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		tutorialEvent: bindActionCreators(tutorialEvents, dispatch),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductAssignator);
