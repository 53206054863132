import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { I18n } from 'react-redux-i18n';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import { checkPrice, showInvalid, deleteInvalidClass } from './../utils';

export default class PriceCategory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: props.checked || false,
			old_value: false,
		};
		this.onSwitchChange = this.onSwitchChange.bind(this);
		this.onPriceChange = this.onPriceChange.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ checked: nextProps.checked, price: nextProps.price });
	}

	onSwitchChange(ev, value) {
		ev.preventDefault();

		if (!value && this.props.price) {
			this.setState({ old_value: this.props.price });
		}

		const price = value
			? this.state.old_value
				? this.state.old_value
				: this.props.default_price
			: '0.00';

		const checked = !this.state.checked;
		this.setState({ checked: checked }, () => {
			this.props.onChange({
				id: this.props.id,
				price: price,
				checked: checked,
				name: this.props.name,
			});
		});
	}

	onPriceChange(ev, value) {
		ev.preventDefault();
		const isNumber = checkPrice(value);
		if (isNaN(isNumber)) {
			showInvalid([this.props.id]);
			this.props.updateWrongPrice(true);
		} else {
			deleteInvalidClass(this.props.id);
			this.props.updateWrongPrice(false);
		}

		this.props.onChange({
			id: this.props.id,
			price: value.replace(/[^.\d]/g, ''),
			checked: this.props.checked,
			name: this.props.name,
		});
	}

	render() {
		const { name, checked, price, id } = this.props;
		return (
			<Row middle={'xs'} around={'xs'}>
				<Col xs={5}>
					<strong style={{ opacity: checked ? 1 : 0.5 }}>{name}</strong>
				</Col>
				<Col xs={4}>
					<TextField
						label={I18n.t('product.form.price')}
						value={price}
						onChange={ev => {
							this.onPriceChange(ev, ev.target.value.replace(/,/g, '.'));
						}}
						className={`form-textfield ${id}-container`}
						name={'product_price'}
						id={'product_price_' + id}
						fullWidth={true}
						disabled={!checked}
					/>
				</Col>
				<Col xs={3}>
					<Switch
						checked={checked}
						onChange={(ev, checked) => {
							this.onSwitchChange(ev, checked);
						}}
					/>
				</Col>
			</Row>
		);
	}
}

PriceCategory.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	price: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),
	default_price: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),
	onChange: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
};
