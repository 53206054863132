import {
	REQUESTING,
	REQUEST_SUCCESS,
	REQUEST_ERROR,
	REQUEST_REJECT,
	SET_NETWORK_STATUS,
} from '../constants/request';

const initialState = {
	/* Typical Requesting obj
  {
    id: 'blabla',
    time: new Date(),
    endpoint: '/blabla',
    method: 'GET',
    secured: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...' (access-token)
  }
  */
	requesting: {
		product: {},
		productMenu: {},
		menuLevel: {},
		category: {},
		color: {},
		tax: {},
		placeSend: {},
		supplementCategory: {},
		floor: {},
		priceCategory: {},
		supplement: {},
		productType: {},
		variable: {},
		variableServer: {},
		menu: {},
		synchro: {},
		patchMenu: {},
		retailPriceType: {},
		all: {},
		menuMasterMono: {},
		zone: {},
		access: {},
		accessToken: {},
	},
	/* Typical successful obj
  {
    id: 'blabla',
    time: new Date(),
    endpoint: '/blabla',
    method: 'GET',
    secured: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...' (access-token)
    responseTime: '1000' (millisecond)
  }
  */
	successfull: {
		product: {},
		productMenu: {},
		menuLevel: {},
		category: {},
		color: {},
		tax: {},
		patchMenu: {},
		placeSend: {},
		supplementCategory: {},
		floor: {},
		priceCategory: {},
		supplement: {},
		productType: {},
		variable: {},
		variableServer: {},
		menu: {},
		synchro: {},
		retailPriceType: {},
		all: {},
		menuMasterMono: {},
		zone: {},
		access: {},
		accessToken: {},
	},
	/* Typical error obj
  {
    id: 'blabla',
    time: new Date(),
    endpoint: '/blabla',
    method: 'GET',
    secured: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...' (access-token)
    responseTime: '1000' (millisecond)
    body: 'blabla request successfull',
    time: new Date(),
  }
  */
	errors: {
		product: {},
		productMenu: {},
		menuLevel: {},
		category: {},
		color: {},
		tax: {},
		patchMenu: {},
		placeSend: {},
		supplementCategory: {},
		floor: {},
		priceCategory: {},
		supplement: {},
		productType: {},
		variable: {},
		variableServer: {},
		menu: {},
		synchro: {},
		retailPriceType: {},
		all: {},
		menuMasterMono: {},
		zone: {},
		access: {},
		accessToken: {},
	},
	networkStatus: window.navigator.onLine ? 'online' : 'offline',
};

window.localStorage.setItem(
	'networkStatus',
	window.navigator.onLine ? 'online' : 'offline'
);

const reducer = function requestReducer(state = initialState, action) {
	const newState = { ...state };
	const { entity } = action;
	switch (action.type) {
		// Set the requesting flag and append a message to be shown
		case REQUESTING:
			const { request } = action;
			const { requesting } = newState;
			requesting[entity][request.id] = request;
			return { ...newState, requesting: requesting };

		case REQUEST_SUCCESS:
			const { successfull } = newState;
			const { successRequest } = action;
			const requestingState = newState.requesting;
			successfull[entity][successRequest.id] = successRequest;
			delete requestingState[entity][successRequest.id];

			return {
				...newState,
				successfull: successfull,
				requesting: requestingState,
			};
		case REQUEST_REJECT:
			return {
				...newState,
				rejected: true,
				...action.result,
			};

		case REQUEST_ERROR:
			const { errors } = newState;
			const { errorRequest } = action;
			errors[entity][errorRequest.id] = errorRequest;

			return { ...newState, errors: errors };

		case SET_NETWORK_STATUS:
			const { networkStatus } = action;
			return { ...newState, networkStatus: networkStatus };

		default:
			return state;
	}
};

export default reducer;
