import React, { Component } from 'react';
import { Portal } from 'react-portal';
import { connect } from 'react-redux';
import 'react-sweet-progress/lib/style.css';
import LadditionLoader from './LadditionLoader';

class Loader extends Component {
	constructor() {
		super();
		this.state = {
			isLoading: true,
		};
	}

	shouldComponentUpdate(nextProps) {
		if (this.props.loading.isLoading !== nextProps.loading.isLoading) {
			return true;
		}
		return false;
	}

	render() {
		return (
			<Portal>
				<div
					className={
						this.props.loading.isLoading
							? 'loader-container'
							: 'loader-container-hidden'
					}>
					<LadditionLoader hidden={!this.props.loading.isLoading} />
				</div>
			</Portal>
		);
	}
}

const mapStateToProps = state => {
	const { loading } = state;

	return {
		loading,
	};
};

export default connect(mapStateToProps)(Loader);
