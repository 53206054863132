import {
	RECEIVE_IMPORT_CATEGORY,
	RECEIVE_IMPORT_PRODUCT,
	PRE_IMPORT_CATEGORY,
	PRE_IMPORT_PRODUCT,
} from './../constants/import';

import { call, put, take, actionChannel } from 'redux-saga/effects';
import { requestHandler } from './fetcher.saga';
import { buffers } from 'redux-saga';

const PRE_REQUEST_IMPORT_CATEGORY = 'PRE_REQUEST_IMPORT_CATEGORY';
const PRE_REQUEST_IMPORT_PRODUCT = 'PRE_REQUEST_IMPORT_PRODUCT';

const REQUEST_IMPORT_CATEGORY_FINISHED = 'REQUEST_IMPORT_CATEGORY_FINISHED';
const REQUEST_IMPORT_PRODUCT_FINISHED = 'REQUEST_IMPORT_PRODUCT_FINISHED';

const ENTITY_PRODUCT = 'product';
const ENTITY_CATEGORY = 'category';
const GET_METHOD = 'GET';

export function* preImportProductSaga() {
	while (true) {
		const action = yield take(PRE_IMPORT_PRODUCT);
		const { id_menu } = action;
		yield put({
			type: PRE_REQUEST_IMPORT_PRODUCT,
			body: { id_menu },
		});

		const result = yield take(REQUEST_IMPORT_PRODUCT_FINISHED);
		yield put({
			type: RECEIVE_IMPORT_PRODUCT,
			response: result.response.result,
		});
	}
}

export function* watchImportProductSaga() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_IMPORT_PRODUCT,
		buffers.expanding()
	);

	while (true) {
		const action = yield take(requestChan);
		const resultImportProducts = yield call(
			requestHandler,
			ENTITY_PRODUCT,
			GET_METHOD,
			true,
			null,
			'',
			{
				id_menu: action.body.id_menu,
			}
		);

		yield put({
			type: REQUEST_IMPORT_PRODUCT_FINISHED,
			response: resultImportProducts,
		});
	}
}

export function* preImportCategorySaga() {
	while (true) {
		const action = yield take(PRE_IMPORT_CATEGORY);
		const { id_menu } = action;
		yield put({
			type: PRE_REQUEST_IMPORT_CATEGORY,
			body: { id_menu },
		});

		const result = yield take(REQUEST_IMPORT_CATEGORY_FINISHED);
		yield put({
			type: RECEIVE_IMPORT_CATEGORY,
			response: result.response.result,
		});
	}
}

export function* watchImportCategorySaga() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_IMPORT_PRODUCT,
		buffers.expanding()
	);

	while (true) {
		const action = yield take(requestChan);
		const resultImportCategories = yield call(
			requestHandler,
			ENTITY_CATEGORY,
			GET_METHOD,
			true,
			null,
			'',
			{
				id_menu: action.body.id_menu,
			}
		);

		yield put({
			type: REQUEST_IMPORT_CATEGORY_FINISHED,
			response: resultImportCategories,
		});
	}
}
