import { RECEIVE_CHILDREN } from '../constants/children';

const initialState = [];

const reducer = function ChildrenReducer(state = initialState, action) {
	switch (action.type) {
		case RECEIVE_CHILDREN:
			return action.data;

		default:
			return state;
	}
};

export default reducer;
