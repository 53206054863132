import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
//import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme'
import classnames from 'classnames';
import { deepEqual } from '../utils';
import { connect } from 'react-redux';
import { getDefaultColor } from '../selectors/color.selector';

class ColorPicker extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			selected: null,
		};

		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
		this.assignData = this.assignData.bind(this);
		this.selectColor = this.selectColor.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (deepEqual(this.props, nextProps) && deepEqual(this.state, nextState)) {
			return false;
		}

		return true;
	}

	/* getChildContext(){
        return {
            muiTheme: getMuiTheme(lightBaseTheme)
        };
    } */

	componentWillMount() {
		this.assignData(this.props);
	}

	componentWillReceiveProps(nextProps) {
		if (!deepEqual(nextProps, this.props)) {
			this.assignData(nextProps);
		}
	}

	selectColor(ev) {
		const { color, onColorSelected } = this.props;

		let colorId = ev.currentTarget.dataset.id;

		let selectedColor, defaultColor;
		for (let i = 0, length = color.length; i < length; i++) {
			if (color[i].id == colorId) {
				selectedColor = color[i];
			}

			if (color[i].id == this.props.defaultColor) {
				defaultColor = color[i];
			}
		}

		this.setState(
			{
				selected: selectedColor || defaultColor,
				open: false,
			},
			() => {
				if (onColorSelected) {
					onColorSelected(selectedColor || defaultColor);
				}
			}
		);
	}

	assignData(props) {
		const { color, value } = props;

		let selectedData, defaultColor;
		for (let i = 0, length = color.length; i < length; i++) {
			// Value can be the id or the color code
			if (
				value &&
				(color[i].name.includes(value) ||
					value == color[i].color ||
					color[i].id == value)
			) {
				selectedData = color[i];
			}

			if (color[i].id == this.props.defaultColor) {
				defaultColor = color[i];
			}
		}

		this.setState({
			selected: selectedData || defaultColor,
		});
	}

	open(ev) {
		ev.preventDefault();

		this.setState({
			open: true,
			anchor: ev.currentTarget,
		});
	}

	close() {
		this.setState({
			open: false,
		});
	}

	render() {
		const { color, className } = this.props;

		let colorBoxes = [];

		for (let i = 0, length = color.length; i < length; i++) {
			let style = {
				backgroundColor: color[i].color,
			};

			let classes = classnames('color-box', {
				selected: color[i].id == this.state.selected.id,
			});

			colorBoxes.push(
				<div
					key={color[i].id}
					data-id={color[i].id}
					className={classes}
					style={style}
					onClick={this.selectColor}
				/>
			);
		}

		let style = {
			backgroundColor:
				this.state.selected && this.state.selected.color
					? this.state.selected.color
					: '#5a5a5a',
			borderRadius: '50%',
		};

		return (
			<div className={className}>
				<div className={'color-picker'} style={style} onClick={this.open} />
				<Popover
					open={this.state.open}
					anchorEl={this.state.anchor}
					onClose={this.close}>
					<div className="color-popover">{colorBoxes}</div>
				</Popover>
			</div>
		);
	}
}

/* ColorPicker.childContextTypes = {
    muiTheme: PropTypes.object
}; */

ColorPicker.propTypes = {
	color: PropTypes.array.isRequired,
	value: PropTypes.string,
	onColorSelected: PropTypes.func.isRequired,
};

export default ColorPicker;
