import { RECEIVE_COLOR } from '../constants/color';

const initialState = [];

const reducer = function productReducer(state = initialState, action) {
	const newState = { ...state };
	switch (action.type) {
		case RECEIVE_COLOR:
			if (action.response.result.length === 0) {
				return newState;
			}
			const colors = action.response.result;
			return colors;
		default:
			return state;
	}
};

export default reducer;
