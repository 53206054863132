import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, InputAdornment } from '@material-ui/core';
import { I18n } from 'react-redux-i18n';
import { checkPrice } from './../../../utils';

class PriceCategoryRenderer extends Component {
	constructor(props) {
		super(props);
		this.inputRef = null;
		let product = {};
		if (props.products && props.products.length > 0 && props.id !== '') {
			product = props.products.filter(product => {
				return product.id === props.id;
			});
			product = product[0];
		}
		let price = 0;
		if (product === undefined) {
			product = { price: 0, prices: [] };
			price = 0;
		} else {
			const productCatPrice = product.prices.find(
				price => price.id === props.priceCategoryId
			);
			price =
				productCatPrice && productCatPrice.price
					? productCatPrice.price
					: product.price;
		}

		this.state = {
			product,
			price: price,
			initPrice: price,
			showInput: false,
		};
	}

	save = e => {
		if (e.charCode == 13) {
			this.setState({ showInput: false }, () =>
				this.props.cellProperties.instance.updateSettings({
					copyPaste: true,
				})
			);
			this.handlePriceChange(
				{ id: this.state.product.id, name: this.state.product.name },
				this.state.price,
				this.state.initPrice
			);
		}
	};

	componentDidMount() {
		document.addEventListener('click', this.onwtHolderClick);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.onwtHolderClick);
	}

	onwtHolderClick = e => {
		if (
			e &&
			e.realTarget &&
			e.realTarget.className === 'wtHolder' &&
			this.state.showInput
		) {
			e.preventDefault();
			e.stopImmediatePropagation();
			this.inputRef.blur();
		}
	};

	updatePrice = e => {
		this.setState({ showInput: false }, () =>
			this.props.cellProperties.instance.updateSettings({
				copyPaste: true,
			})
		);
		this.handleChange('price');
		this.handlePriceChange(
			{ id: this.state.product.id, name: this.state.product.name },
			this.state.price,
			this.state.initPrice
		);
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextState.product &&
			nextState.product.id &&
			nextState.product.id === this.state.product.id &&
			nextState.price === this.state.price &&
			nextState.showInput === this.state.showInput &&
			this.state.product.is_free_price === nextState.product.is_free_price &&
			this.state.product.id_retail_price_type ===
				nextState.product.id_retail_price_type
		) {
			return false;
		}
		return true;
	}

	handlePriceChange = (productInfo, newPrice, currentPrice) => {
		const isNumber = checkPrice(newPrice);
		let actualPrice = checkPrice(currentPrice);
		if (!isNaN(isNumber) && actualPrice !== isNumber) {
			let product = this.props.products.filter(product => {
				return product.id === productInfo.id;
			});
			product = product[0];
			const prices = product.prices.filter(
				price => price.id !== this.props.priceCategoryId
			);
			return this.props.productAction.updateProducts(
				[productInfo.id],
				[
					{
						prices: [
							...prices,
							{
								id: this.props.priceCategoryId,
								price: isNumber,
								id_table: productInfo.id,
							},
						],
					},
				],
				['priceCategory']
			);
		} else if (isNaN(isNumber)) {
			setTimeout(() => {
				this.props.alertWrongPrice(productInfo.id.name);
				this.setState({ price: currentPrice });
			}, 100);
		}
	};

	componentWillReceiveProps(nextProps) {
		let product = {};
		if (
			nextProps.products &&
			nextProps.products.length > 0 &&
			nextProps.id !== ''
		) {
			product = nextProps.products.filter(product => {
				return product.id === nextProps.id;
			});
			product = product[0];
		}
		let price = 0;
		if (product === undefined) {
			price = 0;
			product = { price: 0, prices: [] };
		} else {
			const productCatPrice = product.prices.find(
				price => price.id === nextProps.priceCategoryId
			);
			price =
				productCatPrice && productCatPrice.price
					? productCatPrice.price
					: product.price;
		}
		this.setState({
			product,
			price: price,
			initPrice: price,
		});
	}

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value,
		});
	};

	renderPriceInput = () => {
		if ('id' in this.state.product) {
			if (this.state.product.is_free_price) {
				return (
					<span style={{ display: 'block', width: '100%' }}>
						{I18n.t('product.form.free_price')}
					</span>
				);
			} else if (this.state.product.id_retail_price_type != '0') {
				let priceTypeValue = this.props.retailPriceTypes.filter(
					rpt => rpt.id === this.state.product.id_retail_price_type
				);
				if (priceTypeValue.length > 0) {
					priceTypeValue = priceTypeValue[0];
				} else {
					priceTypeValue = { unit: 'Kg' };
				}

				return this.state.showInput ? (
					<div>
						<Input
							inputRef={el => (this.inputRef = el)}
							id="adornment-weight"
							classes={{ input: 'handson_price' }}
							value={this.state.price}
							autoFocus
							onChange={this.handleChange('price')}
							style={{ maxWidth: '100%' }}
							onBlur={this.updatePrice}
							onKeyPress={this.save}
							endAdornment={
								<InputAdornment position="end">
									/{I18n.t(priceTypeValue.unit)}
								</InputAdornment>
							}
						/>
					</div>
				) : (
					<span
						style={{ display: 'block', width: '100%' }}
						onClick={e => {
							e.preventDefault();
							this.props.cellProperties.instance.updateSettings({
								copyPaste: false,
							});
							this.setState({ showInput: true });
						}}>
						{this.state.price} /{I18n.t(priceTypeValue.unit)}
					</span>
				);
			} else {
				return this.state.showInput ? (
					<Input
						inputRef={el => (this.inputRef = el)}
						value={this.state.price}
						autoFocus
						classes={{ input: 'handson_price' }}
						onKeyPress={this.save}
						onBlur={this.updatePrice}
						onChange={this.handleChange('price')}
					/>
				) : (
					<span
						style={{ display: 'block', width: '100%' }}
						onClick={e => {
							e.preventDefault();
							this.props.cellProperties.instance.updateSettings({
								copyPaste: false,
							});
							this.setState({ showInput: true });
						}}>
						{this.state.price}
					</span>
				);
			}
		} else {
			return <span style={{ display: 'block', width: '100%' }} />;
		}
	};

	render() {
		return <div>{this.renderPriceInput()}</div>;
	}
}

PriceCategoryRenderer.propTypes = {
	products: PropTypes.array,
	id: PropTypes.string,
};

export default PriceCategoryRenderer;
