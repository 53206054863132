import { createConfirmation } from 'react-confirm';
import Confirm from './components/Confirm';
import { I18n } from 'react-redux-i18n';
import moment from 'moment/moment';
import difference from 'lodash/difference';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';
import _toNumber from 'lodash/toNumber';
import clonedeep from 'lodash/cloneDeep';

export const ACTION_DELETE = 'delete';
export const ACTION_CREATE = 'create';
export const ACTION_UPDATE = 'update';
export const PRICE_PRECISION = 2;

export const ENTITY_CATEGORY = 'category';
export const ENTITY_EXCLUSION = 'exclusion';
export const ENTITY_SUPPLEMENT = 'supplement';
export const ENTITY_PRICE_CATEGORY = 'priceCategory';
export const ENTITY_PRODUCT = 'products';

export const ENTITY_PRODUCT_MENU_CATEGORY = 'category';
export const ENTITY_PRODUCT_MENU_EXCLUSION = 'exclusion';
export const ENTITY_PRODUCT_MENU_PRICE_CATEGORY = 'priceCategory';
export const ENTITY_PRODUCT_MENU_SUPPLEMENT = 'supplement';

export const PRICE_CATEGORY_INIT = {
	COPY: 'copy',
	FIXED_PRICE: 'fixed_price',
	NORMAL_PRICE: 'normal_price',
	PERCENTAGE_PLUS: 'percentage_plus',
	PERCENTAGE_MINUS: 'percentage_minus',
	PRICE_PLUS: 'price_plus',
	PRICE_MINUS: 'price_minus',
};

export const DRINK_TYPE = [101, 102, 103, 104, 105, 106, 107, 108, 109];

export const TYPE_ALCOOL = [101, 102, 103, 104];
export const TYPE_SYNCHRO = {
	neverDeploy: 'neverDeploy',
	noSynchro: 'noSynchro',
	synchroNoDeployed: 'synchroNoDeployed',
	synchro: 'synchro',
};

export function dispatchGridChanges(data, currentData) {
	let d = [];

	for (let key in data) {
		if (data[key].id === 'empty') {
			d.push(data[key]);
		} else if (data[key].action && data[key].action === 'delete') {
			d.push(data[key]);
		} else {
			if (currentData[data[key].index] && data[key].id !== 'temp') {
				// If we replace product with an other one, delete previous, create new
				if (
					data[key].id !== currentData[data[key].index].id &&
					currentData[data[key].index].id !== 'temp'
				) {
					let productToDelete = Object.assign({}, currentData[data[key].index]);
					productToDelete.action = 'delete';
					data[key].action = 'create';
					d.push(productToDelete);
				} else {
					data[key].action = 'update';
				}
			} else {
				data[key].action = 'create';

				for (let k in data[key]) {
					if (data[key][k] === null) {
						delete data[key][k];
					}
				}
			}

			d.push(data[key]);
		}
	}

	return d;
}

export function replaceVarInString(string, variables) {
	for (let i = 0, length = variables.length; i < length; i++) {
		string = string.replace(new RegExp(`\\{${i}\\}`, 'g'), variables[i]);
	}

	return string;
}

export function getVariable(variables, key) {
	let data;

	for (let i = 0, length = variables.length; i < length; i++) {
		if (variables[i].key_ === key) {
			data = variables[i];
			break;
		}
	}

	return data;
}

//noinspection JSAnnotator
export function deepEqual(objA, objB) {
	if (objA === objB) {
		return true;
	}

	if (
		typeof objA !== 'object' ||
		objA === null ||
		typeof objB !== 'object' ||
		objB === null
	) {
		return false;
	}

	var keysA = Object.keys(objA);
	var keysB = Object.keys(objB);

	if (keysA.length !== keysB.length) {
		return false;
	}

	// Test for A's keys different from B.
	var bHasOwnProperty = hasOwnProperty.bind(objB);
	for (var i = 0; i < keysA.length; i++) {
		if (
			!bHasOwnProperty(keysA[i]) ||
			(typeof objA[keysA[i]] !== 'function' &&
				!deepEqual(objA[keysA[i]], objB[keysA[i]]))
		) {
			return false;
		}
	}

	return true;
}

export function shallowCompare(instance, nextProps, nextState) {
	return (
		!deepEqual(instance.props, nextProps) ||
		!deepEqual(instance.state, nextState)
	);
}

export function sort(arrayToSort, property = 'weight') {
	if (!arrayToSort || !Array.isArray(arrayToSort)) {
		return null;
	}

	return arrayToSort.sort((a, b) => {
		if (a.id === 'temp' && b.id !== 'temp') {
			return 1;
		}

		if (b.id === 'temp' && a.id !== 'temp') {
			return -1;
		}

		if (a[property] > b[property]) {
			return 1;
		}

		if (a[property] < b[property]) {
			return -1;
		}

		return 0;
	});
}

export function replaceAccent(str) {
	const accentMap = {
		é: 'e',
		è: 'e',
		ë: 'e',
		ê: 'e',
		à: 'a',
		â: 'a',
		ä: 'a',
		ï: 'i',
		œ: 'oe',
		ù: 'u',
	};

	let result = '';

	for (let i = 0, length = str.length; i < length; i++) {
		result += accentMap[str.charAt(i)] || str.charAt(i);
	}

	return result;
}

export function capitalizeFirstLetter(str) {
	if (!str) {
		return null;
	}

	str = str.trim();

	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function deepClone(data) {
	return clonedeep(data);
}

export function detectBrowser(userAgentString) {
	var browsers = [
		['edge', /Edge\/([0-9._]+)/],
		['yandexbrowser', /YaBrowser\/([0-9._]+)/],
		['chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/],
		['crios', /CriOS\/([0-9.]+)(:?\s|$)/],
		['firefox', /Firefox\/([0-9.]+)(?:\s|$)/],
		['opera', /Opera\/([0-9.]+)(?:\s|$)/],
		['opera', /OPR\/([0-9.]+)(:?\s|$)$/],
		['ie', /Trident\/7\.0.*rv:([0-9.]+)\).*Gecko$/],
		['ie', /MSIE\s([0-9.]+);.*Trident\/[4-7].0/],
		['ie', /MSIE\s(7\.0)/],
		['bb10', /BB10;\sTouch.*Version\/([0-9.]+)/],
		['android', /Android\s([0-9.]+)/],
		['ios', /Version\/([0-9._]+).*Mobile.*Safari.*/],
		['safari', /Version\/([0-9._]+).*Safari/],
	];

	return browsers
		.map(rule => {
			if (rule[1].test(userAgentString)) {
				var match = rule[1].exec(userAgentString);
				var version = match && match[1].split(/[._]/).slice(0, 3);

				if (version && version.length < 3) {
					Array.prototype.push.apply(
						version,
						version.length === 1 ? [0, 0] : [0]
					);
				}

				return {
					name: rule[0],
					version: version.join('.'),
				};
			}
		})
		.filter(Boolean)
		.shift();
}


export function extractGetVariables(searchString) {
	const params = new URLSearchParams(searchString);
  const getVariables = {};
  for (let key of params.keys()) {
    getVariables[key] = params.get(key);
  }
  return getVariables;
}

const conf = createConfirmation(Confirm);
export function confirm(confirmation, options = {}) {
	return conf({ confirmation, options });
}

export function getCounterTypes() {
	return [
		{ id: 1, name: I18n.t('floor.counter_type.stay_in') },
		{ id: 2, name: I18n.t('floor.counter_type.take_away') },
		{ id: 3, name: I18n.t('floor.counter_type.delivery') },
	];
}

export function getHoursAndMinutes(intHour) {
	let hours = '';
	let minutes = '';

	if (intHour < 1000) {
		hours = String(intHour).charAt(0);

		minutes = String(intHour).charAt(1);
		minutes += String(intHour).charAt(2);
	} else {
		hours = String(intHour).charAt(0);
		hours += String(intHour).charAt(1);

		minutes = String(intHour).charAt(2);
		minutes += String(intHour).charAt(3);
	}

	return { hours: hours, minutes: minutes };
}

export function formatTime(start, end, hide) {
	let string = '';

	if (!hide) {
		if (start === end) {
			string = I18n.t('priceCategory.form.all_day');
		} else {
			let START = getHoursAndMinutes(start);
			let END = getHoursAndMinutes(end);

			string =
				I18n.t('priceCategory.form.times.from') +
				' ' +
				START.hours +
				':' +
				START.minutes +
				' ' +
				I18n.t('priceCategory.form.times.to') +
				' ' +
				END.hours +
				':' +
				END.minutes;

			if (end <= start) {
				string += ' ' + I18n.t('priceCategory.form.times.tomorrow');
			}
		}
	}

	return string;
}

export function getDaysArray(value) {
	value = parseInt(value, 10);

	let initStr = '0000000';

	if (isNaN(value)) return initStr;

	let byte = Number(value).toString(2);
	let diffLength = initStr.length - byte.length;

	return initStr.substr(0, diffLength) + byte;
}

export function getCurrentTime() {
	return moment().format('DD-MM-YYYY HH:mm:ss');
}

export function getDiffForWeigth(arrayBefore, arrayAfter) {
	let idsBefore = arrayBefore.map(pc => pc.id);
	let idsAfter = arrayAfter.map(pc => pc.id);

	let weights = [];
	for (let i = 0; i < idsAfter.length; i++) {
		if (idsAfter[i] !== idsBefore[i]) {
			weights.push({ id: arrayAfter[i].id, weight: i });
		}
	}

	return weights;
}

/*
Retourne tout les champs différente pour un objet
les champs de types array renverrons la totalité du nouveau champs si ils ne sont pas égaux
 /!\/!\ Si un champs est un tableau d'objets, il doit comporter un champs ID pour la comparaison/!\/!\
 */
export function getDifferences(initObj, newObj) {
	return Object.keys(newObj).reduce((diff, key) => {
		if (typeof newObj[key] !== 'object' || newObj[key] == null) {
			if (initObj[key] === newObj[key]) return diff;
			return {
				...diff,
				[key]: newObj[key],
			};
		} else {
			let initIds = [];
			let newIds = [];

			if (initObj[key] && newObj[key]) {
				if (key === 'categories') {
					initIds = initObj[key];
					newIds = newObj[key];
				} else {
					if (key === 'exclusions' || key === 'floors') {
						initIds = initObj[key].map(i => i.id + '#' + i.counter_type);
						newIds = newObj[key].map(i => i.id + '#' + i.counter_type);
					} else if (key === 'supplements' || key === 'prices') {
						return { ...diff, [key]: newObj[key] };
					} else {
						initIds = initObj[key].map(i => i.id);
						newIds = newObj[key].map(i => i.id);
					}
				}
			}

			const d = difference(initIds, newIds);

			if (d.length || initIds.length !== newIds.length)
				return { ...diff, [key]: newObj[key] };
			else return diff;
		}
	}, {});
}

/**
 * retourne la liste des differences pour les liste utilisant sortable.js
 * @param {*} newOrder
 * @param {*} initialOrder
 */
export const computeChanges = (newOrder, initialOrder) => {
	let hasAddedSpace = false;
	let skip = false;
	const length = newOrder.length;
	return newOrder.reduce((acc, item, index) => {
		let flag = false;
		if (skip) {
			return acc;
		}
		if (item === '2,2') {
			skip = true;
			return acc;
		}
		if (item === '2,1') {
			item = 'space,' + index;
			flag = true;
		}
		let itemSplitted = item.split(',');
		if (hasAddedSpace) {
			index -= 1;
		}
		if (index <= length - 1 && initialOrder[index]) {
			let newWeight = initialOrder[index].weight;
			if (hasAddedSpace) {
				newWeight += 1;
			}
			if (itemSplitted[0] !== 'space' && newWeight != itemSplitted[1]) {
				const goodItem = _find(
					initialOrder,
					item => item.id === itemSplitted[2]
				);
				acc.push({ id: goodItem.id, weight: newWeight });
			}
			if (flag) {
				hasAddedSpace = true;
			}
		}

		return acc;
	}, []);
};

export const uppercaseFirstLetter = str => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export function isProductInMenu(productMenus, id_product) {
	let inMenu = false;
	productMenus.forEach(menu => {
		menu.menuLevels.forEach(menuLevels => {
			menuLevels.products.forEach(product => {
				if (id_product === product.id) inMenu = true;
			});
		});
	});
	return inMenu;
}

function getTaxRate(taxRates = [], rate = 0.1, rateTakeAway = []) {
	let tax_rate = taxRates.find(
		tr =>
			tr.rate === rate &&
			tr.rate_take_away === rateTakeAway &&
			tr.active &&
			!tr.removed
	);
	if (tax_rate) {
		return tax_rate;
	} else {
		return false;
	}
}

/*
 Pour un changement de type renvoie et le nouveau taxe rate (si necessaire sinon renvoie false) et le texte de notification
 Si pas de changement de type, renvoie false
 */
export function handleProductTypeChange(
	oldType = 1,
	newType = 1,
	taxRates = []
) {
	if (oldType !== newType) {
		let newTaxRate = false;
		let newPlaceSend = false;

		const isOldTypeAlcool = TYPE_ALCOOL.indexOf(parseInt(oldType, 10)) > -1;
		const isNewTypeAlcool = TYPE_ALCOOL.indexOf(parseInt(newType, 10)) > -1;
		if (
			(isOldTypeAlcool && !isNewTypeAlcool) ||
			(!isOldTypeAlcool && isNewTypeAlcool)
		) {
			if (isOldTypeAlcool) {
				newTaxRate = getTaxRate(taxRates, 0.1, 0.055);
			} else {
				newTaxRate = getTaxRate(taxRates, 0.2, 0.2);
			}
		}

		const isOldTypeDrink = DRINK_TYPE.indexOf(parseInt(oldType, 10)) > -1;
		const isNewTypeDrink = DRINK_TYPE.indexOf(parseInt(newType, 10)) > -1;

		if (
			(isOldTypeDrink && !isNewTypeDrink) ||
			(!isOldTypeDrink && isNewTypeDrink)
		) {
			if (isOldTypeDrink) {
				newPlaceSend = 'def0-1';
			} else {
				newPlaceSend = 'def0-2';
			}
		}

		const notification = newTaxRate
			? 'product.productTVAAjusted'
			: 'product.productTypeChange';

		return {
			notification: notification,
			id_tax: newTaxRate,
			id_place_send: newPlaceSend,
		};
	} else {
		return false;
	}
}

export function getTypeLabel(type = 1) {
	if (type > 0 && type < 101) {
		return I18n.t('product.types.solid');
	} else if (type >= 101 && type <= 104) {
		return I18n.t('product.types.alcool');
	} else if (type > 104 && type <= 200) {
		return I18n.t('product.types.no_alcool');
	} else {
		return '';
	}
}

export function getAuthUrl(withScope = false) {
	let authUrl = '';
	if (window.location && window.location.hostname === 'localhost') {
		const scope = withScope ? '?scope=carte-web-local' : '';
		authUrl = `http://localhost:3000/${scope}`;
	} else if (
		window.location &&
		window.location.hostname === 'dev.menu.laddition.com'
	) {
		const scope = withScope ? '?scope=carte-web' : '';
		authUrl = `https://auth.laddit.io/${scope}`;
	} else if (window.location && window.location.hostname === 'menu.laddit.io') {
		const scope = withScope ? '?scope=carte-web' : '';
		authUrl = `https://auth.laddit.io/${scope}`;
	} else if (
		window.location &&
		window.location.hostname === 'preprod.menu.laddition.com'
	) {
		const scope = withScope ? '?scope=carte-web-preprod' : '';
		authUrl = `https://auth.laddition.com/${scope}`;
	} else if (
		window.location &&
		window.location.hostname === 'dev.next.menu.laddition.com'
	) {
		const scope = withScope ? '?scope=carte-web-next' : '';
		authUrl = `https://auth.laddit.io/${scope}`;
	} else if (
		window.location &&
		window.location.hostname === 'dev.turfu.menu.laddition.com'
	) {
		const scope = withScope ? '?scope=carte-web-turfu' : '';
		authUrl = `https://auth.laddit.io/${scope}`;
	} else if (
		window.location &&
		window.location.hostname === 'multi.menu.laddition.com'
	) {
		const scope = withScope ? '?scope=carte-web-multi' : '';
		authUrl = `https://auth.laddition.com/${scope}`;
	} else if (
		window.location &&
		window.location.hostname === 'next-menu.laddition.com'
	) {
		const scope = withScope ? '?scope=carte-web-next' : '';
		authUrl = `https://auth.laddition.com/${scope}`;
	} else {
		const scope = withScope ? '?scope=carte-web' : '';
		authUrl = `https://auth.laddition.com/${scope}`;
	}

	return authUrl;
}

export const fetchMethod = {
	POST: 'POST',
	PATCH: 'PATCH',
	DELETE: 'DELETE',
	PUT: 'PUT',
};

export const entity = {
	product: 'product',
	category: 'category',
	tax: 'tax',
	supplement: 'supplement',
	supplementCategory: 'supplementCategory',
	placeSend: 'placeSend',
	variable: 'variable',
	priceCategory: 'priceCategory',
	productMenu: 'productMenu',
	menuLevel: 'menuLevel',
	menu: 'menu',
	deploy: 'deploy',
};

export const entityRelationToRefetch = {
	product: {
		[fetchMethod.POST]: [entity.product, entity.tax],
		[fetchMethod.PATCH]: [entity.product, entity.tax],
		[fetchMethod.DELETE]: [entity.product, entity.tax],
		[fetchMethod.PUT]: [entity.product, entity.tax],
	},
	category: {
		[fetchMethod.POST]: [entity.category, entity.product],
		[fetchMethod.PATCH]: [entity.category, entity.product],
		[fetchMethod.DELETE]: [entity.category, entity.product],
		[fetchMethod.PUT]: [entity.category, entity.product],
	},
	exclusion: ['exclusion'],
	priceCategory: ['priceCategory', 'product'],
	productMenu: ['productMenu'],
	supplement: ['supplement', 'product'],
	supplementCategory: ['supplementCategory', 'product'],
};

/**
 *
 * @param {Array} colors
 * @param {string} colorName
 */
export const colorNameToId = (colors, colorName) => {
	const targetedColor = colors.filter(
		color => color.name.toLowerCase() === colorName.toLowerCase()
	);
	const colorId = targetedColor.length > 0 ? targetedColor[0].id : colors[0].id;
	return colorId;
};

export const getMenuLevelsDiff = (
	initialmenuLevels,
	receivedMenuLevels,
	id_product_menu
) => {
	const deletedMenuLevel = initialmenuLevels.reduce((acc, initialMenuLevel) => {
		const identicalMenuLevel = _find(
			receivedMenuLevels,
			updatedMenuLevel => updatedMenuLevel.id === initialMenuLevel.id
		);
		if (identicalMenuLevel === undefined) {
			acc.push({
				id_product_menu: id_product_menu,
				id: initialMenuLevel.id,
			});
		}
		return acc;
	}, []);

	const createdMenuLevel = receivedMenuLevels.reduce(
		(acc, updatedMenuLevel) => {
			const identicalMenuLevel = _find(
				initialmenuLevels,
				initialMenuLevel => initialMenuLevel.id === updatedMenuLevel.id
			);
			if (identicalMenuLevel === undefined) {
				acc.push(updatedMenuLevel);
			}

			return acc;
		},
		[]
	);

	const updatedMenuLevel = receivedMenuLevels.reduce(
		(acc, updatedMenuLevel) => {
			const targetedInitialMenuLevel = _find(
				initialmenuLevels,
				initialMenuLevel => initialMenuLevel.id === updatedMenuLevel.id
			);
			if (targetedInitialMenuLevel !== undefined) {
				const diff = getDifferences(targetedInitialMenuLevel, updatedMenuLevel);
				if (
					_size(diff) > 0 ||
					!_isEqual(
						targetedInitialMenuLevel.products,
						updatedMenuLevel.products
					)
				) {
					acc.push({
						id: targetedInitialMenuLevel.id,
						id_product_menu: id_product_menu,
						diff: diff,
						products: updatedMenuLevel.products,
					});
				}
			}

			return acc;
		},
		[]
	);

	return {
		created: createdMenuLevel,
		deleted: deletedMenuLevel,
		updated: updatedMenuLevel,
	};
};
/**
 * check if price is good format
 * @param {*String} price
 */
export const checkPrice = price => {
	let copyPrice = price.toString().replace(/,/i, '.');
	copyPrice = copyPrice.replace(/[^.\d]/g, '');
	const checkPrice = _toNumber(copyPrice);

	if (checkPrice !== NaN) {
		return checkPrice.toFixed(2);
	} else {
		return checkPrice;
	}
};

export const showInvalid = invalidFields => {
	for (let i = 0, length = invalidFields.length; i < length; i++) {
		let field = document.getElementsByClassName(
			`${invalidFields[i]}-container`
		);

		if (field && field[0]) {
			field[0].classList.add('invalid-fields');
		}
	}
};

export const deleteInvalidClass = fieldToRemove => {
	const field = document.getElementsByClassName(`${fieldToRemove}-container`);
	field[0].classList.remove('invalid-fields');
};

export const sendUnloadBeacon = () => {
	let headers = {
		type: 'text/plain; charset=UTF-8',
		'access-token': window.localStorage.access_token,
	};

	let body = {
		guid: window.sessionStorage.guid,
		'access-token': window.localStorage.access_token,
		master_mono_child: window.sessionStorage.master_mono_child, // in case of master mono utilisation
	};
	let blob = new Blob([JSON.stringify(body)], headers);
	let beaconUrl;
	if (window.location && window.location.hostname !== 'localhost') {
		beaconUrl = `https://${window.location.host}/api`;
	} else {
		beaconUrl = `http://${window.location.hostname}:4000/api`;
	}
	window.navigator.sendBeacon(beaconUrl + '/variableServer/unload', blob);
};

export const haveSkuRequierments = (sku, check_sku, check_sku_int) => {
	let canCreate = true;
	if (
		(check_sku && check_sku == '1') ||
		(check_sku_int && check_sku_int == '1')
	) {
		if (sku) {
			if (check_sku_int == '1') {
				const sp = sku.split('');
				canCreate = sp.every(x => !!parseInt(x));
			} else if (check_sku == '1') {
				canCreate = !!sku.trim() != '';
			}
		} else {
			canCreate = false;
		}
	}
	return !canCreate;
};
