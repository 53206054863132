export const RECEIVE_PRODUCT_MENU = 'RECEIVE_PRODUCT_MENU';
export const FORMAT_PRODUCT_MENU = 'FORMAT_PRODUCT_MENU';
export const SWITCH_SORT_PRODUCT_MENU = 'SWITCH_SORT_PRODUCT_MENU';
export const SEND_PRODUCT_MENU = 'SEND_PRODUCT_MENU';
export const PRODUCT_MENU_SENT = 'PRODUCT_MENU_SENT';
export const REMOVE_PRODUCT_MENU = 'REMOVE_PRODUCT_MENU';
export const UPDATE_PRODUCT_MENU = 'UPDATE_PRODUCT_MENU';
export const PRE_UPDATE_PRODUCT_MENU = 'PRE_UPDATE_PRODUCT_MENU';
export const CREATE_PRODUCT_MENU = 'CREATE_PRODUCT_MENU';
export const PRE_CREATE_PRODUCT_MENU = 'PRE_CREATE_PRODUCT_MENU';
export const DELETE_PRODUCT_MENU = 'DELETE_PRODUCT_MENU';
export const PRE_DELETE_PRODUCT_MENU = 'PRE_DELETE_PRODUCT_MENU';
export const PRE_REWEIGHT_PRODUCT_MENU = 'PRE_REWEIGHT_PRODUCT_MENU';
export const PRE_REQUEST_REWEIGHT_PRODUCT_MENU =
	'PRE_REQUEST_REWEIGHT_PRODUCT_MENU';
export const REQUESTS_REWEIGHT_PRODUCT_MENU_FINISHED =
	'REQUESTS_REWEIGHT_PRODUCT_MENU_FINISHED';
export const REWEIGHT_PRODUCT_MENU = 'REWEIGHT_PRODUCT_MENU';
