import { HELPER_TABS, SET_OPEN, SET_CURRENT_TAB } from '../constants/helper';

const initialState = {
	open: false,
	currentTab: HELPER_TABS.QUICKSTART,
};

const reducer = function helperReducer(state = initialState, action) {
	switch (action.type) {
		case SET_OPEN:
			state.open = !!action.value;
			return state;
		case SET_CURRENT_TAB:
			for (let tab in HELPER_TABS) {
				if (HELPER_TABS[tab] === action.value) {
					state.currentTab = action.value;
				}
			}
			return state;
		default:
			return state;
	}
};

export default reducer;
