import { createStore, applyMiddleware, compose } from 'redux';
//import createLogger from 'redux-logger'
import { syncTranslationWithStore } from 'react-redux-i18n';
import reducer from './reducers';
import createSagaMiddleware from 'redux-saga';
import IndexSagas from './saga';
import { routerMiddleware } from 'react-router-redux';
import { browserHistory } from 'react-router';

// const loggerMiddleware = createLogger();
export const initialState = {
	globalState: {
		network: false,
		rejected: false,
		snack: {
			show: false,
			message: '',
			type: '',
			close: null,
		},
		dialog: {
			title: '',
			actions: [],
			modal: false,
			show: false,
			close: null,
			content: '',
		},
		auth: {
			user: null,
			forbidden: false,
		},
		loaded: [],
		tutorial: {
			show: false,
			steps: [],
			callback: null,
		},
	},
	menuLevel: {
		level: [],
		product: [],
	},
	product: {
		data: [],
		loading: false,
	},
	category: {
		filter: null,
		data: [],
	},
	tax: [],
	supplement: [],
	supplementCategory: [],
	productType: [],
	color: [],
	placeSend: [],
	variable: [],
	floor: [],
	menu: [],
	priceCategory: [],
	retailPriceType: [],
};

export default function configureStore() {
	// const composeEnhancers =
	//   (process.env.NODE_ENV !== "production"
	//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	//     : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
	const composeEnhancers =
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const sagaMiddleware = createSagaMiddleware();
	const middleware = routerMiddleware(browserHistory);
	let middlewares = [sagaMiddleware, middleware];

	if (process.env.NODE_ENV !== 'production') {
		//const { logger } = require(`redux-logger`);
		//middlewares.push(logger);
		middlewares = [...middlewares];
	}

	const store = createStore(
		reducer,
		composeEnhancers(applyMiddleware(...middlewares))
	);

	syncTranslationWithStore(store);

	sagaMiddleware.run(IndexSagas);

	return store;
}
