import {
	CREATE_SUPPLEMENT,
	RECEIVE_SUPPLEMENT,
	REFRESH_SUPPLEMENT_LIST,
	REWEIGHT_SUPPLEMENT,
	UPDATE_SUPPLEMENT,
	UPDATE_SUPPLEMENT_LIST,
} from '../constants/supplement';

import findIndex from 'lodash/findIndex';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';

const initialState = [];

const reducer = function supplementReducer(state = initialState, action) {
	const newState = [...state];
	const { ids } = action;
	switch (action.type) {
		case CREATE_SUPPLEMENT:
		case REWEIGHT_SUPPLEMENT:
		case RECEIVE_SUPPLEMENT:
			// if (action.response.result.length === 0) {
			//   return newState;
			// }
			const supplements = action.response.result;
			return supplements;

		case UPDATE_SUPPLEMENT:
			_forEach(ids, (id, key) => {
				const elementToUpdateIndex = findIndex(newState, p => p.id === id);
				const elementToUpdate = newState[elementToUpdateIndex];
				const updatedElement = { ...elementToUpdate };
				_forEach(action.newValues[key], (value, key) => {
					updatedElement[key] = value;
				});
				newState[elementToUpdateIndex] = updatedElement;
			});
			return newState;

		case REFRESH_SUPPLEMENT_LIST:
			const { newList } = action;

			return newList;
		/*
    case CREATE_SUPPLEMENT:
      const { newSupplements } = action;

      return [...newState, ...newSupplements];
*/
		case UPDATE_SUPPLEMENT_LIST:
			const { elements } = action;
			return newState.map(supp => {
				const element = _find(elements, e => e.id === supp.id);
				if (element) {
					return element;
				} else {
					return supp;
				}
			});

		default:
			return state;
	}
};

export default reducer;
