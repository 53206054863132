import React from 'react';
import { styles } from './styles';
import { Translate } from 'react-redux-i18n';

const styleInterac = {
	buttonNext: {
		display: 'none',
	},
	buttonSkip: {
		display: 'none',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const stylesForTooltipBody = {
	buttonBack: {
		display: 'none',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const styleBis = {
	buttonClose: {
		fontSize: '16px',
	},
	buttonSkip: {
		fontSize: '16px',
	},
	buttonBack: {
		fontSize: '16px',
	},
	buttonNext: {
		fontSize: '16px',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

export const stepsTax = [
	{
		content: <Translate value="tutorial.tax.step1.content" />,
		placement: 'center',
		disableBeacon: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.noDemo" />,
			close: <Translate value="tutorial.yesDemo" />,
		},
		target: 'body',
		title: <Translate value="tutorial.tax.step1.title" />,
	},
	{
		content: <Translate value="tutorial.tax.step2.content" />,
		placement: 'right',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.container_left_tax',
		title: <Translate value="tutorial.tax.step2.title" />,
	},
	{
		content: <Translate value="tutorial.tax.step3.content" />,
		placement: 'left',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.tax-denomination',
		title: <Translate value="tutorial.tax.step3.title" />,
	},
	{
		content: <Translate value="tutorial.tax.step4.content" />,
		placement: 'left',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.tax-rates',
		title: <Translate value="tutorial.tax.step4.title" />,
	},
	{
		content: <Translate value="tutorial.tax.step5.content" />,
		placement: 'left',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.tas_isdefault',
		title: <Translate value="tutorial.tax.step5.title" />,
	},
	{
		content: <Translate value="tutorial.tax.step6.content" />,
		placement: 'top',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleInterac,
		target: '.button_save_tax',
		title: <Translate value="tutorial.tax.step6.title" />,
	},
	{
		content: <Translate value="tutorial.tax.step7.content" />,
		placement: 'bottom',
		disableBeacon: true,
		locale: {
			close: <Translate value="tutorial.tax.endDemoTax" />,
		},
		styles: stylesForTooltipBody,
		target: '.add_tax',
		title: <Translate value="tutorial.tax.step7.title" />,
	},
];
