export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT';
export const FORMAT_PRODUCT = 'FORMAT_PRODUCT';
export const SWITCH_SORT_PRODUCT = 'SWITCH_SORT_PRODUCT';
export const SEND_PRODUCT = 'SEND_PRODUCT';
export const PRODUCT_SENT = 'PRODUCT_SENT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const PRE_UPDATE_PRODUCT = 'PRE_UPDATE_PRODUCT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const PRE_CREATE_PRODUCT = 'PRE_CREATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const PRE_DELETE_PRODUCT = 'PRE_DELETE_PRODUCT';
export const PRE_REWEIGHT_PRODUCT = 'PRE_REWEIGHT_PRODUCT';
export const PRE_REQUEST_REWEIGHT_PRODUCT = 'PRE_REQUEST_REWEIGHT_PRODUCT';
export const REQUESTS_REWEIGHT_PRODUCT_FINISHED =
	'REQUESTS_REWEIGHT_PRODUCT_FINISHED';
export const REWEIGHT_PRODUCT = 'REWEIGHT_PRODUCT';
export const UNDO_PRODUCT = 'UNDO_PRODUCT';
export const REDO_PRODUCT = 'REDO_PRODUCT';
export const PRE_ASSIGN_AND_REWEIGHT_PRODUCT =
	'PRE_ASSIGN_AND_REWEIGHT_PRODUCT';
export const PRE_IMPORT_PRODUCTS = 'PRE_IMPORT_PRODUCTS';
export const IMPORT_PRODUCTS = 'IMPORT_PRODUCTS';
export const UPDATE_PRODUCT_WITHOUT_FETCH = 'UPDATE_PRODUCT_WITHOUT_FETCH';
