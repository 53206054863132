import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import { browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import Route from './route';
import getMuiTheme from 'material-ui-old/styles/getMuiTheme';
import blue from '@material-ui/core/colors/blue';
//import 'handsontable.css';
import * as serviceWorker from './serviceWorker';
import * as initFastClick from 'react-fastclick';
import {
	MuiThemeProvider as NewMuiThemeProvider,
	createMuiTheme,
} from '@material-ui/core/styles';
import MuiThemeProvider from 'material-ui-old/styles/MuiThemeProvider';
import './sass/screen.scss';
import './sass/materialOveride.scss';
import getAllUrlParams from './getUrlParams.js';

initFastClick();

const clear = getAllUrlParams('clear');
if (clear) {
	if (window.localStorage.cacheCleared) {
		window.localStorage.clear();
		//browserHistory.push("/");
	} else {
		window.localStorage.clear();
		window.localStorage.cacheCleared = true;
		document.location.reload(true);
	}
}

const muiTheme = getMuiTheme({
	palette: {
		primary1Color: blue['600'],
		pickerHeaderColor: blue['600'],
	},
});

function uuidv4() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

let guid = window.sessionStorage.getItem('guid');
if (!guid) {
	window.sessionStorage.setItem('guid', uuidv4());
}

const themeV1 = createMuiTheme({
	palette: {
		type: 'light',
		action: {
			selected: blue['600'],
		},
		primary: {
			// light: will be calculated from palette.primary.main,
			light: '#e84249',
			main: '#e84249',
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contast with palette.primary.main
		},
		secondary: {
			light: '#e84249',
			main: '#e84249',
		},
		other: {
			light: '#434892',
			main: '#262957',
		},
	},
	overrides: {
		MuiButton: {
			// Name of the component ⚛️ / style sheet
			root: {
				// Name of the rule
				borderRadius: 30,
			},
		},
	},
});

const store = configureStore(),
	history = syncHistoryWithStore(browserHistory, store);

render(
	<NewMuiThemeProvider theme={themeV1}>
		<MuiThemeProvider muiTheme={muiTheme}>
			<Provider store={store}>
				<Route history={history} />
			</Provider>
		</MuiThemeProvider>
	</NewMuiThemeProvider>,
	document.getElementById('root')
);
serviceWorker.unregister();
