import fr from './fr-FR';
import en from './en-US';
import es from './es-ES';
import ca from './ca-ES';

export default {
	fr,
	en,
	es,
	ca,
};
