import { put, select, take, call } from 'redux-saga/effects';
import langs from '../lang';
import { RECEIVE_VARIABLE_SERVER } from '../constants/variableServer';
import { getServiceLang } from '../selectors/global.selector';

export const ENUM_LANG = {
	'fr-FR': 'fr',
	'es-ES': 'es',
	'ca-ES': 'ca',
};

export default function* i18nFlowSaga() {
	let lang = 'fr-FR';
	yield call(setLang, lang);

	while (true) {
		yield take(RECEIVE_VARIABLE_SERVER);
		const store = yield select();
		const service_lang = getServiceLang(store.entity.variableServer);

		const langsArr = Object.keys(langs);
		if (
			ENUM_LANG.hasOwnProperty(service_lang) &&
			langsArr.includes(ENUM_LANG[service_lang])
		) {
			lang = ENUM_LANG[service_lang];
		}
		yield call(setLang, lang);
	}
}

function* setLang(lang) {
	yield put({ type: 'loadTranslation', translations: langs });
	yield put({ type: 'setLocal', locale: lang });
}
