import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import _find from 'lodash/find';

class SelectMultiple extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			values: props.values || [''],
			elements: props.elements,
		};

		this.valueRenderer = this.valueRenderer.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.values !== this.props.values) {
			this.setState({ values: nextProps.values });
		}
		if (nextProps.elements !== this.props.elements) {
			this.setState({ elements: nextProps.elements });
		}
		this.setState({ open: nextProps.isOpen });
	}

	SelectOptions(elements) {
		return elements && elements.length > 0
			? elements.map(element => {
					return (
						<MenuItem
							key={element.id || element.value}
							value={element.id || element.value}
							classes={{ selected: 'select__multiple__selected' }}>
							<Checkbox
								checked={
									this.state.values.indexOf(element.id || element.value) > -1
								}
							/>
							<ListItemText primary={element.name || element.text} />
						</MenuItem>
					);
			  })
			: [];
	}

	valueRenderer() {
		if (this.props.hideInput) {
			return '';
		}
		const selectedValuesText = this.state.values.map(id => {
			const relatedElement = _find(
				this.state.elements,
				element => element.id === id || element.value === id
			);
			if (relatedElement && relatedElement.name) {
				return relatedElement.name;
			}
			if (relatedElement && relatedElement.text) {
				return relatedElement.text;
			}
			return '';
		});

		return selectedValuesText.join(', ');
	}

	handleClick(e) {
		e.preventDefault();
		if (!this.state.open) {
			this.props.handleMultipleOpen(true);
		}
	}

	render() {
		const { elements, values } = this.state;
		const {
			onSelectChange,
			onSelectClose,
			selectStyle,
			iconClass,
			selectClassname,
		} = this.props;
		const specialOption = {};

		if (selectStyle) {
			specialOption.style = selectStyle;
		}
		if (iconClass) {
			specialOption.classes = iconClass;
		}
		if (onSelectClose) {
			specialOption.onClose = ev => onSelectClose(this.state.values);
			specialOption.open = this.state.open;
			specialOption.onOpen = e => e;
			specialOption.onClick = this.handleClick;
		}

		return (
			<Select
				style={{ maxHeight: 150, maxWidth: 250 }}
				value={values}
				onChange={ev => {
					onSelectChange(ev.target.value);
				}}
				className={
					selectClassname ? selectClassname + ' form-select' : 'form-select'
				}
				inputProps={{ id: 'select-category' }}
				multiple={true}
				input={<Input id="select-multiple-checkbox" />}
				renderValue={this.valueRenderer}
				{...specialOption}>
				{this.SelectOptions(elements)}
			</Select>
		);
	}
}

SelectMultiple.propTypes = {
	elements: PropTypes.array,
	values: PropTypes.array,
	onSelectChange: PropTypes.func,
	selectStyle: PropTypes.object,
	iconClass: PropTypes.object,
	onClose: PropTypes.func,
};

export default SelectMultiple;
