import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import find from 'lodash/find';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { getColors } from '../selectors/global.selector';

class ProductPreview extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: props.name || '',
			price: props.price || this.formatValue(0),
			prices: [],
			backgroundColor: this.findColor(props.backgroundColor) || '#292929',
		};

		this.findColor = this.findColor.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.hasOwnProperty('name'))
			this.setState({ name: this.formatText(nextProps.name) });

		if (nextProps.hasOwnProperty('price')) {
			this.setState({ price: nextProps.price, prices: nextProps.prices });
		}

		if (
			nextProps.hasOwnProperty('backgroundColor') &&
			nextProps.hasOwnProperty('colors')
		) {
			this.setState({
				backgroundColor: this.findColor(nextProps.backgroundColor),
			});
		}
	}

	formatValue(price) {
		if (this.props.free_price) {
			return I18n.t('product.free_price');
		} else if (isNaN(price) || price === '') {
			return '0.00';
		}
		return parseFloat(price).toFixed(2);
	}

	formatText(text) {
		if (text && text.length > 50) {
			return text.slice(0, 49) + '...';
		} else {
			return text;
		}
	}

	findColor(id) {
		let color = find(this.props.colors, c => {
			return c.id === id;
		});
		return color ? color.color : '#292929';
	}

	render() {
		const { backgroundColor, price, name } = this.state;
		const backgroundColorOveride = this.props.disabled ? '#eeeeee' : null;

		const retailPriceType = this.props.retailPriceTypes.find(
			r => r.id === this.props.idRetailPriceType
		);
		const labelRetailPrice = retailPriceType
			? '/' + I18n.t(retailPriceType.unit)
			: '';

		let classes = ['product-preview'];
		if (this.props.disabled) classes.push('product-preview-disabled');

		return (
			<Grid
				className={classes.join(' ')}
				style={{ backgroundColor: backgroundColorOveride || backgroundColor }}
				onClick={this.props.onClick}>
				<Row className={'product-preview-name'} middle={'xs'}>
					<Col xs={12}>
						<span
							style={{ overflowWrap: 'break-word', textOverflow: 'ellipsis' }}>
							{' '}
							{name || I18n.t('product.form.no_name')}{' '}
						</span>
					</Col>
				</Row>
				<div className={'product-preview-price'}>
					{this.formatValue(price)}
					{labelRetailPrice}
				</div>
			</Grid>
		);
	}
}

ProductPreview.propTypes = {
	name: PropTypes.string,
	price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	prices: PropTypes.array,
	retailPriceTypes: PropTypes.array,
	idRetailPriceType: PropTypes.string,
	backgroundColor: PropTypes.string,
	free_price: PropTypes.bool,
	onClick: PropTypes.func,
};

ProductPreview.defaultProps = {
	prices: [],
	idRetailPriceType: '',
	retailPriceTypes: [],
};

const mapStateToProps = state => {
	const colors = getColors(state);

	return {
		colors: colors,
		retailPriceTypes: state.entity.retailPriceType,
	};
};

export default connect(mapStateToProps)(ProductPreview);
