import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class ColorPanel extends Component {
	constructor(props) {
		super(props);

		const { color, selectedData } = this.props;

		let selected = color.filter(c => {
			return c.id == selectedData;
		})[0];

		this.state = {
			selected: selected,
		};

		this.onClick = this.onClick.bind(this);
	}

	onClick(c) {
		const { onSelectValue } = this.props;

		this.setState({
			selected: c,
		});

		onSelectValue(c);
	}

	componentWillReceiveProps(nextProps) {
		const { selectedData, color } = nextProps;

		let selected = color.filter(c => {
			return c.id == selectedData;
		})[0];

		this.setState({
			selected: selected,
		});
	}

	render() {
		const { color } = this.props;

		const panel = color.map((c, index) => {
			let style = { backgroundColor: c.color },
				classes = classnames('color-block', {
					selected: this.state.selected
						? this.state.selected.id == c.id
						: index == 0,
				});

			return (
				<span
					key={c.id}
					style={style}
					className={classes}
					onClick={() => {
						this.onClick(c);
					}}
				/>
			);
		});

		return <div className={'color-panel'}>{panel}</div>;
	}
}

ColorPanel.propTypes = {
	color: PropTypes.array.isRequired,
	selectedData: PropTypes.string,
	onSelectValue: PropTypes.func.isRequired,
};
