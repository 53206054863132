import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../SvgIcon';

const EditCell = React.memo(({ value, onEditClick, disabled }) => {
	return (
		<div className="action-button">
			<button
				className={`edit ${disabled ? 'remove-disabled' : ''}`}
				onClick={ev => {
					return onEditClick(value);
				}}
				disabled={disabled}>
				<SvgIcon icon="editer" />
			</button>
		</div>
	);
});

EditCell.propTypes = {
	row: PropTypes.number,
	td: PropTypes.string,
	col: PropTypes.number,
	prop: PropTypes.string,
	value: PropTypes.any,
	onEditClick: PropTypes.func,
};

export default EditCell;
