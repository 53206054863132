import React from 'react';
import { styles } from './styles';
import { Translate } from 'react-redux-i18n';

const stylesInterac = {
	buttonNext: {
		display: 'none',
	},
	buttonSkip: {
		display: 'none',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const stylesForTooltipBody = {
	buttonBack: {
		display: 'none',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const styleBis = {
	buttonClose: {
		fontSize: '16px',
	},
	buttonSkip: {
		fontSize: '16px',
	},
	buttonBack: {
		fontSize: '16px',
	},
	buttonNext: {
		fontSize: '16px',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

export const stepsProductMenus = [
	{
		content: <Translate value="tutorial.productMenu.step1.content" />,
		placement: 'center',
		disableBeacon: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.noDemo" />,
			close: <Translate value="tutorial.yesDemo" />,
		},
		target: 'body',
		title: <Translate value="tutorial.productMenu.step1.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step2.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: stylesInterac,
		target: '.product_menus_button_header',
		title: <Translate value="tutorial.productMenu.step2.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step3.content" />,
		placement: 'center',
		disableBeacon: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: stylesForTooltipBody,
		target: 'body',
		title: <Translate value="tutorial.productMenu.step3.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step4.content" />,
		placement: 'top',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.title_denomination_product_menu1',
		title: <Translate value="tutorial.productMenu.step4.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step5.content" />,
		placement: 'top',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.color_panel_product_menu1',
		title: <Translate value="tutorial.productMenu.step5.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step6.content" />,
		placement: 'top',
		styles: stylesInterac,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		spotlightClicks: true,
		target: '.button_next_product_menu1',
		title: <Translate value="tutorial.productMenu.step6.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step7.content" />,
		placement: 'top',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.exclusion_product_menu2',
		title: <Translate value="tutorial.productMenu.step7.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step8.content" />,
		placement: 'left',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.price_product_menu2',
		title: <Translate value="tutorial.productMenu.step8.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step9.content" />,
		placement: 'top',
		styles: stylesInterac,
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.button_next_product_menu1',
		title: <Translate value="tutorial.productMenu.step9.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step10.content" />,
		placement: 'center',
		disableBeacon: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: stylesForTooltipBody,
		target: 'body',
		title: <Translate value="tutorial.productMenu.step10.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step11.content" />,
		placement: 'bottom',
		styles: stylesInterac,
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.add_level_menu3',
		title: <Translate value="tutorial.productMenu.step11.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step12.content" />,
		placement: 'center',
		disableBeacon: true,
		styles: stylesForTooltipBody,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: 'body',
		title: <Translate value="tutorial.productMenu.step12.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step13.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.name_level_menu3',
		title: <Translate value="tutorial.productMenu.step13.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step14.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.options_level_menu3',
		title: <Translate value="tutorial.productMenu.step14.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step15.content" />,
		placement: 'top',
		spotlightClicks: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.image-container',
		title: <Translate value="tutorial.productMenu.step15.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step16.content" />,
		placement: 'left',
		styles: stylesInterac,
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.button_save_options_level_menu3',
		title: <Translate value="tutorial.productMenu.step16.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step17.content" />,
		placement: 'center',
		disableBeacon: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: stylesForTooltipBody,
		target: 'body',
		title: <Translate value="tutorial.productMenu.step17.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step18.content" />,
		placement: 'right',
		styles: stylesInterac,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		spotlightClicks: true,
		target: '.entrance_level_menu3',
		title: <Translate value="tutorial.productMenu.step18.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step19.content" />,
		placement: 'right',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.list_menu_left_menu3',
		title: <Translate value="tutorial.productMenu.step19.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step20.content" />,
		placement: 'left',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.list_menu_right_menu3',
		title: <Translate value="tutorial.productMenu.step20.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step21.content" />,
		placement: 'bottom',
		styles: stylesInterac,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		spotlightClicks: true,
		target: '.button_save_menu3',
		title: <Translate value="tutorial.productMenu.step21.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step22.content" />,
		placement: 'center',
		disableBeacon: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: stylesForTooltipBody,
		target: 'body',
		title: <Translate value="tutorial.productMenu.step22.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step23.content" />,
		placement: 'right',
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.stylizedItem > span > div:first-child',
		title: <Translate value="tutorial.productMenu.step23.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step24.content" />,
		placement: 'top',
		placementBeacon: 'left',
		styles: stylesInterac,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		spotlightClicks: true,
		target: '.button_next_product_menu1',
		title: <Translate value="tutorial.productMenu.step24.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step25.content" />,
		placement: 'center',
		disableBeacon: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: stylesForTooltipBody,
		target: 'body',
		title: <Translate value="tutorial.productMenu.step25.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step26.content" />,
		placement: 'top',
		spotlightClicks: true,
		styles: stylesInterac,
		target: '.button_next_product_menu1',
		title: <Translate value="tutorial.productMenu.step26.title" />,
	},
	{
		content: <Translate value="tutorial.productMenu.step27.content" />,
		placement: 'center',
		disableBeacon: true,
		spotlightClicks: true,
		locale: {
			close: <Translate value="tutorial.productMenu.endDemoProductMenu" />,
		},
		styles: stylesForTooltipBody,
		target: 'body',
		title: <Translate value="tutorial.productMenu.step27.title" />,
	},
];
