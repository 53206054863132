import React from 'react';
import logo from '../img/logo-text-white.png';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const LadditionLoader = React.memo(function LadditionLoader({ hidden }) {
	return (
		<div
			className={classNames('laddition-loader-container', {
				'laddition-loader-container__hidden': hidden,
			})}>
			<div className="pulse">
				<img alt={"loader L'addition"} src={logo} width={150} />
			</div>
		</div>
	);
});

LadditionLoader.propTypes = {
	hidden: PropTypes.bool,
};
LadditionLoader.defaultProps = {
	hidden: true,
};

export default LadditionLoader;
