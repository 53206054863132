import React, { Component } from 'react';
import SvgIcon from '../../SvgIcon';

class DeleteCell extends Component {
	render() {
		const { value, onDeleteClick, disabled, creationInProgress } = this.props;
		return (
			<button
				className={`remove  ${disabled ? 'remove-disabled' : ''}`}
				onClick={ev => {
					if (creationInProgress) return null;
					return onDeleteClick(value);
				}}
				disabled={disabled}>
				<SvgIcon icon="close" />
			</button>
		);
	}
}

export default DeleteCell;
