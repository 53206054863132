import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Translate } from 'react-redux-i18n';
import { sort, deepClone, deepEqual } from '../utils';

class CategoryList extends Component {
	constructor(props) {
		super(props);

		this.onCategoryClick = this.onCategoryClick.bind(this);
	}

	onCategoryClick(catId) {
		this.props.changeProductCategoryView(catId);
	}

	render() {
		const { category, color, product, selectedCategory } = this.props;
		let catClick = [];

		if (color.length == 0) {
			return null;
		}

		let catList = sort(category.categories);
		let p = product.filter(p => {
			return p != null;
		});

		if (category.categories.length > 0) {
			catList = catList.map((cat, i) => {
				catClick = () => {
					this.onCategoryClick(cat.id);
				};

				let classes = classnames('category-box', {
					selected: selectedCategory == cat.id,
				});

				let selectedColor = color.filter(c => {
					return c.id == cat.id_color;
				})[0];

				let style = {
					borderColor: selectedColor ? selectedColor.color : color[0].color,
				};

				let categoryzedProduct = product.filter(p => {
					return p && p.categories.indexOf(cat.id) !== -1;
				});

				return (
					<div key={i} onClick={catClick} className={classes} style={style}>
						<span
							className={
								'text'
							}>{`${cat.name} (${categoryzedProduct.length})`}</span>
					</div>
				);
			});

			let classes = classnames('category-header', {
				selected: selectedCategory === undefined,
			});

			return (
				<div className={'category-list'} id={'category-list'}>
					<div
						className={classes}
						style={{ borderColor: color[0].color }}
						onClick={() => this.onCategoryClick()}>
						<Translate value="category.all" />
						<span>
							&nbsp;
							{`(${p.length})`}
						</span>
					</div>
					{catList}
				</div>
			);
		} else {
			return (
				<div className={'category-list'} id={'category-list'}>
					<div
						className={'category-box selected'}
						style={{ borderColor: color[0].color }}
						onClick={() => this.onCategoryClick()}>
						<Translate value="category.all" />
						<span>
							&nbsp;
							{`(${p.length})`}
						</span>
					</div>
				</div>
			);
		}
	}
}

CategoryList.propTypes = {
	category: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
	const { category, color, product } = state.entity;

	return {
		category,
		color,
		product: deepClone(product.products),
	};
};

export default connect(mapStateToProps)(CategoryList);
