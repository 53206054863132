export const RECEIVE_SUPPLEMENT_CATEGORY = 'RECEIVE_SUPPLEMENT_CATEGORY';
export const PRE_DELETE_SUPPLEMENT_CATEGORY = 'PRE_DELETE_SUPPLEMENT_CATEGORY';
export const DELETE_SUPPLEMENT_CATEGORY = 'DELETE_SUPPLEMENT_CATEGORY';
export const PRE_PATCH_SUPPLEMENT_CATEGORY = 'PRE_PATCH_SUPPLEMENT_CATEGORY';
export const PATCH_SUPPLEMENT_CATEGORY = 'PATCH_SUPPLEMENT_CATEGORY';
export const PRE_CREATE_SUPPLEMENT_CATEGORY = 'PRE_CREATE_SUPPLEMENT_CATEGORY';
export const CREATE_SUPPLEMENT_CATEGORY = 'CREATE_SUPPLEMENT_CATEGORY';
export const PRE_UPDATE_SUPPLEMENT_CATEGORY = 'PRE_UPDATE_SUPPLEMENT_CATEGORY';
export const UPDATE_SUPPLEMENT_CATEGORY = 'UPDATE_SUPPLEMENT_CATEGORY';
export const REFRESH_SUPPLEMENT_CATEGORIES_LIST =
	'REFRESH_SUPPLEMENT_CATEGORIES_LIST';
export const PRE_REWEIGHT_SUPPLEMENT_CATEGORY =
	'PRE_REWEIGHT_SUPPLEMENT_CATEGORY';
export const REWEIGHT_SUPPLEMENT_CATEGORY = 'REWEIGHT_SUPPLEMENT_CATEGORY';
export const PRE_UPDATE_SUPPLEMENT_CATEGORY_PRODUCTS =
	'PRE_UPDATE_SUPPLEMENT_CATEGORY_PRODUCTS';
export const UPDATE_SUPPLEMENT_CATEGORY_PRODUCTS =
	'UPDATE_SUPPLEMENT_CATEGORY_PRODUCTS';
export const PRE_ASSIGN_PRODUCTS = 'PRE_ASSIGN_PRODUCTS';
export const ASSIGN_PRODUCTS = 'ASSIGN_PRODUCTS';
