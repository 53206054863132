import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SvgIcon extends Component {
	render() {
		const { icon, onClick } = this.props;
		let { classes = [] } = this.props;

		classes.push('svg-icon');
		classes.push(`icon-${icon}`);

		if (icon === 'plus') {
			return (
				<span
					style={{ color: 'white' }}
					className={classes.join(' ')}
					onClick={onClick}
				/>
			);
		}
		return <span className={classes.join(' ')} onClick={onClick} />;
	}
}

SvgIcon.propTypes = {
	icon: PropTypes.string.isRequired,
	classes: PropTypes.array,
	onClick: PropTypes.func,
};
