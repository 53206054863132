import {
	PRE_DELETE_SUPPLEMENT,
	PRE_CREATE_SUPPLEMENT,
	PRE_PATCH_SUPPLEMENT,
	PRE_UPDATE_SUPPLEMENT,
	DELETE_SUPPLEMENT,
	CREATE_SUPPLEMENT,
	UPDATE_SUPPLEMENT,
	PATCH_SUPPLEMENT,
	PRE_REWEIGHT_SUPPLEMENT,
	REWEIGHT_SUPPLEMENT,
	RECEIVE_SUPPLEMENT,
} from '../constants/supplement';
import { buffers, channel } from 'redux-saga';
import { call, put, take, actionChannel, select } from 'redux-saga/effects';
import { requestHandler } from './fetcher.saga';
import { ENTITY_EXCLUSION, ENTITY_PRICE_CATEGORY } from '../utils';
import { getMenuSelected } from './login';

const PRE_REQUEST_DELETE_SUPPLEMENT = 'PRE_REQUEST_DELETE_SUPPLEMENT';
const PRE_REQUEST_PATCH_SUPPLEMENT = 'PRE_REQUEST_PATCH_SUPPLEMENT';
const PRE_REQUEST_CREATE_SUPPLEMENT = 'PRE_REQUEST_CREATE_SUPPLEMENT';
const PRE_REQUEST_UPDATE_SUPPLEMENT = 'PRE_REQUEST_UPDATE_SUPPLEMENT';
const PRE_REQUEST_REWEIGHT_SUPPLEMENT = 'PRE_REQUEST_REWEIGHT_SUPPLEMENT';

const REQUESTS_DELETE_SUPPLEMENT_FINISHED =
	'REQUESTS_DELETE_SUPPLEMENT_FINISHED';
const REQUESTS_PATCH_SUPPLEMENT_FINISHED = 'REQUESTS_PATCH_SUPPLEMENT_FINISHED';
const REQUESTS_CREATE_SUPPLEMENT_FINISHED =
	'REQUESTS_CREATE_SUPPLEMENT_FINISHED';
const REQUESTS_UPDATE_SUPPLEMENT_FINISHED =
	'REQUESTS_UPDATE_SUPPLEMENT_FINISHED';
const REQUESTS_REWEIGHT_SUPPLEMENT_FINISHED =
	'REQUESTS_REWEIGHT_SUPPLEMENT_FINISHED';

const ENTITY = 'supplement';
const DELETE_METHOD = 'DELETE';
const POST_METHOD = 'POST';
const PATCH_METHOD = 'PATCH';

////////////
// DELETE //
////////////
export function* preDeleteSupplement() {
	while (true) {
		const action = yield take(PRE_DELETE_SUPPLEMENT);
		const { ids, id_menu, id_category_supplement } = action;
		let length = ids.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_DELETE_SUPPLEMENT,
				body: {
					id: ids[i],
					id_menu: id_menu,
					id_category_supplement: id_category_supplement,
				},
				index: i,
				totalLength: length,
			});
		}
		yield take(REQUESTS_DELETE_SUPPLEMENT_FINISHED);
		yield put({ type: DELETE_SUPPLEMENT, ids: ids });
	}
}

export function* watchDeleteSupplement() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_DELETE_SUPPLEMENT,
		buffers.expanding()
	);

	while (true) {
		const action = yield take(requestChan);

		yield call(requestHandler, ENTITY, DELETE_METHOD, true, action.body);
		if (action.index === action.totalLength - 1) {
			yield put({ type: REQUESTS_DELETE_SUPPLEMENT_FINISHED });
		}
	}
}

////////////
// PATCH  //
////////////
export function* prePatchSupplement() {
	while (true) {
		const action = yield take(PRE_PATCH_SUPPLEMENT);
		const { data } = action;
		let length = data.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_PATCH_SUPPLEMENT,
				body: { patchs: data[i] },
				index: i,
				totalLength: length,
			});
		}

		yield take(REQUESTS_PATCH_SUPPLEMENT_FINISHED);

		yield put({ type: PATCH_SUPPLEMENT });
	}
}

export function* watchPatchSupplement() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_PATCH_SUPPLEMENT,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);
		const id_menu = yield call(getMenuSelected);

		yield call(requestHandler, ENTITY, PATCH_METHOD, true, {
			...action.body.patchs,
			id_menu,
		});

		if (action.index === action.totalLength - 1) {
			yield put({ type: REQUESTS_PATCH_SUPPLEMENT_FINISHED });
		}
	}
}

////////////
// CREATE //
////////////
export function* preCreateSupplement() {
	while (true) {
		const action = yield take(PRE_CREATE_SUPPLEMENT);
		const { newSupplements } = action;
		let length = newSupplements.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_CREATE_SUPPLEMENT,
				body: { supplement: newSupplements[i] },
				index: i,
				totalLength: length,
			});
		}
		const receivedSupplements = yield take(REQUESTS_CREATE_SUPPLEMENT_FINISHED);
		yield put({
			type: CREATE_SUPPLEMENT,
			response: receivedSupplements.newSupplements.result,
		});
	}
}

export function* watchCreateSupplement() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_CREATE_SUPPLEMENT,
		buffers.expanding()
	);
	let createdSupplements = [];
	while (true) {
		const action = yield take(requestChan);
		const id_menu = yield call(getMenuSelected);
		const result = yield call(requestHandler, ENTITY, POST_METHOD, true, {
			...action.body.supplement,
			id_menu,
		});
		let newSupplement = { ...action.body.supplement };
		newSupplement.id = result.result.result;

		createdSupplements.push(newSupplement);

		if (action.index === action.totalLength - 1) {
			const id_menu = yield call(getMenuSelected);
			const newSupplements = yield call(
				requestHandler,
				'supplement',
				'GET',
				true,
				null,
				'',
				{ id_menu: id_menu }
			);
			yield put({
				type: REQUESTS_CREATE_SUPPLEMENT_FINISHED,
				newSupplements: newSupplements,
			});
			//yield put({ type : REQUESTS_CREATE_SUPPLEMENT_FINISHED, createdSupplements : createdSupplements });
			createdSupplements = [];
		}
	}
}

////////////
// UPDATE //
////////////
export function* preUpdateSupplement() {
	const requestChan = yield actionChannel(
		PRE_UPDATE_SUPPLEMENT,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);
		const { ids, entity } = action;
		const idsLength = ids.length;
		const id_menu = yield call(getMenuSelected);
		for (let i = 0; i < idsLength; i++) {
			let dataToSend;

			if (entity === ENTITY_EXCLUSION) {
				dataToSend = {
					id: ids[i],
					exclusions: action.newValues[i].exclusions,
					id_menu: id_menu,
				};
			} else if (entity === ENTITY_PRICE_CATEGORY) {
				dataToSend = {
					id: ids[i],
					prices: action.newValues[i].prices,
					id_menu: id_menu,
				};
			} else {
				const key = Object.keys(action.newValues[i])[0];
				dataToSend = {
					id: ids[i],
					key: key,
					value: action.newValues[i][key],
					id_menu: id_menu,
				};
			}

			yield put({
				type: PRE_REQUEST_UPDATE_SUPPLEMENT,
				body: dataToSend,
				index: i,
				totalLength: idsLength,
				entity,
			});
		}
		const errored = yield take(REQUESTS_UPDATE_SUPPLEMENT_FINISHED);
		let idsWithoutError = [];
		let newValuesWithoutError = [];

		for (let i = 0; i < idsLength; i++) {
			if (ids[i] === errored.errored[i]) {
				continue;
			} else {
				idsWithoutError.push(ids[i]);
				newValuesWithoutError.push(action.newValues[i]);
			}
		}

		yield put({
			type: UPDATE_SUPPLEMENT,
			ids: idsWithoutError,
			newValues: newValuesWithoutError,
		});
	}
}

export function* watchUpdateSupplement() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_UPDATE_SUPPLEMENT,
		buffers.expanding()
	);
	let errored = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			PATCH_METHOD,
			true,
			action.body,
			action.entity ? '/' + action.entity : ''
		);
		if (result.result === false) {
			errored.push(action.body.id);
		} else {
			errored.push(false);
		}
		if (action.index === action.totalLength - 1) {
			yield put({
				type: REQUESTS_UPDATE_SUPPLEMENT_FINISHED,
				errored: errored,
			});
			errored = [];
		}
	}
}

//////////////
// REWEIGHT //
//////////////
export function* preReweightSupplement() {
	while (true) {
		const action = yield take(PRE_REWEIGHT_SUPPLEMENT);
		const { newValues, id_supplement_category } = action;
		const id_menu = yield call(getMenuSelected);

		yield put({
			type: PRE_REQUEST_REWEIGHT_SUPPLEMENT,
			body: {
				entity: newValues,
				entityCategorySupplementId: id_supplement_category,
				synchroId: id_supplement_category,
				id_menu,
			},
		});

		const result = yield take(REQUESTS_REWEIGHT_SUPPLEMENT_FINISHED);
		yield put({
			type: REWEIGHT_SUPPLEMENT,
			response: result.newSupplements.result,
		});
	}
}

const reweightBuffer = buffers.expanding();
export function* watchReweightSupplement() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_REWEIGHT_SUPPLEMENT,
		reweightBuffer
	);
	let successfullRequest = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			PATCH_METHOD,
			true,
			action.body,
			'/category-re-weight'
		);
		if (result.result && result.result.status == 200) {
			successfullRequest.push(action.body);
		}

		if (reweightBuffer.isEmpty()) {
			const id_menu = yield call(getMenuSelected);
			const newSupplements = yield call(
				requestHandler,
				'supplement',
				'GET',
				true,
				null,
				'',
				{ id_menu: id_menu }
			);
			yield put({
				type: REQUESTS_REWEIGHT_SUPPLEMENT_FINISHED,
				newSupplements,
			});
			successfullRequest = [];
		}
	}
}

export function* assoSuppToCategory() {
	while (true) {
		const { body } = yield take('ASSO_SUPP_TO_CATEGORY_SUPP');

		const result = yield call(
			requestHandler,
			ENTITY,
			PATCH_METHOD,
			true,
			body,
			'/categorySupplement'
		);

		const newSupplements = yield call(
			requestHandler,
			'supplement',
			'GET',
			true,
			null,
			'',
			{ id_menu: body.id_menu }
		);
		yield put({
			type: RECEIVE_SUPPLEMENT,
			response: newSupplements.result,
		});
		yield put({ type: 'ASSO_SUPP_TO_CATEGORY_SUPP_FINISHED' });
	}
}
