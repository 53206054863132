import {
	RECEIVE_PRODUCT_MENU,
	SWITCH_SORT_PRODUCT_MENU,
	SEND_PRODUCT_MENU,
	PRODUCT_MENU_SENT,
	REMOVE_PRODUCT_MENU,
	UPDATE_PRODUCT_MENU,
	CREATE_PRODUCT_MENU,
	DELETE_PRODUCT_MENU,
} from '../constants/productMenu';

const initialState = [];

const reducer = function productMenuReducer(state = initialState, action) {
	const newState = Object.assign({}, state);
	switch (action.type) {
		case CREATE_PRODUCT_MENU:
		case DELETE_PRODUCT_MENU:
		case UPDATE_PRODUCT_MENU:
		case RECEIVE_PRODUCT_MENU:
			const received_productMenus = action.response.result;
			return [...received_productMenus];
		case SWITCH_SORT_PRODUCT_MENU:
			return {
				...newState,
				sort: state.sort === 'category_weight' ? 'name' : 'category_weight',
			};
		case SEND_PRODUCT_MENU:
			return state;
		case PRODUCT_MENU_SENT:
			return state;
		case REMOVE_PRODUCT_MENU:
			return state;
		default:
			return state;
	}
};

export default reducer;
