import { buffers } from 'redux-saga';
import {
	call,
	put,
	take,
	actionChannel,
	select,
	race,
} from 'redux-saga/effects';
import { requestHandler } from './fetcher.saga';
import { getMenuSelected, fetchAll } from './login';
import {
	ENTITY_CATEGORY,
	ENTITY_EXCLUSION,
	ENTITY_PRICE_CATEGORY,
	ENTITY_SUPPLEMENT,
} from '../utils';
import {
	CREATE_PRODUCT,
	PRE_CREATE_PRODUCT,
	PRE_UPDATE_PRODUCT,
	UPDATE_PRODUCT,
	RECEIVE_PRODUCT,
	PRE_DELETE_PRODUCT,
	DELETE_PRODUCT,
	PRE_REWEIGHT_PRODUCT,
	REWEIGHT_PRODUCT,
	REQUESTS_REWEIGHT_PRODUCT_FINISHED,
	PRE_REQUEST_REWEIGHT_PRODUCT,
	PRE_ASSIGN_AND_REWEIGHT_PRODUCT,
	PRE_IMPORT_PRODUCTS,
	IMPORT_PRODUCTS,
} from '../constants/product';
import _find from 'lodash/find';
import { ADD_NOTIFICATION, TAGS } from '../constants/notification';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import {
	refreshProductMenu,
	restoreProductMenuLevel,
} from './productMenu.saga';

const ENTITY = 'product';
const DELETE_METHOD = 'DELETE';
const POST_METHOD = 'POST';
const PATCH_METHOD = 'PATCH';
const GET_METHOD = 'GET';

const PRE_REQUEST_UPDATE_PRODUCT = 'PRE_REQUEST_UPDATE_PRODUCT';
const PRE_REQUEST_CREATE_PRODUCT = 'PRE_REQUEST_CREATE_PRODUCT';
const PRE_REQUEST_DELETE_PRODUCT = 'PRE_REQUEST_DELETE_PRODUCT';
const PRE_REQUEST_IMPORT_PRODUCTS = 'PRE_REQUEST_IMPORT_PRODUCTS';

const REQUESTS_UPDATE_PRODUCT_FINISHED = 'REQUESTS_UPDATE_PRODUCT_FINISHED';
const REQUESTS_CREATE_PRODUCT_FINISHED = 'REQUESTS_CREATE_PRODUCT_FINISHED';
const REQUESTS_DELETE_PRODUCT_FINISHED = 'REQUESTS_DELETE_PRODUCT_FINISHED';
const REQUEST_IMPORT_PRODUCTS_FINISHED = 'REQUEST_ADD_PRODUCTS_FINISHED';

////////////
// UPDATE //
////////////
export default function* productSaga() {
	const requestChan = yield actionChannel(
		PRE_UPDATE_PRODUCT,
		buffers.expanding()
	);

	while (true) {
		const action = yield take(requestChan);
		const { ids, entities, forceRefetch } = action;
		const isRecuperation = !!action.newValues.find(
			v => v.hasOwnProperty('removed') && v.removed == 0
		);
		const idsLength = ids.length;
		const id_menu = yield call(getMenuSelected);
		let dataToSend = {
			noBulk: [],
			bulk: [],
			catBulk: [],
		};
		let nbEntityCat;
		if (entities) {
			nbEntityCat = entities.filter(entity => entity === ENTITY_CATEGORY);
		}

		for (let i = 0; i < idsLength; i++) {
			if (entities && entities[i] === ENTITY_CATEGORY) {
				if (nbEntityCat.length > 1) {
					dataToSend.catBulk.push({
						id: ids[i],
						categories: action.newValues[i].categories,
						id_menu: id_menu,
						entities: entities[i],
					});
				} else {
					dataToSend.noBulk.push({
						id: ids[i],
						categories: action.newValues[i].categories,
						id_menu: id_menu,
						entities: entities[i],
					});
				}
			} else if (entities && entities[i] === ENTITY_PRICE_CATEGORY) {
				dataToSend.noBulk.push({
					id: ids[i],
					prices: action.newValues[i].prices,
					id_menu: id_menu,
					entities: entities[i],
				});
			} else if (entities && entities[i] === ENTITY_EXCLUSION) {
				dataToSend.noBulk.push({
					id: ids[i],
					exclusions: action.newValues[i].exclusions,
					id_menu: id_menu,
					entities: entities[i],
				});
			} else if (entities && entities[i] === ENTITY_SUPPLEMENT) {
				dataToSend.noBulk.push({
					id: ids[i],
					supplements: action.newValues[i].supplements,
					id_menu: id_menu,
					entities: entities[i],
				});
			} else {
				dataToSend.bulk.push({
					id: ids[i],
					newData: action.newValues[i],
					id_menu: id_menu,
				});
			}
		}

		const noBulkLength = dataToSend.noBulk.length;
		const bulkLength = dataToSend.bulk.length;
		const catBulkLength = dataToSend.catBulk.length;
		let completeLength = noBulkLength;
		if (bulkLength > 0) {
			completeLength += 1;
		}
		if (noBulkLength > 0) {
			for (let i = 0; i < noBulkLength; i++) {
				const entity = dataToSend.noBulk[i].entities;
				delete dataToSend.noBulk[i].entities;
				yield put({
					type: PRE_REQUEST_UPDATE_PRODUCT,
					body: dataToSend.noBulk[i],
					index: i,
					totalLength: completeLength,
					entity: entity,
					id_menu,
					noBulkLength: noBulkLength,
				});
				if (i === noBulkLength - 1 && bulkLength > 0) {
					yield put({
						type: PRE_REQUEST_UPDATE_PRODUCT,
						body: dataToSend.bulk,
						index: i + 1,
						totalLength: completeLength,
						entity: '',
						id_menu,
						noBulkLength: noBulkLength,
					});
				}
			}
		} else {
			if (bulkLength > 0) {
				yield put({ type: 'RESET_LOADING' });
				yield put({
					type: PRE_REQUEST_UPDATE_PRODUCT,
					body: dataToSend.bulk,
					index: 0,
					totalLength: 1,
					entity: '',
					id_menu,
					noBulkLength: noBulkLength,
				});
			}
			if (catBulkLength > 0) {
				yield put({ type: 'RESET_LOADING' });
				yield put({
					type: PRE_REQUEST_UPDATE_PRODUCT,
					body: dataToSend.catBulk,
					index: 0,
					totalLength: 1,
					entity: ENTITY_CATEGORY,
					id_menu,
					noBulkLength: noBulkLength,
					catBulkLength: catBulkLength,
				});
			}
		}
		const productAction = { ...action };
		const store = yield select();
		const currentProducts = store.entity.product.products;
		let newValues = [];
		ids.forEach((id, key) => {
			let product = currentProducts.find(product => product.id === id);
			const valueAccessor = Object.keys(action.newValues[key]);
			let obj = {};
			valueAccessor.forEach(value => {
				if (product) {
					obj[value] = product[value];
				}
			});
			newValues.push(obj);
		});
		productAction.newValues = newValues;
		productAction.fromUndoRedo = true;
		const errored = yield take(REQUESTS_UPDATE_PRODUCT_FINISHED);
		if (noBulkLength > 0 || catBulkLength > 0) {
			yield put({
				type: 'UPDATE_PRODUCT',
				response: errored.newProducts.result,
				action: action,
			});
		} else {
			yield put({
				type: 'UPDATE_PRODUCT_WITHOUT_FETCH',
				response: errored.newProducts.result,
				action: action,
			});
			if (isRecuperation) yield call(refreshProduct);
		}
		if (!action.fromUndoRedo) {
			yield put({ type: 'ADD_PRODUCT_ACTION_PAST', action: productAction });
		} else {
			if (action.fromUndo) {
				yield put({ type: 'ADD_PRODUCT_ACTION_FUTURE', action: productAction });
			} else if (action.fromRedo) {
				yield put({ type: 'ADD_PRODUCT_ACTION_PAST', action: productAction });
			}
		}
	}
}

export function* watchUpdateProduct() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_UPDATE_PRODUCT,
		buffers.expanding()
	);
	let errored = [];
	while (true) {
		const action = yield take(requestChan);
		const result = yield call(
			requestHandler,
			ENTITY,
			PATCH_METHOD,
			true,
			action.body,
			action.entity ? '/' + action.entity : ''
		);
		if (result.result === false) {
			errored.push(action.body.id);
		} else {
			errored.push(false);
		}
		if (action.index === action.totalLength - 1) {
			let newProducts;
			if (action.noBulkLength > 0 || action.catBulkLength > 0) {
				newProducts = yield call(
					requestHandler,
					'product',
					'GET',
					true,
					null,
					'',
					{
						id_menu: action.id_menu,
					}
				);
			} else {
				newProducts = result;
			}

			yield put({
				type: REQUESTS_UPDATE_PRODUCT_FINISHED,
				errored: errored,
				newProducts,
			});
			errored = [];
		}
	}
}

////////////
// CREATE //
////////////
export function* preCreateProduct() {
	while (true) {
		const action = yield take(PRE_CREATE_PRODUCT);
		const { newProducts, massInsert } = action;
		let length = newProducts.length;

		const id_menu = yield call(getMenuSelected);
		if (massInsert) {
			yield put({
				type: PRE_REQUEST_CREATE_PRODUCT,
				body: {
					newProduct: newProducts,
				},
				index: 0,
				totalLength: 1,
				id_menu: id_menu,
			});
		} else {
			for (let i = 0; i < length; i++) {
				yield put({
					type: PRE_REQUEST_CREATE_PRODUCT,
					body: {
						newProduct: newProducts[i],
					},
					index: i,
					totalLength: length,
					id_menu: id_menu,
				});
			}
		}

		const { createdProducts } = yield take(REQUESTS_CREATE_PRODUCT_FINISHED);
		yield put({
			type: CREATE_PRODUCT,
			response: createdProducts.result,
			action: { ...action, byPassReduxUndo: true },
		});
	}
}

export function* watchCreateProduct() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_CREATE_PRODUCT,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);
		const result = yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			action.body.newProduct
		);
		let newProduct = {
			...action.body.newProduct,
		};
		newProduct.id = result.result.result;

		if (action.index === action.totalLength - 1) {
			const newProducts = yield call(
				requestHandler,
				'product',
				'GET',
				true,
				null,
				'',
				{
					id_menu: action.id_menu,
				}
			);
			yield put({
				type: REQUESTS_CREATE_PRODUCT_FINISHED,
				createdProducts: newProducts,
			});
		}
	}
}

////////////
// DELETE //
////////////
export function* preDeleteProduct() {
	while (true) {
		const action = yield take(PRE_DELETE_PRODUCT);
		const { ids, id_menu } = action;
		let length = ids.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_DELETE_PRODUCT,
				body: {
					id: ids[i],
					id_menu: id_menu,
				},
				index: i,
				totalLength: length,
			});
		}

		yield take(REQUESTS_DELETE_PRODUCT_FINISHED);
		const listProduct = yield call(
			requestHandler,
			'product',
			GET_METHOD,
			true,
			null,
			'/removed',
			{
				id_menu: id_menu,
			}
		);
		yield put({
			type: DELETE_PRODUCT,
			ids: ids,
			action: action,
		});
		yield put({
			type: RECEIVE_PRODUCT,
			response: listProduct.result,
		});
	}
}

export function* watchDeleteProduct() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_DELETE_PRODUCT,
		buffers.expanding()
	);

	while (true) {
		const action = yield take(requestChan);

		yield call(requestHandler, ENTITY, DELETE_METHOD, true, action.body);
		if (action.index === action.totalLength - 1) {
			yield put({
				type: REQUESTS_DELETE_PRODUCT_FINISHED,
			});
		}
	}
}

//////////////
// REWEIGHT //
//////////////
export function* preReweightProduct() {
	while (true) {
		const action = yield take(PRE_REWEIGHT_PRODUCT);
		const { newValues, id_category } = action;
		const id_menu = yield call(getMenuSelected);

		yield put({
			type: PRE_REQUEST_REWEIGHT_PRODUCT,
			body: {
				entity: newValues,
				entityCategoryId: id_category,
				synchroId: id_category,
				id_menu,
			},
		});
		const result = yield take(REQUESTS_REWEIGHT_PRODUCT_FINISHED);
		yield put({
			type: REWEIGHT_PRODUCT,
			response: result.newProducts.result,
			action: { ...action, byPassReduxUndo: true },
		});
	}
}

const reweightBuffer = buffers.expanding();
export function* watchReweightProduct() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_REWEIGHT_PRODUCT,
		reweightBuffer
	);

	while (true) {
		const action = yield take(requestChan);
		yield call(
			requestHandler,
			ENTITY,
			PATCH_METHOD,
			true,
			action.body,
			'/category-re-weight'
		);

		if (reweightBuffer.isEmpty()) {
			const id_menu = yield call(getMenuSelected);
			const newProducts = yield call(
				requestHandler,
				'product',
				'GET',
				true,
				null,
				'',
				{
					id_menu: id_menu,
				}
			);
			yield put({
				type: REQUESTS_REWEIGHT_PRODUCT_FINISHED,
				newProducts,
			});
		}
	}
}

//////////////
// ASSIGN && REWEIGHT //
//////////////

/**
 * first assign product to specified categories
 * then reweight new assigned product from specified weight
 */
export function* preAssignCategoryAndReweight() {
	const requestChan = yield actionChannel(
		PRE_ASSIGN_AND_REWEIGHT_PRODUCT,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);
		const { assign, assignFromWeight, categoryId } = action;
		const { ids, assignNewValues } = assign;

		let byPassReweight = false;
		if (assignFromWeight[assignFromWeight.length - 1] === '2,2') {
			byPassReweight = true;
		}

		let entities = [];
		for (let i = 0; i <= assignNewValues.length - 1; i++) {
			entities.push(ENTITY_CATEGORY);
		}
		yield put({
			type: PRE_UPDATE_PRODUCT,
			entities: entities,
			ids,
			newValues: assignNewValues,
			disableLoader: true,
		});
		const updatedProducts = yield take(UPDATE_PRODUCT);

		const newAssignedProductWeight = ids.reduce((acc, productId) => {
			const product = _find(
				updatedProducts.response.result,
				product => product.id === productId
			);
			const productCategoryWeight = _find(
				product.product_category_weight,
				product_category_weight =>
					product_category_weight.idCategory === categoryId
			);
			acc.push({
				id: productId,
				weight: productCategoryWeight.productWeight,
			});

			return acc;
		}, []);
		if (!byPassReweight) {
			let flag = false;
			const productToReweight = assignFromWeight.reduce(
				(acc, weight, index) => {
					if (weight === '2,2') {
						flag = true;
					}
					const splitted = weight.split(',');
					if (splitted[0] === '1' && flag) {
						acc.push({
							id: splitted[2],
							weight: splitted[1],
						});
					}

					return acc;
				},
				[]
			);
			let reweightNewValues = [];
			let fromWeight = productToReweight[0].weight;
			const newAssignedProductWeightLength = newAssignedProductWeight.length;
			for (let i = 0; i < newAssignedProductWeightLength; i++) {
				newAssignedProductWeight[i].weight = fromWeight;
				reweightNewValues.push(newAssignedProductWeight[i]);
				fromWeight++;
			}
			const productToReweightLength = productToReweight.length;
			for (let j = 0; j < productToReweightLength; j++) {
				productToReweight[j].weight =
					parseInt(productToReweight[j].weight, 10) +
					parseInt(newAssignedProductWeightLength, 10);
				reweightNewValues.push(productToReweight[j]);
				fromWeight++;
			}

			yield put({
				type: PRE_REWEIGHT_PRODUCT,
				newValues: reweightNewValues,
				id_category: categoryId,
				disableLoader: true,
			});
			yield take(REQUESTS_REWEIGHT_PRODUCT_FINISHED);
		}
	}
}

export function* handleUndoRedo() {
	while (true) {
		const undoRedoAction = yield race({
			undo: take('UNDO_PRODUCT'),
			redo: take('REDO_PRODUCT'),
		});
		const store = yield select();
		if (undoRedoAction.undo) {
			const pastProductAction = store.entity.product.product_action_past;
			if (pastProductAction.length > 0) {
				const actionToReplay = pastProductAction[pastProductAction.length - 1];
				yield put({ ...actionToReplay, fromUndo: true, fromRedo: true });
				yield put({ type: 'POP_PRODUCT_ACTION_PAST' });
			}
		} else {
			const futureProductAction = store.entity.product.product_action_future;
			if (futureProductAction.length > 0) {
				const actionToReplay =
					futureProductAction[futureProductAction.length - 1];
				yield put({ ...actionToReplay, fromRedo: true, fromUndo: false });
				yield put({ type: 'POP_PRODUCT_ACTION_FUTURE' });
			}
		}
	}
}

export function* restoreProduct() {
	while (true) {
		const { id_product, entity, id_category_supplement, name } = yield take(
			'PRE_RESTORE_PRODUCT'
		);
		const id_menu = yield call(getMenuSelected);

		if (entity == 'product') {
			yield put({
				type: PRE_UPDATE_PRODUCT,
				ids: [id_product, id_product],
				newValues: [
					{
						removed: 0,
					},
					{
						active: 1,
					},
				],
				entities: [false, false],
				disableLoader: false,
			});
			yield race({
				a: take('UPDATE_PRODUCT_WITHOUT_FETCH'),
				b: take(UPDATE_PRODUCT),
			});

			yield put({
				type: ADD_NOTIFICATION,
				notification: {
					tags: [TAGS.SUCCESS],
					text: I18n.t('product.restore.success', { name }),
					date: moment().format('DD/MM/YYYY HH:mm'),
					viewed: false,
				},
			});
		} else if (entity == 'supplement') {
			yield put({
				type: 'PRE_UPDATE_SUPPLEMENT',
				ids: [id_product],
				newValues: [
					{
						removed: 0,
					},
				],
				entity: false,
			});
			yield put({
				type: 'PRE_UPDATE_SUPPLEMENT',
				ids: [id_product],
				newValues: [
					{
						active: 1,
					},
				],
				entity: false,
			});
			yield put({
				type: 'ASSO_SUPP_TO_CATEGORY_SUPP',
				body: { id: id_product, id_category_supplement, id_menu },
			});
			yield take('ASSO_SUPP_TO_CATEGORY_SUPP_FINISHED');
		} else {
			yield put({
				type: 'PRE_REQUEST_UPDATE_PRODUCT_MENU',
				body: {
					entity: 'productMenu',
					route: '',
					method: 'PATCH',
					changes: { key: 'removed', value: 0, id: id_product },
				},
				id_menu,
			});
			yield put({
				type: 'PRE_REQUEST_UPDATE_PRODUCT_MENU',
				body: {
					entity: 'productMenu',
					route: '',
					method: 'PATCH',
					changes: { key: 'active', value: 1, id: id_product },
				},
				id_menu,
			});
			yield take('REQUESTS_UPDATE_PRODUCT_MENU_FINISHED');

			yield call(restoreProductMenuLevel, id_product, id_menu);

			yield call(refreshProductMenu);
			yield put({
				type: ADD_NOTIFICATION,

				notification: {
					tags: [TAGS.SUCCESS],
					text: I18n.t('product.restore.product_menu_success', { name }),
					date: moment().format('DD/MM/YYYY HH:mm'),
					viewed: false,
				},
			});
		}
		yield put({ type: 'RESTORE_PRODUCT', id_product });
	}
}

function* refreshProduct() {
	const id_menu = yield call(getMenuSelected);
	const res = yield call(requestHandler, 'product', 'GET', true, null, '', {
		id_menu,
	});
	yield put({
		type: RECEIVE_PRODUCT,
		response: res.result,
	});
}

//Add products with import
export function* preImportProducts() {
	while (true) {
		const action = yield take(PRE_IMPORT_PRODUCTS);
		const { id_menu_source, ids } = action;
		const id_menu_selected = yield call(getMenuSelected);

		yield put({
			type: PRE_REQUEST_IMPORT_PRODUCTS,
			body: { id_menu_source: id_menu_source, ids, id_menu: id_menu_selected },
		});
		const result = yield take(REQUEST_IMPORT_PRODUCTS_FINISHED);
		const importProducts = yield call(
			requestHandler,
			'product',
			'GET',
			true,
			null,
			'',
			{
				id_menu: id_menu_selected,
			}
		);
		yield put({
			type: RECEIVE_PRODUCT,
			response: importProducts.result,
		});
	}
}

export function* watchImportProducts() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_IMPORT_PRODUCTS,
		buffers.expanding()
	);

	while (true) {
		const action = yield take(requestChan);
		const resultImportProducts = yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			action.body,
			'/import'
		);

		yield put({
			type: REQUEST_IMPORT_PRODUCTS_FINISHED,
		});
	}
}
