import React from 'react';
import { styles } from './styles';
import { Translate } from 'react-redux-i18n';

const styleInterac = {
	buttonNext: {
		display: 'none',
	},
	buttonSkip: {
		display: 'none',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const stylesForTooltipBody = {
	buttonBack: {
		display: 'none',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const stylesButtonSave = {
	buttonClose: {
		display: 'none',
	},
	buttonNext: {
		display: 'none',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const styleBis = {
	buttonClose: {
		fontSize: '16px',
	},
	buttonSkip: {
		fontSize: '16px',
	},
	buttonBack: {
		fontSize: '16px',
	},
	buttonNext: {
		fontSize: '16px',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const styleWhenNoBack = {
	buttonClose: {
		fontSize: '16px',
	},
	buttonBack: {
		display: 'none',
	},
	buttonNext: {
		fontSize: '16px',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

export const stepsProducts = [
	{
		content: <Translate value="tutorial.product.step1.content" />,
		placement: 'center',
		disableBeacon: true,
		styles,
		locale: {
			skip: <Translate value="tutorial.noDemo" />,
			close: <Translate value="tutorial.yesDemo" />,
		},
		target: 'body',
		title: <Translate value="tutorial.product.step1.title" />,
	},
	{
		content: <Translate value="tutorial.product.step2.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		styles: styleInterac,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.product_button_main_page',
		title: <Translate value="tutorial.product.step2.title" />,
	},
	{
		content: <Translate value="tutorial.product.step3.content" />,
		placement: 'left',
		spotlightClicks: true,
		styles: styleInterac,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '#button_add_product_fast',
		title: <Translate value="tutorial.product.step3.title" />,
	},
	{
		content: <Translate value="tutorial.product.step4.content" />,
		placement: 'center',
		spotlightClicks: true,
		styles: styleWhenNoBack,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.htCore>tbody>tr:last-child',
		title: <Translate value="tutorial.product.step4.title" />,
	},
	{
		content: <Translate value="tutorial.product.step5.content" />,
		placement: 'center',
		styles: styleWhenNoBack,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.htCore>tbody>tr:last-child>td:nth-child(10)',
		title: <Translate value="tutorial.product.step5.title" />,
	},
	{
		content: <Translate value="tutorial.product.step6.content" />,
		placement: 'bottom-start',
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.filter_bar',
		title: <Translate value="tutorial.product.step6.title" />,
	},
	{
		content: <Translate value="tutorial.product.step7.content" />,
		placement: 'bottom',
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.undo_redo_container',
		title: <Translate value="tutorial.product.step7.title" />,
	},
	{
		content: <Translate value="tutorial.product.step8.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		styles: styleInterac,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.product_button_main_page',
		title: <Translate value="tutorial.product.step8.title" />,
	},
	{
		content: <Translate value="tutorial.product.step9.content" />,
		placement: 'right',
		spotlightClicks: true,
		styles: styleInterac,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '#button_add_product',
		title: <Translate value="tutorial.product.step9.title" />,
	},
	{
		content: <Translate value="tutorial.product.step10.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.name-container',
		title: <Translate value="tutorial.product.step10.title" />,
	},
	{
		content: <Translate value="tutorial.product.step11.content" />,
		placement: 'right',
		spotlightClicks: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.name_product_form',
		title: <Translate value="tutorial.product.step11.title" />,
	},
	{
		content: <Translate value="tutorial.product.step12.content" />,
		placement: 'right',
		spotlightClicks: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.price_product_form',
		title: <Translate value="tutorial.product.step12.title" />,
	},
	{
		content: <Translate value="tutorial.product.step13.content" />,
		placement: 'left',
		spotlightClicks: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.informations_product',
		title: <Translate value="tutorial.product.step13.title" />,
	},
	{
		content: <Translate value="tutorial.product.step14.content" />,
		placement: 'top',
		spotlightClicks: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.kitchen_option',
		title: <Translate value="tutorial.product.step14.title" />,
	},
	{
		content: <Translate value="tutorial.product.step15.content" />,
		placement: 'left',
		spotlightClicks: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.informations_color_product',
		title: <Translate value="tutorial.product.step15.title" />,
	},
	{
		content: <Translate value="tutorial.product.step16.content" />,
		placement: 'top',
		spotlightClicks: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.informations_exclusion_product',
		title: <Translate value="tutorial.product.step16.title" />,
	},
	{
		content: <Translate value="tutorial.product.step17.content" />,
		placement: 'left',
		spotlightClicks: true,
		styles: stylesButtonSave,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
		},
		target: '.information_button_save_product',
		title: <Translate value="tutorial.product.step17.title" />,
	},
	{
		content: <Translate value="tutorial.product.step18.content" />,
		placement: 'center',
		spotlightClicks: true,
		disableBeacon: true,
		styles: stylesForTooltipBody,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: 'body',
		title: <Translate value="tutorial.product.step18.title" />,
	},
	{
		content: <Translate value="tutorial.product.step19.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		target: '.htCore>tbody>tr:last-child>td:nth-child(7)',
		title: <Translate value="tutorial.product.step19.title" />,
	},
	{
		content: <Translate value="tutorial.product.step20.content" />,
		placement: 'right',
		spotlightClicks: true,
		styles: styleInterac,
		target: '.category-list',
		title: <Translate value="tutorial.product.step20.title" />,
	},
	{
		content: <Translate value="tutorial.product.step21.content" />,
		placement: 'center',
		spotlightClicks: true,
		disableBeacon: true,
		styles: stylesForTooltipBody,
		locale: {
			close: <Translate value="tutorial.product.endDemoProduct" />,
		},
		target: 'body',
		title: <Translate value="tutorial.product.step21.title" />,
	},
];
