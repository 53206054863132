import React, { Component } from 'react';
import ProductForm from '../components/ProductForm';
import ActionChromeReaderMode from '@material-ui/icons/ChromeReaderMode';
import AvPlaylistAdd from '@material-ui/icons/PlaylistAdd';
import AddCircle from '@material-ui/icons/AddCircle';
import RestoreFromTrashIcon from '@material-ui/icons/Restore';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ClearIcon from '@material-ui/icons/Clear';
import { connect } from 'react-redux';
import ProductList from '../components/ProductList';
import moment from 'moment';

import {
	confirm,
	deepClone,
	ENTITY_CATEGORY,
	ENTITY_EXCLUSION,
	ENTITY_PRICE_CATEGORY,
	ENTITY_SUPPLEMENT,
	getDifferences,
	deepEqual,
	haveSkuRequierments,
} from '../utils';
import CategoryList from '../components/CategoryList';
import { getProductsSorted } from '../selectors/product.selector';
import {
	getCategories,
	getSelectedMenu,
	getNbTempsDenvoi,
	getVariableServer,
} from '../selectors/global.selector';
import * as productAction from '../actions/product.action';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import find from 'lodash/find';
import ProductCategoryList from '../components/ProductCategoryList';
import IconButton from '@material-ui/core/IconButton';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import FilterBar from '../components/FilterBar';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import orderBY from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import * as actionsAssignator from '../actions/assignator.action';
import * as actionsImport from './../actions/import.action';
import * as actionsProduct from './../actions/product.action';
import * as tutorialEvents from './../actions/tutorial.action.events';
import * as tutorialActions from './../actions/tutorial.action';
import * as notificationAction from '../actions/notification.action';
import { ACTIONS, EVENTS } from 'react-joyride';
import { TYPE } from '../constants/assignator';
import PopUpAddProduct from '../components/PopUpAddProduct';
import Loader from '../components/Loader';
import ProductPriceList from '../components/ProductPriceList';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getAccess } from '../selectors/access.selector';
import AccessForbidden from '../components/AccessForbidden';
import { TAGS } from '../constants/notification';
import ProductAssignator from '../components/ProductAssignator';
import SideWindow from '../components/SideWindow';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Button, Tooltip } from '@material-ui/core';
import PopupRestoreBin from '../components/PopupRestoreBin';

class Product extends Component {
	constructor(props) {
		super(props);
		const products = deepClone(props.products);
		this.state = {
			products: products,
			productsInit: products,
			addActionBtnOpen: false,
			width: 0,
			height: 0,
			scrollToBottom: false,
			formProductOpen: false,
			selectedProduct: {},
			selectedCategory: undefined,
			filters: [],
			sort: {
				id: 0,
				name: I18n.t('product.sort.category_asc'),
				value: ['list_categories'],
				direction: 'asc',
			},
			openPopUp: false,
			creationInProgress: false,
			newLine: false,
			gridPriceMode: false,
			popupDeleteBulkProductOpen: false,
		};

		this.changeProductCategoryView = this.changeProductCategoryView.bind(this);
		this.updateProductInCreation = this.updateProductInCreation.bind(this);
		this.onClickAddProductLine = this.onClickAddProductLine.bind(this);
		this.filterProductCategory = this.filterProductCategory.bind(this);
		this.updateStateCreation = this.updateStateCreation.bind(this);
		this.onClickOpenPoppover = this.onClickOpenPoppover.bind(this);
		this.resetScrollToBottom = this.resetScrollToBottom.bind(this);
		this.recoverNameCategory = this.recoverNameCategory.bind(this);
		this.handleRequestClose = this.handleRequestClose.bind(this);
		this.listingCategories = this.listingCategories.bind(this);
		this.onClickAddProduct = this.onClickAddProduct.bind(this);
		this.updateDimensions = this.updateDimensions.bind(this);
		this.selectImportMenu = this.selectImportMenu.bind(this);
		this.onCloseImport = this.onCloseImport.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
		this.filterProducts = this.filterProducts.bind(this);
		this.createProduct = this.createProduct.bind(this);
		this.deleteProduct = this.deleteProduct.bind(this);
		this.saveProduct = this.saveProduct.bind(this);
		this.validImport = this.validImport.bind(this);
		this.filteredMenu = this.filteredMenu.bind(this);
		this.popupCancel = this.popupCancel.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.onEditClick = this.onEditClick.bind(this);
		this.handleOpen = this.handleOpen.bind(this);
		this.popupValid = this.popupValid.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
		this.notifSkuRequirement = this.notifSkuRequirement.bind(this);
		this.deleteBulkProducts = this.deleteBulkProducts.bind(this);
		this.handleBulkDeletePopup = this.handleBulkDeletePopup.bind(this);
		//this.reSort = this.reSort.bind(this);
	}

	handleClick = event => {
		// This prevents ghost click.
		event.preventDefault();

		this.setState({
			open: true,
			anchorEl: event.currentTarget,
		});
	};

	updateDimensions() {
		const height =
			window.innerHeight ||
			document.documentElement.clientHeight ||
			document.body.clientHeight;

		const width =
			window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth;

		this.setState({ width, height });
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		document.addEventListener('keypress', this.onKeyPress);
		const height =
			window.innerHeight ||
			document.documentElement.clientHeight ||
			document.body.clientHeight;

		const width =
			window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth;

		this.setState({ width, height });
		//this.reSort(this.state.sort);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
		document.removeEventListener('keypress', this.onKeyPress);
	}

	onClickOpenPoppover(e) {
		e.preventDefault();
		this.setState(
			{
				addActionBtnOpen: !this.state.addActionBtnOpen,
			},
			() =>
				this.props.tutorial.run
					? this.props.tutorialEvent.triggerProductButtonMainPage()
					: null
		);
	}

	handleClose = () => {
		this.setState({
			addActionBtnOpen: false,
		});
	};

	// handleOpen = () => {
	//   this.setState({
	//     addActionBtnOpen: true
	//   });
	// };

	onClickAddProductLine(e) {
		e.preventDefault();
		const newProducts = deepClone(this.state.products);
		newProducts.push({
			id_color: '',
			name: '',
			price: '',
			taxName: '',
			product_type_name: '',
			category_name: '',
			place_send_name: '',
		});
		this.setState(
			{
				products: newProducts,
				scrollToBottom: true,
				addActionBtnOpen: false,
				btnPopoverClass: '',
				newLine: true,
				//creationInProgress: true
			},
			() => {
				if (this.props.tutorial.run)
					this.props.tutorialEvent.triggerButtonAddProductFast();
			}
		);
	}

	onClickAddProduct(e) {
		e.preventDefault();
		this.setState(
			{
				formProductOpen: true,
				productAddBtnClasses: 'buttonAdd buttonAdd-hidden',
				products: this.state.products,
				selectedProduct: {},
			},
			() =>
				this.props.tutorial.run
					? this.props.tutorialEvent.triggerButtonAddProduct()
					: null
		);
	}

	onEditClick(id) {
		if (this.props.access.update) {
			if (!id) {
				this.setState({
					formProductOpen: true,
					productAddBtnClasses: 'buttonAdd buttonAdd-hidden',
					products: this.state.products,
					selectedProduct: {},
				});
			} else {
				const product = find(this.state.products, p => p.id === id);
				this.setState({
					selectedProduct: product,
					formProductOpen: true,
					productAddBtnClasses: 'buttonAdd buttonAdd-hidden',
				});
			}
		}
	}

	popupCancel() {
		this.setState({
			formProductOpen: false,
			productAddBtnClasses: 'buttonAdd buttonAdd-visible',
			addActionBtnOpen: false,
		});
		//this.props.tutorialAction.stopTutorialProducts();
	}

	resetScrollToBottom() {
		this.setState({ scrollToBottom: false });
	}

	popupValid(data, originalProduct) {
		this.setState({
			formProductOpen: false,
			productAddBtnClasses: 'buttonAdd buttonAdd-visible',
		});

		if (data.id && originalProduct) {
			const diffs = getDifferences(originalProduct, data);
			const ids = [];
			const values = [];
			const entities = [];

			for (let property in diffs) {
				if (diffs.hasOwnProperty(property)) {
					let entity = false;

					if (property === 'categories') {
						entity = ENTITY_CATEGORY;
					} else if (property === 'exclusions') {
						entity = ENTITY_EXCLUSION;
					} else if (property === 'supplements') {
						entity = ENTITY_SUPPLEMENT;
					} else if (property === 'prices') {
						entity = ENTITY_PRICE_CATEGORY;
					}

					ids.push(data.id);
					values.push({ [property]: diffs[property] });
					entities.push(entity);
				}
			}
			this.props.productAction.updateProducts(ids, values, entities);
		} else if (data.id) {
			const diffs = getDifferences(this.state.selectedProduct, data);
			const ids = [];
			const values = [];
			const entities = [];

			for (let property in diffs) {
				if (diffs.hasOwnProperty(property)) {
					let entity = false;

					if (property === 'categories') {
						entity = ENTITY_CATEGORY;
					} else if (property === 'exclusions') {
						entity = ENTITY_EXCLUSION;
					} else if (property === 'supplements') {
						entity = ENTITY_SUPPLEMENT;
					} else if (property === 'prices') {
						entity = ENTITY_PRICE_CATEGORY;
					}

					ids.push(data.id);
					values.push({ [property]: diffs[property] });
					entities.push(entity);
				}
			}
			this.props.productAction.updateProducts(ids, values, entities);
		} else {
			this.props.productAction.createProducts([data]);
		}
		if (this.props.tutorial.run) this.props.tutorialEvent.triggerSaveProduct();
		this.setState({ newLine: false });
		this.handleClose();
	}

	onDeleteClick(id) {
		if (this.state.creationInProgress || this.state.newLine) {
			const products = [...this.state.products];
			products.pop();
			this.setState({ products, creationInProgress: false, newLine: false });
		} else {
			const product = this.props.products.find(p => p.id === id);
			const label = product
				? I18n.t('product.deleteConfirm', { name: product.name })
				: I18n.t('product.confirm');
			confirm(label).then(res => {
				this.props.productAction.deleteProducts([id], this.props.id_menu);
			});
		}
	}

	handleOpen = () => {
		if (!this.state.hidden) {
			this.setState({
				addActionBtnOpen: true,
			});
		}
	};

	componentWillReceiveProps(nextProps) {
		//const sort = this.state.sort;
		const filters = this.state.filters;
		this.setState(
			{
				products: nextProps.products,
				productsInit: nextProps.products,
				categories: nextProps.categories,
			},
			() => {
				if (filters.length === 0) {
					//this.reSort(sort);
				} else {
					this.filterProducts(this.state.filters);
				}
			}
		);
	}

	updateProducts = products => {
		this.setState({ products });
	};

	handleRequestClose() {
		this.setState({
			addActionBtnOpen: false,
		});
	}

	changeProductCategoryView(categoryId) {
		this.setState({ selectedCategory: categoryId });
		if (categoryId !== undefined) {
			this.filterProducts([]);
			/* this.reSort({
        id: 0,
        name: I18n.t("product.sort.category_asc"),
        value: ["list_categories"],
        direction: "asc"
      }); */
		}
		if (this.props.tutorial.run)
			this.props.tutorialEvent.triggerChangeProductCategory();
		this.props.actionsAssignator.editAssignatorTitle(
			this.getCategoryName(categoryId),
			TYPE.CATEGORY
		);
	}

	getCategoryName(id) {
		const cat = this.props.categories.find(c => c.id === id);
		return cat ? cat.name : '';
	}

	sortProduct = () => {
		const sort = this.props.sort;
		let products = deepClone(this.state.products);
		let newProduct;
		if (this.state.products.length > 0) {
			const lastElement = products[products.length - 1];
			if (lastElement.id === 'main') {
				newProduct = products.pop();
			}
		}

		if (sort.value[0] === 'price') {
			products = sortBy(products, [
				product => (product.is_free_price === 0 ? 1 : 0),
				product => parseFloat(product.price),
			]);
		} else if (sort.value[0] === 'list_categories') {
			products = this.listingCategories(products);
		} else {
			products = orderBY(products, sort.value);
		}
		products = this.filterProductCategory(products);

		/* this.setState({
      products:
        sort.direction !== "desc"
          ? [...products.withCategory, ...products.withoutCategory]
          : [...products.withCategory.reverse(), ...products.withoutCategory]
    }); */

		const productsSorted =
			sort.direction !== 'desc'
				? [...products.withCategory, ...products.withoutCategory]
				: [...products.withCategory.reverse(), ...products.withoutCategory];

		if (newProduct) {
			productsSorted.push(newProduct);
		}
		return uniqBy(productsSorted, 'id');
	};

	filterProductCategory(array) {
		const productFiltered = {
			withCategory: [],
			withoutCategory: [],
		};

		array.forEach(arr => {
			arr.category_name !== ''
				? productFiltered.withCategory.push(arr)
				: productFiltered.withoutCategory.push(arr);
		});

		return productFiltered;
	}

	listingCategories(products = []) {
		let copyArray = products.map(product => {
			if (product.category_name_a_z) {
				let modifyName = product.category_name_a_z.map(name =>
					name.toUpperCase()
				);
				product.category_name_a_z = modifyName.sort();
			}

			return product;
		});

		const productsSortedByAZ = orderBY(copyArray, ['category_name_a_z']);
		const productWithoutCategory = productsSortedByAZ.filter(
			prod => !prod.category_name
		);
		const categories = this.props.categories;
		let productsSortedByCategory = [];

		categories.forEach(cate => {
			productsSortedByAZ.forEach(prod => {
				if (
					prod.category_name_a_z &&
					prod.category_name_a_z.length > 0 &&
					cate.name.toUpperCase() === prod.category_name_a_z[0]
				) {
					productsSortedByCategory.push(prod);
				}
			});
		});

		return [...productsSortedByCategory, ...productWithoutCategory];
	}

	filterProducts(filters) {
		let products = deepClone(this.state.productsInit);
		const sort = this.state.sort;
		filters.forEach((value, key) => {
			if (
				value.ids.length > 0 ||
				(value.filter &&
					value.filter.productKey &&
					value.filter.productKey === 'name')
			) {
				products = products.reduce((acc, product, index) => {
					if (value.filter.productKey === 'categories') {
						value.ids.forEach(id => {
							if (id === 'no_category') {
								if (product.categories.length === 0) {
									const pIndex = findIndex(acc, p => p.id === product.id);
									if (pIndex === -1) {
										acc.push(product);
									}
								}
							} else {
								if (product.categories.indexOf(id) !== -1) {
									const pIndex = findIndex(acc, p => p.id === product.id);
									if (pIndex === -1) {
										acc.push(product);
									}
								}
							}
						});

						return acc;
					}
					if (value.filter.productKey === 'price') {
						value.texts.forEach(text => {
							const priceRange = text.split(' - ');
							if (
								product.price >= parseInt(priceRange[0], 10) &&
								product.price <= parseInt(priceRange[1], 10)
							) {
								const pIndex = findIndex(acc, p => p.id === product.id);
								if (pIndex === -1) {
									acc.push(product);
								}
							}
						});

						return acc;
					}
					if (value.filter.productKey === 'name' && value.filter.text !== '') {
						if (
							product.name.toLowerCase().indexOf(value.text.toLowerCase()) > -1
						) {
							const pIndex = findIndex(acc, p => p.id === product.id);
							if (pIndex === -1) {
								acc.push(product);
							}
						}
						return acc;
					}
					if (value.filter.productKey === 'visible') {
						if (value.ids.includes(product.active)) {
							const pIndex = findIndex(acc, p => p.id === product.id);
							if (pIndex === -1) {
								acc.push(product);
							}
						}
						return acc;
					}

					value.ids.forEach(id => {
						if (product[value.filter.productKey] === id) {
							const pIndex = findIndex(acc, p => p.id === product.id);
							if (pIndex === -1) {
								acc.push(product);
							}
						}
					});
					return acc;
				}, []);
			}
		});

		/* this.setState({ products: products, filters: filters }, () => {
      if (sort && Object.keys(sort).length !== 0) this.reSort(sort);
    }); */
		this.setState({ products: products, filters: filters });
	}

	shouldComponentUpdate(nextProps, nextState) {
		/* if (this.props.tutorial.run !== nextProps.tutorial.run) {
      return false;
    } */
		if (deepEqual(this.state, nextState) && deepEqual(nextProps, this.props)) {
			return false;
		}
		return true;
	}

	onKeyPress(e) {
		if (e.charCode === 13 && !!e.shiftKey && !this.state.newLine) {
			// MAJ + ENTER
			this.onClickAddProductLine(e);
		}
	}

	callback = tour => {
		const { action, index, type } = tour;
		if (type === EVENTS.TOUR_END) {
			this.props.tutorialAction.stopTutorial();
			this.setState({
				addActionBtnOpen: false,
				formProductOpen: false,
			});
		} else if (action === ACTIONS.UPDATE && index === 1) {
			this.setState({
				addActionBtnOpen: true,
			});
		} else if (action === ACTIONS.UPDATE && index === 2) {
			this.setState({
				formProductOpen: true,
			});
		}
	};

	handleChangeAddProductMenu = e => {
		e.preventDefault();
		this.setState(state => ({
			openPopUp: !state.openPopUp,
			addActionBtnOpen: false,
		}));
	};

	handleShowRestoreProduct = e => {
		e.preventDefault();
		this.setState(state => ({
			openPopUpRestoration: true,
			addActionBtnOpen: false,
		}));
	};

	validImport(id_menu, ids) {
		this.props.actionsProduct.importProducts(id_menu, ids);
		this.onCloseImport();
	}

	selectImportMenu(id_menu) {
		this.setState({ openPopUp: false, addActionBtnOpen: false });
		this.props.actionsImport.activeImport();
		this.props.actionsImport.importProduct(id_menu);
		this.props.actionsImport.importCategory(id_menu);
	}

	onCloseImport() {
		this.setState({ addActionBtnOpen: false });
		this.props.actionsImport.disableImport();
	}

	filteredMenu() {
		const id_menu = this.props.id_menu;
		const menus = this.props.menu;

		const filteredMenu = menus.filter(menu => menu.id !== id_menu);
		return filteredMenu;
	}

	createProduct(newProduct, products) {
		const products2 = deepClone(products);
		products2.pop();
		products2.push(newProduct);
		this.setState({ products: products2, creationInProgress: true });
	}

	notifSkuRequirement() {
		this.props.notificationAction.addNotification({
			tags: [TAGS.WARNING],
			text: I18n.t(
				this.props.check_sku_int == '1'
					? 'product.skuIntRequired'
					: 'product.skuRequired'
			),
			date: moment().format('DD/MM/YYYY HH:mm'),
			viewed: false,
			openable: true,
		});
	}

	saveProduct(id) {
		const { products } = this.state;

		let product = products.find(product => product.id === id);
		let canCreate = !haveSkuRequierments(
			product.sku,
			this.props.check_sku,
			this.props.check_sku_int
		);

		if (!canCreate) {
			this.notifSkuRequirement();
			return;
		}
		delete product.id;
		this.props.productAction.createProducts([product]);
		this.setState({ creationInProgress: false, newLine: false });
	}

	deleteProduct(id) {
		const { products } = this.state;
		const deleteProducts = products.filter(product => product.id !== id);
		this.setState({
			products: deleteProducts,
			creationInProgress: false,
			newLine: false,
		});
	}

	updateProductInCreation(ids, changes) {
		const products = [...this.state.products];
		const updateProducts = products.map(product => {
			if (product.id === ids[0]) {
				const ObjectKeysRecover = Object.keys(changes[0]);
				const ValuesRecover = Object.values(changes[0]);
				if (ObjectKeysRecover[0] === 'categories') {
					product[ObjectKeysRecover] = ValuesRecover[0];
					product.category_name_a_z = this.recoverNameCategory(
						ValuesRecover[0]
					);
					return product;
				} else {
					product[ObjectKeysRecover] = ValuesRecover[0];
					return product;
				}
			}
			return product;
		});
		this.setState({ products: updateProducts }, () => {
			this.forceUpdate();
		});
	}

	recoverNameCategory(idsCategories) {
		const categories = this.props.categories;
		const result = [];

		idsCategories.forEach(id => {
			categories.forEach(category => {
				if (id === category.id) {
					result.push(category.name);
				}
			});
		});
		return result;
	}

	handleGridModeChange = e => {
		//e.preventDefault();
		this.setState({ gridPriceMode: !this.state.gridPriceMode });
	};

	handleBulkDeletePopup = _ => {
		this.setState({
			popupDeleteBulkProductOpen: !this.state.popupDeleteBulkProductOpen,
		});
	};

	deleteBulkProducts = (e, productIds) => {
		if (productIds && productIds.length) {
			confirm(I18n.t('product.deleteBulkConfirm')).then(() => {
				this.props.productAction.deleteProducts(productIds, this.props.id_menu);
				this.handleBulkDeletePopup();
			});
		}
	};

	restoreProduct = productId => {
		if (productId) {
			const prod = this.props.productsRemoved.find(p => p.id == productId);
			this.props.productAction.restoreProduct(productId, prod.name);
		}
		this.setState(state => ({
			openPopUpRestoration: false,
		}));
	};

	updateStateCreation() {
		this.setState({ newLine: false, creationInProgress: false });
	}

	render() {
		const {
			productType,
			categories,
			id_menu,
			menu,
			importProduct,
			access,
		} = this.props;
		const {
			products,
			width,
			formProductOpen,
			selectedProduct,
			selectedCategory,
			filters,
			newLine,
			creationInProgress,
			popupDeleteBulkProductOpen,
		} = this.state;
		const leftPosition = (width - 120) / 2 + 120 - 16;
		const style = {
			position: 'fixed',
			top: 45,
			left: leftPosition,
			zIndex: 1002,
		};

		const speedDialDisplay =
			!this.state.gridPriceMode &&
			selectedCategory === undefined &&
			importProduct.active === false;
		const filteredMenu = this.filteredMenu();
		const validators = {
			name: (value, callback) => {
				let result = value !== null && value.length <= 255 && value.length > 0;

				if (callback) {
					callback(result);
				} else {
					return result;
				}
			},
			price: (value, callback) => {
				let result =
					value !== null && value !== undefined
						? parseFloat(
								value
									.toString()
									.replace(/,/, '.')
									.match(/^-?\d*(\.\d+)?$/)
						  ) >= 0
						: false;

				if (callback) {
					callback(result);
				} else {
					return result;
				}
			},
			id_product_type: (value, callback) => {
				let result = value !== null && value !== '';
				if (callback) {
					callback(result);
				} else {
					return result;
				}
			},
			id_tax_rate: (value, callback) => {
				let result = value !== null && value !== '';
				if (callback) {
					callback(result);
				} else {
					return result;
				}
			},
		};
		const productSorted = this.sortProduct();

		if (!access.read) {
			return <AccessForbidden cantRead={true} status={403} />;
		}

		const productsToRestore = this.props.productsRemoved.filter(
			p => p.id_product_type < 201
		);

		return (
			<div className={'product'}>
				<CategoryList
					changeProductCategoryView={this.changeProductCategoryView}
					selectedCategory={selectedCategory}
				/>
				<div className="product-list" onClick={this.onContainerClick}>
					<div className="product-grid">
						<div className="product-header">
							{!!selectedCategory ? (
								''
							) : (
								<div
									style={{
										width: '100%',
										display: 'flex',
										alignItems: 'flex-end',
									}}>
									<div
										style={{
											display: 'inline-block',
											width: 'calc(100% - 280px)',
											float: 'left',
										}}
										className={'filter_bar'}>
										<FilterBar
											filterProducts={this.filterProducts}
											//reSort={this.reSort}
										/>
									</div>
									<div
										style={{
											display: 'inline-block',
											width: '280px',
											float: 'right',
											textAlign: 'right',
										}}
										className={'undo_redo_container'}>
										{productSorted && productSorted.length ? (
											<Tooltip title={I18n.t('product.tooltip_bulk_delete')}>
												<div style={{ marginRight: 30, display: 'inline' }}>
													<Button
														variant="raised"
														color={'other'}
														onClick={this.handleBulkDeletePopup}>
														<DeleteForeverIcon />
													</Button>
												</div>
											</Tooltip>
										) : null}
										{this.props.priceCategories &&
										this.props.priceCategories.length ? (
											<FormControlLabel
												control={
													<Switch
														checked={this.state.gridPriceMode}
														onChange={e => this.handleGridModeChange(e)}
														value="gridMode"
														disabled={newLine}
													/>
												}
												label={I18n.t('product.grid.price_mode')}
												labelPlacement="start"
											/>
										) : null}

										{/* <IconButton
											disabled={!this.props.canUndo}
											onClick={e => {
												e.preventDefault();
												this.props.productAction.undo();
											}}
											aria-label={'undo'}>
											<UndoIcon />
										</IconButton>
										<IconButton
											disabled={!this.props.canRedo}
											onClick={e => {
												e.preventDefault();
												this.props.productAction.redo();
											}}
											aria-label={'undo'}>
											<RedoIcon />
										</IconButton> */}
									</div>
								</div>
							)}
						</div>
						<div
							style={{
								display: selectedCategory === undefined ? 'block' : 'none',
							}}>
							<div
								className={
									this.state.gridPriceMode ? 'product-handsontable-hidden' : ''
								}>
								<ProductList
									products={productSorted}
									categories={categories}
									scrollToBottom={this.state.scrollToBottom}
									resetScrollToBottom={this.resetScrollToBottom}
									className={'Product'}
									onEditClick={this.onEditClick}
									onDeleteClick={this.onDeleteClick}
									id_menu={id_menu}
									filters={filters}
									filteredProduct={this.filterProducts}
									createProduct={this.createProduct}
									saveProduct={this.saveProduct}
									deleteProduct={this.deleteProduct}
									updateProductInCreation={this.updateProductInCreation}
									recoverNameCategory={this.recoverNameCategory}
									creationInProgress={this.state.creationInProgress}
									newLine={this.state.newLine}
									updateProducts={this.updateProducts}
									updateStateCreation={this.updateStateCreation}
									canDelete={access.delete}
									canEdit={access.update}
									notifSkuRequirement={this.notifSkuRequirement}
								/>
							</div>
							<div
								className={
									!this.state.gridPriceMode ? 'product-handsontable-hidden' : ''
								}>
								<ProductPriceList
									products={productSorted}
									filters={filters}
									filteredProduct={this.filterProducts}
									onEditClick={this.onEditClick}
									canEdit={access.update}
								/>
							</div>
						</div>
						{selectedCategory === undefined ? null : (
							<ProductCategoryList
								categoryId={selectedCategory}
								onEditClick={this.onEditClick}
							/>
						)}
					</div>
				</div>

				<div style={style}>
					<SpeedDial
						ariaLabel="action bouton"
						icon={<SpeedDialIcon className={'product_button_main_page'} />}
						onClick={access.create ? this.onClickOpenPoppover : () => {}}
						onClose={this.handleClose}
						ButtonProps={{
							style: {
								backgroundColor: access.create ? '#e84249' : '#e6e6e6',
								boxShadow: access.create ? 'initial' : 'none',
							},
						}}
						open={this.state.addActionBtnOpen}
						classes={{
							root: 'btn_speedDial' + ' ' + this.state.productAddBtnClasses,
							actions: 'btn_speedDial',
						}}
						hidden={!speedDialDisplay}>
						<SpeedDialAction
							icon={<ActionChromeReaderMode id={'button_add_product'} />}
							tooltipTitle={I18n.t('product.speedDial.add_product')}
							onClick={this.onClickAddProduct}
							classes={{ root: 'btn_speedDial' }}
						/>{' '}
						<SpeedDialAction
							icon={<AvPlaylistAdd id={'button_add_product_fast'} />}
							tooltipTitle={
								!creationInProgress
									? I18n.t('product.speedDial.add_product_line')
									: ''
							}
							onClick={newLine ? null : this.onClickAddProductLine}
							ButtonProps={{
								classes: {
									root: newLine
										? 'btn__add_product_line_disabled'
										: 'btn__add_product_line',
								},
							}}
							classes={{ root: 'btn_speedDial' }}
						/>
						{menu.length !== 1 ? (
							<SpeedDialAction
								icon={<AddCircle />}
								classes={{ root: 'btn_speedDial' }}
								tooltipTitle={I18n.t('product.speedDial.add_product_menu')}
								onClick={this.handleChangeAddProductMenu}
								ButtonProps={{ classes: { root: 'btn__add_product_menu' } }}
							/>
						) : (
							''
						)}
						{productsToRestore && productsToRestore.length ? (
							<SpeedDialAction
								icon={<RestoreFromTrashIcon />}
								classes={{ root: 'btn_speedDial' }}
								tooltipTitle={I18n.t('product.speedDial.restore_from_bin')}
								onClick={this.handleShowRestoreProduct}
								ButtonProps={{ classes: { root: 'btn__add_product_menu' } }}
							/>
						) : (
							''
						)}
					</SpeedDial>
				</div>

				<ProductForm
					categories={categories}
					open={formProductOpen}
					onCancel={this.popupCancel}
					onValid={this.popupValid}
					validators={validators}
					leftPosition={leftPosition}
					product={selectedProduct}
					id_menu={id_menu}
				/>

				<PopUpAddProduct
					open={this.state.openPopUp}
					handleChangeAddProductMenu={this.handleChangeAddProductMenu}
					menus={filteredMenu}
					onValid={this.validImport}
					onSelectMenu={this.selectImportMenu}
					onCancel={this.onCloseImport}
					editAssignator={this.props.actionsAssignator.editAssignatorTitle}
				/>
				<SideWindow
					open={popupDeleteBulkProductOpen}
					onCancel={this.handleBulkDeletePopup}>
					{popupDeleteBulkProductOpen ? (
						<ProductAssignator
							onAssignProducts={this.deleteBulkProducts}
							SideWindowpen={popupDeleteBulkProductOpen}
							saveLabel={I18n.t('product.assign_form.delete')}
							checkIcon={ClearIcon}
							title={I18n.t('product.assign_form.title_delete_bulk')}
						/>
					) : null}
				</SideWindow>
				<PopupRestoreBin
					open={this.state.openPopUpRestoration}
					products={productsToRestore}
					type={'product'}
					onSelect={this.restoreProduct}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const { entity, tutorial, loading, access } = state;
	const { variableServer, menu, importProduct, product } = entity;

	const products = getProductsSorted(state);
	return {
		canUndo: entity.product.product_action_past.length > 0,
		canRedo: entity.product.product_action_future.length > 0,
		products: products,
		priceCategories: entity.priceCategory,
		categories: getCategories(state),
		menu,
		importProduct,
		id_menu: getSelectedMenu(variableServer),
		tutorial,
		sort: product.sort,
		access: getAccess(access, 'product'),
		productsRemoved: entity.product.productsRemoved,
		check_sku: getVariableServer(variableServer, 'check_sku'),
		check_sku_int: getVariableServer(variableServer, 'check_sku_int'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		productAction: bindActionCreators(productAction, dispatch),
		actionsAssignator: bindActionCreators(actionsAssignator, dispatch),
		actionsImport: bindActionCreators(actionsImport, dispatch),
		actionsProduct: bindActionCreators(actionsProduct, dispatch),
		tutorialEvent: bindActionCreators(tutorialEvents, dispatch),
		tutorialAction: bindActionCreators(tutorialActions, dispatch),
		notificationAction: bindActionCreators(notificationAction, dispatch),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Product);
