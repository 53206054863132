import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 *
 * @param {Array} items
 * items = [{
 *  name: 'toto',
 *  weight: 0,
 * }]
 * @param {func} setIsDragging
 */
const ProductCategoryHelperItem = React.memo(
	({ items, setIsDragging, size }) => {
		const style = {
			height: 140,
			width: '200px',
			maxWidth: 300,
			marginLeft: 'auto',
			marginRight: 'auto',
			padding: 0,
			backgroundColor: '#f3f3f3',
			textTransform: 'uppercase',
			display: 'flex',
			justifyContent: 'center',
			textWrap: 'balance',
			alignItems: 'center',
		};
		return items.map(val => {
			return (
				<div
					key={val.weight}
					data-id={'2,' + val.weight}
					style={{
						width: size.width,
						height: size.height,
						margin: '1px',
						padding: '1px',
					}}
					className={classNames('stylizedItem', 'gridItem')}
					onMouseUp={e => {
						setIsDragging();
					}}
					onMouseDown={e => {
						setIsDragging();
					}}>
					<div style={style}>{val.name}</div>
				</div>
			);
		});
	}
);

ProductCategoryHelperItem.propTypes = {
	items: PropTypes.array,
	setIsDragging: PropTypes.func,
};

export default ProductCategoryHelperItem;
