import { sort, deepClone } from '../utils';
import _find from 'lodash/find';
import { KEY_FIRST_LOGIN } from '../constants/variable';

export const getProducts = state => {
	return state.entity.product.products;
};
export const getCategories = state => {
	return state.entity.category.categories || [];
};
export const getTaxes = state => state.entity.tax;
export const getProductTypes = state => state.entity.productType;
export const getPlaceSends = state => state.entity.placeSend;
export const getColors = state => state.entity.color;
export const getProductsFilter = state => state.entity.product.sort;

export const getTaxesSorted = taxes => sort(deepClone(taxes));
export const getCategoriesSorted = categories => sort(deepClone(categories));
export const getPlaceSendsSorted = placeSends => sort(deepClone(placeSends));

export const getCategoriesName = categories =>
	categories && categories.length > 0
		? categories.map(category => category.name)
		: [];
export const getColorsName = colors => colors.map(color => color.name);
export const getTaxesName = taxes => taxes.map(tax => tax.name);
export const getPlaceSendsName = placeSends => {
	if (placeSends && placeSends.length > 0)
		return placeSends.map(placeSend => placeSend.name);
};
export const getProductTypesName = productTypes =>
	productTypes.map(productType => productType.name);

export const getSelectedMenu = variables => {
	if (variables.length > 0) {
		const menu = _find(
			variables,
			variable => variable.key_ === 'selected_menu'
		);
		return menu ? menu.value : '';
	}
	return '';
};

export const getAccessCreateMenu = variableServer => {
	if (variableServer.length) {
		const accessCreateMenu = _find(
			variableServer,
			variable => variable.key_ === 'access_admin_menu_new'
		);

		return accessCreateMenu ? Number(accessCreateMenu.value) : 0;
	}
	return 0;
};

export const getNbTempsDenvoi = variables => {
	if (variables.length > 0) {
		const nb = _find(variables, variable => variable.key_ === 'nbTempsDenvoi');
		return parseInt(nb.value, 10);
	}
	return 0;
};
export const getSigle = variables => {
	if (variables.length > 0) {
		const sigle = _find(variables, variable => variable.key_ === 'sigle');
		return sigle.value;
	}
	return '';
};
export const getRestaurantName = variables => {
	if (variables.length > 0) {
		const name = _find(
			variables,
			variable => variable.key_ === 'nomRestaurant'
		);
		return name.value;
	}
	return '';
};
export const getFirstLogin = variables => {
	if (variables.length > 0) {
		const name = _find(
			variables,
			variable => variable.key_ === KEY_FIRST_LOGIN
		);
		return name ? name.value : 0;
	}
	return '';
};
export const getIpadUpdate = variables => {
	if (variables.length > 0) {
		const name = _find(variables, variable => variable.key_ === 'ipadUpdate');
		return name ? name.value : 0;
	}
	return 0;
};

export const getPatchMenuProducts = (
	products,
	synchro,
	id_patch_menu,
	id_menu
) => {
	const productSynchroLines = synchro.filter(
		line => line.id_patch_menu === id_patch_menu && line.type === 'product'
	);
	const lineIds = productSynchroLines.map(p => p.id_table);
	return products.filter(
		product => product.id_menu === id_menu && lineIds.includes(product.id)
	);
};

export const getMenuProducts = (products, id_menu) => {
	return products.filter(product => product.id_menu === id_menu);
};

export const getServiceLang = variables => {
	if (variables.length > 0) {
		const name = _find(variables, variable => variable.key_ === 'service_lang');
		return name ? name.value : 'fr-FR';
	}
	return 'fr-FR';
};

export const getMasterMonoUse = variables => {
	if (variables.length > 0) {
		const variable = _find(
			variables,
			variable => variable.key_ === 'master_mono_use'
		);
		return variable ? variable.value : 0;
	}
	return 0;
};

export const getVariableServer = (variables, key) => {
	if (variables.length > 0) {
		const name = _find(variables, variable => variable.key_ === key);
		return name ? name.value : 0;
	}
	return 0;
};
