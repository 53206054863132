//Little piece of code find on stackoverflow. It return an obj with param find in the url
export default function getAllUrlParams(variable) {
	let query = window.location.search.substring(1);
	let vars = query.split('&');
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split('=');
		// eslint-disable-next-line
		if (decodeURIComponent(pair[0]) == variable) {
			return decodeURIComponent(pair[1]);
		}
	}
	return undefined;
}
