import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { I18n } from 'react-redux-i18n';

class Confirmation extends Component {
	render() {
		const {
			confirmation,
			show,
			proceed,
			dismiss,
			cancel,
			options,
		} = this.props;
		const opts = options || {};

		return (
			<Dialog open={show} onClose={dismiss}>
				<DialogTitle> {opts.title || I18n.t('confirmation')} </DialogTitle>
				<DialogContent>
					<DialogContentText>{confirmation}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={cancel} color={'secondary'}>
						{opts.cancelLabel
							? opts.cancelLabel
							: I18n.t('generalButtons.cancel')}
					</Button>
					<Button onClick={proceed} variant="raised" color={'primary'}>
						{opts.confirmLabel
							? opts.confirmLabel
							: I18n.t('generalButtons.yes')}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

Confirmation.propTypes = {
	show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
	proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
	cancel: PropTypes.func, // from confirmable. call to close the dialog with promise rejected.
	dismiss: PropTypes.func, // from confirmable. call to only close the dialog.
	confirmation: PropTypes.string, // arguments of your confirm function
	options: PropTypes.shape({
		// arguments of your confirm function
		cancelLabel: PropTypes.string,
		confirmLabel: PropTypes.string,
		title: PropTypes.string,
	}),
};

export default confirmable(Confirmation);
