import {
	RESET_PATCH_MENU,
	PRE_DEPLOY_PATCH_MENU,
	DEPLOY_PATCH_MENU,
	PRE_RESET_PATCH_MENU,
} from '../constants/patchMenu';
import {
	call,
	put,
	take,
	actionChannel,
	all,
	select,
} from 'redux-saga/effects';
import { requestHandler } from './fetcher.saga';
import {
	getRestaurantName,
	getServiceLang,
} from '../selectors/global.selector';
import { getMenuSelected, fetchAll } from './login';
import { buffers } from 'redux-saga';
import { ADD_NOTIFICATION, TAGS } from '../constants/notification';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { browserHistory } from 'react-router';

const PRE_REQUEST_RESET_PATCH_MENU = 'PRE_REQUEST_RESET_PATCH_MENU';
const PRE_REQUEST_DEPLOY_PATCH_MENU = 'PRE_REQUEST_DEPLOY_PATCH_MENU';

const REQUEST_RESET_PATCH_MENU_FINISHED = 'REQUEST_RESET_PATCH_MENU_FINISHED';
const REQUESTS_DEPLOY_PATCH_MENU_FINISHED =
	'REQUESTS_DEPLOY_PATCH_MENU_FINISHED';

const ENTITY = 'patchMenu';
const PATCH_METHOD = 'PATCH';
const POST_METHOD = 'POST';

////////////
// RESET  //
////////////
export function* preResetPatchMenu() {
	while (true) {
		const action = yield take(PRE_RESET_PATCH_MENU);
		const { id_menu } = action.patch;

		yield put({
			type: PRE_REQUEST_RESET_PATCH_MENU,
			body: { id_menu },
		});

		yield take(REQUEST_RESET_PATCH_MENU_FINISHED);

		yield call(fetchAll);
		//
		yield put({ type: RESET_PATCH_MENU });
	}
}

export function* watchResetPatchMenu() {
	while (true) {
		const action = yield take(PRE_REQUEST_RESET_PATCH_MENU);
		yield call(
			requestHandler,
			ENTITY,
			PATCH_METHOD,
			true,
			action.body,
			'/reset-patchMenu',
			{
				id_menu: action.body.id_menu,
			}
		);

		yield put({
			type: ADD_NOTIFICATION,
			notification: {
				tags: [TAGS.SUCCESS],
				text: I18n.t('patchMenu.resetSuccess'),
				date: moment().format('DD/MM/YYYY HH:mm'),
				viewed: false,
			},
		});
		yield put({ type: REQUEST_RESET_PATCH_MENU_FINISHED });
	}
}

////////////
// DEPLOY //
////////////
export function* preDeployPatchMenu() {
	while (true) {
		const action = yield take(PRE_DEPLOY_PATCH_MENU);
		const { patchMenu, date } = action;
		const id_menu = action.patchMenu.id_menu;
		let length = 1;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_DEPLOY_PATCH_MENU,
				body: {
					...patchMenu,
					refresh_token: window.localStorage.refresh_token,
				},
				index: i,
				totalLength: length,
			});
		}

		const store = yield select();
		const result = yield take(REQUESTS_DEPLOY_PATCH_MENU_FINISHED);
		let children = result.response.idsCompanies.reduce((acc, id) => {
			let child = store.entity.children.find(c => c.id == id);
			if (child) acc.push(child);
			return acc;
		}, []);

		if (!result.response.patchEmpty && !result.response.updateFail) {
			let zone = store.entity.zone.find(
				z => z.id == window.sessionStorage.getItem('id_zone')
			);

			browserHistory.push({
				pathname: '/deployRecap',
				state: {
					patch: { name: patchMenu.name },
					children: children,
					isMaster: result.response.isMaster,
					id_menu: id_menu,
					nameRestaurant: getRestaurantName(store.entity.variable),
					nameMenu: patchMenu.nameMenu,
					date,
					lang: getServiceLang(store.entity.variableServer),
					zone: zone ? zone.name : null,
				},
			});
		}

		yield put({ type: DEPLOY_PATCH_MENU, response: result.response });
	}
}

export function* watchDeployPatchMenu() {
	while (true) {
		const action = yield take(PRE_REQUEST_DEPLOY_PATCH_MENU);
		const { result } = yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			action.body,
			'/deploy'
		);

		if (result && result.result && result.result.patchEmpty) {
			yield put({
				type: ADD_NOTIFICATION,
				notification: {
					tags: [TAGS.ERROR],
					text: I18n.t('patchMenu.noModifications'),
					date: moment().format('DD/MM/YYYY HH:mm'),
					viewed: false,
				},
			});
		} else if (result && result.result && result.result.updateFail) {
			yield put({
				type: ADD_NOTIFICATION,
				notification: {
					tags: [TAGS.ERROR],
					text: I18n.t('patchMenu.updateFail'),
					date: moment().format('DD/MM/YYYY HH:mm'),
					viewed: false,
				},
			});
		} else if (result && result.result && result.result.deployFail) {
			yield put({
				type: ADD_NOTIFICATION,
				notification: {
					tags: [TAGS.ERROR],
					text: I18n.t('patchMenu.deployFail'),
					date: moment().format('DD/MM/YYYY HH:mm'),
					viewed: false,
				},
			});
		} else {
			yield put({
				type: ADD_NOTIFICATION,
				notification: {
					tags: [TAGS.SUCCESS],
					text: I18n.t('patchMenu.deploySuccess'),
					date: moment().format('DD/MM/YYYY HH:mm'),
					viewed: false,
				},
			});
		}
		yield put({
			type: REQUESTS_DEPLOY_PATCH_MENU_FINISHED,
			response: {
				idsCompanies: result && result.result ? result.result.idsCompanies : [],
				patchEmpty:
					result && result.result ? !!result.result.patchEmpty : false,
				updateFail:
					result && result.result ? !!result.result.updateFail : false,
			},
		});
	}
}
