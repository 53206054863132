import {
	PRE_DELETE_SUPPLEMENT_CATEGORY,
	PRE_CREATE_SUPPLEMENT_CATEGORY,
	PRE_PATCH_SUPPLEMENT_CATEGORY,
	PRE_UPDATE_SUPPLEMENT_CATEGORY,
	DELETE_SUPPLEMENT_CATEGORY,
	CREATE_SUPPLEMENT_CATEGORY,
	UPDATE_SUPPLEMENT_CATEGORY,
	PATCH_SUPPLEMENT_CATEGORY,
	PRE_REWEIGHT_SUPPLEMENT_CATEGORY,
	REWEIGHT_SUPPLEMENT_CATEGORY,
	PRE_ASSIGN_PRODUCTS,
	ASSIGN_PRODUCTS,
} from '../constants/supplementCategory';
import { buffers, channel } from 'redux-saga';
import { call, put, take, actionChannel, select } from 'redux-saga/effects';
import { requestHandler } from './fetcher.saga';
import { ENTITY_EXCLUSION, ENTITY_PRODUCT } from '../utils';
import { getMenuSelected } from './login';
import { RECEIVE_PRODUCT } from '../constants/product';

const PRE_REQUEST_DELETE_SUPPLEMENT_CATEGORY =
	'PRE_REQUEST_DELETE_SUPPLEMENT_CATEGORY';
const PRE_REQUEST_PATCH_SUPPLEMENT_CATEGORY =
	'PRE_REQUEST_PATCH_SUPPLEMENT_CATEGORY';
const PRE_REQUEST_CREATE_SUPPLEMENT_CATEGORY =
	'PRE_REQUEST_CREATE_SUPPLEMENT_CATEGORY';
const PRE_REQUEST_UPDATE_SUPPLEMENT_CATEGORY =
	'PRE_REQUEST_UPDATE_SUPPLEMENT_CATEGORY';
const PRE_REQUEST_REWEIGHT_SUPPLEMENT_CATEGORY =
	'PRE_REQUEST_REWEIGHT_SUPPLEMENT_CATEGORY';
const PRE_REQUEST_ASSIGN_PRODUCTS = 'PRE_REQUEST_ASSIGN_PRODUCTS';

const REQUESTS_DELETE_SUPPLEMENT_CATEGORY_FINISHED =
	'REQUESTS_DELETE_SUPPLEMENT_CATEGORY_FINISHED';
const REQUESTS_PATCH_SUPPLEMENT_CATEGORY_FINISHED =
	'REQUESTS_PATCH_SUPPLEMENT_CATEGORY_FINISHED';
const REQUESTS_CREATE_SUPPLEMENT_CATEGORY_FINISHED =
	'REQUESTS_CREATE_SUPPLEMENT_CATEGORY_FINISHED';
const REQUESTS_UPDATE_SUPPLEMENT_CATEGORY_FINISHED =
	'REQUESTS_UPDATE_SUPPLEMENT_CATEGORY_FINISHED';
const REQUESTS_REWEIGHT_SUPPLEMENT_CATEGORY_FINISHED =
	'REQUESTS_REWEIGHT_SUPPLEMENT_CATEGORY_FINISHED';
const REQUESTS_ASSIGN_PRODUCTS_FINISHED = 'REQUESTS_ASSIGN_PRODUCTS_FINISHED';

const ENTITY = 'supplementCategory';
const DELETE_METHOD = 'DELETE';
const POST_METHOD = 'POST';
const PATCH_METHOD = 'PATCH';
const GET_METHOD = 'GET';

////////////
// DELETE //
////////////
export function* preDeleteSupplementCategory() {
	while (true) {
		const action = yield take(PRE_DELETE_SUPPLEMENT_CATEGORY);
		const { ids, id_menu } = action;
		let length = ids.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_DELETE_SUPPLEMENT_CATEGORY,
				body: { id: ids[i], id_menu: id_menu },
				index: i,
				totalLength: length,
			});
		}
		yield take(REQUESTS_DELETE_SUPPLEMENT_CATEGORY_FINISHED);
		yield put({ type: DELETE_SUPPLEMENT_CATEGORY, ids: ids });
	}
}

export function* watchDeleteSupplementCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_DELETE_SUPPLEMENT_CATEGORY,
		buffers.expanding()
	);

	while (true) {
		const action = yield take(requestChan);

		yield call(requestHandler, ENTITY, DELETE_METHOD, true, action.body);
		if (action.index === action.totalLength - 1) {
			yield put({ type: REQUESTS_DELETE_SUPPLEMENT_CATEGORY_FINISHED });
		}
	}
}

////////////
// PATCH  //
////////////
export function* prePatchSupplementCategory() {
	while (true) {
		const action = yield take(PRE_PATCH_SUPPLEMENT_CATEGORY);
		const { data } = action;
		let length = data.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_PATCH_SUPPLEMENT_CATEGORY,
				body: { patchs: data[i] },
				index: i,
				totalLength: length,
			});
		}

		yield take(REQUESTS_PATCH_SUPPLEMENT_CATEGORY_FINISHED);

		yield put({ type: PATCH_SUPPLEMENT_CATEGORY });
	}
}

export function* watchPatchSupplementCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_PATCH_SUPPLEMENT_CATEGORY,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);
		const id_menu = yield call(getMenuSelected);

		yield call(requestHandler, ENTITY, PATCH_METHOD, true, {
			...action.body.patchs,
			id_menu,
		});

		if (action.index === action.totalLength - 1) {
			yield put({ type: REQUESTS_PATCH_SUPPLEMENT_CATEGORY_FINISHED });
		}
	}
}

////////////
// CREATE //
////////////
export function* preCreateSupplementCategory() {
	while (true) {
		const action = yield take(PRE_CREATE_SUPPLEMENT_CATEGORY);
		const { newSupplementCategories } = action;
		let length = newSupplementCategories.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_CREATE_SUPPLEMENT_CATEGORY,
				body: { supplementCategory: newSupplementCategories[i] },
				index: i,
				totalLength: length,
			});
		}
		let { createdSupplementCategories } = yield take(
			REQUESTS_CREATE_SUPPLEMENT_CATEGORY_FINISHED
		);
		if (!createdSupplementCategories) {
			createdSupplementCategories = [];
		}
		createdSupplementCategories = createdSupplementCategories.filter(
			cs => !!cs.id
		);
		yield put({
			type: CREATE_SUPPLEMENT_CATEGORY,
			newSupplementCategories: createdSupplementCategories,
		});
	}
}

export function* watchCreateSupplementCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_CREATE_SUPPLEMENT_CATEGORY,
		buffers.expanding()
	);
	let createdSupplementCategories = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			action.body.supplementCategory
		);
		let newSupplementCategory = { ...action.body.supplementCategory };
		newSupplementCategory.id = result.result.result;

		createdSupplementCategories.push(newSupplementCategory);

		if (action.index === action.totalLength - 1) {
			yield put({
				type: REQUESTS_CREATE_SUPPLEMENT_CATEGORY_FINISHED,
				createdSupplementCategories: createdSupplementCategories,
			});
			createdSupplementCategories = [];
		}
	}
}

////////////
// UPDATE //
////////////
export function* preUpdateSupplementCategory() {
	const requestChan = yield actionChannel(
		PRE_UPDATE_SUPPLEMENT_CATEGORY,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);
		const { changes } = action;
		const changeLength = changes.length;
		const id_menu = yield call(getMenuSelected);

		for (let i = 0; i < changeLength; i++) {
			let dataToSend;

			if (changes[i].entity === ENTITY_EXCLUSION) {
				dataToSend = {
					id: changes[i].id,
					exclusions: changes[i].value,
					id_menu: id_menu,
				};
			} else if (changes[i].entity === ENTITY_PRODUCT) {
				dataToSend = {
					id_category_supplement: changes[i].id,
					nb_min: changes[i].nb_min,
					nb_max: changes[i].nb_max,
					id_menu: id_menu,
				};
			} else {
				dataToSend = {
					id: changes[i].id,
					key: changes[i].property,
					value: changes[i].value,
					id_menu: id_menu,
				};
			}

			yield put({
				type: PRE_REQUEST_UPDATE_SUPPLEMENT_CATEGORY,
				body: dataToSend,
				index: i,
				totalLength: changeLength,
				entity: changes[i].entity,
			});
		}
		const errored = yield take(REQUESTS_UPDATE_SUPPLEMENT_CATEGORY_FINISHED);
		let idsWithoutError = [];
		let newValuesWithoutError = [];

		for (let i = 0; i < changeLength; i++) {
			if (changes[i].id === errored.errored[i]) {
				continue;
			} else {
				idsWithoutError.push(changes[i].id);
				newValuesWithoutError.push({
					key: changes[i].property,
					value: changes[i].value,
				});
			}
		}

		yield put({
			type: UPDATE_SUPPLEMENT_CATEGORY,
			ids: idsWithoutError,
			newValues: newValuesWithoutError,
		});
	}
}

export function* watchUpdateSupplementCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_UPDATE_SUPPLEMENT_CATEGORY,
		buffers.expanding()
	);
	let errored = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			PATCH_METHOD,
			true,
			action.body,
			action.entity ? '/' + action.entity : ''
		);
		if (result.result === false) {
			errored.push(action.body.id);
		} else {
			errored.push(false);
		}
		if (action.index === action.totalLength - 1) {
			yield put({
				type: REQUESTS_UPDATE_SUPPLEMENT_CATEGORY_FINISHED,
				errored: errored,
			});
			errored = [];
		}
	}
}

//////////////
// REWEIGHT //
//////////////
export function* preReweightSupplementCategory() {
	while (true) {
		const action = yield take(PRE_REWEIGHT_SUPPLEMENT_CATEGORY);
		const { newValues } = action;
		let length = newValues.length;
		const id_menu = yield call(getMenuSelected);
		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_REWEIGHT_SUPPLEMENT_CATEGORY,
				body: { entity: newValues[i], id_menu },
				index: i,
				totalLength: length,
			});
		}
		const result = yield take(REQUESTS_REWEIGHT_SUPPLEMENT_CATEGORY_FINISHED);

		yield put({
			type: REWEIGHT_SUPPLEMENT_CATEGORY,
			newValues: result.successfullRequest,
		});
	}
}

export function* watchReweightSupplementCategory() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_REWEIGHT_SUPPLEMENT_CATEGORY,
		buffers.expanding()
	);
	let successfullRequest = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			PATCH_METHOD,
			true,
			action.body,
			'/weight'
		);
		if (result.result && result.result.status == 200) {
			successfullRequest.push(action.body);
		}

		if (action.index === action.totalLength - 1) {
			yield put({
				type: REQUESTS_REWEIGHT_SUPPLEMENT_CATEGORY_FINISHED,
				successfullRequest: successfullRequest,
			});
			successfullRequest = [];
		}
	}
}

/////////////////////
// ASSIGN PRODUCTS //
/////////////////////
export function* preAssignProducts() {
	const requestChan = yield actionChannel(
		PRE_ASSIGN_PRODUCTS,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);
		const { id, ids_products } = action;

		const id_menu = yield call(getMenuSelected);

		let dataToSend = {
			id_category_supplement: id,
			ids_products: ids_products,
			id_menu: id_menu,
		};
		yield put({
			type: PRE_REQUEST_ASSIGN_PRODUCTS,
			body: dataToSend,
			index: 0,
			totalLength: 1,
		});

		const result = yield take(REQUESTS_ASSIGN_PRODUCTS_FINISHED);

		yield put({ type: ASSIGN_PRODUCTS });
		yield put({ type: RECEIVE_PRODUCT, response: result.products.result });
	}
}

export function* watchAssignProducts() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_ASSIGN_PRODUCTS,
		buffers.expanding()
	);
	let errored = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			action.body,
			'/products'
		);
		if (result.result === false) {
			errored.push(action.body.id);
		} else {
			errored.push(false);
		}
		if (action.index === action.totalLength - 1) {
			const id_menu = yield call(getMenuSelected);
			const products = yield call(
				requestHandler,
				'product',
				GET_METHOD,
				true,
				null,
				'',
				{ id_menu: id_menu }
			);
			yield put({
				type: REQUESTS_ASSIGN_PRODUCTS_FINISHED,
				errored: errored,
				products,
			});

			errored = [];
		}
	}
}
