import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, InputAdornment } from '@material-ui/core';
import { I18n } from 'react-redux-i18n';
import { checkPrice } from './../../../utils';

class PriceRenderer extends Component {
	constructor(props) {
		super(props);
		this.inputRef = null;
		let product = {};
		if (props.products && props.products.length > 0 && props.id !== '') {
			product = props.products.filter(product => product.id === props.id);
			product = product[0];
		}
		if (product === undefined) {
			product = { price: 0 };
		}
		this.state = {
			product,
			price: product.price || 0,
			showInput: false,
		};
	}

	save = e => {
		if (e.charCode == 13) {
			this.setState({ showInput: false }, () =>
				this.props.cellProperties.instance.updateSettings({
					copyPaste: true,
				})
			);
			this.handlePriceChange(
				{ id: this.state.product.id, name: this.state.product.name },
				this.state.price,
				this.props.currentPrice
			);
		}
	};

	componentDidMount() {
		document.addEventListener('click', this.onwtHolderClick);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.onwtHolderClick);
	}

	onwtHolderClick = e => {
		if (
			e &&
			e.realTarget &&
			e.realTarget.className === 'wtHolder' &&
			this.state.showInput
		) {
			e.preventDefault();
			e.stopImmediatePropagation();
			this.inputRef.blur();
		}
	};

	updatePrice = e => {
		this.setState({ showInput: false }, () =>
			this.props.cellProperties.instance.updateSettings({
				copyPaste: true,
			})
		);
		this.handleChange('price');
		this.handlePriceChange(
			{ id: this.state.product.id, name: this.state.product.name },
			this.state.price,
			this.props.currentPrice
		);
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextState.product &&
			nextState.product.id &&
			nextState.product.id === this.state.product.id &&
			nextState.price === this.state.price &&
			nextState.showInput === this.state.showInput &&
			this.state.product.is_free_price === nextState.product.is_free_price &&
			this.state.product.id_retail_price_type ===
				nextState.product.id_retail_price_type
		) {
			return false;
		}
		return true;
	}

	handlePriceChange = (product, newPrice, currentPrice) => {
		let isNumber = checkPrice(newPrice);
		isNumber = Math.abs(isNumber);
		let actualPrice = checkPrice(currentPrice);
		if (
			!isNaN(isNumber) &&
			actualPrice !== isNumber &&
			!this.props.creationInProgress
		) {
			return this.props.productAction.updateProducts(
				[product.id],
				[{ price: isNumber }],
				[false]
			);
		} else if (isNaN(isNumber)) {
			setTimeout(() => {
				this.props.alertWrongPrice(product.name);
				this.setState({ price: currentPrice });
			}, 100);
		}
		if (this.props.creationInProgress && !isNaN(isNumber)) {
			if (product.id === 'main') {
				return this.props.updateProductInCreation(
					[product.id],
					[{ price: isNumber }]
				);
			}
			const productInCreation = this.props.products[
				this.props.products.length - 1
			];
			this.props.alertProductInCreation(productInCreation.name);
		}
	};

	componentWillReceiveProps(nextProps) {
		let product = {};
		if (
			nextProps.products &&
			nextProps.products.length > 0 &&
			nextProps.id !== ''
		) {
			product = nextProps.products.filter(
				product => product.id === nextProps.id
			);
			product = product[0];
		}
		if (!product) {
			product = { price: 0 };
		}
		this.setState({
			product,
			price: product.price || 0,
		});
	}

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value,
		});
	};

	renderPriceInput = () => {
		if ('id' in this.state.product) {
			if (this.state.product.is_free_price) {
				return (
					<span style={{ display: 'block', width: '100%' }}>
						{I18n.t('product.form.free_price')}
					</span>
				);
			} else if (this.state.product.id_retail_price_type != '0') {
				let priceTypeValue = this.props.retailPriceTypes.filter(
					rpt => rpt.id === this.state.product.id_retail_price_type
				);
				if (priceTypeValue.length > 0) {
					priceTypeValue = priceTypeValue[0];
				} else {
					priceTypeValue = { unit: 'Kg' };
				}

				return this.state.showInput ? (
					<div>
						<Input
							inputRef={el => (this.inputRef = el)}
							id="adornment-weight"
							classes={{ input: 'handson_price' }}
							value={this.state.price}
							autoFocus
							onChange={this.handleChange('price')}
							style={{ maxWidth: '100%' }}
							onBlur={this.updatePrice}
							onKeyPress={this.save}
							endAdornment={
								<InputAdornment position="end">
									/{I18n.t(priceTypeValue.unit)}
								</InputAdornment>
							}
						/>
					</div>
				) : (
					<span
						style={{ display: 'block', width: '100%' }}
						onClick={e => {
							e.preventDefault();
							this.props.cellProperties.instance.updateSettings({
								copyPaste: false,
							});
							this.setState({ showInput: true });
						}}>
						{this.state.price} /{I18n.t(priceTypeValue.unit)}
					</span>
				);
			} else {
				return this.state.showInput ? (
					<Input
						inputRef={el => (this.inputRef = el)}
						value={this.state.price}
						autoFocus
						classes={{ input: 'handson_price' }}
						onKeyPress={this.save}
						onBlur={this.updatePrice}
						onChange={this.handleChange('price')}
					/>
				) : (
					<span
						style={{ display: 'block', width: '100%' }}
						onClick={e => {
							e.preventDefault();
							this.props.cellProperties.instance.updateSettings({
								copyPaste: false,
							});
							this.setState({ showInput: true });
						}}>
						{this.state.price}
					</span>
				);
			}
		} else {
			return <span style={{ display: 'block', width: '100%' }} />;
		}
	};

	render() {
		return <div>{this.renderPriceInput()}</div>;
	}
}

PriceRenderer.propTypes = {
	products: PropTypes.array,
	id: PropTypes.string,
};

export default PriceRenderer;
