import {
	RECEIVE_IMPORT_CATEGORY,
	RECEIVE_IMPORT_PRODUCT,
	DISABLE_IMPORT,
	ACTIVE_IMPORT,
} from '../constants/import';

const initialState = { products: [], categories: [], active: false };

const reducer = function taxReducer(state = initialState, action) {
	let newState = { ...state };
	switch (action.type) {
		case RECEIVE_IMPORT_CATEGORY:
			newState.categories = action.response.result;
			return newState;
		case RECEIVE_IMPORT_PRODUCT:
			newState.products = action.response.result;
			return newState;
		case ACTIVE_IMPORT:
			newState.active = true;
			return newState;
		case DISABLE_IMPORT:
			newState.active = false;
			return newState;
		default:
			return state;
	}
};

export default reducer;
