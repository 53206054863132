import {
	RECEIVE_MENU_MASTER_MONO,
	DELETE_MENU_MASTER_MONO,
} from '../constants/menu';

const initialState = [];

const reducer = function menuReducer(state = initialState, action) {
	const newState = [...state];
	switch (action.type) {
		case RECEIVE_MENU_MASTER_MONO:
			if (!action.response.result) {
				return newState;
			}
			return action.response.result;
		case DELETE_MENU_MASTER_MONO:
			const { ids, account_id } = action;

			let newStateWithoutDeleted = newState.map(etab => {
				if (etab.id === account_id) {
					etab.menus = etab.menus.filter(menu => !ids.includes(menu.id));
				}
				return etab;
			});
			return newStateWithoutDeleted;
		default:
			return state;
	}
};

export default reducer;
