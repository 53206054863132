import { buffers } from 'redux-saga';
import {
	take,
	call,
	put,
	all,
	actionChannel,
	select,
} from 'redux-saga/effects';
import { requestHandler } from './fetcher.saga';
import {
	PRE_SET_SERVICE_LANG,
	UPDATE_SESSION_ID,
	PRE_SET_SELECTED_DATE,
} from '../constants/variableServer';
import { browserHistory } from 'react-router';

const PRE_REQUEST_SET_SERVICE_LANG = 'PRE_REQUEST_SET_SERVICE_LANG';
const REQUEST_SET_SERVICE_LANG_FINISHED = 'REQUEST_SET_SERVICE_LANG_FINISHED';
const PRE_REQUEST_UPDATE_SESSION_ID = 'PRE_REQUEST_UPDATE_SESSION_ID';
const REQUEST_UPDATE_SESSION_ID_FINISHED = 'REQUEST_UPDATE_SESSION_ID_FINISHED';
const PRE_REQUEST_SET_SELECTED_DATE = 'PRE_REQUEST_SET_SELECTED_DATE';
const REQUEST_SET_SELECTED_DATE_FINISHED = 'REQUEST_SET_SELECTED_DATE_FINISHED';

function* preSetServiceLang() {
	while (true) {
		const { lang } = yield take(PRE_SET_SERVICE_LANG);

		yield put({ type: PRE_REQUEST_SET_SERVICE_LANG, lang });
		yield take(REQUEST_SET_SERVICE_LANG_FINISHED);

		window.location.reload();
	}
}

function* watchSetServiceLang() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_SET_SERVICE_LANG,
		buffers.expanding()
	);
	while (true) {
		const { lang } = yield take(requestChan);
		yield call(
			requestHandler,
			'variableServer',
			'POST',
			true,
			{ lang },
			'/set-lang'
		);
		yield put({ type: REQUEST_SET_SERVICE_LANG_FINISHED });
	}
}

function* preUpdateSession() {
	while (true) {
		const { master_mono_use, reload } = yield take(UPDATE_SESSION_ID);

		yield put({
			type: PRE_REQUEST_UPDATE_SESSION_ID,
			body: { uuid: window.sessionStorage.getItem('guid'), master_mono_use },
		});
		yield take(REQUEST_UPDATE_SESSION_ID_FINISHED);
		if (reload) {
			window.sessionStorage.setItem('ALREADY_IN_USE', false);
			window.location.reload();
		}
	}
}

function* watchSetUpdateSession() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_UPDATE_SESSION_ID,
		buffers.expanding()
	);
	while (true) {
		const { body } = yield take(requestChan);
		yield call(
			requestHandler,
			'variableServer',
			'PUT',
			true,
			body,
			'/update-session'
		);
		yield put({ type: REQUEST_UPDATE_SESSION_ID_FINISHED });
	}
}

function* preSetSelectedDate() {
	while (true) {
		const { date } = yield take(PRE_SET_SELECTED_DATE);

		yield put({ type: PRE_REQUEST_SET_SELECTED_DATE, date });
		yield take(REQUEST_SET_SELECTED_DATE_FINISHED);
		browserHistory.push('/');
		window.location.reload();
	}
}

function* watchSetSelectedDate() {
	while (true) {
		const { date } = yield take(PRE_REQUEST_SET_SELECTED_DATE);
		yield call(
			requestHandler,
			'variableServer',
			'POST',
			true,
			{ date },
			'/select-date'
		);
		yield put({ type: REQUEST_SET_SELECTED_DATE_FINISHED });
	}
}

export default function* variableServerSaga() {
	yield all([
		preSetServiceLang(),
		watchSetServiceLang(),
		preUpdateSession(),
		watchSetUpdateSession(),
		preSetSelectedDate(),
		watchSetSelectedDate(),
	]);
}
