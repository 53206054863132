import { RECEIVE_SYNCHRO } from '../constants/synchro';

const initialState = [];

const reducer = function synchroReducer(state = initialState, action) {
	const newState = { ...state };
	switch (action.type) {
		case RECEIVE_SYNCHRO:
			if (action.response.result.length === 0) {
				return newState;
			}
			const synchros = action.response.result;
			return synchros;
		default:
			return state;
	}
};

export default reducer;
