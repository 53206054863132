import { NEW_CLIENT } from '../constants/patchMenu';

const initialState = [];

const reducer = function patchMenuReducer(state = initialState, action) {
	let newState = [...state];
	switch (action.type) {
		case NEW_CLIENT:
			newState.isNewClient = action.response;
			return newState;

		default:
			return state;
	}
};

export default reducer;
