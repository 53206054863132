import { RECEIVE_ZONE } from '../constants/zone';

const initialState = [];

const reducer = function zoneReducer(state = initialState, action) {
	const newState = [...state];
	switch (action.type) {
		case RECEIVE_ZONE:
			return action.response.result;

		default:
			return state;
	}
};

export default reducer;
