import { buffers } from 'redux-saga';
import { call, put, take, actionChannel, select } from 'redux-saga/effects';
import { requestHandler } from './fetcher.saga';
import {
	CREATE_PRODUCT_MENU,
	PRE_CREATE_PRODUCT_MENU,
	PRE_UPDATE_PRODUCT_MENU,
	UPDATE_PRODUCT_MENU,
	PRE_DELETE_PRODUCT_MENU,
	DELETE_PRODUCT_MENU,
	PRE_REWEIGHT_PRODUCT_MENU,
	REWEIGHT_PRODUCT_MENU,
	REQUESTS_REWEIGHT_PRODUCT_MENU_FINISHED,
	PRE_REQUEST_REWEIGHT_PRODUCT_MENU,
	RECEIVE_PRODUCT_MENU,
} from '../constants/productMenu';

import { getMenuSelected } from './login';

const ENTITY = 'productMenu';
const DELETE_METHOD = 'DELETE';
const POST_METHOD = 'POST';
const PATCH_METHOD = 'PATCH';

const PRE_REQUEST_UPDATE_PRODUCT_MENU = 'PRE_REQUEST_UPDATE_PRODUCT_MENU';
const PRE_REQUEST_CREATE_PRODUCT_MENU = 'PRE_REQUEST_CREATE_PRODUCT_MENU';
const PRE_REQUEST_DELETE_PRODUCT_MENU = 'PRE_REQUEST_DELETE_PRODUCT_MENU';

const REQUESTS_UPDATE_PRODUCT_MENU_FINISHED =
	'REQUESTS_UPDATE_PRODUCT_MENU_FINISHED';
const REQUESTS_CREATE_PRODUCT_MENU_FINISHED =
	'REQUESTS_CREATE_PRODUCT_MENU_FINISHED';
const REQUESTS_DELETE_PRODUCT_MENU_FINISHED =
	'REQUESTS_DELETE_PRODUCT_MENU_FINISHED';

////////////
// UPDATE //
////////////
export function* preUpdateProductMenu() {
	const requestChan = yield actionChannel(
		PRE_UPDATE_PRODUCT_MENU,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);
		const { id, exclusions, priceCategory, productMenu, menuLevels } = action;
		const id_menu = yield call(getMenuSelected);
		yield put({
			type: PRE_REQUEST_UPDATE_PRODUCT_MENU,
			body: exclusions,
			id_menu,
		});
		yield take(REQUESTS_UPDATE_PRODUCT_MENU_FINISHED);
		yield put({
			type: PRE_REQUEST_UPDATE_PRODUCT_MENU,
			body: priceCategory,
			id_menu,
		});
		yield take(REQUESTS_UPDATE_PRODUCT_MENU_FINISHED);
		for (let i = 0; i <= Object.keys(productMenu.changes).length - 1; i++) {
			const key = Object.keys(productMenu.changes)[i];
			if (
				key !== 'menuLevels' &&
				key !== 'exclusions' &&
				key !== 'priceCategory' &&
				key !== 'prices' &&
				key !== id
			) {
				yield put({
					type: PRE_REQUEST_UPDATE_PRODUCT_MENU,
					body: productMenu,
					key: key,
					id: id,
					id_menu,
				});
				yield take(REQUESTS_UPDATE_PRODUCT_MENU_FINISHED);
			}
		}

		for (
			let i = 0, deleteLength = menuLevels.deleted.length;
			i <= deleteLength - 1;
			i++
		) {
			let body = {
				entity: 'menuLevel',
				route: '',
				method: 'DELETE',
				changes: { ...menuLevels.deleted[i] },
			};
			yield put({
				type: PRE_REQUEST_UPDATE_PRODUCT_MENU,
				body: body,
				id_menu,
			});
			yield take(REQUESTS_UPDATE_PRODUCT_MENU_FINISHED);
		}

		for (
			let i = 0, updateLength = menuLevels.updated.length;
			i <= updateLength - 1;
			i++
		) {
			let body = {
				entity: 'menuLevel',
				route: 'product',
				method: 'POST',
				changes: {
					id_menu_level: menuLevels.updated[i].id,
					id_product_menu: id,
					products: [...menuLevels.updated[i].products],
				},
			};
			yield put({
				type: PRE_REQUEST_UPDATE_PRODUCT_MENU,
				body: body,
				id_menu,
			});
			yield take(REQUESTS_UPDATE_PRODUCT_MENU_FINISHED);
			for (
				let j = 0, diffLength = Object.keys(menuLevels.updated[i].diff).length;
				j <= diffLength - 1;
				j++
			) {
				const key = Object.keys(menuLevels.updated[i].diff)[j];
				if (key !== 'products') {
					let bodyDiff = {
						entity: 'menuLevel',
						route: '',
						method: 'PATCH',
						changes: {
							id: menuLevels.updated[i].id,
							id_product_menu: menuLevels.updated[i].id_product_menu,
							key: key,
							value: menuLevels.updated[i].diff[key],
						},
					};
					yield put({
						type: PRE_REQUEST_UPDATE_PRODUCT_MENU,
						body: bodyDiff,
						id_menu,
					});
					yield take(REQUESTS_UPDATE_PRODUCT_MENU_FINISHED);
				}
			}
		}

		for (
			let i = 0, createLength = menuLevels.created.length;
			i <= createLength - 1;
			i++
		) {
			let body = {
				entity: 'menuLevel',
				route: '',
				method: 'POST',
				changes: { ...menuLevels.created[i], id_product_menu: id },
			};
			yield put({
				type: PRE_REQUEST_UPDATE_PRODUCT_MENU,
				body: body,
				id_menu,
			});
			yield take(REQUESTS_UPDATE_PRODUCT_MENU_FINISHED);
		}

		yield call(refreshProductMenu);
	}
}

export function* refreshProductMenu() {
	const id_menu = yield call(getMenuSelected);
	const newProductsMenu = yield call(
		requestHandler,
		'productMenu',
		'GET',
		true,
		null,
		'',
		{
			id_menu: id_menu,
		}
	);

	yield put({ type: UPDATE_PRODUCT_MENU, response: newProductsMenu.result });
}

export function* watchUpdateProductMenu() {
	const buffer = buffers.expanding();
	const requestChan = yield actionChannel(
		PRE_REQUEST_UPDATE_PRODUCT_MENU,
		buffer
	);
	while (true) {
		const action = yield take(requestChan);
		let newChanges = action.body.changes;
		let changes;
		if (action.key) {
			changes = {
				id: action.id,
				key: action.key,
				value: newChanges[action.key],
			};
		} else {
			changes = { ...newChanges };
		}

		changes.id_menu = action.id_menu;

		yield call(
			requestHandler,
			action.body.entity,
			action.body.method,
			true,
			changes,
			action.body.route !== '' ? '/' + action.body.route : ''
		);

		if (buffer.isEmpty()) {
			yield put({ type: REQUESTS_UPDATE_PRODUCT_MENU_FINISHED });
		}
	}
}

////////////
// CREATE //
////////////
export function* preCreateProductMenu() {
	while (true) {
		const action = yield take(PRE_CREATE_PRODUCT_MENU);
		const { newProductMenu } = action;
		let length = newProductMenu.length;

		const id_menu = yield call(getMenuSelected);
		for (let i = 0; i < length; i++) {
			newProductMenu[i].id_menu = id_menu;
			yield put({
				type: PRE_REQUEST_CREATE_PRODUCT_MENU,
				body: { newProductMenu: newProductMenu[i] },
				index: i,
				totalLength: length,
				id_menu: id_menu,
			});
		}
		let { createdProductMenus } = yield take(
			REQUESTS_CREATE_PRODUCT_MENU_FINISHED
		);
		if (!createdProductMenus) {
			createdProductMenus = {};
			createdProductMenus.result = [];
		}
		yield put({
			type: CREATE_PRODUCT_MENU,
			response: createdProductMenus.result,
		});
	}
}

export function* watchCreateProductMenu() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_CREATE_PRODUCT_MENU,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			action.body.newProductMenu
		);
		let newProductMenu = { ...action.body.newProductMenu };
		newProductMenu.id = result.result.result;

		if (action.index === action.totalLength - 1) {
			const newProductsMenu = yield call(
				requestHandler,
				'productMenu',
				'GET',
				true,
				null,
				'',
				{
					id_menu: action.id_menu,
				}
			);
			yield put({
				type: REQUESTS_CREATE_PRODUCT_MENU_FINISHED,
				createdProductMenus: newProductsMenu,
			});
		}
	}
}

////////////
// DELETE //
////////////
export function* preDeleteProductMenu() {
	while (true) {
		const action = yield take(PRE_DELETE_PRODUCT_MENU);
		const { ids } = action;
		const id_menu = yield call(getMenuSelected);

		let length = ids.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_DELETE_PRODUCT_MENU,
				body: { id: ids[i], id_menu: id_menu },
				index: i,
				totalLength: length,
				id_menu: id_menu,
			});
		}
		const { newProductsMenu } = yield take(
			REQUESTS_DELETE_PRODUCT_MENU_FINISHED
		);
		yield put({ type: DELETE_PRODUCT_MENU, response: newProductsMenu.result });
	}
}

export function* watchDeleteProductMenu() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_DELETE_PRODUCT_MENU,
		buffers.expanding()
	);

	while (true) {
		const action = yield take(requestChan);

		yield call(requestHandler, ENTITY, DELETE_METHOD, true, action.body);
		if (action.index === action.totalLength - 1) {
			const newProductsMenu = yield call(
				requestHandler,
				'productMenu',
				'GET',
				true,
				null,
				'',
				{
					id_menu: action.id_menu,
				}
			);
			yield put({
				type: REQUESTS_DELETE_PRODUCT_MENU_FINISHED,
				newProductsMenu: newProductsMenu,
			});
		}
	}
}

////////////
// REWEIGHT //
////////////
export function* preReweightProductMenu() {
	while (true) {
		const action = yield take(PRE_REWEIGHT_PRODUCT_MENU);
		const { newWeights } = action;
		const id_menu = yield call(getMenuSelected);

		yield put({
			type: PRE_REQUEST_REWEIGHT_PRODUCT_MENU,
			body: {
				entity: newWeights,
				id_menu,
			},
			index: 0,
			totalLength: 1,
		});

		const result = yield take(REQUESTS_REWEIGHT_PRODUCT_MENU_FINISHED);

		const productMenus = yield call(
			requestHandler,
			'productMenu',
			'GET',
			true,
			null,
			'',
			{
				id_menu: id_menu,
			}
		);

		yield put({
			type: RECEIVE_PRODUCT_MENU,
			response: productMenus.result,
		});
	}
}

export function* watchReweightProductMenu() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_REWEIGHT_PRODUCT_MENU,
		buffers.expanding()
	);
	let successfullRequest = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			PATCH_METHOD,
			true,
			action.body,
			'/weight'
		);
		if (result.result && result.result.status == 200) {
			successfullRequest.push(action.body);
		}

		if (action.index === action.totalLength - 1) {
			yield put({
				type: REQUESTS_REWEIGHT_PRODUCT_MENU_FINISHED,
				successfullRequest: successfullRequest,
			});
			successfullRequest = [];
		}
	}
}

export function* restoreProductMenuLevel(id_product, id_menu) {
	yield call(
		requestHandler,
		'menuLevel',
		'PATCH',
		true,
		{
			id_product,
			id_menu,
		},
		'/restore'
	);
}
