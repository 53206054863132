import React, { Component } from 'react';

import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import ProductAssignator from './ProductAssignator';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { I18n } from 'react-redux-i18n';
import SideWindow from './SideWindow';

const styles = theme => ({
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
});

class PopUpAddProduct extends Component {
	constructor() {
		super();
		this.state = {
			SideWindowOpen: false,
			open: false,
			card: {},
			disabledButtonSave: true,
		};
	}

	handleOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handleChange = event => {
		this.setState({ card: event.target.value }, () => {
			this.state.card === {}
				? this.setState({ disabledButtonSave: true })
				: this.setState({ disabledButtonSave: false });
		});
	};

	handleClosePopUp = e => {
		this.setState({ card: {} });
		this.props.handleChangeAddProductMenu(e);
	};

	closeSideWindow = () => {
		this.setState({ SideWindowOpen: false }, () => this.props.onCancel());
	};

	handleSelectMenu = () => {
		this.props.onSelectMenu(this.state.card.id);
		this.setState({ SideWindowOpen: true });
		this.props.editAssignator('', 'Produit');
	};

	handleValidAssigntor = (e, ids) => {
		this.closeSideWindow();
		this.props.onValid(this.state.card.id, ids);
	};

	render() {
		const { menus, classes } = this.props;
		const { card, SideWindowOpen, disabledButtonSave } = this.state;
		return (
			<div>
				<Dialog open={this.props.open} onClose={this.handleClose}>
					<DialogTitle>{I18n.t('product.popUpAddProduct.title')}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor="demo-controlled-open-select">
									Carte
								</InputLabel>
								<Select
									open={this.state.open}
									onClose={this.handleClose}
									onOpen={this.handleOpen}
									value={card}
									onChange={this.handleChange}>
									{menus.map((menu, index) => {
										return (
											<MenuItem key={index} value={menu}>
												{menu.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClosePopUp} color="primary">
							{I18n.t('generalButtons.cancel')}
						</Button>
						<Button
							color="primary"
							disabled={disabledButtonSave}
							onClick={this.handleSelectMenu}>
							{I18n.t('product.popUpAddProduct.import')}
						</Button>
					</DialogActions>
				</Dialog>
				<SideWindow open={SideWindowOpen} onCancel={this.closeSideWindow}>
					<ProductAssignator
						SideWindowpen={SideWindowOpen}
						onAssignProducts={this.handleValidAssigntor}
					/>
				</SideWindow>
			</div>
		);
	}
}

export default withStyles(styles)(PopUpAddProduct);
