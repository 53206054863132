import React, { Component } from 'react';
import vault from '../img/vault.svg';
import { I18n } from 'react-redux-i18n';
import { Button } from '@material-ui/core';

class AccessForbidden extends Component {
	constructor(props) {
		super(props);
	}

	switchSession = () => {
		this.props.updateSessionId(
			true,
			!!window.sessionStorage.getItem('master_mono_child')
		);
	};

	render() {
		const canSwitchSession = sessionStorage.master_mono_child
			? true
			: this.props.master_mono_use == 1
			? false
			: true;

		let canReload = false;

		const code = this.props.status ? String(this.props.status) : '403';
		let text = '';
		if (this.props.checkVersion === false) {
			text = I18n.t('no_access_version');
		} else if (this.props.isModificationPossible === false) {
			if (this.props.needActivation) {
				text = I18n.t('no_access_need_activation');
			} else {
				text = I18n.t('no_access_reject');
			}
		} else if (this.props.taxeRateEmpty === true) {
			text = I18n.t('no_access_tax_rate_empty');
		} else if (this.props.multiWindow === true) {
			text = I18n.t('no_access_multi_window');
		} else if (this.props.urlRejected) {
			text = I18n.t('wrong_url');
		} else if (this.props.inSynchro) {
			if (this.props.maintenance === true) {
				text = I18n.t('no_access_maintenance');
			} else {
				text = I18n.t('no_access_in_synchro');
			}
			canReload = true;
		} else if (this.props.cantRead) {
			text = I18n.t('no_access_restriction');
		} else {
			text = I18n.t('no_access');
		}

		return (
			<div style={{ textAlign: 'center', color: 'white' }}>
				<h1 style={{ fontSize: '5em', color: '#e84249' }}>
					{code[0]}
					<span>{code[1]}</span>
					{code[2]}
				</h1>
				<h3 style={{ marginRight: '50px', marginLeft: '50px' }}>{text}</h3>
				{this.props.multiWindow ? (
					<div>
						{' '}
						<div>
							<p>{I18n.t('no_access_multi_window_2')}</p>
							<p>
								<Button
									variant="raised"
									color={'primary'}
									onClick={this.switchSession}>
									{I18n.t('no_access_multi_window_button')}
								</Button>
							</p>
						</div>
					</div>
				) : null}

				<img src={vault} alt={'Vault image'} />
				{canReload ? (
					<p>
						<Button
							variant="raised"
							color={'primary'}
							onClick={() => {
								window.location.reload(false);
							}}>
							{I18n.t('generalButtons.reload')}
						</Button>
					</p>
				) : null}
			</div>
		);
	}
}

export default AccessForbidden;
