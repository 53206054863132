import React, { Component } from 'react';
import { Portal } from 'react-portal';
import MuiThemeProvider from 'material-ui-old/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui-old/styles/getMuiTheme';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SelectMultiple from './utils/SelectMultiple';
import _isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import { ENTITY_CATEGORY } from '../utils';

class SelectPortal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: props.selected,
			initialSelected: props.selected,
			multipleSelectIsOpen: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.onChangeMultiple = this.onChangeMultiple.bind(this);
		this.onCloseMultiple = this.onCloseMultiple.bind(this);
		this.handleMultipleOpen = this.handleMultipleOpen.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		const { selected } = nextProps;
		this.setState({
			selected: selected,
			initialSelected:
				nextProps.isMultipleSelect === true ||
				nextProps.isMultipleSelect === 'true'
					? selected.split(',')
					: selected,
		});
	}

	handleChange(event) {
		const value = event.target.value;
		this.setState({ selected: value });
		this.props.handleUpdate(
			value,
			this.props.productId,
			this.props.dataKeyAccessor
		);
	}

	onChangeMultiple(value) {
		this.setState({ selected: value.join(',') });
	}

	onCloseMultiple(value) {
		this.setState({ multipleSelectIsOpen: false });
		if (!_isEqual(value.sort(), this.state.initialSelected.sort())) {
			this.props.handleUpdate(
				value,
				this.props.productId,
				'categories',
				ENTITY_CATEGORY
			);
		}
	}

	handleClick(e) {
		e.stopPropagation();
	}

	handleMultipleOpen(isOpen) {
		this.setState({ multipleSelectIsOpen: isOpen });
	}

	render() {
		const {
			items,
			position,
			elementStyle,
			display,
			isMultipleSelect,
			dataKeyAccessor,
			products,
			productId,
		} = this.props;
		let { selected } = this.state;
		let disabledItem;
		if (isMultipleSelect === true || isMultipleSelect === 'true') {
			selected = selected !== '' ? selected.split(',') : [''];
		}
		if (dataKeyAccessor === 'id_place_send') {
			const targetedProduct = find(
				products,
				product => product.id === productId
			);
			if (targetedProduct && targetedProduct.id_place_send_2 != 0) {
				disabledItem = targetedProduct.id_place_send_2;
			}
		}

		const muiTheme = getMuiTheme({
			palette: {
				primary1Color: '#1f8dd6',
				pickerHeaderColor: '#1f8dd6',
			},
		});
		return (
			<Portal node={this.props.node}>
				{items.length > 0 ? (
					<div
						className={'handsontableInputHolder'}
						onClick={this.handleClick}
						style={{
							top: position.top,
							left: position.left - 1,
							display: display,
							position: 'fixed',
						}}>
						<MuiThemeProvider muiTheme={muiTheme}>
							{isMultipleSelect === true || isMultipleSelect === 'true' ? (
								<SelectMultiple
									elements={items}
									values={selected}
									onSelectChange={this.onChangeMultiple}
									onSelectClose={this.onCloseMultiple}
									handleMultipleOpen={this.handleMultipleOpen}
									selectStyle={{
										width: elementStyle.width - 8,
										height: elementStyle.height + 1,
										maxHeight: elementStyle.height + 1,
										maxWidth: elementStyle.width - 8,
										backgroundColor: elementStyle.backgroundColor,
									}}
									isOpen={this.state.multipleSelectIsOpen}
									iconClass={{ icon: 'select_icon__fullWidth' }}
									selectClassname={'handsontableInput'}
									//hideInput={true}
								/>
							) : (
								<Select
									value={selected}
									onChange={this.handleChange}
									id={'select-in-handson'}
									className={'handsontableInput'}
									classes={{ icon: 'select_icon__fullWidth' }}
									style={{
										width: elementStyle.width - 8,
										height: elementStyle.height + 1,
										maxHeight: elementStyle.height + 1,
										maxWidth: elementStyle.width - 8,
										backgroundColor: elementStyle.backgroundColor,
									}}>
									{items.map((item, index) => {
										return (
											<MenuItem
												key={index}
												value={item.value}
												disabled={item.value === disabledItem}>
												{item.text}
											</MenuItem>
										);
									})}
								</Select>
							)}
						</MuiThemeProvider>
					</div>
				) : (
					<div />
				)}
			</Portal>
		);
	}
}

SelectPortal.defaultProps = {
	isMultipleSelect: false,
};

export default SelectPortal;
