import {
	START_TUTO,
	STOP_TUTO,
	START_TUTO_TAX,
	START_TUTO_PRODUCTS,
	STOP_TUTO_PRODUCTS,
	START_TUTO_SUPPLEMENT,
	STOP_TUTO_PRODUCT_MENU,
	START_TUTO_PRODUCT_MENUS,
	START_TUTO_PRICE_CATEGORY,
	ACTIVE_TUTORIAL,
} from '../constants/tutorial';

export const startTutorial = () => {
	return {
		type: START_TUTO,
	};
};

export const stopTutorial = () => {
	return {
		type: STOP_TUTO,
	};
};

export const stopTutorialProductMenu = () => {
	return {
		type: STOP_TUTO_PRODUCT_MENU,
	};
};

export const startTutorialProducts = () => {
	return {
		type: START_TUTO_PRODUCTS,
	};
};

export const stopTutorialProducts = () => {
	return {
		type: STOP_TUTO_PRODUCTS,
	};
};

export const startTutorialProductMenus = () => {
	return {
		type: START_TUTO_PRODUCT_MENUS,
	};
};

export const startTutorialSupplement = () => {
	return {
		type: START_TUTO_SUPPLEMENT,
	};
};

export const startTutorialTax = () => {
	return {
		type: START_TUTO_TAX,
	};
};

export const startTutorialPriceCategory = () => {
	return {
		type: START_TUTO_PRICE_CATEGORY,
	};
};

export const activeTutorial = active => {
	return {
		type: ACTIVE_TUTORIAL,
		value: active,
	};
};
