import {
	RECEIVE_TAX,
	DELETE_TAX,
	CREATE_TAX,
	REFRESH_TAX_LIST,
	UPDATE_TAX,
} from '../constants/tax';

const initialState = [];

const reducer = function taxReducer(state = initialState, action) {
	let newState = [...state];
	switch (action.type) {
		case CREATE_TAX:
		case UPDATE_TAX:
		case DELETE_TAX:
		case RECEIVE_TAX:
			// if (action.response.result.length === 0) {
			//   return newState;
			// }
			const taxes = action.response.result;
			return taxes;

		case REFRESH_TAX_LIST:
			const { newList } = action;

			newState = newList;

			return newState;
		default:
			return state;
	}
};

export default reducer;
