import {
	ADD_LOADING,
	SUBSTRACT_LOADING,
	RESET_LOADING,
	RECEIVED_ALL,
	INIT_RECEIVED_ALL,
} from '../constants/loading';

const initialState = {
	totalLoading: 0,
	isLoading: true,
	receivedAll: false,
};

const reducer = function loadingReducer(state = initialState, action) {
	const newState = { ...state };
	switch (action.type) {
		case ADD_LOADING:
			return {
				...newState,
				totalLoading: newState.totalLoading + 1,
				isLoading: true,
			};
		case SUBSTRACT_LOADING:
			return {
				...newState,
				totalLoading: newState.totalLoading - 1,
				isLoading: newState.totalLoading - 1 > 0,
			};
		case RESET_LOADING:
			return {
				...newState,
				totalLoading: 0,
				isLoading: false,
			};
		case RECEIVED_ALL:
			return {
				receivedAll: true,
				isLoading: false,
				totalLoading: 0,
			};
		case INIT_RECEIVED_ALL:
			return {
				...newState,
				receivedAll: false,
				isLoading: true,
			};
		default:
			return state;
	}
};

export default reducer;
