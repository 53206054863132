import React, { Component } from 'react';
import ColorPicker from './ColorPicker';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css';
import { I18n } from 'react-redux-i18n';
import {
	deepEqual,
	ENTITY_CATEGORY,
	isProductInMenu,
	colorNameToId,
	deepClone,
} from '../utils';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { renderReactCell, MemoizedReactDomContainers } from 'react-lru';
import EditOrDeleteCell from './handsonTable/cellRenderer/EditOrDeleteCell';
import SaveOrDeleteCell from './handsonTable/cellRenderer/SaveOrDeleteCell';
import { connect } from 'react-redux';
import * as globalSelector from '../selectors/global.selector';
import * as productAction from '../actions/product.action';
import { bindActionCreators } from 'redux';
import SelectRenderer from './handsonTable/cellRenderer/SelectRenderer';
import SelectPortal from './SelectPortal';
import * as notificationAction from '../actions/notification.action';
import { TAGS } from '../constants/notification';
import moment from 'moment';
import PriceRenderer from './handsonTable/cellRenderer/PriceRenderer';
import { getDefaultColor } from '../selectors/color.selector';

const validators = {
	name: (value, callback) => {
		let result = value !== null && value.length <= 255 && value.length > 0;

		if (callback) {
			callback(result);
		} else {
			return result;
		}
	},
	price: (value, callback) => {
		let result =
			value !== null && value !== undefined
				? parseFloat(
						value
							.toString()
							.replace(/,/, '.')
							.match(/^-?\d*(\.\d+)?$/)
				  ) >= 0
				: false;

		if (callback) {
			callback(result);
		} else {
			return result;
		}
	},
};

let canSelectCell = true;
let defaultData = {
	name: '',
	short_name: '',
	description: '',
	price: '0.00',
	id_tax_rate: '',
	id_color: '',
	id_product_type: 1,
	id_place_send: 'def0-1',
	id_place_send_2: '',
	id_retail_price_type: '0',
	active: true,
	id_status_send_index: 0,
	sku: '',
	is_free_price: false,
	id_menu: '',
	supplements: [],
	exclusions: [],
	categories: [],
	prices: [],
};

const memoizedContainers = new MemoizedReactDomContainers(2000);

class ProductList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: 0,
			height: 0,
			currentSelection: null,
			portalTarget: null,
			portalSelectedId: '',
			portalItems: [],
			portalPosition: {},
			portalElementStyle: {},
			portalDisplay: 'none',
			portalDataKeyAccessor: '',
			portalIsMultipleSelect: false,
			receivedAll: false,
		};

		this.alertProductInCreation = this.alertProductInCreation.bind(this);
		this.alertProductInMenu = this.alertProductInMenu.bind(this);
		this.handlePortalIssue = this.handlePortalIssue.bind(this);
		this.onContainerClick = this.onContainerClick.bind(this);
		this.updateDimensions = this.updateDimensions.bind(this);
		this.getDefaultTaxId = this.getDefaultTaxId.bind(this);
		this.checkTypeValues = this.checkTypeValues.bind(this);
		this.alertWrongPrice = this.alertWrongPrice.bind(this);
		this.checkTypePrice = this.checkTypePrice.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.scrollToBottom === true) {
			this.tableWrapper.hotInstance.scrollViewportTo(
				this.props.products.length - 1,
				0,
				true
			);
			this.props.resetScrollToBottom();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.scrollToBottom === true) {
			this.tableWrapper.hotInstance.scrollViewportTo(
				this.props.products.length - 1,
				0,
				true
			);
			this.props.resetScrollToBottom();
		}
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);

		this.handlePortalIssue('navLeft-container');
		this.handlePortalIssue('header-container');

		const categoryListElement = document.getElementById('category-list');
		if (categoryListElement) {
			categoryListElement.addEventListener('mousedown', this.onContainerClick);
		}

		const height =
			window.innerHeight ||
			document.documentElement.clientHeight ||
			document.body.clientHeight;

		const width =
			window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth;

		this.setState({ width, height });
	}

	updateDimensions() {
		const height =
			window.innerHeight ||
			document.documentElement.clientHeight ||
			document.body.clientHeight;

		const width =
			window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth;

		this.setState({ width, height });
	}

	handlePortalIssue(id) {
		const element = document.getElementById(id);
		if (element) {
			element.addEventListener('mousedown', this.onContainerClick);
		}
	}

	onContainerClick(ev) {
		if (this.state.portalTarget !== '') {
			this.setState({
				portalTarget: '',
				portalSelectedId: '',
				portalItems: [],
				portalPosition: {},
				portalElementStyle: {},
				portalDisplay: 'none',
				portalProductId: '',
			});
		}
	}

	handleUpdate(value, productId, keyAccessor, entity = false) {
		let values = [];
		let ids = [productId];
		let entities = [];
		const { creationInProgress } = this.props;
		if (creationInProgress && productId !== 'main') {
			const productInCreation = this.props.products[
				this.props.products.length - 1
			];
			return this.alertProductInCreation(productInCreation.name);
		}
		if (keyAccessor === 'id_product_type') {
			const targetedProduct = find(
				this.props.products,
				product => product.id === productId
			);
			this.props.notificationAction.addNotification({
				tags: [TAGS.WARNING],
				text: I18n.t('product.productTypeChange', {
					productName: `( ${targetedProduct.name} )`,
				}),
				date: moment().format('DD/MM/YYYY HH:mm'),
				viewed: false,
				openable: false,
			});
		}

		if (keyAccessor === 'id_tax_rate') {
			const targetedProduct = find(
				this.props.products,
				product => product.id === productId
			);
			if (
				targetedProduct.id_tax_rate !== '' &&
				targetedProduct.id_tax_rate !== value
			) {
				this.props.notificationAction.addNotification({
					tags: [TAGS.WARNING],
					text: I18n.t('product.productTVAChange', {
						productName:
							targetedProduct.name !== '' ? `(${targetedProduct.name})` : '',
					}),
					date: moment().format('DD/MM/YYYY HH:mm'),
					viewed: false,
					openable: false,
				});
			}
		}
		if (keyAccessor === 'categories' && !this.props.creationInProgress) {
			this.props.filteredProduct(this.props.filters);
		}
		values.push({ [keyAccessor]: value });
		entities.push(entity);
		let newValues = this.checkTypeValues(values);
		this.setState({
			portalSelectedId: '',
			portalItems: [],
			portalPosition: {},
			portalElementStyle: {},
			portalDisplay: 'none',
			portalProductId: '',
		});
		if (!this.props.creationInProgress) {
			this.props.productAction.updateProducts(ids, newValues, entities);
		} else {
			this.props.updateProductInCreation(ids, newValues);
		}
	}
	checkTypeValues(values) {
		let checkValues = values;
		if (Array.isArray(values)) {
			if (checkValues[0].categories) {
				checkValues[0].categories = checkValues[0].categories.filter(
					value => value !== ''
				);
			}
		}
		return checkValues;
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
		const navLeftContainerElement = document.getElementById(
			'navLeft-container'
		);
		if (navLeftContainerElement) {
			navLeftContainerElement.removeEventListener(
				'mousedown',
				this.onContainerClick
			);
		}
		const categoryListElement = document.getElementById('category-list');
		if (categoryListElement) {
			categoryListElement.removeEventListener(
				'mousedown',
				this.onContainerClick
			);
		}
		this.tableWrapper.hotInstance.deselectCell();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.width === 0 && nextState.height === 0) {
			return false;
		}
		if (deepEqual(this.props, nextProps) && deepEqual(this.state, nextState)) {
			return false;
		}
		return true;
	}

	getDefaultTaxId() {
		const defaultTaxId = this.props.taxes.reduce((acc, tax) => {
			if (tax.is_default) {
				return tax.id;
			}

			return acc;
		}, '');

		return defaultTaxId !== '' ? defaultTaxId : 'def0-1';
	}

	/* handlePriceChange = (product, newPrice, currentPrice) => {
    const isNumber = checkPrice(newPrice);
    let actualPrice = checkPrice(currentPrice);

    if (!isNaN(isNumber) && actualPrice !== isNumber) {
      return this.props.productAction.updateProducts(
        [product.id],
        [{ price: isNumber }],
        [false]
      );
    } else if (isNaN(isNumber)) {
      this.alertWrongPrice(product.name);
    }
  }; */

	alertProductInMenu(productName = '') {
		this.props.notificationAction.addNotification({
			tags: [TAGS.WARNING],
			text: I18n.t('product.priceWarning', { productName: productName }),
			date: moment().format('DD/MM/YYYY HH:mm'),
			viewed: false,
			openable: false,
		});
	}

	alertWrongPrice(productName = '') {
		this.props.notificationAction.addNotification({
			tags: [TAGS.ERROR],
			text: I18n.t('product.wrongPrice', { productName: productName }),
			date: moment().format('DD/MM/YYYY HH:mm'),
			viewed: false,
			openable: false,
		});
	}

	alertProductInCreation(productName = '') {
		this.props.notificationAction.addNotification({
			tags: [TAGS.WARNING],
			text: I18n.t('product.productInCreation', { productName: productName }),
			date: moment().format('DD/MM/YYYY HH:mm'),
			viewed: false,
			openable: false,
		});
	}

	checkTypePrice(productsChange) {
		const products = this.props.products;
		const valueCopy = productsChange[0][3];
		let result = {};
		const intervalCopied = [
			productsChange[0][0],
			productsChange[productsChange.length - 1][0],
		];
		const productsCopied = products.slice(
			intervalCopied[0],
			intervalCopied[1] + 1
		);
		const productsCopiedIsVolume = productsCopied.some(
			product => product.is_free_price || product.id_retail_price_type != '0'
		);
		result.productsCopiedIsVolume = productsCopiedIsVolume;

		if (
			!!products[intervalCopied[0] - 1] &&
			products[intervalCopied[0] - 1].price === valueCopy
		) {
			result.copiedProductSource = products[intervalCopied[0] - 1];
		} else {
			result.copiedProductSource = products[intervalCopied[1] + 1];
		}

		return result;
	}

	render() {
		if (this.state.width === 0 && this.state.height === 0) {
			return null;
		}
		const { width, height } = this.state;
		const widthWithoutLeftMenu = width - 120 - 196 - 16;
		const columnsWidth = widthWithoutLeftMenu / 10;
		const {
			products,
			taxes,
			colors,
			productTypes,
			categories,
			placeSends,
			onEditClick,
			onDeleteClick,
		} = this.props;
		const defaultTaxId = this.getDefaultTaxId();
		let sortedTax = globalSelector.getTaxesSorted(taxes);
		sortedTax = sortedTax.filter(tax => tax.active === 1 && tax.removed === 0);
		const selectTax = sortedTax.map(tax => {
			return { value: tax.id.toString(), text: tax.name };
		});
		const selectProductTypes = productTypes.map(pt => {
			return { value: pt.id.toString(), text: pt.name };
		});
		const selectCategories =
			categories && categories.length > 0
				? categories.map(c => {
						return { value: c.id.toString(), text: c.name };
				  })
				: [];
		let selectPlaceSend;
		if (placeSends && placeSends.length > 0) {
			selectPlaceSend = placeSends.map(ps => {
				return { value: ps.id.toString(), text: ps.name };
			});
		}

		const options = {
			colHeaders: [
				'',
				'',
				I18n.t('product.name').toUpperCase(),
				I18n.t('product.shortName').toUpperCase(),
				I18n.t('product.price').toUpperCase(),
				I18n.t('product.sku').toUpperCase(),
				I18n.t('product.tva').toUpperCase(),
				I18n.t('product.type').toUpperCase(),
				I18n.t('product.categories').toUpperCase(),
				I18n.t('product.place').toUpperCase(),
				'',
			],
			columns: [
				{
					data: 'id',
					type: 'text',
				},
				{
					data: 'id_color',
					editor: false,
					renderer: (instance, td, row, col, prop, value, cellProperties) => {
						renderReactCell({
							memoizedContainers,
							td,
							row,
							col,
							jsx: (
								<ColorPicker
									onColorSelected={selectedColor => {
										const rowData = instance.getDataAtRow(
											cellProperties.visualRow
										);
										const productInCreation = this.props.creationInProgress
											? this.props.products[this.props.products.length - 1]
											: '';
										this.props.creationInProgress && rowData[0] !== 'main'
											? this.alertProductInCreation(productInCreation.name)
											: this.props.creationInProgress && rowData[0] === 'main'
											? this.props.updateProductInCreation(
													[rowData[0]],
													[{ id_color: selectedColor.id }]
											  )
											: this.props.productAction.updateProducts(
													[rowData[0]],
													[{ id_color: selectedColor.id }],
													[false]
											  );
									}}
									color={colors}
									value={value}
									defaultColor={this.props.defaultColor}
								/>
							),
						});
						if (cellProperties.className !== undefined) {
							td.className = cellProperties.className;
						}
						return td;
					},
				},
				{
					data: 'name',
					type: 'text',
					validator: validators.name,
				},
				{
					data: 'short_name',
					type: 'text',
					validator: validators.name,
				},
				{
					data: 'price',
					editor: false,
					renderer: (instance, td, row, col, prop, value, cellProperties) => {
						const rowData = instance.getDataAtRow(row);
						renderReactCell({
							memoizedContainers,
							td,
							row,
							col,
							jsx: (
								<PriceRenderer
									cellProperties={cellProperties}
									products={products}
									id={rowData[0]}
									retailPriceTypes={this.props.retailPriceTypes}
									changePrice={this.handlePriceChange}
									currentPrice={rowData[4]}
									productAction={this.props.productAction}
									alertWrongPrice={this.alertWrongPrice}
									alertProductInCreation={this.alertProductInCreation}
									creationInProgress={this.props.creationInProgress}
									updateProductInCreation={this.props.updateProductInCreation}
								/>
							),
						});
						if (cellProperties.className !== undefined) {
							td.className = cellProperties.className;
						}
						return td;
					},
					validator: validators.price,
				},
				{
					data: 'sku',
					type: 'text',
					validator: validators.name,
				},
				{
					data: 'id_tax_rate',
					editor: false,
					renderer: (instance, td, row, col, prop, value, cellProperties) => {
						renderReactCell({
							memoizedContainers,
							td,
							row,
							col,
							jsx: (
								<SelectRenderer
									items={selectTax}
									value={value}
									keyAccessor={'id_tax_rate'}
								/>
							),
						});
						if (cellProperties.className !== undefined) {
							td.className = cellProperties.className;
						}
						return td;
					},
				},
				{
					data: 'id_product_type',
					editor: false,
					renderer: (instance, td, row, col, prop, value, cellProperties) => {
						renderReactCell({
							memoizedContainers,
							td,
							row,
							col,
							jsx: (
								<SelectRenderer
									items={selectProductTypes}
									value={value}
									keyAccessor={'id_product_type'}
								/>
							),
						});
						if (cellProperties.className !== undefined) {
							td.className = cellProperties.className;
						}
						return td;
					},
				},
				{
					data: 'categories',
					editor: false,
					renderer: (instance, td, row, col, prop, value, cellProperties) => {
						renderReactCell({
							memoizedContainers,
							td,
							row,
							col,
							jsx: (
								<SelectRenderer
									items={selectCategories}
									value={value}
									keyAccessor={'category_id'}
								/>
							),
						});
						if (cellProperties.className !== undefined) {
							td.className = cellProperties.className;
						}
						return td;
					},
				},
				{
					data: 'id_place_send',
					editor: false,
					renderer: (instance, td, row, col, prop, value, cellProperties) => {
						renderReactCell({
							memoizedContainers,
							td,
							row,
							col,
							jsx: (
								<SelectRenderer
									items={selectPlaceSend}
									value={value}
									keyAccessor={'id_place_send'}
								/>
							),
						});
						if (cellProperties.className !== undefined) {
							td.className = cellProperties.className;
						}
						return td;
					},
				},
				{
					data: 'id',
					editor: false,
					renderer: (instance, td, row, col, prop, value, cellProperties) => {
						{
							renderReactCell({
								memoizedContainers,
								td,
								row,
								col,
								onEditClick: onEditClick,
								onDeleteClick: onDeleteClick,
								jsx:
									this.props.creationInProgress && value === 'main' ? (
										<SaveOrDeleteCell
											onSaveClick={this.props.saveProduct}
											onDeleteClick={this.props.deleteProduct}
											value={value}
										/>
									) : (
										<EditOrDeleteCell
											onEditClick={onEditClick}
											onDeleteClick={onDeleteClick}
											value={value}
											creationInProgress={this.props.creationInProgress}
											canDelete={this.props.canDelete}
											canEdit={this.props.canEdit}
										/>
									),
							});
							if (cellProperties.className !== undefined) {
								td.className = cellProperties.className;
							}
							return td;
						}
					},
				},
			],
			beforeKeyDown: event => {
				if (event.realTarget.className.indexOf('handson_price') !== -1) {
					event.stopImmediatePropagation();
				}
			},
			beforeCopy: (data, coords) => {
				const isPriceColumn = coords[0].startCol === 4;
				const isEditDeleteColumn = coords[0].startCol === 10;
				if (isEditDeleteColumn) {
					return false;
				}
				if (isPriceColumn) {
					const instance = this.tableWrapper.hotInstance;
					const firstRowData = instance.getDataAtRow(coords[0].startRow);
					let product = products.filter(
						product => product.id === firstRowData[0]
					);
					if (product.length > 0) {
						product = product[0];
					}
					if (product.is_free_price) {
						this.props.notificationAction.addNotification({
							tags: [TAGS.WARNING],
							text: I18n.t('product.disalowAutoFillForPrice', {
								productName: `( ${product.name} )`,
							}),
							date: moment().format('DD/MM/YYYY HH:mm'),
							viewed: false,
							openable: false,
						});
						return false;
					}
					if (product.id_retail_price_type != '0') {
						this.props.notificationAction.addNotification({
							tags: [TAGS.WARNING],
							text: I18n.t('product.disalowAutoFillForPrice', {
								productName: `( ${product.name} )`,
							}),
							date: moment().format('DD/MM/YYYY HH:mm'),
							viewed: false,
							openable: false,
						});
						return false;
					}
				}
			},
			beforePaste: (data, coords) => {
				/**
				 * data:
				 *  array[
				 *    array[
				 *      0: value,
				 *      2: value,
				 *    ]
				 *  ]
				 *
				 * coords: define coords where data will be put
				 *  array[
				 *    object{
				 *      endCol: 0
				 *      endRow: 618
				 *      startCol: 0
				 *      startRow: 0
				 *    }
				 *  ]
				 */
				const columnIndexToKeyName = {
					0: 'id',
					1: 'id_color',
					2: 'name',
					3: 'short_name',
					4: 'price',
					5: 'sku',
					6: 'id_tax_rate',
					7: 'id_product_type',
					8: 'id_place_send',
				};
				const instance = this.tableWrapper.hotInstance;
				const selectedCell = instance.getSelected();
				const selectedCellSorted = sortBy(
					selectedCell,
					cellCoords => cellCoords[0]
				);
				let newDefaultData = {
					...defaultData,
					id_menu: this.props.id_menu,
					id_tax_rate: defaultTaxId,
					id_color: this.props.defaultColor,
				};
				const isPastableColumn =
					coords[0].startCol >= 1 && coords[0].startCol <= 5;

				if (isPastableColumn) {
					const firstRowData = instance.getDataAtRow(coords[0].startRow);
					const idsArray = [];
					const changeArray = [];
					const productsToCreate = data.reduce((acc, productData, index) => {
						const productDataLength = productData.length;
						if (firstRowData[0] && firstRowData[0] !== 'main') {
							for (let i = 0; i <= productDataLength - 1; i++) {
								const colIndex = coords[0].startCol + i;
								const isPastableColumn = colIndex >= 1 && colIndex <= 5;
								const isPriceColumn = colIndex === 4;
								const isColorColumn = colIndex === 1;
								if (data.length !== selectedCellSorted.length) {
									//
									const rowData = instance.getDataAtRow(
										coords[0].startRow + index
									);
									// Si le produit est dans un menu est qu'on essaie de le mettre à 0 on empeche la manipulation est on dispatch une erreur visuelle
									if (
										isPriceColumn &&
										data[index][0] == 0 &&
										isProductInMenu(this.props.productMenu, rowData[0])
									) {
										this.alertProductInMenu(rowData[2]);
									} else {
										if (isPastableColumn) {
											if (isPriceColumn) {
												let product = products.filter(
													product => product.id === rowData[0]
												);
												product = product[0];
												if (product.is_free_price) {
													this.props.notificationAction.addNotification({
														tags: [TAGS.WARNING],
														text: I18n.t('product.disalowForProductVolume', {
															productName: `( ${rowData[2]} )`,
														}),
														date: moment().format('DD/MM/YYYY HH:mm'),
														viewed: false,
														openable: false,
													});
													return acc;
												}
												if (product.id_retail_price_type != '0') {
													this.props.notificationAction.addNotification({
														tags: [TAGS.WARNING],
														text: I18n.t('product.disalowForProductVolume', {
															productName: `( ${rowData[2]} )`,
														}),
														date: moment().format('DD/MM/YYYY HH:mm'),
														viewed: false,
														openable: false,
													});
													return acc;
												}
												productData[i] = productData[i].replace(/,/g, '.');
											}
											if (isColorColumn) {
												productData[i] = colorNameToId(
													this.props.colors,
													productData[i]
												);
											}
											idsArray.push(rowData[0]);
											changeArray.push({
												[columnIndexToKeyName[colIndex]]: productData[i],
											});
										}
									}
								} else {
									if (isPastableColumn) {
										const rowData = instance.getDataAtRow(
											selectedCellSorted[index][0]
										);
										if (isPriceColumn) {
											let product = products.filter(
												product => product.id === rowData[0]
											);
											product = product[0];
											if (product.is_free_price) {
												this.props.notificationAction.addNotification({
													tags: [TAGS.WARNING],
													text: I18n.t('product.disalowForProductVolume', {
														productName: `( ${rowData[2]} )`,
													}),
													date: moment().format('DD/MM/YYYY HH:mm'),
													viewed: false,
													openable: false,
												});
												return acc;
											}
											if (product.id_retail_price_type != '0') {
												this.props.notificationAction.addNotification({
													tags: [TAGS.WARNING],
													text: I18n.t('product.disalowForProductVolume', {
														productName: `( ${rowData[2]} )`,
													}),
													date: moment().format('DD/MM/YYYY HH:mm'),
													viewed: false,
													openable: false,
												});
												return acc;
											}
											productData[i] = productData[i].replace(/,/g, '.');
										}
										if (isColorColumn) {
											productData[i] = colorNameToId(
												this.props.colors,
												productData[i]
											);
										}
										idsArray.push(rowData[0]);
										changeArray.push({
											[columnIndexToKeyName[colIndex]]: productData[i],
										});
									}
								}
							}
						} else {
							let baseData = { ...newDefaultData };
							for (let i = 0; i <= productDataLength - 1; i++) {
								const colIndex = coords[0].startCol + i;
								const isPriceColumn = colIndex === 4;
								const isColorColumn = colIndex === 1;
								const isPastableColumn = colIndex >= 1 && colIndex <= 5;
								if (isPastableColumn) {
									if (isPriceColumn) {
										productData[i] = productData[i].replace(/,/g, '.');
									}
									if (isColorColumn) {
										productData[i] = colorNameToId(
											this.props.colors,
											productData[i]
										);
									}
									baseData[columnIndexToKeyName[colIndex]] = productData[i];
								}
							}
							acc.push(baseData);
						}
						return acc;
					}, []);
					if (
						idsArray.length === 0 &&
						changeArray.length === 0 &&
						productsToCreate.length === 0
					) {
						return false;
					}
					if (idsArray.length > 0) {
						this.props.productAction.updateProducts(
							idsArray,
							changeArray,
							null,
							false
						);
					} else {
						const massInsert = true;
						if (productsToCreate.length > 1) {
							this.props.productAction.createProducts(
								productsToCreate,
								massInsert
							);
							this.props.updateStateCreation();
						} else {
							if (data[0].length > 1) {
								this.props.productAction.createProducts(productsToCreate);
								this.props.updateStateCreation();
							} else {
								return true;
							}
						}
					}
				}
				return false;
			},
			afterValidate: (isValid, value, row, prop, source) => {
				//TODO dispatch snack error
			},
			afterScrollVertically: e => {
				if (this.state.portalSelectedId !== '') {
					const instance = this.tableWrapper.hotInstance;
					const hotId = instance.rootElement.id;
					const hotDiv = document.getElementById(hotId);
					this.setState({
						portalTarget: hotDiv,
						portalSelectedId: '',
						portalItems: [],
						portalPosition: {},
						portalElementStyle: {},
						portalDisplay: 'none',
					});
				}
			},
			beforeAutofill: (start, end, data, baseRange) => {
				if (start.col === 8) {
					const instance = this.tableWrapper.hotInstance;
					let changeArray = [];
					let idsArray = [];
					let entities = [];
					for (let i = start.row; i <= end.row; i++) {
						const cellMeta = instance.getCellMetaAtRow(i);
						const rowData = instance.getDataAtRow(cellMeta[0].visualRow);

						if (rowData[0]) {
							idsArray.push(rowData[0]);
							changeArray.push({ categories: data[0][0] });
							entities.push(ENTITY_CATEGORY);
						}
					}
					this.props.productAction.updateProducts(
						idsArray,
						changeArray,
						entities
					);
				}

				return false;
			},
			modifyAutofillRange: (startArea, entireArea) => {
				const instance = this.tableWrapper.hotInstance;
				if (startArea[0] < entireArea[0]) {
					instance.scrollViewportTo(startArea[0], startArea[1]);
				}
			},
			beforeChange: (changes, source) => {
				const { creationInProgress } = this.props;
				const indexProductInCreation = creationInProgress
					? this.props.products.length - 1
					: null;
				const indexProductModifiy = changes[0][0];
				if (changes[0][1] === 'id') {
					return false;
				}

				if (source === 'Autofill.fill') {
					if (!creationInProgress) {
						if (changes[0][1] === 'categories') {
							return false;
						}
						if (changes[0][1] === 'price') {
							const copiedProduct = this.checkTypePrice(changes);
							if (copiedProduct.productsCopiedIsVolume) {
								this.props.notificationAction.addNotification({
									tags: [TAGS.WARNING],
									text: I18n.t('product.disalowForProductVolume'),
									date: moment().format('DD/MM/YYYY HH:mm'),
									viewed: false,
									openable: false,
								});
								return false;
							}

							if (copiedProduct.copiedProductSource.is_free_price) {
								this.props.notificationAction.addNotification({
									tags: [TAGS.WARNING],
									text: I18n.t('product.disalowForFreePrice', {
										productName: `( ${
											copiedProduct.copiedProductSource.name
										} )`,
									}),
									date: moment().format('DD/MM/YYYY HH:mm'),
									viewed: false,
									openable: false,
								});
								return false;
							}
							if (
								copiedProduct.copiedProductSource.id_retail_price_type != '0'
							) {
								this.props.notificationAction.addNotification({
									tags: [TAGS.WARNING],
									text: I18n.t('product.disalowAutoFillForPrice', {
										productName: `( ${
											copiedProduct.copiedProductSource.name
										} )`,
									}),
									date: moment().format('DD/MM/YYYY HH:mm'),
									viewed: false,
									openable: false,
								});
								return false;
							}
						}
					}
					if (changes[0][1] === 'id') {
						return false;
					}
				}

				if (
					source === 'edit' &&
					creationInProgress &&
					indexProductInCreation !== indexProductModifiy
				) {
					const productInCreation = this.props.products[
						this.props.products.length - 1
					];
					return this.alertProductInCreation(productInCreation.name);
				}

				const idsArray = [];
				const changeArray = [];
				let priceWarning = false;
				changes.forEach(change => {
					if (change[2] !== change[3]) {
						const instance = this.tableWrapper.hotInstance;
						const cellMeta = instance.getCellMetaAtRow(change[0]);
						const rowData = instance.getDataAtRow(cellMeta[0].visualRow);

						if (rowData && rowData.length && rowData[0]) {
							if (!this.props.creationInProgress) {
								if (change[1] !== 'id') {
									if (change[1] === 'price') {
										change[3] = change[3].replace(/,/g, '.');
									}
									if (
										change[1] === 'price' &&
										change[3] == 0 &&
										isProductInMenu(this.props.productMenu, rowData[0])
									) {
										this.alertProductInMenu(rowData[2]);
										priceWarning = true;
									}
									if (change[1] === 'id_product_type') {
										const targetedProduct = find(
											this.props.products,
											product => product.id === rowData[0]
										);
										this.props.notificationAction.addNotification({
											tags: [TAGS.WARNING],
											text: I18n.t('product.productTypeChange', {
												productName: `( ${rowData[2]} )`,
											}),
											date: moment().format('DD/MM/YYYY HH:mm'),
											viewed: false,
											openable: false,
										});
									}

									if (change[1] === 'id_tax_rate') {
										if (change[2] !== '' && change[2] !== change[3]) {
											this.props.notificationAction.addNotification({
												tags: [TAGS.WARNING],
												text: I18n.t('product.productTVAChange', {
													productName:
														rowData[2] !== '' ? `(${rowData[2]})` : '',
												}),
												date: moment().format('DD/MM/YYYY HH:mm'),
												viewed: false,
												openable: false,
											});
										}
									}
								}

								idsArray.push(rowData[0]);
								changeArray.push({ [change[1]]: change[3] });
							}
						}
					}
				});
				if (priceWarning) return false;

				if (idsArray.length > 0) {
					const entity =
						changes[0][1] === 'categories' ? ENTITY_CATEGORY : false;
					if (!this.props.creationInProgress) {
						const currentProducts = deepClone(this.props.products);
						let skuRequired = false;
						idsArray.forEach((id, key) => {
							let product = currentProducts.find(product => product.id === id);
							const valueAccessor = Object.keys(changeArray[key]);
							valueAccessor.forEach(value => {
								if (
									value == 'sku' &&
									this.props.check_sku == '1' &&
									!changeArray[key][value].trim()
								) {
									skuRequired = true;
								} else {
									product[value] = changeArray[key][value];
								}
							});
						});
						if (skuRequired) {
							this.props.notifSkuRequirement();
							return;
						}
						this.props.updateProducts(currentProducts);
						this.props.productAction.updateProducts(
							idsArray,
							changeArray,
							idsArray.map(id => entity),
							[],
							true
						);
					} else {
						this.props.updateProductInCreation(idsArray, changeArray);
					}
				} else {
					if (changes[0][2] !== changes[0][3]) {
						let newDefaultData = {
							...defaultData,
							[changes[0][1]]: changes[0][3],
							id_menu: this.props.id_menu,
							id_tax_rate: defaultTaxId,
							id_color: this.props.defaultColor,
						};
						if (
							(changes[0][1] == 'short_name' || changes[0][1] == 'sku') &&
							creationInProgress
						) {
							const productInCreation = this.props.products[
								this.props.products.length - 1
							];
							const id = [productInCreation.id];
							const _changes = [{ [changes[0][1]]: changes[0][3] }];
							this.props.updateProductInCreation(id, _changes);
						}
						if (changes[0][1] === 'name' && changes[0][3] != '') {
							newDefaultData.id = 'main';
							this.props.createProduct(newDefaultData, this.props.products);
							//this.props.productAction.createProducts([newDefaultData]);
						}
						if (changes[0][1] !== 'name') {
							return true;
						}
					}
				}
				//return false comme ça handson ne render pas les changes ils seront render lors de l'ajout dans redux (evite de render 2 fois)
				return false;
			},
			afterSelection: (r, c, r2, c2) => {
				const instance = this.tableWrapper.hotInstance;
				const setting = instance.getSettings();
				if (c === 2) {
					instance.updateSettings({
						fillHandle: false,
					});
				} else {
					if (setting.__proto__.fillHandle === false) {
						instance.updateSettings({
							fillHandle: {
								direction: 'vertical',
								autoInsertRow: false,
							},
						});
					}
				}
				const hotId = instance.rootElement.id;
				const hotDiv = document.getElementById(hotId);
				if (c === 6 || c === 7 || c === 8 || c === 9) {
					const cell = instance.getCell(r, c);
					if (cell) {
						const cellDataId = cell.querySelector('div[data-id]');
						if (cellDataId) {
							const dataId = cellDataId.getAttribute('data-id');
							const dataIsMultiple = cellDataId.getAttribute(
								'data-is-multiple'
							);
							const dataKeyAccessor = cellDataId.getAttribute(
								'data-key-accessor'
							);
							const left = cell.getBoundingClientRect().left;
							const top = cell.getBoundingClientRect().top;
							const width = cell.clientWidth;
							const height = cell.clientHeight;
							const backgroundColor = window
								.getComputedStyle(cell, null)
								.getPropertyValue('background-color');
							const rowData = instance.getDataAtRow(r);
							const productId = rowData[0];
							const taxItems = sortedTax.map(tax => {
								return { value: tax.id.toString(), text: tax.name };
							});
							const typeItems = productTypes.map(pt => {
								return { value: pt.id.toString(), text: pt.name };
							});
							const categoryItems = categories.map(c => {
								return { value: c.id.toString(), text: c.name };
							});
							const placeSendItems = placeSends.map(ps => {
								return { value: ps.id.toString(), text: ps.name };
							});
							const items = {
								6: taxItems,
								7: typeItems,
								8: categoryItems,
								9: placeSendItems,
							};
							this.setState({
								portalTarget: hotDiv,
								portalSelectedId: dataId,
								portalIsMultipleSelect: dataIsMultiple,
								portalItems: items[c],
								portalPosition: { top, left },
								portalElementStyle: { width, height, backgroundColor },
								portalDataKeyAccessor: dataKeyAccessor,
								portalDisplay: 'block',
								portalProductId: productId,
							});
						}
					}
				} else {
					if (this.state.portalSelectedId) {
						this.setState({
							portalTarget: hotDiv,
							portalSelectedId: '',
							portalItems: [],
							portalPosition: {},
							portalElementStyle: {},
							portalDisplay: 'hidden',
						});
					}
				}
			},
			afterUpdateSettings: settings => {
				const instance = this.tableWrapper.hotInstance;
				const selected = instance.getSelected(); // [[startRow, startCol, endRow, endCol]]
				if (
					selected &&
					(selected[0][1] === 2 ||
						selected[0][3] === 2 ||
						selected[0][3] === 3 ||
						selected[0][1] === 3 ||
						selected[0][1] === 10 ||
						selected[0][3] === 10)
				) {
					if (settings.fillHandle !== false) {
						instance.updateSettings({
							fillHandle: false,
						});
					}
				}
			},
			cells: function(row, col, prop) {
				var cellProperties = {};
				var visualRowIndex = this.instance.toVisualRow(row);

				const rowData = this.instance.getDataAtRow(visualRowIndex);
				if (!rowData[0]) {
					if (col === 2 && canSelectCell) {
						cellProperties.selected = true;
					}
					if (col !== 2 && col !== 10) {
						cellProperties.readOnly = true;
						cellProperties.editor = false;
						cellProperties.className = 'handson-disabled';
						cellProperties.renderer = 'text';
					}
				} else {
					const product = find(products, p => p.id === rowData[0]);
					if (!product.active) {
						cellProperties.className = 'handson-disabled';
					}
				}
				/* if (visualRowIndex === 0 && visualColIndex === 0) {
          cellProperties.readOnly = true;
        } */

				return cellProperties;
			},
			beforeOnCellMouseDown: (event, coords, TD) => {
				if (coords.col === 10) {
					if (event.realTarget.className === 'svg-icon icon-editer') {
						TD.getElementsByClassName('edit')[0].click();
					} else if (event.realTarget.className === 'svg-icon icon-close') {
						TD.getElementsByClassName('remove')[0].click();
					} else if (event.realTarget.className === 'svg-icon icon-plus') {
						TD.getElementsByClassName('edit')[0].click();
					}
				}
			},
			fillHandle: {
				direction: 'vertical',
				autoInsertRow: false,
			},
			outsideClickDeselects: true,
			colWidths: [
				0.1, // id
				34, //color
				columnsWidth + (columnsWidth - 34), //name
				columnsWidth + 50, //short name
				columnsWidth - 50, //price
				columnsWidth - 20, // sku
				columnsWidth - 50, //tva
				columnsWidth + (columnsWidth - 75), //type
				columnsWidth + 50, // category
				columnsWidth, //place send
				75, //buttons
			],
			rowHeights: 30,
			width: widthWithoutLeftMenu,
			height: height - 170,
			autoColumnSize: false,
			autoRowSize: false,
			stretchH: 'none',
			selectionMode: 'single',
			renderAllRows: false,
			variableRowHeights: false,
			copyPaste: true,
		};
		return (
			<React.Fragment>
				<HotTable
					ref={table => (this.tableWrapper = table)}
					data={products}
					settings={options}
					onEditClick={onEditClick}
					onDeleteClick={onDeleteClick}
				/>
				<SelectPortal
					node={this.state.portalTarget}
					items={this.state.portalItems}
					selected={this.state.portalSelectedId}
					position={this.state.portalPosition}
					productId={this.state.portalProductId}
					elementStyle={this.state.portalElementStyle}
					display={this.state.portalDisplay}
					dataKeyAccessor={this.state.portalDataKeyAccessor}
					handleUpdate={this.handleUpdate}
					products={products}
					isMultipleSelect={this.state.portalIsMultipleSelect}
				/>
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		productAction: bindActionCreators(productAction, dispatch),
		notificationAction: bindActionCreators(notificationAction, dispatch),
	};
};

const mapStateToProps = state => {
	return {
		retailPriceTypes: state.entity.retailPriceType,
		productMenu: state.entity.productMenu,
		placeSends: state.entity.placeSend,
		colors: state.entity.color,
		productTypes: state.entity.productType,
		taxes: state.entity.tax,
		check_sku: globalSelector.getVariableServer(
			state.entity.variableServer,
			'check_sku'
		),
		check_sku_int: globalSelector.getVariableServer(
			state.entity.variableServer,
			'check_sku_int'
		),
		defaultColor: getDefaultColor(state.user),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductList);
