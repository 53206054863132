import { call, put, take, select } from 'redux-saga/effects';
import { handleApiErrors } from '../lib/api-errors';
import {
	REQUESTING,
	REQUEST_SUCCESS,
	REQUEST_ERROR,
	REQUEST_REJECT,
} from '../constants/request';
import { ADD_NOTIFICATION, TAGS } from '../constants/notification';
import reduce from 'lodash/reduce';
import { getCurrentTime } from '../utils';
import _find from 'lodash/find';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { browserHistory } from 'react-router';

let API_URL;

if (window.location && window.location.hostname !== 'localhost') {
	API_URL = `https://${window.location.host}/api`;
} else {
	API_URL = `http://${window.location.hostname}:4000/api`;
}

export function fetcher(
	endPoint,
	method = 'GET',
	secured = true,
	body = null,
	route = '',
	url_params = {}
) {
	const headers = { 'Content-Type': 'application/json' };
	if (secured) {
		headers['access-token'] = window.localStorage.access_token;
	}
	if (window.sessionStorage.master_mono_child) {
		headers['x-master-mono-child'] = window.sessionStorage.master_mono_child;
	}
	if (window.sessionStorage.id_zone) {
		headers['x-zone'] = window.sessionStorage.id_zone;
	}

	headers['x-guid'] = window.sessionStorage.getItem('guid');
	headers['x-client-date'] = moment().format('YYYY-MM-DD');

	const fetchParams = {
		method: method,
		headers: headers,
	};
	if (body !== null) {
		fetchParams.body = JSON.stringify(body);
	}

	const paramStr = reduce(
		url_params,
		function(result, value, key) {
			if (result === '') {
				result += '?' + key + '=' + value;
			} else {
				result += '&' + key + '=' + value;
			}
			return result;
		},
		''
	);

	return fetch(`${API_URL}/${endPoint}${route}${paramStr}`, fetchParams)
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => json)
		.catch(error => {
			throw error;
		});
}

export function generateId(entity, method) {
	const currentTimestamp = new Date().getTime();
	return `${method}_${entity}_${currentTimestamp}`;
}

export function getMillisecondDiff(lastTimestamp) {
	const currentTimestamp = new Date().getTime();
	return currentTimestamp - lastTimestamp;
}

export function* requestHandler(
	entity,
	method = 'GET',
	secured = true,
	body = null,
	route = '',
	url_params = {}
) {
	if (
		window.localStorage.networkStatus === 'online' &&
		(!secured || (secured && window.localStorage.access_token !== undefined))
	) {
		const id = yield call(generateId, entity, method);
		const requestObj = {
			id: id,
			time: getCurrentTime(),
			endpoint: entity,
			method: method,
			secured: window.localStorage.access_token,
		};
		try {
			yield put({ type: REQUESTING, request: requestObj, entity: entity });
			const result = yield call(
				fetcher,
				entity,
				method,
				secured,
				body,
				route,
				url_params
			);

			//yield put({ type: "NEW_CLIENT", response: result.newClient });
			if (result.ipadChanges) {
				yield put({
					type: ADD_NOTIFICATION,
					notification: {
						tags: [TAGS.WARNING],
						text: I18n.t('patchMenu.ipadChanges'),
						date: moment().format('DD/MM/YYYY HH:mm'),
						viewed: false,
						openable: false,
					},
				});
			}
			if (result.failIpadChanges) {
				const text = result.nameCompany
					? I18n.t('patchMenu.ipadNoChangesCompany', {
							nameMenu: result.nameMenu,
							nameCompany: result.nameCompany,
					  })
					: I18n.t('patchMenu.ipadNoChanges', {
							nameMenu: result.nameMenu,
					  });
				yield put({
					type: ADD_NOTIFICATION,
					notification: {
						tags: [TAGS.ERROR],
						text,
						date: moment().format('DD/MM/YYYY HH:mm'),
						viewed: false,
						openable: false,
					},
				});
			}

			if (result.badIdChild || result.masterMonoDisabled) {
				window.sessionStorage.removeItem('master_mono_child');
				browserHistory.push('/menu');
				return { entity: entity, result: false };
			}

			if (result.inSynchro) {
				window.localStorage.setItem('CAN_ACCESS', false);
				yield put({ type: REQUEST_REJECT, result: { ...result } });
				return { entity: entity, result: false };
			}

			//TODO GARDER ??? MODIFIER ???
			if (
				result.isModificationPossible == false ||
				result.taxeRateEmpty ||
				result.checkVersion == false
			) {
				yield put({ type: 'RESET_LOADING' });
				if (window.location.pathname != '/deployRecap') {
					browserHistory.push('/menu');
					yield put({ type: REQUEST_REJECT, result });
				}
			} else {
				const idSplited = id.split('_');
				const responseTime = yield call(getMillisecondDiff, idSplited[2]);

				const successfullObj = {
					...requestObj,
					time: getCurrentTime(),
					responseTime: responseTime,
				};

				yield put({
					type: REQUEST_SUCCESS,
					successRequest: successfullObj,
					entity: entity,
				});
			}

			if (
				result.multiSessionId &&
				result.multiSessionId !== window.sessionStorage.getItem('guid')
			) {
				window.sessionStorage.setItem('ALREADY_IN_USE', true);
				yield put({ type: 'RESET_LOADING' });
			}

			return { entity: entity, result };
		} catch (err) {
			const idSplited = id.split('_');
			const responseTime = yield call(getMillisecondDiff, idSplited[2]);

			const errorRequestObj = {
				...requestObj,
				responseTime: responseTime,
				body: body,
				err: err,
				url_params: url_params,
				time: getCurrentTime(),
			};

			yield put({
				type: REQUEST_ERROR,
				errorRequest: errorRequestObj,
				entity: entity,
			});

			return { entity: entity, result: false };
		}
	}
}

const findTarget = (entityItem, errorRequest) => {
	_find(entityItem, item => item.id === errorRequest.body.id);
};

export function* watchRequestError() {
	while (true) {
		const { errorRequest, entity } = yield take(REQUEST_ERROR);
		const state = yield select();
		const entityItem = yield call(entityGetter, entity, state);
		const targetItem = findTarget(entityItem, errorRequest);
		if (errorRequest.err === 'Forbidden') {
			yield put({
				type: ADD_NOTIFICATION,
				notification: {
					tags: [TAGS.ERROR],
					text: I18n.t('notification.toast.forbidden'),
					date: moment().format('DD/MM/YYYY HH:mm'),
					viewed: false,
					request: errorRequest,
					entity: entity,
					entityItem: targetItem,
				},
			});
		} else if (errorRequest.err === 'Unauthorized') {
			yield put({
				type: ADD_NOTIFICATION,
				notification: {
					tags: [TAGS.ERROR],
					text: I18n.t('notification.toast.unauthorized'),
					date: moment().format('DD/MM/YYYY HH:mm'),
					viewed: false,
					request: errorRequest,
					entity: entity,
					entityItem: targetItem,
				},
				forceInsert: true,
			});
		} else {
			yield put({
				type: ADD_NOTIFICATION,
				notification: {
					tags: [TAGS.ERROR],
					text: I18n.t('notification.toast.error'),
					date: moment().format('DD/MM/YYYY HH:mm'),
					viewed: false,
					request: errorRequest,
					entity: entity,
					entityItem: targetItem,
				},
			});
		}
	}
}

const entityGetter = (entity, state) => {
	let entityItem = {};
	switch (entity) {
		case 'category':
			entityItem = state.entity[entity].categories;
			break;
		case 'product':
			entityItem = state.entity[entity].products;
			break;
		default:
			entityItem = state.entity[entity];
			break;
	}
	return entityItem;
};
