import { take, put, select, call, all } from 'redux-saga/effects';
import { browserHistory } from 'react-router';
import { requestHandler } from './fetcher.saga';
import { getMenuSelected } from './login';
import * as utils from '../utils';

import { RECEIVE_MENU, RECEIVE_MENU_MASTER_MONO } from './../constants/menu';
import {
	RECEIVE_DAY_CHANGES,
	RECEIVE_HAS_DAY_CHANGES,
} from '../constants/dayChanges';

export default function* routerFlowSaga() {
	while (true) {
		const route = yield take('@@router/LOCATION_CHANGE');
		const pathName = route.payload.pathname;
		const pathNameSplitted = pathName.split('/');
		const entity = pathNameSplitted[1];
		const menuChanged = window.localStorage.MENU_CHANGED;
		if (
			utils.entity[entity] &&
			route.payload.action === 'PUSH' &&
			!menuChanged
		) {
			const id_menu = yield call(getMenuSelected);
			const reqCanAccess = yield call(
				requestHandler,
				'menu',
				'GET',
				true,
				null,
				'/canAccess',
				{ id_menu }
			);

			const canAccessMenu =
				reqCanAccess.result.result &&
				reqCanAccess.result &&
				!!reqCanAccess.result.result.access; //menu accessible and menuS not empty

			if (!canAccessMenu && entity !== 'menu') {
				if (entity != 'menu') {
					browserHistory.push('/menu');
				}
			} else {
				const entityToAction = {
					product: 'RECEIVE_PRODUCT',
					category: 'RECEIVE_CATEGORY',
					productMenu: 'RECEIVE_PRODUCT_MENU',
					tax: 'RECEIVE_TAX',
					supplement: 'RECEIVE_SUPPLEMENT',
					supplementCategory: 'RECEIVE_SUPPLEMENT_CATEGORY',
					priceCategory: 'RECEIVE_PRICE_CATEGORY',
				};
				if (entity === 'supplement') {
					const results = yield all([
						call(requestHandler, 'supplementCategory', 'GET', true, null, '', {
							id_menu: id_menu,
						}),
						call(requestHandler, 'supplement', 'GET', true, null, '', {
							id_menu: id_menu,
						}),
					]);

					for (let i = 0; i <= results.length - 1; i++) {
						yield put({
							type: entityToAction[results[i].entity],
							response: results[i].result,
						});
					}
				} else if (entity === 'menu') {
					let calls = [];
					calls.push(call(requestHandler, 'menu'));
					calls.push(
						call(requestHandler, 'synchro', 'GET', true, null, '/dayChanges', {
							id_menu,
						})
					);
					if (window.sessionStorage.master_mono_child) {
						calls.push(call(requestHandler, 'menuMasterMono'));
					}

					const [getMenus, dayChanges, getMenusChildren] = yield all(calls);
					yield put({
						type: RECEIVE_MENU,
						response: getMenus.result,
					});
					yield put({
						type: RECEIVE_DAY_CHANGES,
						data: dayChanges.result.result,
					});
					if (getMenusChildren) {
						yield put({
							type: RECEIVE_MENU_MASTER_MONO,
							response: getMenusChildren.result,
						});
					}
				} else if (entity === 'deploy') {
					let resultsCall = yield all([
						call(requestHandler, 'synchro', 'GET', true, null, '/dayChanges', {
							id_menu: id_menu,
						}),
						call(
							requestHandler,
							'synchro',
							'GET',
							true,
							null,
							'/hasDayChanges',
							{
								id_menu: id_menu,
							}
						),
					]);

					yield put({
						type: RECEIVE_HAS_DAY_CHANGES,
						data: resultsCall[0].result.result,
					});

					yield put({
						type: RECEIVE_HAS_DAY_CHANGES,
						data: resultsCall[1].result.result,
					});
				} else {
					const products = yield call(
						requestHandler,
						entity,
						'GET',
						true,
						null,
						'',
						{
							id_menu: id_menu,
						}
					);
					yield put({
						type: entityToAction[entity],
						response: products.result,
					});
				}
			}
		}
		if (menuChanged) {
			window.localStorage.removeItem('MENU_CHANGED');
		}
	}
}
