import { RECEIVE_VARIABLE } from '../constants/variable';

const initialState = [];

const reducer = function variableReducer(state = initialState, action) {
	const newState = { ...state };
	switch (action.type) {
		case RECEIVE_VARIABLE:
			if (!action.response || action.response.result.length === 0) {
				return newState;
			}
			const variables = action.response.result;
			return variables;
		default:
			return state;
	}
};

export default reducer;
