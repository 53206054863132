import {
	LOGIN_REQUESTING,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
} from '../constants/login';

const initialState = {
	requesting: false,
	successful: false,
	messages: [],
	errors: [],
	unauthorized: false,
};

const reducer = function loginReducer(state = initialState, action) {
	const newState = { ...state };
	switch (action.type) {
		// Set the requesting flag and append a message to be shown
		case LOGIN_REQUESTING:
			return {
				requesting: true,
				successful: false,
				messages: [{ body: 'Logging in...', time: new Date() }],
				errors: [],
				unauthorized: false,
			};

		// Successful?  Reset the login state.
		case LOGIN_SUCCESS:
			const messages = newState.errors;
			messages.push({
				body: 'access token generated successfully',
				time: new Date(),
			});
			return {
				errors: [],
				messages: messages,
				requesting: false,
				successful: true,
				unauthorized: false,
			};

		// Append the error returned from our api
		// set the success and requesting flags to false
		case LOGIN_ERROR:
			const errors = newState.errors;
			errors.push({
				body: action.error.toString(),
				time: new Date(),
			});
			return {
				errors: errors,
				messages: [],
				requesting: false,
				successful: false,
				unauthorized: action.error.code && action.error.code === "SUB_USER_NO_ACCESS",
			};

		default:
			return state;
	}
};

export default reducer;
