import {
	DELETE_KEY_INFO,
	RECEIVE_MENU,
	DELETE_MENU,
	PATCH_MENU,
	CREATE_MENU,
	UPDATE_MENU,
	FETCH_MENU,
	BIND_MENU,
	UNTIE_MENU,
	EDIT_MENU,
} from '../constants/menu';

import findIndex from 'lodash/findIndex';
import _forEach from 'lodash/forEach';
const initialState = [];

const reducer = function menuReducer(state = initialState, action) {
	const newState = [...state];
	switch (action.type) {
		case RECEIVE_MENU:
			if (action.response.menuEmpty || !action.response.result) {
				return newState;
			}
			const menu = action.response.result;
			return menu;

		case FETCH_MENU:
			newState.forEach(menu => {
				if (menu.id === action.id_menu) {
					menu.info = action.response;
				}
			});
			return newState;
		case DELETE_MENU:
			const { ids } = action;

			let newStateWithoutDeleted = newState.filter(menu => {
				if (ids.includes(menu.id)) {
					return false;
				}
				return true;
			});
			return newStateWithoutDeleted;

		case PATCH_MENU:
			let { idsRestored } = action;
			_forEach(newState, menu => {
				if (menu.id === idsRestored[0]) {
					menu.removed = 0;
				}
				return menu;
			});
			return newState;

		case CREATE_MENU:
			const { newMenu } = action;
			newMenu[0].nbProduct = 0;
			newMenu[0].nbMenu = 0;
			newMenu[0].nbCategory = 0;
			newMenu[0].removed = 0;
			newState.push(newMenu[0]);

			return newState;

		case UPDATE_MENU:
			const { updatedMenu } = action;
			_forEach(newState, (menu, key) => {
				const elementToUpdateIndex = findIndex(
					updatedMenu,
					m => m.id === menu.id
				);

				if (elementToUpdateIndex > -1)
					newState[key] = updatedMenu[elementToUpdateIndex];
				return menu;
			});
			return newState;
		case DELETE_KEY_INFO:
			const { idMenu } = action;
			newState.map(menu => {
				if (menu.id === idMenu && menu.hasOwnProperty('info')) {
					delete menu.info;
				}
				return menu;
			});
			return newState;
		case BIND_MENU:
			const { bindedChildren } = action;
			return newState;
		case UNTIE_MENU:
			const { untiedChildren } = action;
			break;
		case EDIT_MENU:
			const { editedMenu } = action;
			return newState;
		default:
			return state;
	}
};

export default reducer;
