import { call, take, put, select, all } from 'redux-saga/effects';
import {
	SELECT_ZONE,
	SELECT_COMPANY,
	SELECT_MASTER_GLOBAL,
} from '../constants/zone';

import { fetchAll } from './login';
import { addNotification } from '../actions/notification.action';
import { TAGS } from '../constants/notification';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { UPDATE_SESSION_ID } from '../constants/variableServer';
import { browserHistory } from 'react-router';
import {
	getSelectedMenu,
	getVariableServer,
} from '../selectors/global.selector';

// test
export function* selectZone() {
	while (true) {
		const { id_zone, name } = yield take(SELECT_ZONE);
		window.sessionStorage.removeItem('master_mono_child');
		window.sessionStorage.setItem('id_zone', id_zone);
		yield call(synchroDateAndMenu);
		if (window.location.pathname == '/deployRecap') {
			browserHistory.push('/');
		}
		yield call(fetchAll);
		yield put(
			addNotification({
				tags: [TAGS.SUCCESS],
				text: I18n.t('zone.zone_loaded', { name }),
				date: moment().format('DD/MM/YYYY HH:mm'),
				viewed: false,
				openable: true,
			})
		);
	}
}

export function* selectCompany() {
	while (true) {
		const { id_company, name } = yield take(SELECT_COMPANY);
		if (id_company) {
			window.sessionStorage.removeItem('id_zone');
			window.sessionStorage.setItem('master_mono_child', id_company);
			yield put({
				type: UPDATE_SESSION_ID,
				master_mono_use: false,
			});
			yield take('REQUEST_UPDATE_SESSION_ID_FINISHED');
			yield call(synchroDateAndMenu);

			if (window.location.pathname == '/deployRecap') {
				browserHistory.push('/');
			}
			yield call(fetchAll);
			yield put(
				addNotification({
					tags: [TAGS.SUCCESS],
					text: I18n.t('zone.company_loaded', { name }),
					date: moment().format('DD/MM/YYYY HH:mm'),
					viewed: false,
					openable: true,
				})
			);
		}
	}
}

export function* selectMasterGlobal() {
	while (true) {
		yield take(SELECT_MASTER_GLOBAL);
		window.sessionStorage.removeItem('master_mono_child');
		window.sessionStorage.removeItem('id_zone');
		yield call(synchroDateAndMenu);
		if (window.location.pathname == '/deployRecap') {
			browserHistory.push('/');
		}
		yield call(fetchAll);
		yield put(
			addNotification({
				tags: [TAGS.SUCCESS],
				text: I18n.t('zone.master_loaded'),
				date: moment().format('DD/MM/YYYY HH:mm'),
				viewed: false,
				openable: true,
			})
		);
	}
}

function* synchroDateAndMenu() {
	const { entity } = yield select();
	let id_menu = getSelectedMenu(entity.variableServer);
	let date = getVariableServer(entity.variableServer, 'selected_date');

	// MAJ selected date du fils
	yield put({ type: 'PRE_REQUEST_SET_SELECTED_DATE', date });

	// MAJ selected menu du fils
	yield put({
		type: 'PRE_REQUEST_SELECT_MENU',
		body: { id: id_menu },
	});
	yield all([
		take('REQUEST_SET_SELECTED_DATE_FINISHED'),
		take('REQUESTS_SELECT_MENU_FINISHED'),
	]);
}
