import React from 'react';
import { styles } from './styles';
import { Translate } from 'react-redux-i18n';

const styleInterac = {
	buttonNext: {
		display: 'none',
	},
	buttonSkip: {
		display: 'none',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const stylesForTooltipBody = {
	buttonBack: {
		display: 'none',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

const styleBis = {
	buttonClose: {
		fontSize: '16px',
	},
	buttonSkip: {
		fontSize: '16px',
	},
	buttonBack: {
		fontSize: '16px',
	},
	buttonNext: {
		fontSize: '16px',
	},
	options: {
		zIndex: 10000,
		primaryColor: '#000',
	},
};

export const stepsPriceCategory = [
	{
		content: <Translate value="tutorial.priceCategory.step1.content" />,
		placement: 'center',
		disableBeacon: true,
		styles: styleBis,
		locale: {
			skip: <Translate value="tutorial.noDemo" />,
			close: <Translate value="tutorial.yesDemo" />,
		},
		target: 'body',
		title: <Translate value="tutorial.priceCategory.step1.title" />,
	},
	{
		content: <Translate value="tutorial.priceCategory.step2.content" />,
		placement: 'bottom',
		styles: styleInterac,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		spotlightClicks: true,
		target: '.button_add_price_category',
		title: <Translate value="tutorial.priceCategory.step2.title" />,
	},
	{
		content: <Translate value="tutorial.priceCategory.step3.content" />,
		placement: 'center',
		disableBeacon: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: stylesForTooltipBody,
		target: 'body',
		title: <Translate value="tutorial.priceCategory.step3.title" />,
	},
	{
		content: <Translate value="tutorial.priceCategory.step4.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.input_name_price_category',
		title: <Translate value="tutorial.priceCategory.step4.title" />,
	},
	{
		content: <Translate value="tutorial.priceCategory.step5.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.short_name_title_price_category',
		title: <Translate value="tutorial.priceCategory.step5.title" />,
	},
	{
		content: <Translate value="tutorial.priceCategory.step6.content" />,
		placement: 'bottom',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.display_short_name_price_category',
		title: <Translate value="tutorial.priceCategory.step6.title" />,
	},
	{
		content: <Translate value="tutorial.priceCategory.step7.content" />,
		placement: 'top',
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		spotlightClicks: true,
		target: '.time_price_category',
		title: <Translate value="tutorial.priceCategory.step7.title" />,
	},
	{
		content: <Translate value="tutorial.priceCategory.step8.content" />,
		placement: 'top',
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		spotlightClicks: true,
		target: '.days_product_category',
		title: <Translate value="tutorial.priceCategory.step8.title" />,
	},
	{
		content: <Translate value="tutorial.priceCategory.step9.content" />,
		placement: 'left',
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		spotlightClicks: true,
		target: '.floors_price_category',
		title: <Translate value="tutorial.priceCategory.step9.title" />,
	},
	{
		content: <Translate value="tutorial.priceCategory.step10.content" />,
		placement: 'left',
		spotlightClicks: true,
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleInterac,
		target: '.save_button_price_category',
		title: <Translate value="tutorial.priceCategory.step10.title" />,
	},
	{
		content: <Translate value="tutorial.priceCategory.step11.content" />,
		placement: 'bottom',
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.stylizedList > div',
		title: <Translate value="tutorial.priceCategory.step11.title" />,
	},
	{
		content: <Translate value="tutorial.priceCategory.step12.content" />,
		placement: 'bottom',
		locale: {
			skip: <Translate value="tutorial.leftDemo" />,
			close: <Translate value="tutorial.nextStep" />,
		},
		styles: styleBis,
		target: '.stylizedList > div > div > div:nth-child(5) > button',
		title: <Translate value="tutorial.priceCategory.step12.title" />,
	},
	{
		content: <Translate value="tutorial.priceCategory.step13.content" />,
		placement: 'bottom',
		styles: styleInterac,
		spotlightClicks: true,
		target: '.stylizedList > div > div',
		title: <Translate value="tutorial.priceCategory.step13.title" />,
	},
	{
		content: <Translate value="tutorial.priceCategory.step14.content" />,
		placement: 'left',
		locale: {
			close: <Translate value="tutorial.priceCategory.endDemoPriceCategory" />,
		},
		styles: styleBis,
		target: '.fastConfigPriceCategory',
		title: <Translate value="tutorial.priceCategory.step14.title" />,
	},
];
