/**
 * get access for a specified entity
 * @param {Object} accesses object from access reducer
 * @param {("category" | "product" | "productMenu" | "categorySupplement" | "supplement" | "priceCategory" | "taxRate" | "exclusion")} entity
 */
export const getAccess = (accesses, entity) => {
	if (accesses.current.hasOwnProperty(entity)) {
		return accesses.current[entity];
	} else {
		return { create: false, read: false, update: false, delete: false };
	}
};

/**
 * return all entities access
 * @param {Object} accesses object from access reducer
 */
export const getEntitiesAccess = accesses => {
	if (accesses && accesses.current) {
		return Object.keys(accesses.current).reduce((acc, entity) => {
			acc[entity] = accesses.current[entity];
			return acc;
		}, {});
	} else {
		return { create: false, read: false, update: false, delete: false };
	}
};
