import { call, take, put } from 'redux-saga/effects';
import { requestHandler } from './fetcher.saga';
import { fetchAll } from './login';
import { REFRESH_PROVIDER } from '../constants/synchro';

export function* refreshProvider() {
	while (true) {
		yield take(REFRESH_PROVIDER);
		const { result } = yield call(
			requestHandler,
			'synchro',
			'POST',
			true,
			{},
			'/provider'
		);
		if (result && result.result && result.result.success) {
			yield call(fetchAll);
		}
	}
}
