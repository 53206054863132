export const RECEIVE_SUPPLEMENT = 'RECEIVE_SUPPLEMENT';
export const PRE_DELETE_SUPPLEMENT = 'PRE_DELETE_SUPPLEMENT';
export const DELETE_SUPPLEMENT = 'DELETE_SUPPLEMENT';
export const PRE_PATCH_SUPPLEMENT = 'PRE_PATCH_SUPPLEMENT';
export const PATCH_SUPPLEMENT = 'PATCH_SUPPLEMENT';
export const PRE_CREATE_SUPPLEMENT = 'PRE_CREATE_SUPPLEMENT';
export const CREATE_SUPPLEMENT = 'CREATE_SUPPLEMENT';
export const PRE_UPDATE_SUPPLEMENT = 'PRE_UPDATE_SUPPLEMENT';
export const UPDATE_SUPPLEMENT = 'UPDATE_SUPPLEMENT';
export const REFRESH_SUPPLEMENT_LIST = 'REFRESH_SUPPLEMENT_LIST';
export const PRE_REWEIGHT_SUPPLEMENT = 'PRE_REWEIGHT_SUPPLEMENT';
export const REWEIGHT_SUPPLEMENT = 'REWEIGHT_SUPPLEMENT';
export const UPDATE_SUPPLEMENT_LIST = 'UPDATE_SUPPLEMENT_LIST';
