import { buffers } from 'redux-saga';
import { call, put, take, actionChannel } from 'redux-saga/effects';
import { requestHandler } from './fetcher.saga';
import { getMenuSelected } from './login';
import {
	CREATE_TAX,
	PRE_CREATE_TAX,
	PRE_PATCH_TAX,
	PATCH_TAX,
	PRE_UPDATE_TAX,
	UPDATE_TAX,
	PRE_DELETE_TAX,
	DELETE_TAX,
	PRE_REWEIGHT_TAX,
	REWEIGHT_TAX,
} from '../constants/tax';

const ENTITY = 'tax';
const DELETE_METHOD = 'DELETE';
const POST_METHOD = 'POST';
const GET_METHOD = 'GET';
const PUT_METHOD = 'PUT';
const PATCH_METHOD = 'PATCH';

const PRE_REQUEST_UPDATE_TAX = 'PRE_REQUEST_UPDATE_TAX';
const PRE_REQUEST_PATCH_TAX = 'PRE_REQUEST_PATCH_TAX';
const PRE_REQUEST_CREATE_TAX = 'PRE_REQUEST_CREATE_TAX';
const PRE_REQUEST_DELETE_TAX = 'PRE_REQUEST_DELETE_TAX';
const PRE_REQUEST_REWEIGHT_TAX = 'PRE_REQUEST_REWEIGHT_TAX';

const REQUESTS_UPDATE_TAX_FINISHED = 'REQUESTS_UPDATE_TAX_FINISHED';
const REQUESTS_CREATE_TAX_FINISHED = 'REQUESTS_CREATE_TAX_FINISHED';
const REQUESTS_PATCH_TAX_FINISHED = 'REQUESTS_PATCH_TAX_FINISHED';
const REQUESTS_DELETE_TAX_FINISHED = 'REQUESTS_DELETE_TAX_FINISHED';
const REQUESTS_REWEIGHT_TAX_FINISHED = 'REQUESTS_REWEIGHT_TAX_FINISHED';

////////////
// DELETE //
////////////
export function* preDeleteTax() {
	while (true) {
		const action = yield take(PRE_DELETE_TAX);
		const { ids, deletedTax, id_menu } = action;
		let length = ids.length;
		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_DELETE_TAX,
				body: { id: ids[i], deletedTax: deletedTax, id_menu: id_menu },
				index: i,
				totalLength: length,
			});
		}
		const { taxes } = yield take(REQUESTS_DELETE_TAX_FINISHED);
		yield put({ type: DELETE_TAX, response: taxes });
	}
}

export function* watchDeleteTax() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_DELETE_TAX,
		buffers.expanding()
	);

	while (true) {
		const action = yield take(requestChan);
		yield call(requestHandler, ENTITY, DELETE_METHOD, true, action.body);
		if (action.index === action.totalLength - 1) {
			const taxes = yield call(requestHandler, ENTITY, GET_METHOD, true);
			yield put({ type: REQUESTS_DELETE_TAX_FINISHED, taxes: taxes.result });
		}
	}
}

////////////
// CREATE //
////////////
export function* preCreateTax() {
	while (true) {
		const action = yield take(PRE_CREATE_TAX);
		const { newTax } = action;
		const tax = newTax;
		let length = tax.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_CREATE_TAX,
				body: { tax: tax[i] },
				index: i,
				totalLength: length,
			});
		}
		let { taxes } = yield take(REQUESTS_CREATE_TAX_FINISHED);
		yield put({ type: CREATE_TAX, response: taxes.result });
	}
}

export function* watchCreateTax() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_CREATE_TAX,
		buffers.expanding()
	);
	let createdTax = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			POST_METHOD,
			true,
			action.body.tax
		);
		let newTax = { ...action.body.tax };
		newTax.id = result.result.result;

		createdTax.push(newTax);

		if (action.index === action.totalLength - 1) {
			const taxes = yield call(requestHandler, ENTITY, GET_METHOD, true);
			yield put({
				type: REQUESTS_CREATE_TAX_FINISHED,
				createdTax: createdTax,
				taxes: taxes,
			});
			createdTax = [];
		}
	}
}

////////////
// UPDATE //
////////////
export function* preUpdateTax() {
	while (true) {
		const action = yield take(PRE_UPDATE_TAX);
		const { updatedTax } = action;
		let length = updatedTax.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_UPDATE_TAX,
				body: { tax: updatedTax[i] },
				index: i,
				totalLength: length,
			});
		}
		const { taxes } = yield take(REQUESTS_UPDATE_TAX_FINISHED);
		yield put({
			type: UPDATE_TAX,
			updatedTax: updatedTax,
			response: taxes.result,
		});
	}
}

export function* watchUpdateTax() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_UPDATE_TAX,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);

		yield call(requestHandler, ENTITY, PUT_METHOD, true, action.body.tax);

		if (action.index === action.totalLength - 1) {
			const taxes = yield call(requestHandler, ENTITY, GET_METHOD, true);
			yield put({ type: REQUESTS_UPDATE_TAX_FINISHED, taxes: taxes });
		}
	}
}

////////////
// PATCH  //
////////////
export function* prePatchTax() {
	while (true) {
		const action = yield take(PRE_PATCH_TAX);
		const { data } = action;
		let length = data.length;

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_PATCH_TAX,
				body: { patchs: data[i] },
				index: i,
				totalLength: length,
			});
		}

		const { taxes } = yield take(REQUESTS_PATCH_TAX_FINISHED);

		yield put({ type: PATCH_TAX, response: taxes.result });
	}
}

export function* watchPatchTax() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_PATCH_TAX,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChan);
		const id_menu = yield call(getMenuSelected);
		yield call(requestHandler, ENTITY, PATCH_METHOD, true, {
			...action.body.patchs,
			id_menu,
		});

		if (action.index === action.totalLength - 1) {
			const taxes = yield call(requestHandler, ENTITY, GET_METHOD, true);
			yield put({ type: REQUESTS_PATCH_TAX_FINISHED, taxes: taxes });
		}
	}
}

//////////////
// REWEIGHT //
//////////////
export function* preReweightTax() {
	while (true) {
		const action = yield take(PRE_REWEIGHT_TAX);
		const { newValues } = action;
		let length = newValues.length;
		const id_menu = yield call(getMenuSelected);

		for (let i = 0; i < length; i++) {
			yield put({
				type: PRE_REQUEST_REWEIGHT_TAX,
				body: { entity: newValues[i], id_menu },
				index: i,
				totalLength: length,
			});
		}
		const { taxes } = yield take(REQUESTS_REWEIGHT_TAX_FINISHED);

		yield put({ type: REWEIGHT_TAX, response: taxes.result });
	}
}

export function* watchReweightTax() {
	const requestChan = yield actionChannel(
		PRE_REQUEST_REWEIGHT_TAX,
		buffers.expanding()
	);
	let successfullRequest = [];
	while (true) {
		const action = yield take(requestChan);

		const result = yield call(
			requestHandler,
			ENTITY,
			PATCH_METHOD,
			true,
			action.body,
			'/weight'
		);
		if (result.result && result.result.status == 200) {
			successfullRequest.push(action.body);
		}

		if (action.index === action.totalLength - 1) {
			const taxes = yield call(requestHandler, ENTITY, GET_METHOD, true);
			yield put({
				type: REQUESTS_REWEIGHT_TAX_FINISHED,
				successfullRequest: successfullRequest,
				taxes: taxes,
			});
			successfullRequest = [];
		}
	}
}
